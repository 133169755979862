import React, { FC } from 'react';
// package imports
import { SectionOneColumnProps } from '@superhuit/starterpack-blocks/utils/typings';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionAccordion } from '../SectionAccordion/render';
// internal imports
import block from './block.json';
import {
	SliderNews,
	SliderNewsProps,
} from '@/components/molecules/SliderNews/render';
import { isEmpty } from 'lodash';

/**
 * TYPINGS
 */
export type SectionPressSliderProps = SliderNewsProps & SectionOneColumnProps;

/**
 * COMPONENT
 */
export const SectionPressSlider: FC<SectionPressSliderProps> & BlockConfigs = ({
	posts = [],
	...props
}) => {
	return isEmpty(posts) ? null : (
		<SectionAccordion {...props}>
			<SliderNews posts={posts} />
		</SectionAccordion>
	);
};

SectionPressSlider.slug = block.slug;
SectionPressSlider.title = block.title;

import React, { FC, useRef, useLayoutEffect } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
	ChildrenProps,
} from '@superhuit/starterpack-blocks/utils/typings';
// internal
import useScrollTrigger from '@/hooks/useScrollTrigger';
import { getStyles as getDfltStyles } from './styles.css';
import block from './block.json';

export type SubsectionBigTextImagesProps = {
	slug: string;
	introduction?: string;
	firstColIsRight?: boolean;
	getStyles?: (styles: any) => string;
} & ThemeProps &
	ChildrenProps;

/**
 * COMPONENT
 */
export const SubsectionBigTextImages: FC<SubsectionBigTextImagesProps> &
	BlockConfigs = ({
	slug,
	children,
	introduction,
	firstColIsRight = false,
	theme = {},
	getStyles = getDfltStyles,
}) => {
	const { variant, blockTheme } = useThemeBlock(slug, theme);

	const trimmedSlug = slug.split('/');
	const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

	const rootClass = cx(
		'supt-subsection',
		`supt-subsection--${modifierSlug}`,
		{ 'supt-subsection--firstColIsRight': firstColIsRight },
		getStyles(blockTheme)
	);

	const ref = useRef(null);
	const { setAnimationParallax } = useScrollTrigger();

	useLayoutEffect(() => {
		const firstImageBlock = ref.current.querySelector('.supt-figure');

		setAnimationParallax([firstImageBlock], firstImageBlock);
	}, []);

	return (
		<div
			className={rootClass}
			data-block={slug}
			data-variant={variant}
			ref={ref}
		>
			{introduction ? (
				<p
					className="supt-subsection__introduction"
					dangerouslySetInnerHTML={{ __html: introduction }}
				/>
			) : null}
			<div className="supt-subsection__content">{children}</div>
		</div>
	);
};

SubsectionBigTextImages.slug = block.slug;
SubsectionBigTextImages.title = block.title;

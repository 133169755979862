import { mergeDeep } from '@superhuit/starterpack-utils';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionOneColumnThemeControls } from '@superhuit/starterpack-blocks/components/themings'; // Needs to be called from /components folder to work properly..

import block from './block.json';

export const SectionArtistsSliderThemeAttributes = {
	columns: {
		content: {
			desktop: {
				offsetLeft: 0,
				columnLeft: 12,
			},
			tablet: {
				offsetLeft: 0,
				columnLeft: 12,
			},
			mobile: {
				offsetLeft: 0,
				columnLeft: 12,
			},
		},
	},
	title: {
		color: '@tokens.variants.default.contentColor',
		typography: '@tokens.typographies.heading-2',
		textAlignment: 'left',
	},
};

export const SectionArtistsSliderThemeControls: ThemeControlsType = mergeDeep(
	SectionOneColumnThemeControls,
	{
		// What's displayed
		title: block.title,

		// Unique string/id of the block (= the key used in theme.json)
		slug: block.slug,

		// styling attributes available for this block
		// ...the same way we define attributes for a gutenberg block
		attributes: SectionArtistsSliderThemeAttributes,
	}
);

import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';
// styles
import { getStyles } from './styles.css';
import { CardEvent, CardEventProps } from '../CardEvent/render';

/**
 * TYPINGS
 */
export type ListEventsProps = ThemeProps & {
	events?: Array<CardEventProps>;
};

/**
 * COMPONENT
 */
export const ListEvents: FC<ListEventsProps> & BlockConfigs = ({
	events,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx(
		'supt-listCards supt-listEvents',
		getStyles(blockTheme)
	);

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			{events?.map((event, index) => (
				<CardEvent key={index} {...event} index={index} />
			))}
		</div>
	);
};

ListEvents.slug = block.slug;
ListEvents.title = block.title;

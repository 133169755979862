import cx from 'classnames';
import React, { FC, useContext } from 'react';

import {
	BlockConfigs,
	SectionOneColumnProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { SectionAccordion } from '../SectionAccordion/render';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { getStyles as getDfltStyles } from '@superhuit/starterpack-blocks/components/organisms/Sections/SectionOneColumn/styles.css';

import block from './block.json';
import { getStyles } from './styles.css';
import { CardDateTime } from '@/components/renders';

/**
 * TYPINGS
 */
type SectionDatesTimesProps = {
	rows: Array<any>;
	isOpened?: boolean;
} & SectionOneColumnProps;

/**
 * COMPONENT
 */
export const SectionDatesTimes: FC<SectionDatesTimesProps> & BlockConfigs = ({
	rows = [],
	...props
}) => {
	const { variant, blockTheme } = useThemeBlock(
		block.slug,
		props.theme,
		props.variant
	);
	const { __t, locale } = useContext(LocaleContext);

	const rootClass = cx(
		'supt-section--colored',
		getDfltStyles(blockTheme),
		getStyles(blockTheme)
	);

	return (
		!!rows.length && (
			<SectionAccordion
				{...props}
				className={rootClass}
				isOpened={props.isOpened || rows.length < 4}
				bottomTrigger={true}
			>
				{rows.map((row, i) => {
					const dte = new Date(row.date.replace(/-/g, '/')); // `.replace(/-/g, '/')` is to fix safari which fails parsing xxxx-xx-xx date format
					return (
						<CardDateTime
							date={dte}
							key={i}
							weekdayDate={dte.toLocaleDateString(locale, {
								weekday: 'short',
								day: '2-digit',
								month: '2-digit',
							})}
							time={dte.toLocaleTimeString(locale, {
								hour: 'numeric',
								minute: '2-digit',
							})}
							location={row.location}
							availability={row.availability}
							availabilityDate={row.availability_date}
							state={row.state}
							stateLabel={__t(`state-${row.state}`, '')}
							ticketLink={row.ticket_link}
							ticketButtonText={row.ticket_button_text}
							publics={row.publics}
						/>
					);
				})}
			</SectionAccordion>
		)
	);
};

SectionDatesTimes.slug = block.slug;
SectionDatesTimes.title = block.title;

import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { resetFirstLastChildMargins } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	breakpoints,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-section--section-partners': cssObj({
			'.supt-section__headline': resetFirstLastChildMargins,
			'.supt-section__title': {
				textAlign: 'center !important' as 'center',
				[`@media (min-width: ${breakpoints.medium})`]: {
					textAlign: 'left !important' as 'left',
				},
			},

			'.supt-listCards--list-logos > .supt-listCards__list': cssObj(
				fluidValues('columnGap', 57, 97),
				fluidValues('rowGap', 50, 80),
				{
					display: 'grid',
					gridTemplateColumns: 'repeat(2, auto)',
					justifyItems: 'center',
					alignItems: 'center',

					[`@media (min-width: ${breakpoints.mobile})`]: {
						gridTemplateColumns: 'repeat(3, auto)',
					},
					[`@media (min-width: ${breakpoints.tablet})`]: {
						gridTemplateColumns: 'repeat(4, auto)',
					},
					[`@media (min-width: ${breakpoints.laptop})`]: {
						gridTemplateColumns: 'repeat(6, auto)',
					},
					[`@media (min-width: ${breakpoints.desktop})`]: {
						gridTemplateColumns: 'repeat(7, auto)',
					},
				}
			),

			'.supt-cardLogo': cssObj(
				fluidValues('maxWidth', 94, 148),
				// fluidValues('maxHeight', 39, 49),
				{
					width: 'auto !important',
				}
			),

			'.supt-section__linkWrapper': cssObj(resetFirstLastChildMargins, {
				gridColumn: '1/13',
				gridRow: 3,
				justifySelf: 'center',
				marginTop: 60,

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '9/13',
					gridRow: 'auto',
					justifySelf: 'end',
					marginTop: 0,
				},
			}),
		}),
	});

export { getCustomStyles };

export const calendarPageThemeAttributes = {
	title: {
		color: '@tokens.variants.default.titleColor',
		typography: '@tokens.typographies.heading-1',
		textAlignment: 'left',
	},
};

export const CalendarPageThemeControls = {
	// name of the block
	title: 'Calendar Page',

	// slug of the block (= the key used in theme.json)
	slug: 'calendarPage',

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: calendarPageThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return <></>;
	},
};

import React, { FC, useContext } from 'react';
import cx from 'classnames';
import { CardLinkProps } from '@superhuit/starterpack-blocks/helpers/CardControls/typings';
import {
	ThemeProps,
	BlockConfigs,
	ImageProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { Link } from '@/components/atoms/Link/render';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Tag, TagProps } from '@/components/atoms/Tag/render';
import { Image } from '@superhuit/starterpack-blocks/renders';
import block from './block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type CardProductionType = {
	id?: number;
	image: ImageProps;
	artist: string;
	title: string;
	creation: string;
	coming?: string;
	state?: 'canceled' | 'postponed';
	link?: CardLinkProps;
	categories?: Array<TagProps>;
};
export type CardProductionProps = CardProductionType &
	ThemeProps & {
		onMouseEnter?: Function;
		onMouseLeave?: Function;
		className?: string;
	};

/**
 * COMPONENT
 */
export const CardProduction: FC<CardProductionProps> & BlockConfigs = ({
	id,
	image,
	artist,
	title,
	creation,
	coming,
	categories,
	state,
	link = {},
	theme = {},
	onMouseEnter,
	onMouseLeave,
	className,
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const htmlProps = {
		'data-variant': variant,
	};

	const { __t } = useContext(LocaleContext);

	const rootClass = cx(
		'supt-card supt-cardProduction',
		getStyles(blockTheme),
		/* @ts-ignore */
		link?.href && 'supt-withLink',
		state ? `supt-cardProduction--${state}` : null,
		className
	);

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			{...htmlProps}
			onMouseEnter={() => onMouseEnter && onMouseEnter(id)}
			onMouseLeave={() => onMouseLeave && onMouseLeave()}
		>
			{/* @ts-ignore */}
			{link?.href ? (
				<Link
					/* @ts-ignore */
					href={link?.href}
					{...(link.opensInNewTab && {
						target: '_blank',
						rel: 'noopener noreferrer',
					})}
					className="supt-cardProduction__link supt-cardProduction__media"
				>
					<div className="supt-cardProduction__image">
						<Image
							sizes="(max-width: 599px) 90vw, (max-width: 1023px) 45vw, 375px"
							{...image}
							layout="fill"
						/>
					</div>
				</Link>
			) : (
				<div className="supt-cardProduction__media supt-cardProduction__image">
					<Image
						sizes="(max-width: 599px) 90vw, (max-width: 1023px) 45vw, 375px"
						{...image}
						layout="fill"
					/>
				</div>
			)}
			<div className="supt-cardProduction__wrapperContent">
				<div className="supt-cardProduction__content">
					<div className="supt-cardProduction__categories">
						{categories.map(({ title, type }, i) => (
							<div
								key={i}
								className="supt-cardProduction__category"
							>
								<Tag title={title} type={type} hasBorder></Tag>
							</div>
						))}
					</div>
					<p
						className="supt-cardProduction__artist"
						dangerouslySetInnerHTML={{ __html: artist }}
					/>
					<h4
						className="supt-cardProduction__title"
						dangerouslySetInnerHTML={{ __html: title }}
					/>
					<div className="supt-cardProduction__info">
						<p
							className="supt-cardProduction__creation"
							dangerouslySetInnerHTML={{ __html: creation }}
						/>
						{coming ? (
							<p
								className="supt-cardProduction__coming"
								dangerouslySetInnerHTML={{ __html: coming }}
							/>
						) : null}
					</div>
					{state ? (
						<div className="supt-cardProduction__state">
							<Tag
								title={__t(`state-${state}`, '')}
								isSquared
								type="alert"
							/>
						</div>
					) : null}
				</div>
				<div className="supt-cardProduction__arrow">
					<svg
						width="13"
						height="13"
						xmlns="http://www.w3.org/2000/svg"
						className="supt-cardProduction__arrow__icon"
						aria-hidden="true"
						focusable="false"
					>
						<use href="#arrow-right" />
					</svg>
				</div>
			</div>
		</div>
	);
};

CardProduction.slug = block.slug;
CardProduction.title = block.title;

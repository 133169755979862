import React, {
	FC,
	useRef,
	useState,
	useEffect,
	useContext,
	useMemo,
} from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import Helmet from 'react-helmet';

import { Image } from '@superhuit/starterpack-blocks/components/molecules/Image/render';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { DataContext } from '@superhuit/starterpack-context';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { TranslationType } from '@superhuit/starterpack-blocks/components/molecules/LangSwitcher/render';
import {
	ThemeProps,
	BlockConfigs,
} from '@superhuit/starterpack-blocks/utils/typings';
import { Link } from '@/components/atoms/Link/render';
import { Alert } from '@/components/renders';
import { NavMobile } from './components/mobile/nav-mobile';
import { PrimaryNav } from './components/desktop/primary-nav';
import { SecondaryNav } from './components/desktop/secondary-nav';
import configs from '@/configs.json';
import block from './block.json';
import { getStyles } from './styles.css';
import { renderSocialIcon } from './helpers/render-social-icon';
import { AlertProps } from '@/components/molecules/Alert/render';
import { useShopCookies } from './useShopCookies';

const NAV_MOBILE_BREAKPOINT = 600;

export type MainNavProps = {
	menu: {
		primary: { items: [] };
		secondary: { items: [] };
		social: { items: [] };
	};
	uri?: string;
	isNavOpen?: boolean;
	openNav?: () => any;
	closeNav?: () => any;
	translations: Array<TranslationType>;
	alert?: AlertProps;
} & ThemeProps;

export const MainNav: FC<MainNavProps> & BlockConfigs = ({
	menu,
	uri = '/',
	isNavOpen,
	openNav,
	closeNav,
	translations,
	alert = {},
	theme = {},
}) => {
	const router = useRouter();
	// refs
	const siteNavigationRef = useRef(null);
	const siteNavigationInnerRef = useRef(null);
	const logoRef = useRef(null);
	const primaryNavRef = useRef(null);
	const secondaryNavRef = useRef(null);
	const navMobileRef = useRef(null);
	const toggleRef = useRef(null);

	// state
	const [isNavSmall, setIsNavSmall] = useState(true);
	const [_isNavOpen, _setIsNavOpen] = useState(false);
	const [isPrimarySubmenuOpen, setIsPrimarySubmenuOpen] = useState(false);
	const [isSecondarySubmenuOpen, setIsSecondarySubmenuOpen] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const [alertDismissed, setAlertDismissed] = useState(false);
	const [subMenuStyles, setSubMenuStyles] = useState({
		top: '0',
		left: '0',
	});

	// context
	const { logo: logoData, settings } = useContext(DataContext);
	const { __t, locale } = useContext(LocaleContext);

	const { blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx(
		'supt-mainNav',
		isNavOpen && 'supt-mainNav--isNavOpen',
		isNavSmall && 'supt-mainNav--isNavSmall',
		isPrimarySubmenuOpen && 'supt-mainNav--isPrimarySubmenuOpen',
		isSecondarySubmenuOpen && 'supt-mainNav--isSecondarySubmenuOpen',
		isReady && 'supt-mainNav--isReady',
		/* @ts-ignore */
		alert?.enabled && 'supt-mainNav--alert',
		alertDismissed && 'supt-mainNav--alert-hidden',
		getStyles(blockTheme)
	);

	function handleNavFit() {
		if (!window || !primaryNavRef.current) return;

		const navInner = siteNavigationInnerRef.current;
		const logo = logoRef.current;
		const nav = primaryNavRef.current.desktopRef();

		const navInnerStyle = window.getComputedStyle(navInner, null);

		const paddingLeft = parseInt(
			navInnerStyle.getPropertyValue('padding-left')
		);
		const paddingRight = parseInt(
			navInnerStyle.getPropertyValue('padding-right')
		);

		const xPadding = paddingLeft + paddingRight + 16 + 16; // + 16 * 2 as it's the container margins
		const navigationWidth = navInner.offsetWidth - xPadding;
		const contentWidth = logo.offsetWidth + nav.offsetWidth;
		const contentFits = navigationWidth > contentWidth;

		setIsNavSmall(() =>
			window.innerWidth < NAV_MOBILE_BREAKPOINT ? true : !contentFits
		);

		const { left } = nav.getBoundingClientRect();
		const { bottom } = siteNavigationRef.current.getBoundingClientRect();
		setSubMenuStyles({ top: bottom, left });

		// if desktop closes mobile nav
		if (contentFits && isNavOpen) {
			setTimeout(() => {
				closeNav();
			}, 250);
		}

		// if mobile calculate height of mobile nav
		if (isNavSmall) {
			const navMobileHeight =
				window.innerHeight; /*- navInner.offsetHeight*/
			navMobileRef.current.style.height = navMobileHeight + 'px';
		}
	}

	const hashListener = () => {
		closeNav();
	};

	const handleScrollPaddingTop = () => {
		if (siteNavigationRef.current)
			document.documentElement.style.scrollPaddingTop = `${siteNavigationRef.current.offsetHeight}px`;
	};

	/**
	 * Set is ready after few milliseconds to fix the blink caused by the animations
	 */
	useEffect(() => {
		setTimeout(() => {
			setIsReady(true);
		}, 300);
	}, []);

	useEffect(() => {
		// if parent doesn't control state, use internal state
		if (typeof isNavOpen === 'undefined') isNavOpen = _isNavOpen;
		if (typeof openNav === 'undefined')
			openNav = () => _setIsNavOpen(() => true);
		if (typeof closeNav === 'undefined')
			closeNav = () => _setIsNavOpen(() => false);
		// timeoutId for debounce mechanism
		// let timeoutId = null;
		setTimeout(() => {
			handleNavFit();
		}, 10);

		// wait to make sure is getting the right size
		setTimeout(() => {
			handleScrollPaddingTop();
		}, 100);

		const resizeListener = () => {
			// prevent execution of previous setTimeout
			// clearTimeout(timeoutId);
			// change setIsNavSmall from the state object after 150 milliseconds
			// timeoutId = setTimeout(() => setWidth(getWidth()), 150);
			handleScrollPaddingTop();

			handleNavFit();
		};

		// TODO: Improve by using a custom hook (useViewport - adapt from Superhuit or AP Tones)
		// set resize listener
		window.addEventListener('resize', resizeListener);
		router.events.on('hashChangeStart', hashListener);

		// clean up function
		return () => {
			window.removeEventListener('resize', resizeListener);
			router.events.off('hashChangeStart', hashListener);
		};
	}, []);

	useEffect(() => {
		// if mobile calculate height of mobile nav
		if (isNavSmall) {
			const navMobileHeight =
				window.innerHeight; /*- navInner.offsetHeight*/
			if (navMobileRef.current)
				navMobileRef.current.style.height = navMobileHeight + 'px';

			// if mobile closes desktop submenu
			if (isPrimarySubmenuOpen) {
				primaryNavRef.current.close();
			} else if (isSecondarySubmenuOpen) {
				secondaryNavRef.current.close();
			}
		}
	}, [isNavSmall]);

	// block document scroll when menu or submenu is open
	useEffect(() => {
		if ((isNavSmall && isNavOpen) || isPrimarySubmenuOpen) {
			document.documentElement.style.overflow = 'hidden';
		} else {
			document.documentElement.style.overflow = '';
		}

		// cleanup
		return () => {
			document.documentElement.style.overflow = '';
		};
	}, [isNavSmall, isNavOpen, isPrimarySubmenuOpen]);

	// close when pressing ESC
	useEffect(() => {
		const handler = (e) => {
			if (['Escape', 'Esc'].includes(e?.key)) {
				// TODO !!
				/*if (isNavSmall && isNavOpen) _closeMobileNav();
				else*/ if (!isNavSmall && isPrimarySubmenuOpen) primaryNavRef.current.close();
			} else if (['Backspace', 'Back'].includes(e?.key))
				if (!isNavSmall && isPrimarySubmenuOpen)
					primaryNavRef.current.close();
			return;
		};
		document.body.addEventListener('keydown', handler);

		// clean up function
		return () => {
			document.body.removeEventListener('keydown', handler);
		};
	}, [isNavOpen, isPrimarySubmenuOpen, isNavSmall]);

	const shopCart = useShopCookies();

	const handleBackdropClick = () => {
		if (!isNavSmall) {
			if (isPrimarySubmenuOpen) {
				primaryNavRef.current.close();
			}

			if (isSecondarySubmenuOpen) {
				secondaryNavRef.current.close();
			}
		}
	};

	const htmlClasses = useMemo(() => {
		const { htmlAttributes } = Helmet.peek();
		const initialClasses = String(htmlAttributes.class)
			.split(' ')
			.reduce((c, i) => ({ ...c, [i]: true }), {});

		return cx({ ...initialClasses, 'is-nav-small': isNavSmall });
	}, [isNavSmall]);

	return (
		<>
			<Helmet
				htmlAttributes={{
					class: htmlClasses,
				}}
			/>
			<div
				ref={siteNavigationRef}
				className={rootClass}
				data-block={block.slug}
			>
				{/* @ts-ignore */}
				{alert?.enabled ? (
					// @ts-ignore
					<Alert
						{...alert}
						alertDismissed={alertDismissed}
						setAlertDismissed={setAlertDismissed}
					/>
				) : null}
				<div
					ref={siteNavigationInnerRef}
					className="supt-mainNav__inner"
				>
					<div className="supt-mainNav__top">
						<div className="supt-mainNav__top__inner">
							<SecondaryNav
								ref={secondaryNavRef}
								primaryNavRef={primaryNavRef}
								items={menu.secondary.items}
								uri={uri}
								translations={translations}
								setIsSecondarySubmenuOpen={
									setIsSecondarySubmenuOpen
								}
								isPrimarySubmenuOpen={isPrimarySubmenuOpen}
							/>
						</div>
					</div>
					<div className="supt-mainNav__bottom">
						<div className="supt-mainNav__bottom__inner">
							<Link
								href={configs.isMultilang ? `/${locale}/` : '/'}
								className="supt-mainNav__logo"
								ref={logoRef}
								aria-label={settings?.title}
							>
								{logoData ? (
									<Image
										src={logoData.relativeUrl}
										width={logoData.mediaDetails.width}
										height={logoData.mediaDetails.height}
										priority
										className="supt-mainNav__logoImg"
										alt={settings?.title}
									/>
								) : null}
							</Link>
							{menu?.primary?.items?.length ? (
								<>
									<PrimaryNav
										ref={primaryNavRef}
										items={menu.primary.items}
										uri={uri}
										setIsPrimarySubmenuOpen={
											setIsPrimarySubmenuOpen
										}
										subMenuStyles={subMenuStyles}
									/>
									<Link
										href="https://billetterie.vidy.ch/"
										className="cart"
									>
										<svg
											viewBox="0 0 16 16"
											width="26"
											height="26"
											xmlns="http://www.w3.org/2000/svg"
										>
											<use href="#cart-black" />
										</svg>
										{shopCart.qty > 0 ? (
											<span className="cart__qty">
												{shopCart.qty}
											</span>
										) : null}
									</Link>
									<button
										ref={toggleRef}
										className="supt-mainNav__toggle"
										aria-label="Toggle menu"
										aria-expanded={
											isNavSmall && isNavOpen
												? 'true'
												: 'false'
										}
										aria-controls="menu_nav"
										onClick={() => {
											isNavOpen ? closeNav() : openNav();
										}}
									>
										<div
											className="supt-mainNav__toggleIcon"
											aria-hidden="true"
										>
											<div className="supt-mainNav__toggleBar"></div>
										</div>
									</button>
								</>
							) : null}
						</div>
					</div>
				</div>
				{menu?.primary?.items?.length ? (
					<NavMobile
						uri={uri}
						primary={menu?.primary}
						secondary={menu?.secondary}
						social={menu?.social}
						translations={translations}
						isNavOpen={isNavOpen}
						ref={navMobileRef}
					/>
				) : null}

				<div className="supt-mainNav__background" style={subMenuStyles}>
					<div
						className="supt-mainNav__background__inner"
						style={{ left: `${subMenuStyles.left}px` }}
					>
						<button
							className="supt-mainNav__background__close-btn"
							onClick={handleBackdropClick}
						>
							<svg
								width="18"
								height="18"
								xmlns="http://www.w3.org/2000/svg"
							>
								<use href="#close" />
							</svg>
						</button>
						{menu?.social?.items ? (
							<div className="supt-mainNav__socials">
								<p className="supt-mainNav__socials__label">
									{__t(
										'main-nav-socials-label',
										'Suivez-nous'
									)}
									:
								</p>
								<ul className="supt-mainNav__socials__list">
									{menu.social.items.map(
										({ label, path }, i) => (
											<li
												key={i}
												className="supt-mainNav__socials__item"
											>
												<Link
													href={path}
													className="supt-mainNav__socials__link"
												>
													{renderSocialIcon(label)}
												</Link>
											</li>
										)
									)}
								</ul>
							</div>
						) : null}
					</div>
				</div>
				<div
					className="supt-mainNav__backdrop supt-mainNav__backdrop--primary"
					onClick={handleBackdropClick}
				></div>
				<div
					className="supt-mainNav__backdrop supt-mainNav__backdrop--secondary"
					onClick={handleBackdropClick}
				></div>
			</div>
		</>
	);
};

MainNav.slug = block.slug;
MainNav.title = block.title;

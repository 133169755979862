import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';

import block from './block.json';

export const subsectionLeadingArtistsThemeAttributes = {
	title: {
		color: '@tokens.variants.default.titleColor',
		typography: '@tokens.typographies.fresco-s',
		textAlignment: 'center',
	},
};

export const SubsectionLeadingArtistsThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: subsectionLeadingArtistsThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return <></>;
	},
};

import { mergeDeep } from '@superhuit/starterpack-utils';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionOneColumnThemeControls } from '@superhuit/starterpack-blocks/components/themings'; // Needs to be called from /components folder to work properly..

import block from './block.json';

export const sectionCardsShowThemeAttributes = {
	backgroundColor: '@tokens.colors.brand-1-base.value',
	padding: {
		// without control (for Storybook only)
		top: [60, 83],
		bottom: [62, 104],
	},
	title: {
		color: '@tokens.variants.default.titleColor',
		typography: '@tokens.typographies.heading-1',
		textAlignment: 'left',
	},
	columns: {
		headline: {
			desktop: {
				offsetLeft: 0,
				columnLeft: 7,
			},
			tablet: {
				offsetLeft: 0,
				columnLeft: 9,
			},
			mobile: {
				offsetLeft: 0,
				columnLeft: 12,
			},
		},
		content: {
			desktop: {
				offsetLeft: 0,
				columnLeft: 12,
			},
			tablet: {
				offsetLeft: 0,
				columnLeft: 12,
			},
			mobile: {
				offsetLeft: 0,
				columnLeft: 12,
			},
		},
	},
};

export const SectionCardsShowThemeControls: ThemeControlsType = mergeDeep(
	SectionOneColumnThemeControls,
	{
		// What's displayed
		title: block.title,

		// Unique string/id of the block (= the key used in theme.json)
		slug: block.slug,

		// styling attributes available for this block
		// ...the same way we define attributes for a gutenberg block
		attributes: sectionCardsShowThemeAttributes,
	}
);

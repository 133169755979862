import Head from 'next/head';

export default function Meta({ node }) {
	const { seo, siteSEO } = node ?? {}; // remember: node can be undefined! see https://nextjs.org/docs/advanced-features/automatic-static-optimization#how-it-works
	const imageSEO =
		seo?.opengraphImage?.relativeUrl ??
		siteSEO?.openGraph?.defaultImage?.relativeUrl;

	return (
		<Head>
			{seo?.title ? (
				<>
					<title>{seo.title}</title>
					<meta property="og:title" content={seo.title} />
				</>
			) : null}
			{/* Open Graph */}
			{siteSEO?.schema?.siteName ? (
				<meta
					property="og:site_name"
					content={siteSEO.schema.siteName}
				/>
			) : null}
			{seo?.opengraphDescription ?? node?.metaDesc ? (
				<>
					<meta
						name="description"
						content={seo?.opengraphDescription ?? node?.metaDesc}
					/>
					<meta
						property="og:description"
						content={seo?.opengraphDescription ?? node?.metaDesc}
					/>
				</>
			) : null}
			{imageSEO ? <meta property="og:image" content={imageSEO} /> : null}
			{seo?.opengraphUrl ? (
				<meta
					property="og:url"
					content={`${node?.baseUrl}${seo.opengraphUrl}`}
				/>
			) : null}
			{/* Twitter */}
			{seo?.twitterTitle ?? seo?.title ? (
				<meta
					name="twitter:title"
					content={seo?.twitterTitle ?? seo?.title}
				/>
			) : null}
			{seo?.twitterDescription ?? seo?.opengraphDescription ? (
				<meta
					name="twitter:description"
					content={
						seo?.twitterDescription ?? seo?.opengraphDescription
					}
				/>
			) : null}
			{seo?.twitterImage?.relativeUrl ?? imageSEO ? (
				<meta
					name="twitter:image"
					content={seo?.twitterImage?.relativeUrl ?? imageSEO}
				/>
			) : null}
			<meta
				name="twitter:card"
				content={
					siteSEO?.social?.twitter?.cardType || 'summary_large_image'
				}
			/>
			{siteSEO?.social?.twitter?.username ? (
				<meta
					name="twitter:site"
					content={`@${siteSEO.social.twitter.username}`}
				/>
			) : null}
			{/* Misc */}
			{seo?.metaRobotsNoindex ?? seo?.metaRobotsNofollow ? (
				<meta
					name="robots"
					content={[
						seo?.metaRobotsNoindex,
						seo?.metaRobotsNofollow,
					].join(',')}
				/>
			) : null}
			{/* Favicon */}
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1.0"
			/>
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href="/apple-touch-icon.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="32x32"
				href="/favicon-32x32.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="16x16"
				href="/favicon-16x16.png"
			/>
			<link rel="manifest" href="/site.webmanifest" />
			<link
				rel="mask-icon"
				href="/safari-pinned-tab.svg"
				color="#000000"
			/>
			<meta name="msapplication-TileColor" content="#000000" />
			<meta name="theme-color" content="#ffffff" />

			{/* Hreflang */}
			<link
				rel="canonical"
				href={`${node?.baseUrl}${
					node?.uri === '/' && node?.language
						? `/${node?.language.code.toLowerCase()}/`
						: node?.uri
				}`}
			/>
			{/* <link rel="alternate" hrefLang="x-default" href={node?.baseUrl} /> */}
			{node?.translations?.map((t, i) => (
				<link
					rel="alternate"
					key={i}
					hrefLang={t.language?.code.toLowerCase()}
					href={
						node?.baseUrl +
						(t.uri === '/'
							? `/${t.language.code.toLowerCase()}/`
							: t.uri)
					}
				/>
			))}
		</Head>
	);
}

import React, {
	FC,
	useContext,
	forwardRef,
	useState,
	useEffect,
	useRef,
	useImperativeHandle,
} from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';

import { LocaleContext } from '@superhuit/starterpack-i18n';
import { Link } from '@/components/atoms/Link/render';

import { PrimarySubmenu } from './primary-submenu';
import { PrimaryNavProps } from '../typings';

export const PrimaryNav: FC<PrimaryNavProps> = forwardRef(
	({ items, uri, setIsPrimarySubmenuOpen, subMenuStyles = {} }, ref) => {
		const { __t, locale } = useContext(LocaleContext);
		const router = useRouter();

		const [openedSubmenuId, setOpenedSubmenuId] = useState(-1);

		const refEl = useRef(null);
		const submenuRefs = useRef([]);

		useImperativeHandle(ref, () => ({
			desktopRef: () => {
				return refEl.current;
			},
			close: () => {
				return closeSubmenu();
			},
		}));

		useEffect(() => {
			// close submenu when pressing ESC
			window.addEventListener('keydown', handleKeyDown);
			// close submenu when a new page gets rendered
			router.events.on('routeChangeComplete', hashListener);

			return () => {
				window.removeEventListener('keydown', handleKeyDown);
				router.events.off('routeChangeComplete', hashListener);
			};
		}, []);

		const hashListener = () => {
			closeSubmenu();
		};

		const handleKeyDown = (e) => {
			if (['Escape', 'Esc'].includes(e?.key)) closeSubmenu();
		};

		const toggleSubmenu = (index) => {
			if (openedSubmenuId === index) {
				// Close current submenu
				closeSubmenu();
			} else {
				// Open current submenu
				openSubmenu(index);
			}
		};

		const openSubmenu = (index) => {
			setOpenedSubmenuId(index);

			setIsPrimarySubmenuOpen(true);
		};
		const closeSubmenu = () => {
			setOpenedSubmenuId(-1);

			setIsPrimarySubmenuOpen(false);
		};

		const isActiveParent = (subItems) => {
			const activeSubItems = subItems.filter(
				(subItem) => subItem.path === uri || uri.includes(subItem.path)
			);

			const activeSubSubItems = subItems.filter(
				(subItem) =>
					subItem.items.filter(
						(subItem) =>
							subItem.path === uri || uri.includes(subItem.path)
					).length > 0
			);

			if (activeSubItems.length || activeSubSubItems.length) return true;

			return false;
		};

		return (
			<nav
				ref={refEl}
				className={cx('supt-mainNav__primary', {
					hasOpenedSubmenu: openedSubmenuId !== -1,
				})}
				aria-label={__t('main-nav-label', 'Main navigation')}
				role="navigation"
			>
				{items.map(
					({ label, path, id, items: subItems, target }, i) => {
						const hasSubItems = !!subItems.length;

						return (
							<div
								className={cx(
									'supt-mainNav__primary__item',
									{
										isOpened: openedSubmenuId === i,
									},
									{
										isCurrent:
											uri === path ||
											isActiveParent(subItems),
									},
									{
										isNotCurrent:
											uri !== path &&
											(uri === '/fr/recherche' ||
												uri === '/en/search'),
									},
									{
										'supt-mainNav__primary__item--hasSubmenu':
											hasSubItems,
									}
								)}
								key={id}
							>
								{hasSubItems ? (
									<button
										className={cx(
											'supt-mainNav__primary__item__label'
										)}
										onClick={() => toggleSubmenu(i)}
									>
										<span>{label}</span>
									</button>
								) : (
									<Link
										href={path ?? '#'}
										suppressHydrationWarning
										className={cx(
											'supt-mainNav__primary__item__label'
										)}
										aria-current={
											uri === path ? 'page' : null
										}
										target={target}
									>
										<span>{label}</span>
									</Link>
								)}

								{hasSubItems ? (
									<PrimarySubmenu
										items={subItems}
										uri={uri}
										ref={(el) =>
											(submenuRefs.current[i] = el)
										}
										style={subMenuStyles}
									/>
								) : null}
							</div>
						);
					}
				)}

				<div
					className={cx('supt-mainNav__primary__item', {
						isCurrent:
							uri === '/fr/recherche' || uri === '/en/search',
					})}
				>
					<a
						className="supt-mainNav__primary__search supt-mainNav__primary__item__label"
						href={locale === 'fr' ? '/fr/recherche' : '/en/search'}
					>
						<svg
							width="26"
							height="26"
							xmlns="http://www.w3.org/2000/svg"
						>
							<use href="#search" />
						</svg>
					</a>
				</div>
			</nav>
		);
	}
);

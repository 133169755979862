import Cookie from 'js-cookie';
import { useEffect, useState } from 'react';

const COOKIE_NAME = 'stx_cartSummary_VIDY_TickShop';

type CookieValuesType = {
	qty?: number;
	amount?: string;
	currency?: string;
	expiration?: string;
	orderId?: number;
};

export const useShopCookies = () => {
	const [isSSR, setIsSSR] = useState(true);
	const [{ qty }, setCookieValue] = useState<CookieValuesType>({});

	useEffect(() => {
		setIsSSR(false);

		const value = Cookie.get(COOKIE_NAME);

		if (typeof value !== 'undefined') {
			// @see https://platform.secutix.com/frontend/cookies
			const [qty, amount, currency, expiration, orderId] =
				value.split('|');

			setCookieValue({ qty, amount, currency, expiration, orderId });
		}
	}, []);

	return {
		qty: !isSSR && qty ? qty : null,
	};
};

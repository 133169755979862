import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	resetFirstLastMargins,
	resetLastMarginBottom,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	breakpoints,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	expandLink,
	fontStyle,
	fluidPadding,
	fluidValues,
	lineClamp,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { aspectRatio, handleIconButton } from '@/css/mixins.css';
import {
	imageExpandTransition,
	imageReduceTransition,
	linkNoUnderlineWithHover,
} from '@/css/placeholders.css';
import { colors } from '@/css/variables.css';

const getDynamicStyles = (styles) =>
	cssObj(
		fluidValues(
			'minHeight',
			styles?.minHeight?.[0],
			styles?.minHeight?.[1]
		),
		{
			backgroundColor: styles?.backgroundColors?.grey,

			'.supt-cardCta__inner': fluidPadding(styles?.padding),

			'.supt-cardCta__title': fontStyle(
				{
					...styles?.title?.typography,
					textAlign: styles?.title?.textAlignment,
					color: styles?.title?.color,
				},
				false
			),

			'.supt-cardCta__text': fontStyle({
				...styles?.text?.typography,
				textAlign: styles?.text?.textAlignment,
				color: styles?.text?.color,
			}),

			/* BACKGROUND COLORS */
			'&.is-style-pink': {
				backgroundColor: styles?.backgroundColors?.pink,
			},
			'&.is-style-black': {
				backgroundColor: styles?.backgroundColors?.black,
			},
		}
	);

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		display: 'flex',
		overflow: 'hidden',
		flexDirection: 'column',
		justifySelf: 'center',
		position: 'relative',
		width: '100%',

		[`@media (min-width: ${breakpoints.tablet})`]: {
			flexDirection: 'row',
			justifyContent: 'center',
		},

		'.supt-cardCta__inner, .supt-cardCta__image': {
			width: '100%',
		},
		'.supt-cardCta__inner': {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			alignItems: 'center',

			'> *': {
				[`@media (min-width: ${breakpoints.tablet})`]: {
					width: '85%',
				},
				[`@media (min-width: ${breakpoints.laptop})`]: {
					width: '67%',
				},
			},
		},

		'.supt-cardCta__content': cssObj(
			fluidValues('marginBottom', 40, 80),
			lineClamp(6),
			{
				textAlign: 'center',

				[`@media (min-width: ${breakpoints.fluidMin})`]: {
					WebkitLineClamp: 8,
				},
				[`@media (min-width: ${breakpoints.tablet})`]: {
					WebkitLineClamp: 10,
				},
			}
		),

		'.supt-cardCta__image': {
			position: 'relative',

			'.supt-figure': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			},
			'span, img': {
				width: '100% !important',
				height: '100% !important',
			},
			img: {
				objectFit: 'cover',
			},
		},

		'.supt-cardCta__title, .supt-cardCta__text': resetFirstLastMargins,

		'.supt-cardCta__title': cssObj(linkNoUnderlineWithHover, {
			marginBottom: 15,
			backgroundPosition: '0 0.85em',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				marginBottom: 30,
			},
		}),

		'.supt-cardCta__buttons': {
			marginTop: 'auto',

			'.supt-button': cssObj(resetLastMarginBottom, {
				marginLeft: 'auto',
				marginRight: 'auto',
			}),

			'.supt-listCards--list-buttons': {
				margin: 0,
			},
			'.supt-listCards__list': cssObj(expandLink('a:only-child'), {
				position: 'initial',
				justifyContent: 'center',
			}),
		},

		/* WITH IMAGE */
		'&.hasImage': {
			'.supt-cardCta__image': cssObj(aspectRatio(1, 1), {
				overflow: 'hidden',
			}),

			'.supt-cardCta__inner': {
				[`@media (min-width: ${breakpoints.tablet})`]: aspectRatio(
					1,
					1
				),

				[`@media (max-width: calc(${breakpoints.mobile} - 1px))`]:
					aspectRatio(1, 1),

				'> *': {
					[`@media (min-width: ${breakpoints.tablet})`]: {
						width: '100%',
					},
				},
			},

			'.supt-cardCta__content': {
				WebkitLineClamp: 4,
				[`@media (min-width: ${breakpoints.fluidMin})`]: {
					WebkitLineClamp: 6,
				},
				[`@media (min-width: ${breakpoints.mobile})`]: {
					WebkitLineClamp: 8,
				},
				[`@media (min-width: ${breakpoints.tablet})`]: {
					WebkitLineClamp: 6,
				},
				[`@media (min-width: ${breakpoints.laptop})`]: {
					WebkitLineClamp: 8,
				},
			},
		},

		/* SMALL CARD */
		'&.isSmall': {
			'.supt-cardCta__inner': {
				height: '100%',
				'> *': {
					width: '100%',
				},

				'.supt-cardCta__content': {
					WebkitLineClamp: 4,
					[`@media (min-width: ${breakpoints.fluidMin})`]: {
						WebkitLineClamp: 6,
					},
					[`@media (min-width: ${breakpoints.mobile})`]: {
						WebkitLineClamp: 8,
					},
					[`@media (min-width: ${breakpoints.medium})`]: {
						WebkitLineClamp: 4,
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						WebkitLineClamp: 6,
					},
					[`@media (min-width: ${breakpoints.desktop})`]: {
						WebkitLineClamp: 8,
					},
				},
			},

			/* SMALL CARD WITH IMAGE */
			'&.hasImage': cssObj(aspectRatio(1, 1), {
				'.supt-cardCta__inner, .supt-cardCta__image': {
					width: '100%',
				},

				'.supt-cardCta__image': {
					position: 'absolute',
					top: 0,
					left: 0,
					height: '100%',
					zIndex: 0,

					'&::before': {
						content: '""',
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						background:
							'linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))',
						zIndex: 1,
						pointerEvents: 'none',
					},
				},
				'.supt-cardCta__inner': {
					zIndex: 1,
				},
				'.supt-cardCta__title, .supt-cardCta__text': {
					color: '#fff',
				},
				'.supt-button': cssObj(handleIconButton('black', 'white'), {
					backgroundColor: '#fff',
					borderColor: '#fff',
					color: '#000',

					'&:hover, &:focus-within': {
						backgroundColor: '#000',
						borderColor: '#000',
						color: '#fff',
					},
				}),
			}),
		},

		/* WITH ONE BUTTON */
		'&.supt-cardCta--oneButton': {
			'&:hover, &:focus-within': {
				'.supt-cardCta__title': {
					backgroundSize: '100% 1px',
				},
			},

			'&.hasImage': {
				'.supt-figure': cssObj(imageReduceTransition),
				'&:hover, &:focus-within': {
					'.supt-figure': cssObj(imageExpandTransition),
				},
			},
		},

		/* WITH LINK */
		'&.supt-withLink': cssObj(expandLink(), {
			transition: `background-color ${transitions.mid} ease-out`,

			'.supt-cardCta__title, .supt-cardCta__text': {
				transition: `all ${transitions.mid} ease-out`,
			},

			'&:hover, &:focus-within': getDynamicStyles(styles?.states?.hover),
		}),

		/* BACKGROUND COLORS */
		'&.is-style-green': {
			backgroundColor: colors.brand.green,
		},
		'&.is-style-orange': {
			backgroundColor: colors.brand.orange,
		},
		'&.is-style-blue': {
			backgroundColor: colors.brand.blue,
		},
		'&.is-style-black': {
			'.supt-cardCta__title, .supt-cardCta__text': {
				color: '#fff',
			},
			'.supt-button': cssObj(handleIconButton('white', 'black'), {
				borderColor: '#fff',
				color: '#fff',

				'&:hover, &:focus-within': {
					backgroundColor: '#fff',
					color: '#000',
				},
			}),
		},
	});

// edit
const getEditGlobalStyles = (styles) =>
	cssObj({
		'.wp-block[data-type="supt/card-cta"]': {
			gridColumnEnd: 'span 2',

			'&:has(.isSmall)': {
				[`@media (min-width: ${breakpoints.tablet})`]: {
					gridColumnEnd: 'span 1',
				},
			},

			'.supt-card': {
				width: '100%',
				height: '100%',
				'a::before': {
					display: 'none',
				},

				'.wp-block': {
					margin: 0,
				},

				'&.isSmall': {
					'.supt-cardCta__inner': {
						pointerEvents: 'none',
						'> *': {
							pointerEvents: 'auto',
						},
					},

					'.supt-cardCta__image .supt-figure__deleteButton': {
						zIndex: 2,
					},
				},
			},

			'.block-editor-block-preview__content.components-disabled & .supt-cardCta':
				{
					minHeight: '0 !important',

					'.supt-cardCta__content': {
						marginBottom: '0 !important',
					},
					'.supt-cardCta__buttons': {
						display: 'none',
					},
				},
		},
	});

export { getStyles, getEditGlobalStyles };

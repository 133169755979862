import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-section--section-artists-slider': {
			overflow: 'hidden',

			'.supt-sliderArtists, .supt-listCards': {
				gridColumn: '1 / span 12',
			},

			'&.supt-section--is-open .supt-section__content': {
				overflow: 'visible',
			},
		},
	});

const getEditCustomStyles = () =>
	cssObj({
		'.supt-section--section-artists-slider': {
			'.supt-sliderArtists, .supt-listCards': {
				width: '100%',
				margin: 0,
			},
		},
	});

export { getCustomStyles, getEditCustomStyles };

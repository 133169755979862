import {} from '@superhuit/starterpack-customizer';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const cardDateTimeThemeAttributes = {
	padding: {
		top: [25, 35],
		bottom: [25, 30],
	},
	content: {
		color: '@tokens.variants.default.contentColor',
		typography: '@tokens.typographies.small-text',
		textAlignment: 'left',
	},
	availability: {
		label: {
			color: '@tokens.variants.default.contentColor',
			typography: '@tokens.typographies.caption',
			textAlignment: 'left',
		},
		backgroundColor: {
			high: '@tokens.colors.available.value',
			low: '@tokens.colors.brand-tertiary-base.value',
			none: '@tokens.colors.warning.value',
			soon: '@tokens.colors.neutral-200.value',
		},
	},
	states: {},
};

export const CardDateTimeThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: cardDateTimeThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return (
			<>
				<h4>Specs</h4>
				{/* Customizer controls */}
			</>
		);
	},
};

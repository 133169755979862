import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
// package imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { SectionProvider } from '@superhuit/starterpack-context';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import {
	Link,
	LinkProps,
} from '@superhuit/starterpack-blocks/helpers/Link/render';
// internal imports
import { ButtonPrimary } from '@/components/atoms/renders';
import { ListCalendar } from '@/components/molecules/ListCalendar/render';
import { CardCalendar } from '@/components/renders';
import block from './block.json';
// styles
import { getStyles } from './styles.css';
import { CardCalendarType } from '@/components/molecules/CardCalendar/render';

/**
 * TYPINGS
 */
type DayType = {
	date: Date;
	shows: Array<CardCalendarType>;
};
type HomeCalendarProps = {
	days: Array<DayType>;
	calendarLink: LinkProps;
	anchor?: string;
	variant?: string;
} & ThemeProps;

/**
 * COMPONENT
 */
export const HomeCalendar: FC<HomeCalendarProps> & BlockConfigs = ({
	days,
	calendarLink,
	variant: currentVariant = 'default',
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(
		block.slug,
		theme,
		currentVariant
	);

	const rootClass = cx('supt-homeCalendar', getStyles(blockTheme));

	const { __t, locale } = useContext(LocaleContext);

	const [activeDay, setActiveDay] = useState(-1);

	const { date, shows } = useMemo(
		() => ({
			date: activeDay < 0 ? null : new Date(days[activeDay].date),
			shows: activeDay < 0 ? null : days[activeDay].shows,
		}),
		[days, activeDay]
	);

	const monthTitle = useMemo(
		() =>
			new Date().toLocaleString(locale, {
				month: 'long',
				year: 'numeric',
			}),
		[locale]
	);

	return (
		(days?.length ?? null) && (
			<SectionProvider variant={variant}>
				<div
					className={rootClass}
					data-block={block.slug}
					data-variant={variant}
				>
					<div className="supt-homeCalendar__header">
						<div className="supt-homeCalendar__header__inner">
							<p className="supt-homeCalendar__month">
								{monthTitle}
							</p>
							<div className="supt-homeCalendar__days">
								{days.map(({ date: dateStr, shows }, i) => {
									const date = new Date(dateStr);
									return (
										<button
											key={i}
											className={cx(
												'supt-homeCalendar__day',
												{
													isActive: activeDay === i,
												}
											)}
											onClick={() => setActiveDay(i)}
											disabled={!shows.length}
										>
											<span className="supt-homeCalendar__day__week">
												{date
													.toLocaleString(locale, {
														weekday: 'short',
													})
													.replace('.', '')}
											</span>
											<span className="supt-homeCalendar__day__num">
												{String(
													date.getDate()
												).padStart(2, '0')}
											</span>
										</button>
									);
								})}
							</div>

							<div className="supt-homeCalendar__calendar-btn">
								{calendarLink?.href ? (
									<>
										<ButtonPrimary
											title={__t(
												'home-calendar-header-button',
												'Calendrier'
											)}
											className="supt-homeCalendar__calendar-btn__desktop"
											href={calendarLink.href}
										/>
										<Link
											className="supt-homeCalendar__calendar-btn__mobile"
											href={calendarLink.href}
										>
											<svg
												width="13"
												height="13"
												xmlns="http://www.w3.org/2000/svg"
											>
												<use href="#arrow-right" />
											</svg>
										</Link>
									</>
								) : null}
							</div>
						</div>
					</div>
					{shows ? (
						<div className="supt-homeCalendar__content">
							<div className="supt-homeCalendar__content__inner">
								<button
									className="supt-homeCalendar__close-btn"
									onClick={() => setActiveDay(-1)}
								>
									<svg
										width="18"
										height="18"
										xmlns="http://www.w3.org/2000/svg"
									>
										<use href="#close" />
									</svg>
								</button>

								{activeDay >= 0 ? (
									<p className="supt-homeCalendar__date">
										{date.toLocaleString(locale, {
											weekday: 'long',
											day: 'numeric',
											month: 'long',
											year: 'numeric',
										})}
									</p>
								) : null}

								<ListCalendar>
									{shows.map((show, id) => (
										<CardCalendar key={id} {...show} />
									))}
								</ListCalendar>
							</div>
						</div>
					) : null}
				</div>
			</SectionProvider>
		)
	);
};

HomeCalendar.slug = block.slug;
HomeCalendar.title = block.title;

import {} from '@superhuit/starterpack-customizer';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const filtersThemeAttributes = {
	padding: {
		top: [65, 95],
	},
	title: {
		color: '@tokens.variants.default.titleColor',
		typography: '@tokens.typographies.heading-1',
		textAlignment: 'left',
	},
	label: {
		color: '@tokens.variants.default.contentColor',
		typography: '@tokens.typographies.caption',
		textAlignment: 'left',
	},
	filter: {
		color: '@tokens.variants.default.contentColor',
		typography: '@tokens.typographies.text-s-medium',
		textAlignment: 'left',
	},
};

export const FiltersThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: filtersThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return (
			<>
				<h4>Specs</h4>
				{/* Customizer controls */}
			</>
		);
	},
};

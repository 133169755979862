import React, { FC, useContext } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Link } from '@/components/atoms/Link/render';
import {
	ThemeProps,
	BlockConfigs,
	ImageProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { CardLinkProps } from '@superhuit/starterpack-blocks/helpers/CardControls/typings';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { Image } from '@superhuit/starterpack-blocks/components/renders';
import block from './block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
type CardShopProps = ThemeProps & {
	image?: ImageProps;
	title: string;
	content?: string;
	link?: CardLinkProps;
};

/**
 * COMPONENT
 */
export const CardShop: FC<CardShopProps> & BlockConfigs = ({
	title,
	image,
	content,
	link = {},
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const { __t } = useContext(LocaleContext);

	const rootClass = cx(
		'supt-card supt-cardShop',
		getStyles(blockTheme),
		link.href && 'supt-withLink'
	);

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			{image?.src && (
				<div className="supt-cardShop__imageWrapper">
					<Image
						sizes="(max-width: 599px) 90vw, (max-width: 1199px) 45vw, (max-width: 1679px) 30vw, 512px"
						{...image}
						className="supt-cardShop__image"
					/>
				</div>
			)}

			<div className="supt-cardShop__titleWrapper">
				<h3
					className="supt-cardShop__title"
					dangerouslySetInnerHTML={{ __html: title }}
				></h3>
			</div>

			<div
				className="supt-cardShop__content"
				dangerouslySetInnerHTML={{ __html: content }}
			></div>
			{link?.href ? (
				<div className="supt-cardShop__linkWrapper">
					<Link
						className="supt-cardShop__link"
						href={link.href}
						{...(link.opensInNewTab && {
							target: '_blank',
							rel: 'noopener noreferrer',
						})}
					>
						<span>{__t('card-shop-link', 'Commander')}</span>
					</Link>
				</div>
			) : null}
		</div>
	);
};

CardShop.slug = block.slug;
CardShop.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import {
	fluidPadding,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { gridNoMargin } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getDynamicStyles = (styles) =>
	cssObj(
		fluidPadding(styles?.padding),
		fontStyle(
			{
				...styles?.content?.typography,
				textAlign: styles?.content?.textAlignment,
				color: styles?.content?.color,
			},
			false
		),
		{}
	);

const getStyles = (styles) =>
	css(getDynamicStyles(styles), gridNoMargin, {
		position: 'relative',
		borderTop: '1px solid #000',
		borderBottom: '1px solid #000',

		[`@media (min-width: ${breakpoints.laptop})`]: {
			alignItems: 'center',
		},

		'.supt-cardSubscription__title': {
			gridColumn: '1 / span 12',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '1 / span 8',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '1 / span 5',
			},
		},

		'.supt-cardSubscription__price': {
			gridColumn: '1 / span 12',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '1 / span 8',
			},
			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '1 / span 9',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '6 / span 3',
				display: 'flex',
			},
		},

		'.supt-cardSubscription__button': {
			gridColumn: '1 / span 12',
			zIndex: '2',
			marginTop: '10px',
			marginBottom: 0,

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '9 / span 4',
				gridRow: '1/4',
				justifySelf: 'end',
				alignSelf: 'auto',
				margin: 0,
			},
			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '10 / span 3',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridRow: '1',
			},
			[`@media (min-width: ${breakpoints.desktop})`]: {
				gridColumn: '11 / span 2',
			},

			'.supt-button': {
				margin: 0,
			},
		},

		'& + .supt-cardSubscription': {
			borderTopWidth: 0,
		},
	});

// edit
const getEditGlobalStyles = (styles) =>
	cssObj({
		'.wp-block[data-type="supt/card-subscription"]': {
			margin: 0,
			'.wp-block[data-type="supt/button-primary"]': {
				margin: 0,
			},
		},
	});

export { getStyles, getEditGlobalStyles };

import React, { FC, useRef, useEffect, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
	ChildrenProps,
} from '@superhuit/starterpack-blocks/utils/typings';
// internal
import { getStyles as getDfltStyles } from './styles.css';
import block from './block.json';

export type SubsectionHeaderProps = {
	slug: string;
	firstYear?: string;
	secondYear?: string;
	getStyles?: (styles: any) => string;
} & ThemeProps &
	ChildrenProps;

/**
 * COMPONENT
 */
export const SubsectionHeader: FC<SubsectionHeaderProps> & BlockConfigs = ({
	slug,
	children,
	firstYear,
	secondYear,
	theme = {},
	getStyles = getDfltStyles,
}) => {
	const { variant, blockTheme } = useThemeBlock(slug, theme);
	const refTitle = useRef(null);
	const refContent = useRef(null);
	const [isResized, setIsResized] = useState(false);

	useResizeObserver(refTitle, (entry) => resizeTitle());

	useEffect(() => {
		setTimeout(() => {
			resizeTitle();
		}, 0);

		const resizeListener = () => {
			resizeTitle();
		};

		// set resize listener
		window.addEventListener('resize', resizeListener);

		// clean up function
		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	const isOverflown = ({ clientWidth, scrollWidth }) =>
		scrollWidth > clientWidth;

	const resizeTitle = () => {
		if (!refTitle.current) return;

		const minSize = 10;
		const maxSize = 590;
		const step = 1;
		const unit = 'px';
		let i = minSize;
		let overflow = false;

		const parent = refTitle.current.parentNode;

		while (!overflow && i < maxSize) {
			refTitle.current.style.fontSize = `${i}${unit}`;
			overflow = isOverflown(parent);

			if (!overflow) i += step;
		}

		// revert to last state where no overflow happened
		refTitle.current.style.fontSize = `${i - step}${unit}`;
		// refContent.current.style.fontSize = `${i - step}${unit}`;
		// refContent.current.style.minHeight = `${i - step}${unit}`;
		setIsResized(() => true);
	};

	const trimmedSlug = slug.split('/');
	const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

	const rootClass = cx(
		'supt-subsection',
		`supt-subsection--${modifierSlug}`,
		isResized && 'supt-subsection--isResized',
		getStyles(blockTheme)
	);

	return (
		<div className={rootClass} data-block={slug} data-variant={variant}>
			<div className="supt-subsection__inner">
				{firstYear || secondYear ? (
					<div className="supt-subsection__titleWrapper">
						<h2 className="supt-subsection__title" ref={refTitle}>
							<span
								className="supt-subsection__title__year"
								dangerouslySetInnerHTML={{ __html: firstYear }}
							/>
							{firstYear && secondYear ? (
								<span className="supt-subsection__title__bullet">
									•
								</span>
							) : null}
							<span
								className="supt-subsection__title__year"
								dangerouslySetInnerHTML={{ __html: secondYear }}
							/>
						</h2>
					</div>
				) : null}
				<div className="supt-subsection__content" ref={refContent}>
					{children}
				</div>
			</div>
		</div>
	);
};

SubsectionHeader.slug = block.slug;
SubsectionHeader.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	gridColumnGap,
	gridRowGap,
	gridNoMargin,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';

const getStyles = () =>
	css(gridColumnGap, gridRowGap, {
		display: 'grid',
		gridTemplateColumns: 'repeat(1, 1fr)',
		alignItems: 'start',

		[`@media (min-width: ${breakpoints.tablet})`]: {
			gridTemplateColumns: 'repeat(2, 1fr)',
		},

		[`@media (min-width: ${breakpoints.medium})`]: {
			gridTemplateColumns: 'repeat(3, 1fr)',
		},

		[`@media (min-width: ${breakpoints.bigLaptop})`]: {
			gridTemplateColumns: 'repeat(4, 1fr)',
		},

		'&.supt-listArtists--bio': cssObj(
			gridNoMargin,
			fluidValues('gridRowGap', 24, 46),
			{
				'.supt-cardArtist': {
					gridColumn: '1/13',

					'&:nth-child(2n + 1)': {
						[`@media (min-width: ${breakpoints.medium})`]: {
							gridColumn: '1/7',
						},

						[`@media (min-width: ${breakpoints.bigLaptop})`]: {
							gridColumn: '1/6',
						},
					},
					'&:nth-child(2n)': {
						[`@media (min-width: ${breakpoints.medium})`]: {
							gridColumn: '7/13',
						},
						[`@media (min-width: ${breakpoints.bigLaptop})`]: {
							gridColumn: '7/12',
						},
					},
				},
			}
		),
	});

export { getStyles };

import React, { FC } from 'react';
import cx from 'classnames';
import {
	ThemeProps,
	BlockConfigs,
} from '@superhuit/starterpack-blocks/utils/typings';
import { CardLinkProps } from '@superhuit/starterpack-blocks/helpers/CardControls/typings';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import block from './block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
type CardRoleProps = ThemeProps & {
	role: string;
	name: string;
	link?: CardLinkProps;
};

/**
 * COMPONENT
 */
export const CardRole: FC<CardRoleProps> & BlockConfigs = ({
	role,
	name,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx('supt-card supt-cardRole', getStyles(blockTheme));

	const htmlProps = {
		'data-variant': variant,
	};

	return (
		<div className={rootClass} data-block={block.slug} {...htmlProps}>
			<p
				className="supt-cardRole__role"
				dangerouslySetInnerHTML={{ __html: role }}
			></p>
			<p
				className="supt-cardRole__name"
				dangerouslySetInnerHTML={{ __html: name }}
			></p>
		</div>
	);
};

CardRole.slug = block.slug;
CardRole.title = block.title;

import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-listAccordions': {
			marginTop: 30,
			[`@media (min-width: ${breakpoints.medium})`]: {
				marginTop: 0,
			},

			'.supt-listAccordions__list': {
				gap: '0px',
				display: 'initial',

				['@media (min-width: 768px)']: {
					gridColumn: '1 / span 12',
				},
			},
		},
	});

export { getCustomStyles };

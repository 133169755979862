export function injectFontFaces(fontfaces) {
	fontfaces.forEach((item) => {
		const oldStyle = document.getElementById(
			'supt-style-font-faces-' + item.group
		);
		if (oldStyle) {
			oldStyle.innerHTML = item.value;
		} else {
			const style = document.createElement('style');
			style.id = 'supt-style-font-faces-' + item.group;
			style.innerHTML = item.value;
			document.head.appendChild(style);
		}
	});
}

import cx from 'classnames';
import { isEmpty } from 'lodash';
import React, { FC } from 'react';

import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { ButtonProps } from '@superhuit/starterpack-blocks/helpers/Button/typings';
import { Link } from '@/components/atoms/Link/render';

// imports from upstream component
import block from '@superhuit/starterpack-blocks/components/atoms/Buttons/ButtonSecondary/block.json';
import { getStyles } from '@superhuit/starterpack-blocks/components/atoms/Buttons/ButtonSecondary/styles.css';

type ButtonSecondaryProps = {
	pageId?: string;
} & ButtonProps;

export const ButtonSecondary: FC<ButtonSecondaryProps> & BlockConfigs = ({
	title = '',
	href = '',
	opensInNewTab,
	className,
	theme = {},
	pageId = null,
	...props
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx(
		className,
		'supt-button',
		'supt-buttonSecondary',
		getStyles(blockTheme)
	);

	return isEmpty(title) ? null : (
		<Link
			href={href}
			className={rootClass}
			{...(opensInNewTab && {
				target: '_blank',
				rel: 'noopener noreferrer',
			})}
			data-block={block.slug}
			data-variant={variant}
			{...props}
		>
			<span className="supt-button__inner">
				{title ? <span>{title}</span> : null}
			</span>
		</Link>
	);
};

ButtonSecondary.slug = block.slug;
ButtonSecondary.title = block.title;

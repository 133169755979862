import React, { FC, useRef, useState } from 'react';
import cx from 'classnames';
// internal imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	ThemeProps,
	BlockConfigs,
} from '@superhuit/starterpack-blocks/utils/typings';
// styles
import { getStyles } from '@superhuit/starterpack-blocks/components/molecules/lists/ListAccordions/styles.css';
import block from '@superhuit/starterpack-blocks/components/molecules/lists/ListAccordions/block.json';
import { Accordion } from '../Accordion/render';
import AccordionChildren from '../Accordion/children';

/**
 * TYPINGS
 */
type ListAccordionsProps = ThemeProps & {
	title?: string;
	multiselectable: boolean;
};

/**
 * COMPONENT
 */
export const ListAccordions: FC<ListAccordionsProps> & BlockConfigs = ({
	multiselectable,
	children,
	theme = {},
}) => {
	const [selectedAccordionIndex, setSelectedAccordionIndex] = useState(-1);
	const accordionsRef = useRef([]);

	const [items] = useState(children['props'].blocks);

	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx('supt-listAccordions', getStyles(blockTheme));

	const htmlProps = {
		'data-variant': variant,
	};

	const closeAllAccordions = (currentIndex) => {
		// Close other accordions only they are not multiselectable
		if (!multiselectable) {
			accordionsRef.current.forEach((accordion) => {
				accordion.closeAccordion(currentIndex);
			});
		}
	};

	const handleAccordionsKeydown = (e) => {
		let shouldSelectIndex;

		// pressed `home` => 1st tab
		if (e.keyCode === 36) {
			shouldSelectIndex = 0;
		}

		// pressed `end` => last tab
		else if (e.keyCode === 35) {
			shouldSelectIndex = accordionsRef.current.length - 1;
		}

		// pressed `up` or `left` => previous tab
		else if ((e.keyCode === 37 || e.keyCode === 38) && !e.ctrlKey) {
			// define tab
			if (selectedAccordionIndex < 1) {
				// reached the beginning => last tab
				shouldSelectIndex = accordionsRef.current.length - 1;
			} else {
				// previous tab
				shouldSelectIndex = selectedAccordionIndex - 1;
			}
		}

		// pressed `down` or `right` => previous tab
		else if ((e.keyCode === 40 || e.keyCode === 39) && !e.ctrlKey) {
			// define tab
			if (selectedAccordionIndex === accordionsRef.current.length - 1) {
				// last tab selected => first tab
				shouldSelectIndex = 0;
			} else {
				// next tab
				shouldSelectIndex = selectedAccordionIndex + 1;
			}
		}

		// bail early if user is not navigating
		if (typeof shouldSelectIndex === 'undefined') return;

		// prevent default event
		e.preventDefault();

		// select target button and unselect all others
		accordionsRef.current.forEach((accordion, index) => {
			if (index === shouldSelectIndex) {
				accordion.selectAccordion();
			} else {
				accordion.unselectAccordion();
			}
		});
	};

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			{...htmlProps}
			onKeyDown={handleAccordionsKeydown}
		>
			<div className="supt-listAccordions__list">
				{items.map((item, itemId) => {
					return (
						<Accordion
							key={itemId}
							{...item.attributes}
							index={itemId}
							onButtonClick={closeAllAccordions}
							onButtonFocus={(index) =>
								setSelectedAccordionIndex(index)
							}
							ref={(ref) => (accordionsRef.current[itemId] = ref)}
						>
							<AccordionChildren blocks={item.innerBlocks} />
						</Accordion>
					);
				})}
			</div>
		</div>
	);
};

ListAccordions.slug = block.slug;
ListAccordions.title = block.title;

import React from 'react';
import cx from 'classnames';
import PlaceholderLoading from 'react-placeholder-loading';

// package imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { SectionProvider } from '@superhuit/starterpack-context';
import { ThemeProps } from '@superhuit/starterpack-blocks/utils/typings';
// internal imports

// styles
import { getStyles } from './styles.css';
import { ListCalendar } from '@/components/renders';

import { getStyles as getCardStyles } from '@/components/molecules/CardCalendar/styles.css';

/**
 * COMPONENT
 */
export const SectionMonthCalendarPlaceholder = ({ theme = {} }: ThemeProps) => {
	const { variant, blockTheme } = useThemeBlock(theme, 'default');

	const rootClass = cx('supt-sectionMonthCalendar', getStyles(blockTheme));

	return (
		<SectionProvider variant={variant}>
			<div className={rootClass}>
				<div className="supt-sectionMonthCalendar__inner">
					<div className="supt-sectionMonthCalendar__header">
						<button
							className="supt-sectionMonthCalendar__prev-btn"
							disabled={true}
						>
							<svg
								width="18"
								height="18"
								xmlns="http://www.w3.org/2000/svg"
							>
								<use href="#arrow-right" />
							</svg>
						</button>
						<h2 className="supt-sectionMonthCalendar__month">
							<PlaceholderLoading
								shape="rect"
								width={300}
								height={60}
							/>
						</h2>
						<button
							className="supt-sectionMonthCalendar__next-btn"
							disabled={true}
						>
							<svg
								width="18"
								height="18"
								xmlns="http://www.w3.org/2000/svg"
							>
								<use href="#arrow-right" />
							</svg>
						</button>
					</div>
					<div className="supt-sectionMonthCalendar__days">
						<ListCalendar>
							{[false, true, false, false, true, false].map(
								(isEvent, id) => (
									<CardCalendarPlaceholder
										key={id}
										isEvent={isEvent}
										theme={theme}
									/>
								)
							)}
						</ListCalendar>
					</div>
				</div>
			</div>
		</SectionProvider>
	);
};

const CardCalendarPlaceholder = ({ isEvent = false, theme }) => {
	const { variant, blockTheme } = useThemeBlock('supt/card-calendar', theme);

	const rootClass = cx(
		'supt-cardCalendar',
		`supt-cardCalendar--${isEvent ? 'event' : 'show'}`,
		getCardStyles(blockTheme),
		'supt-cardCalendar--hasDate'
	);

	return (
		<div className={rootClass}>
			<div className="supt-cardCalendar__header">
				<div className="supt-cardCalendar__date">
					<span className="supt-cardCalendar__date__digit">
						<PlaceholderLoading
							shape="rect"
							width={40}
							height={60}
						/>
					</span>
				</div>
				<div className="supt-cardCalendar__headlines">
					{isEvent && (
						<span className="supt-cardCalendar__label">
							<PlaceholderLoading
								shape="rect"
								width={200}
								height={18}
							/>
						</span>
					)}
					<p className="supt-cardCalendar__artist">
						<PlaceholderLoading
							shape="rect"
							width={400}
							height={24}
						/>
					</p>
					<h5 className="supt-cardCalendar__title">
						<PlaceholderLoading
							shape="rect"
							width={300}
							height={22}
						/>
					</h5>
				</div>
			</div>

			<div className="supt-cardCalendar__infos">
				<p className="supt-cardCalendar__time">
					<PlaceholderLoading shape="rect" width={250} height={22} />
				</p>
				<p className="supt-cardCalendar__location">
					<PlaceholderLoading shape="rect" width={200} height={22} />
				</p>
				<div className="supt-cardCalendar__genres">
					<span style={{ borderRadius: '1em', overflow: 'hidden' }}>
						<PlaceholderLoading
							shape="rect"
							width={70}
							height={22}
						/>
					</span>
				</div>
			</div>

			<div className="supt-cardCalendar__tickets-btn">
				<PlaceholderLoading shape="rect" width={120} height={40} />
			</div>
		</div>
	);
};

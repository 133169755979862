import { mergeDeep } from '@superhuit/starterpack-utils';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionOneColumnThemeControls } from '@superhuit/starterpack-blocks/components/themings'; // Needs to be called from /components folder to work properly..

import block from './block.json';

export const sectionCardsArtistThemeAttributes = {
	columns: {
		headline: {
			desktop: {
				offsetLeft: 0,
				columnLeft: 12,
			},
			tablet: {
				offsetLeft: 0,
				columnLeft: 12,
			},
			mobile: {
				offsetLeft: 0,
				columnLeft: 12,
			},
		},
		content: {
			desktop: {
				offsetLeft: 0,
				columnLeft: 12,
			},
			tablet: {
				offsetLeft: 0,
				columnLeft: 12,
			},
			mobile: {
				offsetLeft: 0,
				columnLeft: 12,
			},
		},
	},
	input: {
		padding: {
			top: [16, 22],
			right: [20, 20],
			bottom: [16, 22],
			left: [44, 44],
		},
		border: {
			color: '@tokens.variants.default.cardContentColor',
			width: {
				top: [1, 1],
				right: [1, 1],
				bottom: [1, 1],
				left: [1, 1],
			},
			radius: {
				topLeft: [0, 0],
				topRight: [0, 0],
				bottomRight: [0, 0],
				bottomLeft: [0, 0],
			},
		},
		typography: '@tokens.typographies.text-s-medium',
		textAlignment: 'left',
		color: '@tokens.variants.default.cardContentColor',
	},
};

export const SectionCardsArtistThemeControls: ThemeControlsType = mergeDeep(
	SectionOneColumnThemeControls,
	{
		// What's displayed
		title: block.title,

		// Unique string/id of the block (= the key used in theme.json)
		slug: block.slug,

		// styling attributes available for this block
		// ...the same way we define attributes for a gutenberg block
		attributes: sectionCardsArtistThemeAttributes,

		// this renders in the Customizer panel
		// ...the same way "<InspectorControls />" renders in the gutenberg panel
		edit: ({
			fullAttributes,
			updateAttribute,
			theme,
			typographiesOptions,
		}) => {
			return <></>;
		},
	}
);

import cx from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

// Import styles
import { getStyles } from './styles/alert.css';

import SwitchToggle from './switch-toggle';

const QUERY_PREVIEW_DRAFT = 'preview-draft';

export default function Alert({ preview, wpUrl, editLink, showCustomizerBtn }) {
	if (!preview) return null;

	const router = useRouter();
	const [viewport, setViewport] = useState();
	const [isDraftPreview, setIsDraftPreview] = useState(false);

	const getViewportSizes = () => ({
		w: document.documentElement.clientWidth,
		h: document.documentElement.clientHeight,
	});

	/**
	 * Debounced resize handler
	 * to limit number of viewport size
	 * state changes and improve perfs
	 */
	const debouncedResize = useCallback(
		debounce(() => setViewport(getViewportSizes()), 100),
		[]
	);

	useEffect(() => {
		setViewport(getViewportSizes());

		// TODO: find a better way than looking with window.location
		if (
			typeof location != 'undefined' &&
			typeof location.search != 'undefined'
		) {
			setIsDraftPreview(location.search.includes(QUERY_PREVIEW_DRAFT));
		}

		window.addEventListener('resize', debouncedResize);
		return () => window.removeEventListener('resize', debouncedResize);
	}, []);

	const toggleIsDraftPreview = () => {
		setIsDraftPreview(!isDraftPreview); // GUI user feedback

		const newUrl = [wpUrl];
		if (router.query?.lang) newUrl.push(router.query.lang);
		newUrl.push(...router.query.uri);

		window.location.assign(
			`${newUrl.join('/')}/${isDraftPreview ? '' : '?preview=true'}`
		);
	};

	return (
		<div className={cx('supt-alert-toolbar', getStyles())}>
			<span>This page is a preview.</span>

			<SwitchToggle
				id="preview_current_page"
				label="Switch to current page"
				checked={!isDraftPreview}
				onChange={toggleIsDraftPreview}
			/>

			{viewport ? (
				<div>
					Screen width: <span>{`${viewport.w}px`}</span>
				</div>
			) : null}

			{showCustomizerBtn ? (
				<div>
					<button
						className="supt-alert-toolbar__button"
						onClick={() =>
							window.dispatchEvent(
								new CustomEvent('toggle-customizer')
							)
						}
					>
						Customizer
					</button>
				</div>
			) : null}

			<div style={{}}>
				{editLink && (
					<a
						href={decodeURI(editLink).replace('&amp;', '&')}
						className="supt-alert-toolbar__button"
					>
						Back to editor
					</a>
				)}
			</div>
		</div>
	);
}

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import {
	expandLink,
	fluidPadding,
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	gridNoMargin,
	resetButton,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	breakpoints,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';

const buttonDisable = cssObj({
	pointerEvents: 'none',
	borderColor: 'transparent',
	backgroundColor: 'rgba(0, 0, 0, 0.1)',
	color: 'rgba(0, 0, 0, 0.5)',

	'&[target="_blank"]': {
		'.supt-button__inner span::before': {
			content: `url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' opacity='0.5' stroke-width='1.4'%3E%3Cpath d='M2.79 2.4h8v8'/%3E%3Cpath stroke-linecap='square' d='M9.825 3.365 2.4 10.789'/%3E%3C/g%3E%3C/svg%3E")`,
		},
	},
});

const getDynamicStyles = (styles) =>
	cssObj(
		fluidPadding(styles?.padding),
		fontStyle(
			{
				...styles?.content?.typography,
				textAlign: styles?.content?.textAlignment,
				color: styles?.content?.color,
			},
			false
		),
		{
			'.supt-cardDateTime__availability__label': fontStyle(
				{
					...styles?.availability?.label?.typography,
					textAlign: styles?.availability?.label?.textAlignment,
					color: styles?.availability?.label?.color,
				},
				false
			),

			// Availabilities
			'&[data-availability="high"], &[data-availability="free"]': {
				'.supt-cardDateTime__availability': {
					backgroundColor:
						styles?.availability?.backgroundColor?.high,
				},
			},
			'&[data-availability="low"]': {
				'.supt-cardDateTime__availability': {
					backgroundColor: styles?.availability?.backgroundColor?.low,
				},
			},
			'&[data-availability="none"]': {
				'.supt-cardDateTime__availability': {
					backgroundColor:
						styles?.availability?.backgroundColor?.none,
				},
			},
			'&[data-availability="soon"]': {
				'.supt-cardDateTime__availability': {
					backgroundColor:
						styles?.availability?.backgroundColor?.soon,
				},
			},
		}
	);

const getStyles = (styles) =>
	css(getDynamicStyles(styles), gridNoMargin, {
		position: 'relative',
		borderTop: '1px solid #000',
		borderBottom: '1px solid #000',

		[`@media (min-width: ${breakpoints.laptop})`]: {
			alignItems: 'center',
		},

		'.supt-cardDateTime__date': {
			gridColumn: '1 / span 12',
			display: 'flex',
			alignItems: 'center',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '1 / span 8',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '1 / span 2',
			},

			'.supt-tag': {
				marginLeft: '8px',
			},
		},

		'.supt-cardDateTime__time': {
			gridColumn: '1 / span 12',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '1 / span 8',
			},
			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '1 / span 9',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '3 / span 3',
				display: 'flex',
			},
		},

		'.supt-cardDateTime__location-publics': {
			gridColumn: '1 / span 12',
			display: 'flex',
			flexDirection: 'column',
			gap: 6,

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '1 / span 8',
				gridRow: 'auto',
			},
			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '1 / span 9',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '6 / span 4',
				marginTop: 0,
			},

			'.supt-cardDateTime__public': cssObj(fluidValues('gap', 10, 12), {
				listStyle: 'none',
				padding: 0,
				display: 'flex',
				position: 'relative',

				li: {
					display: 'flex',
					alignItems: 'center',

					'figure, span': {
						width: '100%',
						maxHeight: '22px',
					},

					'&.supt-cardDateTime__public-extra': cssObj(
						fluidValues('fontSize', 15, 16),
						{
							letterSpacing: '-0.03125em',
							lineHeight: '1.375em',
						}
					),
				},
			}),
		},

		'.supt-cardDateTime__availability': cssObj(resetButton, {
			display: 'block',
			width: 14,
			height: 14,
			borderRadius: '50%',

			position: 'absolute',
			top: 28,
			right: 0,

			cursor: 'default',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '9 / span 1',
				position: 'relative',
				top: 'auto',
				right: 'auto',
				gridRow: '1',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '10 / span 1',
			},

			'&__label': {
				position: 'absolute',
				right: 22,
				top: '50%',
				whiteSpace: 'nowrap',
				pointerEvents: 'none',
				transform: 'translateY(-50%) translateX(4px)',
				opacity: 0,
				transition: `opacity ${transitions.fast} ease, transform ${transitions.fast} ease`,

				'&::before': {
					content: '""',
					position: 'absolute',
					top: -8,
					right: 0,
					width: '200%',
					height: 30,
					background:
						'linear-gradient(to left, rgba(198,230,215) 60%, rgba(198,230,215, 0) 100%)',
					zIndex: -1,
					[`@media (min-width: ${breakpoints.tablet})`]: {
						top: -14,
						height: 50,
					},
				},
			},
		}),

		'.supt-cardDateTime__tickets-btn': {
			gridColumn: '1 / span 12',
			zIndex: '2',
			marginTop: '10px',
			marginBottom: 0,

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '9 / span 4',
				gridRow: '1/4',
				justifySelf: 'end',
				alignSelf: 'auto',
				margin: 0,
			},
			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '10 / span 3',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridRow: '1',
			},
			[`@media (min-width: ${breakpoints.desktop})`]: {
				gridColumn: '11 / span 2',
			},
		},

		'&:hover, &:focus-within': {
			'.supt-cardDateTime__availability__label': {
				transform: 'translateY(-50%) translateX(0px)',
				opacity: 1,
			},
		},

		/* with link */
		'&.supt-withLink': cssObj(expandLink(), {
			'.supt-cardDateTime__tickets-btn': {
				'&::before': {
					content: `none`,
				},
			},
		}),

		'&:not(.supt-withLink) .supt-cardDateTime__tickets-btn, &[data-availability="none"] .supt-cardCalendar__tickets-btn, &[data-availability="soon"] button.supt-cardCalendar__tickets-btn, &[data-availability="free"] button.supt-cardCalendar__tickets-btn':
			buttonDisable,

		'&[data-availability="soon"]': {
			'.supt-cardDateTime__tickets-btn': {
				'.supt-button__inner span': {
					backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 13 13' width='13' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath d='M6.5 0C10.0899 0 13 2.9101 13 6.5S10.0899 13 6.5 13 0 10.0899 0 6.5 2.9101 0 6.5 0Z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M6.5 13c3.5899 0 6.5-2.9101 6.5-6.5S10.0899 0 6.5 0 0 2.9101 0 6.5 2.9101 13 6.5 13Z' stroke='%23737373' stroke-width='2.8' fill='none' stroke-miterlimit='5'/%3E%3C/g%3E%3Cpath d='M8.9944 8.1606 6.44 6.6366 6.4974 3' stroke='%23737373' stroke-width='1.4' fill='none' stroke-miterlimit='10'/%3E%3C/svg%3E") !important`,
					backgroundPosition: 'calc(100% - 1px) center',
					backgroundRepeat: 'no-repeat',
					paddingRight: '22px !important',

					'&::before, &::after': {
						content: `none`,
					},
				},
			},
		},

		// States
		'&.supt-cardDateTime--canceled, &.supt-cardDateTime--postponed': {
			'.supt-cardDateTime__date .supt-cardDateTime__date__text': {
				textDecoration: 'line-through',
			},
		},

		'& + .supt-cardDateTime': {
			borderTopWidth: 0,
		},
	});

export { getStyles };

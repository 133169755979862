import React, { forwardRef, Ref } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Section } from '@superhuit/starterpack-blocks/helpers/Section';
import { SectionOneColumnProps } from '@superhuit/starterpack-blocks/utils/typings';
import { LinkProps } from '@superhuit/starterpack-blocks/helpers/Link/render';
import { ButtonSecondary } from '@/components/atoms/renders';
// styles
import { ListHighlightedArtists } from '@/components/renders';
import { getStyles as getSectionStyles } from '@superhuit/starterpack-blocks/components/organisms/Sections/SectionOneColumn/styles.css';
import { getStyles } from './styles.css';
import block from './block.json';
import { HighlightedArtistType } from '@/components/molecules/ListHighlightedArtists/render';

/**
 * TYPINGS
 */
type SectionHighlightedArtistsProps = SectionOneColumnProps & {
	link?: LinkProps;
	artists?: Array<HighlightedArtistType>;
};

/**
 * COMPONENT
 */
export const SectionHighlightedArtists = forwardRef<
	Ref<any>,
	SectionHighlightedArtistsProps
>(
	(
		{
			anchor,
			className,
			title,
			link,
			artists,
			slug,
			variant: currentVariant = 'dark',
			titleTag = 'h2',
			theme = {},
			getStyles: getDfltStyles = getSectionStyles,
		},
		ref
	) => {
		const { variant, blockTheme } = useThemeBlock(
			slug,
			theme,
			currentVariant
		);

		const trimmedSlug = slug.split('/');
		const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

		const rootClass = cx(
			'supt-section supt-section--oneCol',
			`supt-section--${modifierSlug}`,
			'supt-section--colored',
			getDfltStyles(blockTheme),
			getStyles(blockTheme),
			className
		);

		return artists?.length ? (
			<Section
				id={anchor}
				className={rootClass}
				variant={variant}
				slug={slug}
				ref={ref}
			>
				<div className="supt-section__inner">
					{(title || link?.href) && (
						<div className="supt-section__headline">
							{title && (
								<Section.Title
									text={title}
									titleTag={titleTag}
								/>
							)}

							{link?.href ? (
								<div className="supt-section__linkWrapper">
									<ButtonSecondary {...link} />
								</div>
							) : null}
						</div>
					)}
					<div className="supt-section__content">
						<ListHighlightedArtists artists={artists} />
					</div>
				</div>
			</Section>
		) : null;
	}
);

// @ts-ignore
SectionHighlightedArtists.slug = block.slug;
// @ts-ignore
SectionHighlightedArtists.title = block.title;

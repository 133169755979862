import { mergeDeep } from '@superhuit/starterpack-utils';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import { ListCardsThemeControls } from '@superhuit/starterpack-blocks/components/themings'; // Needs to be called from /components folder to work properly..

import block from './block.json';

export const ListEventsThemeControls: ThemeControlsType = mergeDeep(
	ListCardsThemeControls,
	{
		// What's displayed
		title: block.title,

		// Unique string/id of the block (= the key used in theme.json)
		slug: block.slug,
	}
);

import React, { forwardRef, Ref, RefObject, useEffect, useState } from 'react';
import cx from 'classnames';
// package imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { SectionProvider } from '@superhuit/starterpack-context';
import { ThemeProps } from '@superhuit/starterpack-blocks/utils/typings';
// internal imports
import {
	CardCalendar,
	CardCalendarProps,
} from '@/components/molecules/CardCalendar/render';
import block from './block.json';
// styles
import { getStyles } from './styles.css';
import { ListCalendar } from '@/components/renders';

/**
 * TYPINGS
 */
type SectionMonthCalendarProps = {
	index: number;
	month: string;
	events: Array<CardCalendarProps>;
	monthsRef: RefObject<Array<HTMLDivElement>>;
} & ThemeProps;

/**
 * COMPONENT
 */
export const SectionMonthCalendar = forwardRef<
	Ref<any>,
	SectionMonthCalendarProps
>(({ index, month, events = [], monthsRef, theme = {} }, ref) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme, 'default');

	const rootClass = cx('supt-sectionMonthCalendar', getStyles(blockTheme));

	const handlePrevButtonClick = () => {
		monthsRef.current[index - 1]?.scrollIntoView();
	};

	const handleNextButtonClick = () => {
		monthsRef.current[index + 1]?.scrollIntoView();
	};

	// console.log(month, events);

	return (
		<SectionProvider variant={variant}>
			<div
				className={rootClass}
				data-block={block.slug}
				data-variant={variant}
				// @ts-ignore
				ref={ref}
			>
				<div className="supt-sectionMonthCalendar__inner">
					<div className="supt-sectionMonthCalendar__header">
						<button
							className="supt-sectionMonthCalendar__prev-btn"
							onClick={handlePrevButtonClick}
							disabled={index === 0}
						>
							<svg
								width="18"
								height="18"
								xmlns="http://www.w3.org/2000/svg"
							>
								<use href="#arrow-right" />
							</svg>
						</button>
						<h2 className="supt-sectionMonthCalendar__month">
							{month}
						</h2>
						<button
							className="supt-sectionMonthCalendar__next-btn"
							onClick={handleNextButtonClick}
							disabled={index === monthsRef.current?.length - 1}
						>
							<svg
								width="18"
								height="18"
								xmlns="http://www.w3.org/2000/svg"
							>
								<use href="#arrow-right" />
							</svg>
						</button>
					</div>
					<div className="supt-sectionMonthCalendar__days">
						<ListCalendar>
							{events.map((show, id) => (
								<CardCalendar key={id} {...show} />
							))}
						</ListCalendar>
					</div>
				</div>
			</div>
		</SectionProvider>
	);
});

// @ts-ignore
SectionMonthCalendar.slug = block.slug;
// @ts-ignore
SectionMonthCalendar.title = block.title;

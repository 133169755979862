import cx from 'classnames';
import { useState } from 'react';

import { getStyles } from './styles/switch-toggle.css';

const toggleWidth = 36;
const toggleHeight = 18;
const toggleBorderWidth = 1;
const borderWidthFocus = 4;

const white = '#fff';
const grey900 = '#1e1e1e';
const wpAdminThemeColor = '#007cba';

export default function SwitchToggle({ id, label, checked, onChange }) {
	const [isFocused, setIsFocused] = useState(false);

	return (
		<div className={cx('supt-switch-toggle', getStyles())}>
			<label className="supt-switch-toggle__label" htmlFor={id}>
				{label}
			</label>
			<span className="supt-switch-toggle__field">
				<input
					className="supt-switch-toggle__input"
					id={id}
					type="checkbox"
					checked={checked}
					onChange={() => onChange()}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
				/>
				<span
					className="supt-switch-toggle__track"
					style={{
						backgroundColor: checked ? wpAdminThemeColor : white,
						border: `${toggleBorderWidth}px solid ${
							checked ? wpAdminThemeColor : grey900
						}`,

						boxShadow: `${
							isFocused
								? `0 0 0 2px ${white}, 0 0 0 ${borderWidthFocus}px ${wpAdminThemeColor}`
								: 'initial'
						}`,
					}}
				/>
				<span
					className="supt-switch-toggle__thumb"
					style={{
						backgroundColor: checked ? white : grey900,
						transform: `translateX(
							${
								checked
									? toggleWidth -
									  toggleBorderWidth * 4 -
									  (toggleHeight - toggleBorderWidth * 4)
									: 0
							}px
						)`,
					}}
				/>
			</span>
		</div>
	);
}

import React, { SetStateAction, Dispatch } from 'react';

export interface HighProdContextData {
	activeId: number;
	setActiveId: Dispatch<SetStateAction<number>>;
}

const HighProdContext = React.createContext<HighProdContextData | undefined>(
	undefined
);

export const HighProdProvider = ({ activeId, setActiveId, children }) => {
	return (
		<HighProdContext.Provider
			value={{
				setActiveId,
				activeId,
			}}
		>
			{children}
		</HighProdContext.Provider>
	);
};

export default HighProdContext;

import {
	// atoms
	ButtonAnchorThemeControls,
	ButtonFilterThemeControls,
	ButtonPrimaryThemeControls,
	ButtonSecondaryThemeControls,
	HeadingThemeControls,
	IntroductionThemeControls,
	ListThemeControls,
	ParagraphThemeControls,
	// molecules
	AccordionThemeControls,
	CardAddressThemeControls,
	// CardCtaThemeControls,
	CardFeatureThemeControls,
	CardIconTextThemeControls,
	CardKeyfactTextThemeControls,
	CardKeyfactIconThemeControls,
	CardLogoThemeControls,
	CardNewsThemeControls,
	// CardPartnerThemeControls,
	CardPeopleThemeControls,
	CardResourceThemeControls,
	FormThemeControls,
	GdprThemeControls,
	ImageThemeControls,
	VideoThemeControls,
	MediaThemeControls,
	ListAccordionsThemeControls,
	ListNewsThemeControls,
	SliderThemeControls,
	// organisms
	CategoriesFilterThemeControls,
	// FooterFullThemeControls,
	// FooterSimpleThemeControls,
	PageHeaderThemeControls,
	PageHeaderSimpleThemeControls,
	PageHeaderTwoColumnsThemeControls,
	PaginationThemeControls,
	// SectionNewsThemeControls,
	// dynamic (starterpack)
	ListAddressThemeControls,
	ListButtonsThemeControls,
	ListButtonsAnchorThemeControls,
	ListCtasThemeControls,
	ListFeaturesThemeControls,
	ListIconTextThemeControls,
	ListKeyfactsIconThemeControls,
	ListKeyfactsTextThemeControls,
	ListLogosThemeControls,
	ListPeopleThemeControls,
	ListResourcesThemeControls,
	SectionButtonsAnchorThemeControls,
	// -- GENERATOR CORE BLOCKS IMPORT SLOT --
} from '@superhuit/starterpack-blocks/themings';

// Custom examples
// prettier-ignore
import {
	// dynamic (custom)
	// ---
	CardCalendarThemeControls,
	CardCtaThemeControls,
	CardDateTimeThemeControls,
	CardDateTourThemeControls,
	CardShowThemeControls,
	FooterThemeControls,
	HeaderAThemeControls,
	HeaderBThemeControls,
	HeaderCThemeControls,
	HomeCalendarThemeControls,
	ListCalendarThemeControls,
	ListShowsThemeControls,
	ListShowsEventsThemeControls,
	MainNavThemeControls,
	QuoteThemeControls,
	SectionCardsShowThemeControls,
	SectionDatesTimesThemeControls,
	SectionDatesTourThemeControls,
	SectionCtasThemeControls,
	SectionDetailsThemeControls,
	SectionFaqThemeControls,
	SectionRichContentThemeControls,
	SubsectionQuoteThemeControls,
	TagThemeControls,
	SubsectionCenteredTextThemeControls,
	SubsectionTwoImagesThemeControls,
	SubsectionBigTextImagesThemeControls,
	SubsectionGalleryThemeControls,
	SubsectionKeywordImageThemeControls,
	SubsectionTextMediaThemeControls,
	SubsectionLeadingArtistsThemeControls,
	CardRoleThemeControls,
	SubsectionHeaderThemeControls,
	SectionDocumentationThemeControls,
	SectionPartnersThemeControls,
	ListFilesThemeControls,
	SectionCardsArtistThemeControls,
	ListArtistsThemeControls,
	SectionCardsEventThemeControls,
	ListEventsThemeControls,
	CardEventThemeControls,
	SectionHighlightedArtistsThemeControls,
	ListHighlightedArtistsThemeControls,
	SectionHighlightedProductionsThemeControls,
	ListHighlightedProductionsThemeControls,
	SectionCardsPressThemeControls,
	SectionPressSliderThemeControls,
	SectionArtistsSliderThemeControls,
	CardArtistThemeControls,
	SectionCardsPartnerThemeControls,
	CardPartnerThemeControls,
	ListPartnersThemeControls,
	SectionMonthCalendarThemeControls,
	FiltersThemeControls,
	ResultsInfoThemeControls,
	AlertThemeControls,
	CardProductionThemeControls,
	ListProductionsThemeControls,
	SectionCardsProductionThemeControls,
	SectionSubscriptionThemeControls,
	CardSubscriptionThemeControls,
	CardShopThemeControls,
	ListShopThemeControls,
	SectionCardsShopThemeControls,
	GoogleSearchThemeControls,
	// -- GENERATOR CUSTOM BLOCKS IMPORT SLOT --
} from '@/components/themings';

// templates
import { SinglePageThemeControls } from '@/templates/SinglePage/theming';
import { CalendarPageThemeControls } from '@/templates/CalendarPage/theming';
import { EventsPageThemeControls } from '@/templates/EventsPage/theming';
import { SeasonPageThemeControls } from '@/templates/SeasonPage/theming';

export const blocksThemeControls = [
	// atoms
	ButtonAnchorThemeControls,
	ButtonFilterThemeControls,
	ButtonPrimaryThemeControls,
	ButtonSecondaryThemeControls,
	HeadingThemeControls,
	IntroductionThemeControls,
	ListThemeControls,
	ParagraphThemeControls,
	// molecules
	AccordionThemeControls,
	CardAddressThemeControls,
	CardCtaThemeControls,
	CardFeatureThemeControls,
	CardIconTextThemeControls,
	CardKeyfactTextThemeControls,
	CardKeyfactIconThemeControls,
	CardLogoThemeControls,
	CardNewsThemeControls,
	// CardPartnerThemeControls,
	CardPeopleThemeControls,
	CardResourceThemeControls,
	FormThemeControls,
	GdprThemeControls,
	ImageThemeControls,
	VideoThemeControls,
	MediaThemeControls,
	ListAccordionsThemeControls,
	ListNewsThemeControls,
	SliderThemeControls,
	// organisms
	CategoriesFilterThemeControls,
	// FooterFullThemeControls,
	// FooterSimpleThemeControls,
	PageHeaderThemeControls,
	PageHeaderSimpleThemeControls,
	PageHeaderTwoColumnsThemeControls,
	PaginationThemeControls,
	// SectionNewsThemeControls,
	// dynamic (starterpack)
	ListAddressThemeControls,
	ListButtonsThemeControls,
	ListButtonsAnchorThemeControls,
	ListCtasThemeControls,
	ListFeaturesThemeControls,
	ListIconTextThemeControls,
	ListKeyfactsIconThemeControls,
	ListKeyfactsTextThemeControls,
	ListLogosThemeControls,
	ListPeopleThemeControls,
	ListResourcesThemeControls,
	SectionButtonsAnchorThemeControls,
	// dynamic (custom)
	CardCalendarThemeControls,
	CardDateTimeThemeControls,
	CardDateTourThemeControls,
	CardShowThemeControls,
	FooterThemeControls,
	HeaderAThemeControls,
	HeaderBThemeControls,
	HeaderCThemeControls,
	HomeCalendarThemeControls,
	ListCalendarThemeControls,
	ListShowsThemeControls,
	ListShowsEventsThemeControls,
	MainNavThemeControls,
	QuoteThemeControls,
	SectionRichContentThemeControls,
	SectionCardsShowThemeControls,
	SectionDatesTimesThemeControls,
	SectionDatesTourThemeControls,
	SectionFaqThemeControls,
	SectionCtasThemeControls,
	SectionDetailsThemeControls,
	SubsectionCenteredTextThemeControls,
	SubsectionQuoteThemeControls,
	SubsectionTwoImagesThemeControls,
	SubsectionBigTextImagesThemeControls,
	SubsectionGalleryThemeControls,
	SubsectionKeywordImageThemeControls,
	SubsectionTextMediaThemeControls,
	SubsectionLeadingArtistsThemeControls,
	CardRoleThemeControls,
	SubsectionHeaderThemeControls,
	SectionDocumentationThemeControls,
	TagThemeControls,
	SectionPartnersThemeControls,
	ListFilesThemeControls,
	SectionCardsArtistThemeControls,
	ListArtistsThemeControls,
	SectionCardsEventThemeControls,
	ListEventsThemeControls,
	CardEventThemeControls,
	SectionHighlightedArtistsThemeControls,
	ListHighlightedArtistsThemeControls,
	SectionHighlightedProductionsThemeControls,
	ListHighlightedProductionsThemeControls,
	SectionCardsPressThemeControls,
	SectionPressSliderThemeControls,
	SectionArtistsSliderThemeControls,
	CardArtistThemeControls,
	SectionCardsPartnerThemeControls,
	CardPartnerThemeControls,
	ListPartnersThemeControls,
	SectionMonthCalendarThemeControls,
	FiltersThemeControls,
	ResultsInfoThemeControls,
	AlertThemeControls,
	CardProductionThemeControls,
	ListProductionsThemeControls,
	SectionCardsProductionThemeControls,
	SectionSubscriptionThemeControls,
	CardSubscriptionThemeControls,
	CardShopThemeControls,
	ListShopThemeControls,
	SectionCardsShopThemeControls,
	GoogleSearchThemeControls,
	// -- GENERATOR THEME CONTROLS SLOT --
];

export const templatesThemeControls = [
	// templates
	SinglePageThemeControls,
	CalendarPageThemeControls,
	EventsPageThemeControls,
	SeasonPageThemeControls,
];

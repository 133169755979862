import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import {
	fluidPadding,
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	grid,
	resetButton,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { transitions } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	calendarButtonPrevHover,
	calendarButtonNextHover,
} from '@/css/animations.css';

const getStyles = (styles) =>
	css(
		fluidPadding({
			top: [45, 65],
			bottom: [40, 70],
		}),
		{
			scrollMarginTop: 70,

			'&:last-child': fluidValues('paddingBottom', 100, 150),
			'html.is-nav-small &': {
				scrollMarginTop: 20,
			},

			'.supt-sectionMonthCalendar__inner': grid,

			'.supt-sectionMonthCalendar__header, .supt-sectionMonthCalendar__days':
				{
					gridColumn: '1 / span 12',
				},

			'.supt-sectionMonthCalendar__header': cssObj(
				fluidValues('top', 114, 132),
				{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',

					position: 'sticky',
					backgroundColor: '#fff',
					zIndex: 2,
					paddingTop: 5,

					'html.is-nav-small &': {
						top: 58,
					},
				}
			),

			'.supt-sectionMonthCalendar__prev-btn, .supt-sectionMonthCalendar__next-btn':
				cssObj(resetButton, {
					position: 'relative',
					overflow: 'hidden',
					width: 26,
					height: 26,

					'&::before': {
						content: '""',
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: 0,
						left: 0,
						backgroundColor: '#000',
						borderRadius: '50%',
						zIndex: -1,
						transform: 'scale(0)',
						transition: `transform ${transitions.mid} ease`,
					},

					svg: {
						display: 'block',
						margin: '0 auto',
					},

					'&:hover, &:focus-within': {
						'&::before': {
							transform: 'scale(1)',
						},
					},

					'&[disabled]': {
						pointerEvents: 'none',
						svg: {
							opacity: 0.5,
						},
					},
				}),

			'.supt-sectionMonthCalendar__prev-btn': {
				svg: {
					transform: 'scaleX(-1)',
				},

				'&:hover, &:focus-within': {
					svg: {
						animation: `${calendarButtonPrevHover} ${transitions.mid} forwards`,
					},
				},
			},
			'.supt-sectionMonthCalendar__next-btn': {
				'&:hover, &:focus-within': {
					svg: {
						animation: `${calendarButtonNextHover} ${transitions.mid} forwards`,
					},
				},
			},

			'.supt-sectionMonthCalendar__month': cssObj(
				fontStyle({
					color: '#000',
					...{
						fontFamily: 'Knockout, sans-serif',
						fontWeight: '500',
						fontSize: [42, 70],
						lineHeight: [36, 58],
						letterSpacing: [0, 0],
						textTransform: 'uppercase',
						margin: {
							top: '0.3em',
							bottom: '0.3em',
						},
					},
					textAlign: 'center',
				}),
				{
					marginLeft: 30,
					marginRight: 30,
				}
			),

			'.supt-listCards--list-calendar': {
				margin: 0,
			},
		}
	);

export { getStyles };

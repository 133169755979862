import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { resetFirstLastChildMargins } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-section--section-faq': cssObj(
			fluidValues('paddingTop', 100, 200, true),
			fluidValues('paddingBottom', 100, 200, true),
			{
				'.supt-section__uptile, .supt-section__introduction': {
					display: 'none',
				},
				'.supt-section__title': {
					marginBottom: '0.2em !important',
				},

				'.supt-section__col--left': cssObj(resetFirstLastChildMargins, {
					'.supt-listCards--list-buttons': {
						marginTop: 0,
					},
				}),

				'.supt-section__col--right': {
					[`@media (min-width: ${breakpoints.medium})`]: {
						gridRow: '1 / 4 !important',
						marginTop: -14, // Visual adjustment
					},
				},

				'& + .supt-section--section-faq': fluidValues(
					'marginTop',
					-100,
					-200,
					true
				),
			}
		),
	});

export { getCustomStyles };

import React, { FC } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { CardLinkProps } from '@superhuit/starterpack-blocks/helpers/CardControls/typings';
import {
	BlockConfigs,
	ThemeProps,
	ImageProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { Image } from '@superhuit/starterpack-blocks/renders';
import { Link } from '@/components/atoms/Link/render';
import block from './block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type CardPartnerProps = ThemeProps & {
	id: number;
	logo: ImageProps;
	text?: string;
	link?: CardLinkProps;
};

/**
 * COMPONENT
 */
export const CardPartner: FC<CardPartnerProps> & BlockConfigs = ({
	text,
	logo,
	link = {} as CardLinkProps,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx(
		'supt-card supt-cardPartner',
		getStyles(blockTheme),
		link?.href && 'supt-withLink'
	);

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			<Image {...logo} className="supt-cardPartner__logo" />
			{text && (
				<p
					className="supt-cardPartner__text"
					dangerouslySetInnerHTML={{ __html: text }}
				></p>
			)}
			{link?.href && (
				<Link
					href={link?.href}
					target="_blank"
					className="supt-cardPartner__link"
				>
					{/* remove http and slash at the end */}
					<span>
						{link?.href
							.replace(/^https?:\/\//, '')
							.replace(/\/+$/, '')}
					</span>
				</Link>
			)}
		</div>
	);
};

CardPartner.slug = block.slug;
CardPartner.title = block.title;

import React, { FC } from 'react';
import cx from 'classnames';
// package imports
import { SectionTwoColumnsProps } from '@superhuit/starterpack-blocks/utils/typings';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionTwoColumns } from '@superhuit/starterpack-blocks/components/renders'; // Needs to be called from /components folder to work properly..
// internal imports
import block from './block.json';

export type HeaderAProps = {
	hasButtons?: boolean;
	hasImage?: boolean;
} & SectionTwoColumnsProps;

/**
 * COMPONENT
 */
export const HeaderA: FC<HeaderAProps> & BlockConfigs = ({
	hasImage = true,
	...props
}) => {
	const rootClass = cx(
		props.className,
		props.hasButtons && 'supt-section--hasButtons',
		hasImage && 'supt-section--hasImage'
	);

	return <SectionTwoColumns {...props} titleTag="h1" className={rootClass} />;
};

HeaderA.slug = block.slug;
HeaderA.title = block.title;

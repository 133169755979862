import { keyframes } from '@emotion/css';

const linkArrowHover = keyframes`
  from {
		transform: translateX(-100%);
	}
  to {
		transform: translateX(0%);
  }
`;
const linkArrowOut = keyframes`
  from {
		transform: translateX(0%);
	}
  to {
		transform: translateX(100%);
  }
`;

const linkArrowExternalHover = keyframes`
  from {
		transform: translate(-100%, 100%);
	}
  to {
		transform: translate(0%, 0%);
  }
`;
const linkArrowExternalOut = keyframes`
  from {
		transform: translate(0%, 0%);
	}
  to {
		transform: translate(100%, -100%);
  }
`;

/**
 * Hover effect for the arrow on the slider buttons
 */
const sliderButtonPrevHover = keyframes`
  0% {
		transform: scaleX(-1) translateX(0%);
	}
  50% {
		transform: scaleX(-1) translateX(100%);
  }
	51% {
		transform: scaleX(-1) translateX(-100%);
		stroke: #fff;
  }
	100% {
		transform: scaleX(-1) translateX(0%);
		stroke: #fff;
	}
`;
const sliderButtonNextHover = keyframes`
  0% {
		transform: translateX(0%);
	}
  50% {
		transform: translateX(100%);
  }
	51% {
		transform: translateX(-100%);
		stroke: #fff;
  }
	100% {
		transform: translateX(0%);
		stroke: #fff;
	}
`;

/**
 * Hover effect for the arrows of the calendar
 */
const calendarButtonPrevHover = keyframes`
 0% {
	 transform: scaleX(-1) translateX(0%);
 }
 50% {
	 transform: scaleX(-1) translateX(100%);
 }
 51% {
	 transform: scaleX(-1) translateX(-100%);
	 filter: invert(1);
 }
 100% {
	 transform: scaleX(-1) translateX(0%);
	 filter: invert(1);
 }
`;
const calendarButtonNextHover = keyframes`
 0% {
	 transform: translateX(0%);
 }
 50% {
	 transform: translateX(100%);
 }
 51% {
	 transform: translateX(-100%);
	 filter: invert(1);
 }
 100% {
	 transform: translateX(0%);
	 filter: invert(1);
 }
`;

export {
	linkArrowHover,
	linkArrowOut,
	linkArrowExternalHover,
	linkArrowExternalOut,
	sliderButtonPrevHover,
	sliderButtonNextHover,
	calendarButtonPrevHover,
	calendarButtonNextHover,
};

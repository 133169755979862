import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	fluidValues,
	flexGapPolyfill,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { resetFirstLastChildMargins } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';

// const getDynamicStyles = (styles) => cssObj();

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-subsection__content': {
			'> *, h2, h3, h4, .supt-introduction, .supt-paragraph, .supt-list':
				{
					color: styles?.color,
				},

			'.supt-quote': {
				'.supt-quote__content': fontStyle({
					...styles?.quote?.content?.typography,
				}),
			},

			'.supt-subsection__author': fontStyle({
				...styles?.author?.typography,
				textAlign: styles?.author?.textAlignment,
				color: styles?.author?.color,
			}),
		},
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		'.supt-subsection__content': cssObj(resetFirstLastChildMargins, {
			gridColumn: '1/13',

			'> *': {
				width: '100%',
				maxWidth: '650px',
				marginLeft: 'auto',
				marginRight: 'auto',
			},

			'h2, h3, h4': {
				textAlign: 'center',
			},

			'.supt-paragraph + .supt-list, .supt-list + .supt-paragraph, .supt-list + .supt-list':
				{
					marginTop: '40px',
				},

			'.supt-paragraph + .supt-paragraph': {
				marginTop: '1em',
			},

			'.supt-quote': cssObj(
				fluidValues('marginTop', 80, 100),
				fluidValues('marginBottom', 60, 80),
				{
					maxWidth: '786px',

					[`@media (min-width: ${breakpoints.medium})`]: {
						transform: 'translateX(60px)',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						transform: 'translateX(130px)',
					},

					cite: {
						marginTop: '26px',
					},
				}
			),

			'.supt-subsection__author': cssObj(
				fluidValues('marginTop', 20, 60),
				{
					opacity: 0.6,
				}
			),
		}),
	});

const getEditStyles = () =>
	css({
		'.wp-block[data-type="supt/introduction"], .wp-block[data-type="core/heading"], .wp-block[data-type="supt/paragraph"], .wp-block[data-type="supt/quote"], .wp-block[data-type="core/list"]':
			{
				width: '100% !important',
				maxWidth: '650px !important',
				marginLeft: 'auto !important',
				marginRight: 'auto !important',
			},
	});

export { getStyles, getEditStyles };

import cx from 'classnames';
import React, { FC } from 'react';
import { isEmpty } from 'lodash';

import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { ButtonProps } from '@superhuit/starterpack-blocks/helpers/Button/typings';

import { Link } from '@/components/atoms/Link/render';

// imports from upstream component
import block from '@superhuit/starterpack-blocks/components/atoms/Buttons/ButtonPrimary/block.json';
import { getStyles } from '@superhuit/starterpack-blocks/components/atoms/Buttons/ButtonPrimary/styles.css';

type ButtonPrimaryProps = {
	pageId?: string;
} & ButtonProps;

export const ButtonPrimary: FC<ButtonPrimaryProps> & BlockConfigs = ({
	title = '',
	href = '',
	opensInNewTab,
	className,
	theme = {},
	pageId = null,
	...props
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx(
		className,
		'supt-button',
		'supt-buttonPrimary',
		getStyles(blockTheme)
	);

	return isEmpty(title) ? null : (
		<Link
			href={href}
			className={rootClass}
			{...(opensInNewTab && {
				target: '_blank',
				rel: 'noopener noreferrer',
			})}
			data-block={block.slug}
			data-variant={variant}
			{...props}
		>
			<span className="supt-button__inner">
				{title ? <span>{title}</span> : null}
			</span>
		</Link>
	);
};

ButtonPrimary.slug = block.slug;
ButtonPrimary.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	breakpoints,
	containers,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	container,
	resetButton,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	fluidValues,
	fontStyle,
	fluidPadding,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { linkWithArrowLeft } from '@/css/mixins.css';

const navTransitions = {
	xxs: '0.05s',
	xs: '0.1s',
	s: '0.2s',
	m: '0.3s',
	l: '0.4s',
	xl: '0.5s',
	easeInOut: 'cubic-bezier(0.42, 0, 0.47, 1)',
	easeOut: 'cubic-bezier(0, 0, 0.44, 1)',
};

const toggle = {
	ease: 'cubic-bezier(0.48, 0, 0.46, 1)',
	barHeight: '3px',
};

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-mainNav__bottom': fluidPadding(styles?.padding),

		'.supt-mainNav__logo': {
			'.supt-mainNav__logoImg': fluidValues(
				'width',
				styles?.logo?.width?.[0],
				styles?.logo?.width?.[1]
			),
		},

		'.cart': {
			'&__qty': cssObj(fontStyle(styles?.cart?.typography, false), {
				position: 'absolute',
				backgroundColor: styles?.cart?.backgroundColor,
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				boxShadow: '0 0 4px 0 rgba(0,0,0,0.3)',

				lineHeight: 17,
				width: 17,
				height: 17,
				bottom: -5,
				right: -8,
			}),
		},

		/***** DESKTOP *****/
		'.supt-mainNav__primary': cssObj(
			fluidValues(
				'columnGap',
				styles?.desktop?.primary?.grid?.gap?.[0],
				styles?.desktop?.primary?.grid?.gap?.[1]
			),
			{
				paddingLeft: '35px',

				/* Children */
				'&__item': {
					'&__label': fontStyle(
						{
							...styles?.desktop?.primary?.typography,
							textAlign: styles?.desktop?.primary?.textAlignment,
							color: styles?.desktop?.primary?.color,
						},
						false
					),
				},
			}
		),

		'.supt-mainNav__secondary': {
			/* Children */
			'&__item, .lang-switcher, &__cart': fluidValues(
				'marginLeft',
				styles?.desktop?.secondary?.grid?.gap?.[0],
				styles?.desktop?.secondary?.grid?.gap?.[1]
			),
			'.lang-switcher, &__cart': fluidValues(
				'paddingLeft',
				styles?.desktop?.secondary?.grid?.gap?.[0],
				styles?.desktop?.secondary?.grid?.gap?.[1]
			),

			'&__item__label, .lang-switcher a': fontStyle(
				{
					...styles?.desktop?.secondary?.typography,
					textAlign: styles?.desktop?.secondary?.textAlignment,
					color: styles?.desktop?.secondary?.color,
				},
				false
			),
		},

		/***** SUBMENU *****/
		'.supt-mainNav__submenu': fluidPadding(styles?.submenu?.padding),

		'.supt-mainNav__submenu__link, .supt-mainNav__socials__label, .supt-mainNav__secondary__submenu__link':
			fontStyle(
				{
					...styles?.desktop?.submenu?.typography,
					textAlign: styles?.desktop?.submenu?.textAlignment,
					color: styles?.desktop?.submenu?.color,
				},
				false
			),

		/***** MOBILE *****/
		'.supt-mainNav__mobile__primary__item button, .supt-mainNav__drawer__title':
			fontStyle(
				{
					...styles?.mobile?.primary?.typography,
					textAlign: styles?.mobile?.primary?.textAlignment,
					color: styles?.mobile?.primary?.color,
				},
				false
			),
		'.supt-mainNav__mobile__secondary__item, .supt-mainNav__mobile__secondary__item .supt-accordion__title, .supt-mainNav__mobile .lang-switcher a, .supt-mainNav__mobile__search span':
			fontStyle(
				{
					...styles?.mobile?.secondary?.typography,
					textAlign: styles?.mobile?.secondary?.textAlignment,
					color: styles?.mobile?.secondary?.color,
				},
				false
			),
		'.supt-mainNav__drawer__link': fontStyle(
			{
				...styles?.mobile?.primary?.submenu?.typography,
				textAlign: styles?.mobile?.primary?.submenu?.textAlignment,
				color: styles?.mobile?.primary?.submenu?.color,
			},
			false
		),

		'.supt-mainNav__toggle': {
			minWidth: styles?.mobile?.hamburger?.width,
			minHeight: styles?.mobile?.hamburger?.height,
			color: styles?.mobile?.hamburger?.color,

			/* Children */
			'.supt-mainNav__toggleIcon': {
				width: styles?.mobile?.hamburger?.width,
				height: styles?.mobile?.hamburger?.height,
			},

			'.supt-mainNav__toggleBar, .supt-mainNav__toggleBar::after, .supt-mainNav__toggleBar::before':
				{
					width: styles?.mobile?.hamburger?.width,
				},
		},

		// // Add margin top to next section after MainNav so it doesn't go beneath MainNav
		// '& + *': fluidValues(
		// 	'marginTop',
		// 	styles?.height?.[0],
		// 	styles?.height?.[1]
		// ),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		position: 'sticky',
		zIndex: 10,
		top: '0',
		width: '100%',

		button: resetButton,

		'.lang-switcher': {
			a: {
				textTransform: 'lowercase',
				display: 'inline-block',
				opacity: 0.5,
				transition: `opacity ${transitions.mid} ease`,

				'&::first-letter': {
					textTransform: 'uppercase',
				},
				'&:not(:first-child)': {
					marginLeft: 10,
				},

				'&:hover, &.isCurrent': {
					opacity: 1,
				},
			},
		},

		/**** DESKTOP *****/
		'.supt-mainNav__top': {
			backgroundColor: '#000',
			position: 'relative',
			zIndex: 3,

			'&__inner': container,
		},
		'.supt-mainNav__bottom': {
			backgroundColor: '#fff',

			'&__inner': cssObj(container, {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				flexWrap: 'wrap',

				'> *': {
					position: 'relative',
					zIndex: 1,
				},

				'.cart': {
					marginRight: '18px',
					display: 'none',
				},
			}),
		},

		'.supt-mainNav__logo': {
			marginRight: 'auto',
			zIndex: 1,

			'.supt-mainNav__logoImg': {
				display: 'block',
				height: 'auto',
			},
		},

		'.supt-mainNav__primary': {
			pointerEvents: 'none',
			display: 'flex',
			justifyContent: 'flex-end',

			'&:not(.hasOpenedSubmenu):hover': {
				'.supt-mainNav__primary__item__label:not(:hover)': {
					opacity: 0.15,
				},
			},

			'&.hasOpenedSubmenu': {
				'.supt-mainNav__primary__item__label:not(:hover, :focus, .isCurrent, .isOpened)':
					{
						opacity: 0.15,
					},
			},

			/* Children */
			'&__item': {
				position: 'relative',
				textDecoration: 'none',
				padding: '10px 0',
				'> *': {
					pointerEvents: 'auto',
				},

				'&__label': {
					// opacity: 0.15,
					transition: `opacity ${transitions.mid} ease`,
				},

				'&:hover, &:focus, &.isCurrent, &.isOpened': {
					'.supt-mainNav__primary__item__label': {
						opacity: '1 !important',
					},
				},

				'&.isNotCurrent': {
					'.supt-mainNav__primary__item__label': {
						opacity: 0.15,
					},
				},

				'&.isOpened': {
					'.supt-mainNav__submenu': {
						'&__link': {
							opacity: '1 !important',
							pointerEvents: 'auto',
							transitionDelay: '0.35s',
						},
					},
				},
			},

			'&__search': {
				pointerEvents: 'auto',
				svg: {
					position: 'relative',
					top: 2,
				},
			},
		},

		'.supt-mainNav__secondary': {
			padding: '13px 0',
			display: 'flex',
			justifyContent: 'flex-end',

			'&__nav': {
				display: 'flex',
			},

			'&__item': {
				position: 'relative',

				'&.isOpened': {
					'.supt-mainNav__secondary__item__label svg': {
						transform: 'rotate(180deg)',
					},
					'.supt-mainNav__secondary__submenu': {
						transform: 'scaleY(1)',
						transitionDelay: '0s',
						'&__link': {
							opacity: 1,
							pointerEvents: 'auto',
							transitionDelay: '0.25s',
						},
					},
				},
			},
			'&__item__label': {
				svg: {
					marginLeft: 4,
					verticalAlign: 'middle',
					stroke: '#fff',
					transition: `transform ${transitions.fast} ease`,
				},
			},

			'&__item__label, .lang-switcher a': {
				color: '#fff',
			},

			'&__submenu': {
				position: 'absolute',
				top: 35,
				left: '-20px',
				width: 256,
				backgroundColor: '#fff',
				zIndex: 1,

				transformOrigin: '0 0',
				transform: 'scaleY(0)',
				// transition: `transform ${transitions.mid} ease-in-out 0.25s`,

				'&__link': cssObj(
					linkWithArrowLeft('.supt-mainNav__secondary__submenu'),
					{
						padding: '15px 20px',
						borderBottom: '1px solid rgba(0,0,0,0.1)',

						opacity: 0,
						pointerEvents: 'none',
						transition: `opacity ${transitions.mid} ease-in-out`,
					}
				),
			},

			'&__cart': {
				position: 'relative',
				display: 'flex',
				alignItems: 'center',

				'&::before': {
					content: '""',
					position: 'absolute',
					left: 0,
					top: 'calc(50% - 13px/2)',
					height: 13,
					width: 1,
					backgroundColor: '#fff',
				},

				'.cart__qty': {
					lineHeight: 15,
					width: 15,
					height: 15,
					bottom: -4,
					right: -8,
				},
			},

			'.lang-switcher': {
				position: 'relative',

				'&::before': {
					content: '""',
					position: 'absolute',
					left: 0,
					top: 'calc(50% - 13px/2)',
					height: 13,
					width: 1,
					backgroundColor: '#fff',
				},
			},
		},

		/**** SUBMENU *****/
		'.supt-mainNav__submenu': {
			position: 'fixed',
			right: 0,
			paddingBottom: '60px',
			height: 'auto',
			pointerEvents: 'none',

			'&__inner': {
				padding: '40px 35px',
				position: 'relative',
				zIndex: 1,
			},

			'&__nav': {
				position: 'relative',
				width: '100%',
				margin: 'auto 0',
				gridColumn: '3/span 8',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
			},

			'&__link': {
				marginBottom: 8,
				opacity: 0,
				pointerEvents: 'none',
				transition: `opacity ${transitions.mid} ease-in-out`,

				'&.nav-break': {
					marginTop: 10,
					paddingTop: 20,

					'&::before': {
						content: '""',
						position: 'absolute',
						left: -35,
						right: -35,
						height: 1,
						marginTop: -20,
						backgroundColor: 'rgba(0, 0, 0, 0.1)',
					},
				},
			},
		},

		/**** BACKGROUND / BACKDROP *****/
		'.supt-mainNav__background': {
			position: 'fixed',
			right: 0,
			bottom: 0,
			backgroundColor: 'white',
			borderTop: '1px solid #000',
			zIndex: 0,
			transformOrigin: '100% 0',
			transform: 'scaleX(0)',
			// transition: `transform ${transitions.mid} ease-in-out 0.25s`,

			'&__inner': {
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				padding: '20px 37px 35px 35px',

				transformOrigin: '100% 0',
				transform: 'scaleX(1)',
				opacity: 0,
				transition: `opacity ${transitions.mid} ease-in-out, transform 0.01s ease-in-out 0.25s`,
			},
			'&__close-btn': {
				alignSelf: 'flex-end',
			},
		},
		'.supt-mainNav__backdrop': {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			pointerEvents: 'none',
			opacity: 0,
			zIndex: -1,
			transition: `opacity ${transitions.mid} ease-in-out`,

			'&--secondary': {
				zIndex: 2,
			},
		},

		/**** SOCIALS *****/
		'.supt-mainNav__socials': {
			display: 'flex',
			'&__label': {
				color: 'rgba(0,0,0,0.6)',
				marginRight: 14,
			},
			'&__list': {
				listStyle: 'none',
				display: 'flex',
				gap: 12,
				alignItems: 'center',
			},
			'&__link': {
				svg: {
					display: 'block',
					width: 16,
					height: 16,
				},
			},
		},

		/**** MOBILE *****/
		'.supt-mainNav__mobile': {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			overflow: 'hidden',
			zIndex: 1,
			pointerEvents: 'none',
			opacity: 0,

			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',

			'> *': {
				opacity: 0,
				pointerEvents: 'none',
				transition: `opacity ${transitions.mid} ease-in-out`,
			},

			'&__inner': cssObj(container, {
				height: '100%',
			}),

			'&__main': {
				marginTop: 100,
				maxHeight: 'calc(100% - 250px)',
				overflow: 'scroll',
			},

			'&__nav': {
				padding: '10px 15px',
			},

			'&__primary': {
				listStyle: 'none',
				'&__item': {
					'> button': {
						padding: '22px 0 18px',
						width: '100%',
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',

						svg: {
							transform: 'rotate(-90deg)',
							position: 'relative',
							top: -2,
							width: 11,
							height: 7,
							stroke: '#000',
						},

						'&::after': {
							content: '""',
							position: 'absolute',
							left: `calc(-1 * ${containers.minOffset})`,
							right: `calc(-1 * ${containers.minOffset})`,
							bottom: 0,
							height: 1,
							backgroundColor: 'rgba(0,0,0,0.6)',

							[`@media (min-width: ${breakpoints.tablet})`]: {
								left: `calc(-1 * ${containers.tabletOffset})`,
								right: `calc(-1 * ${containers.tabletOffset})`,
							},
						},
					},
				},
			},

			'&__secondary': {
				marginTop: 40,

				'&__item': {
					display: 'block',
					margin: '20px 0',

					'&__submenu': {
						padding: '0 20px',
					},

					'.supt-mainNav__drawer__link.nav-break::before': {
						left: `calc(-1 * ${containers.minOffset} - 20px)`, // - submenu padding
						right: `calc(-1 * ${containers.minOffset} - 20px)`, // - submenu padding

						[`@media (min-width: ${breakpoints.tablet})`]: {
							left: `calc(-1 * ${containers.tabletOffset} - 20px)`, // - submenu padding
							right: `calc(-1 * ${containers.tabletOffset} - 20px)`, // - submenu padding
						},
					},

					'.supt-accordion': {
						boxShadow: 'none',
						background: 'none',
						width: 'auto',
					},
					'.supt-accordion__title': {
						width: 'auto',
						padding: '0 18px 0 0',
						textTransform: 'none',
						zIndex: 'auto',

						svg: {
							right: 6,
							width: 10,
							height: 10,
						},
					},
					'.supt-accordion__panel': {
						overflow: 'visible !important',
						zIndex: 'auto',
						'&::before': {
							display: 'none',
						},
					},

					'.supt-accordion__content': {
						padding: '42px 0 5px',
						position: 'relative',

						'&::before': {
							content: '""',
							position: 'absolute',
							top: 20,
							bottom: 0,
							left: `calc(-1 * ${containers.minOffset})`,
							right: `calc(-1 * ${containers.minOffset})`,
							zIndex: -1,
							borderTop: '1px solid rgba(0, 0, 0, 0.1)',
							borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
							backgroundColor: 'rgba(237, 237, 236, 0.5)',
							[`@media (min-width: ${breakpoints.tablet})`]: {
								left: `calc(-1 * ${containers.tabletOffset})`,
								right: `calc(-1 * ${containers.tabletOffset})`,
							},
						},
					},
				},
			},

			'&__footer': {
				zIndex: 1,
				backgroundColor: '#fff',
				position: 'relative',

				'&::before': {
					content: '""',
					position: 'absolute',
					bottom: '100%',
					left: 0,
					width: '100%',
					height: '100%',
					background: 'linear-gradient(transparent, #fff)',
					pointerEvents: 'none',
				},

				'&__top': {
					padding: '15px 0',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				},
				'&__bottom': {
					borderTop: '1px solid #000',
					padding: '25px 0',

					'.supt-mainNav__socials__list': {
						justifyContent: 'center',
						gap: 50,
					},
				},
			},

			'&__search': {
				display: 'flex',
				alignItems: 'center',
				svg: {
					width: 17,
					height: 17,
					marginRight: 8,
					overflow: 'visible',
					position: 'relative',
					top: -1,
				},
			},

			'.supt-mainNav__socials__link': {
				svg: {
					width: 20,
					height: 20,
				},
			},

			'.lang-switcher': {
				a: {
					'&:not(:first-child)': {
						marginLeft: 15,
					},
				},
			},
		},

		'&.supt-mainNav--alert:not(.supt-mainNav--alert-hidden)': {
			'.supt-mainNav__mobile__main': {
				marginTop: 120,
			},
		},

		/**** DRAWERS *****/
		'.supt-mainNav__drawer': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: 'calc(100% - 150px)',
			overflow: 'scroll',
			backgroundColor: '#fff',
			padding: '125px 0 50px',
			zIndex: 1,
			opacity: 0,
			visibility: 'hidden',
			transform: 'translate3d(100%, 0, 0)',
			transition: `transform ${transitions.mid} ease-in-out, opacity ${transitions.fast}, visibility ${transitions.fast}`,

			'&__inner': container,

			'&__back-btn': {
				position: 'absolute',
				top: 19,
				left: 11,
				padding: '0 5px',
				svg: {
					display: 'block',
					transform: 'scaleX(-1)',
				},
			},

			'&__title': {
				paddingBottom: 15,
				position: 'relative',

				'&::after': {
					content: '""',
					position: 'absolute',
					left: `calc(-1 * ${containers.minOffset})`,
					right: `calc(-1 * ${containers.minOffset})`,
					bottom: 0,
					height: 1,
					backgroundColor: 'rgba(0,0,0,0.6)',

					[`@media (min-width: ${breakpoints.tablet})`]: {
						left: `calc(-1 * ${containers.tabletOffset})`,
						right: `calc(-1 * ${containers.tabletOffset})`,
					},
				},
			},

			'&__nav': {
				padding: '30px 0px',
				listStyle: 'none',
			},

			'&__link': {
				display: 'block',
				marginBottom: 15,

				'&.nav-break': {
					marginTop: 22,
					paddingTop: 22,
					position: 'relative',

					'&::before': {
						content: '""',
						position: 'absolute',
						left: `calc(-1 * ${containers.minOffset})`,
						right: `calc(-1 * ${containers.minOffset})`,
						height: 1,
						marginTop: -22,
						backgroundColor: 'rgba(0, 0, 0, 0.1)',

						[`@media (min-width: ${breakpoints.tablet})`]: {
							left: `calc(-1 * ${containers.tabletOffset})`,
							right: `calc(-1 * ${containers.tabletOffset})`,
						},
					},
				},

				'&.isCurrent': {
					backgroundSize: '100% 2px',
				},
			},

			'&.supt-mainNav__drawer--opened': {
				opacity: 1,
				visibility: 'visible',
				transform: 'translate3d(0, 0, 0)',
			},
		},

		/**** TOGGLE *****/
		'.supt-mainNav__toggle': {
			fontSize: '0',
			display: 'none',
			justifyContent: 'center',
			alignItems: 'center',
			transform: 'translate3d(0, 0, 0)',
			zIndex: 2,

			/* State: focus */
			'&::before': {
				content: '""',
				position: 'absolute',
				width: 'calc(100% + 20px)',
				height: 'calc(100% + 20px)',
				border: '2px solid currentColor',
				opacity: '0',
				transition: 'opacity 0.3s',
			},

			'&:focus': {
				outline: 'none',
				'&::before': {
					opacity: '0.5',
				},
			},

			/* Children */
			'.supt-mainNav__toggleIcon': {
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
			},

			'.supt-mainNav__toggleBar, .supt-mainNav__toggleBar::after, .supt-mainNav__toggleBar::before':
				{
					height: toggle.barHeight,
					backgroundColor: 'currentColor',
				},

			'.supt-mainNav__toggleBar': {
				transition: `background-color 0s ease-out ${navTransitions.xs}`,

				'&::before, &::after': {
					content: '""',
					position: 'absolute',
					left: '0px',
				},

				'&::before': {
					top: '0',
					transition: `top ${navTransitions.xs} ${toggle.ease} ${navTransitions.xs}, transform ${navTransitions.xs} ease-out`,
				},

				'&::after': {
					bottom: '0',
					transition: `top ${navTransitions.xs} ${toggle.ease} ${navTransitions.xs}, transform ${navTransitions.xs} ease-out`,
				},
			},
		},

		'&.supt-mainNav--isPrimarySubmenuOpen': {
			'.supt-mainNav__background': {
				transform: 'scaleX(1)',
				transitionDelay: '0s',

				'&__inner': {
					opacity: 1,
					transitionDelay: '0.35s',
				},
			},
			'.supt-mainNav__backdrop--primary': {
				opacity: 1,
				pointerEvents: 'auto',
			},
		},
		'&.supt-mainNav--isSecondarySubmenuOpen': {
			'.supt-mainNav__backdrop--secondary': {
				opacity: 1,
				pointerEvents: 'auto',
			},
		},

		'&.supt-mainNav--isNavSmall': {
			'.supt-mainNav__top, .supt-mainNav__primary': {
				position: 'fixed',
				opacity: '0',
				pointerEvents: 'none',
				visibility: 'hidden',
			},

			'.supt-mainNav__bottom': {
				height: '64px',
			},

			'.supt-mainNav__toggle, .cart': {
				display: 'flex',
			},

			'.supt-mainNav__mobile': {
				opacity: 1,
			},

			'.supt-mainNav__background': {
				top: 0,
				borderTop: 0,
			},

			// Add margin top to next section after MainNav so it doesn't go beneath MainNav
			// '& + *': {
			// 	marginTop: 58,
			// },

			'&.supt-mainNav--isNavOpen': {
				'.supt-mainNav__mobile': {
					'> *': {
						pointerEvents: 'auto',
						opacity: 1,
						transitionDelay: '0.25s',
					},
				},

				'.supt-mainNav__background': {
					transform: 'scaleX(1)',
					transitionDelay: '0s',
				},

				'.supt-mainNav__toggle .supt-mainNav__toggleBar': {
					transition: `background-color 0s ease-out ${navTransitions.xxs}`,
					backgroundColor: 'transparent',

					'&::before': {
						top: `calc(50% - ${toggle.barHeight} / 2)`,
						transform: 'rotate(45deg)',
						transition: `top ${navTransitions.xs} ${toggle.ease},
					transform ${navTransitions.xs} ease-out ${navTransitions.xs}`,
					},

					'&::after': {
						bottom: `calc(50% - ${toggle.barHeight} / 2)`,
						transform: 'rotate(-45deg)',
						transition: `bottom ${navTransitions.xs} ${toggle.ease},
					transform ${navTransitions.xs} ease-out ${navTransitions.xs}`,
					},
				},
			},
		},

		// Fix menu elements blinking on 1st load => only add transitions when the header is ready (wait for few milliseconds)
		'&.supt-mainNav--isReady': {
			'.supt-mainNav__secondary__submenu': {
				transition: `transform ${transitions.mid} ease-in-out 0.25s`,
			},
			'.supt-mainNav__background': {
				transition: `transform ${transitions.mid} ease-in-out 0.25s`,
			},
		},
	});

export { getStyles };

import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

export const useVideoThumbnail = (source, id, poster) => {
	const [thumbnail, setThumbnail] = useState(poster);

	useEffect(() => {
		if (!isEmpty(thumbnail)) return;

		const defaultThumbnailArgs = {
			width: 2560,
			height: 1440,
			unoptimized: true,
		};

		if (source === 'youtube') {
			setThumbnail({
				...defaultThumbnailArgs,
				src: `https://img.youtube.com/vi/${id}/maxresdefault.jpg`,
			});
		} else {
			// Videmo
			fetch(`https://vimeo.com/api/v2/video/${id}.json`)
				.then((res) => res.json())
				.then(
					([
						{ thumbnail_large, thumbnail_medium, thumbnail_small },
					]) => {
						setThumbnail({
							...defaultThumbnailArgs,
							src:
								thumbnail_large ??
								thumbnail_medium ??
								thumbnail_small,
						});
					}
				)
				.catch((error) => {
					setThumbnail({
						...defaultThumbnailArgs,
						src: `https://via.placeholder.com/900x500/ccc/ccc?text=`,
					});
				});
		}
	}, []);

	return thumbnail;
};

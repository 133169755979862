import React, { FC } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { CardNews, CardNewsProps } from '../CardNews/render';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import block from '@superhuit/starterpack-blocks/components/molecules/lists/ListNews/block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type ListNewsProps = ThemeProps & {
	posts?: Array<CardNewsProps>;
};

/**
 * COMPONENT
 */
export const ListNews: FC<ListNewsProps> & BlockConfigs = ({
	posts,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx('supt-listCards supt-listNews', getStyles(blockTheme));

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			{posts.map((post, index) => (
				<CardNews key={index} {...post} index={index} isRandom />
			))}
		</div>
	);
};

ListNews.slug = block.slug;
ListNews.title = block.title;

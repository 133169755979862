import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	grid,
	gridColumnGap,
	gridNoMargin,
	resetFirstChildMarginTop,
	resetLastChildMarginBottom,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-section__uptitle': fontStyle(
			{
				...styles?.uptitle?.typography,
				textAlign: styles?.uptitle?.textAlignment,
				color: styles?.uptitle?.color,
			},
			false
		),
		'.supt-section__title': fontStyle(
			{
				...styles?.title?.typography,
				textAlign: styles?.title?.textAlignment,
				color: styles?.title?.color,
			},
			false
		),
		'.supt-section__subtitle': fontStyle(
			{
				...styles?.subtitle?.typography,
				textAlign: styles?.subtitle?.textAlignment,
				color: styles?.subtitle?.color,
			},
			false
		),
		'.supt-introduction': fontStyle(
			{
				...styles?.introduction?.typography,
				textAlign: styles?.introduction?.textAlignment,
				color: styles?.introduction?.color,
			},
			false
		),

		'.supt-headerMetas': {
			'.supt-headerMetas__info': fontStyle(
				{
					...styles?.metas?.text?.typography,
					textAlign: styles?.metas?.text?.textAlignment,
					color: styles?.metas?.text?.color,
				},
				false
			),
			'.supt-headerMetas__practical': {
				'.supt-headerMetas__practical__title': fontStyle(
					{
						...styles?.metas?.practical?.title?.typography,
						textAlign:
							styles?.metas?.practical?.title?.textAlignment,
						color: styles?.metas?.practical?.title?.color,
					},
					false
				),
				'.supt-headerPracticalInfo__title, .supt-headerPracticalInfo__content':
					fontStyle(
						{
							...styles?.metas?.practical?.text?.typography,
							textAlign:
								styles?.metas?.practical?.text?.textAlignment,
							color: styles?.metas?.practical?.text?.color,
						},
						false
					),
			},
		},
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), fluidValues('paddingBottom', 100, 200), {
		paddingTop: 0,

		'.supt-section__inner': cssObj(grid, {
			'.supt-section__image': {
				gridColumn: '1/13',
				position: 'relative',
				overflow: 'hidden',

				'.supt-figure': cssObj(fluidValues('height', 220, 640), {
					display: 'flex',
					width: '100%',

					figure: {
						display: 'flex',
						width: '100%',
						height: '100%',
					},

					img: {
						objectFit: 'cover',
					},
				}),
			},

			'.supt-section__headline': cssObj(
				resetFirstChildMarginTop,
				fluidValues('marginTop', 25, 15),
				{
					gridColumn: '1/13',

					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '2/12',
					},

					'.supt-section__title': {
						fontStyle: 'italic',
						marginTop: '12px',
					},
					'.supt-section__subtitle': {
						marginTop: '8px',
						fontWeight: 400,
					},
				}
			),

			'.supt-section__content': cssObj(
				fluidValues('marginTop', 60, 150),
				gridColumnGap,
				{
					display: 'grid',
					gridColumn: '1/13',
					gridTemplateColumns: '1fr',

					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '2/11',

						gridTemplateColumns: 'repeat(9, 1fr)',
					},

					'.supt-introduction': {
						gridColumn: '1/2',
						textAlign: 'left',
						margin: 0,

						[`@media (min-width: ${breakpoints.medium})`]: {
							gridColumn: '4/10',
							gridRow: '1',
						},
					},
					'.supt-falcDescription': {
						gridColumn: '1/2',
						marginTop: '40px',

						[`@media (min-width: ${breakpoints.medium})`]: {
							gridColumn: '4/10',
							gridRow: '2',
						},
					},
					'.supt-headerListAnchor': {
						gridColumn: '1/2',

						[`@media (min-width: ${breakpoints.medium})`]: {
							gridColumn: '4/10',
							gridRow: '3',
						},
					},
					'.supt-introduction + .supt-headerListAnchor': {
						[`@media (min-width: ${breakpoints.medium})`]: {
							gridRow: '2',
						},
					},
					'.supt-headerMetas': {
						gridColumn: '1/2',

						[`@media (min-width: ${breakpoints.medium})`]: {
							gridColumn: '1/4',
							gridRow: '1 / span 4',
						},
					},
				}
			),
		}),

		'&:not(.supt-headerB--hasImage)': fluidValues('paddingTop', 40, 80),
	});

const getEditStyles = () =>
	css({
		'.supt-section__inner': {
			marginTop: '0 !important',
			marginBottom: '0 !important',

			'.supt-section__headline': {
				'.supt-section__uptitle, .supt-section__title, .supt-section__subtitle':
					{
						marginBottom: 0,
					},
			},

			'.supt-section__content': cssObj(grid, {
				'> .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block':
					{
						display: 'contents',
					},
			}),
		},
	});

export { getStyles, getEditStyles };

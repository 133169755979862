import React, { FC, useEffect, useRef } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ChildrenProps,
	ImageProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { ThemeProps } from '@superhuit/starterpack-blocks/utils/typings';
import { Image } from '@superhuit/starterpack-blocks/renders';
import block from '@superhuit/starterpack-blocks/components/molecules/cards/CardCta/block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type CardCtaProps = {
	title: string;
	text?: string;
	image?: ImageProps;
	isSmall?: boolean;
	backgroundColor?: 'grey' | 'black' | 'pink';
	className?: string;
} & ThemeProps &
	ChildrenProps;

/**
 * COMPONENT
 */
export const CardCta: FC<CardCtaProps> & BlockConfigs = ({
	title,
	text,
	image,
	isSmall,
	backgroundColor = 'grey', // TODO: to deprecate
	theme = {},
	children,
	className,
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	if (backgroundColor && backgroundColor !== 'grey' && !className) {
		className = `is-style-${backgroundColor}`;
	}

	const rootClass = cx(
		'supt-card supt-cardCta',
		// @ts-ignore
		children?.props?.blocks[0].innerBlocks.length === 1 &&
			'supt-cardCta--oneButton',
		getStyles(blockTheme),
		{
			hasImage: image?.src,
			isSmall,
		},
		className
	);

	const elRef = useRef(null);

	useEffect(() => {
		if (isSmall && image?.src) {
			elRef.current.parentElement.classList.add('hasImage');
		}
	}, []);

	// if image ends up being larger than the container, we need to correct the width in "sizes" to fetch a bigger image
	// (e.g. a 2:1 image needs to be 200vw if taking full screen width in a square container, since half of the image will take full width)
	const imageRatio = image ? image?.width / image?.height : 1;
	const containerRatio = 1 / 1; // square
	const ratioCorrection =
		imageRatio > containerRatio ? imageRatio / containerRatio : 1;

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
			ref={elRef}
		>
			{image?.src ? (
				<div className="supt-cardCta__image">
					<Image
						sizes={`(max-width: 767px) ${
							ratioCorrection * 90
						}vw, (max-width: 899px) ${
							ratioCorrection * 40
						}vw, (max-width: 1679px) ${ratioCorrection * 35}vw, ${
							ratioCorrection * 600
						}px`}
						{...image}
					/>
				</div>
			) : null}

			<div className="supt-cardCta__inner">
				<div className="supt-cardCta__content">
					<h3
						className="supt-cardCta__title"
						dangerouslySetInnerHTML={{ __html: title }}
					></h3>

					{text && (
						<p
							className="supt-cardCta__text"
							dangerouslySetInnerHTML={{ __html: text }}
						></p>
					)}
				</div>
				<div className="supt-cardCta__buttons">{children}</div>
			</div>
		</div>
	);
};

CardCta.slug = block.slug;
CardCta.title = block.title;

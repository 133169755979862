import React, { FC } from 'react';
import cx from 'classnames';
import {
	ThemeProps,
	ChildrenProps,
	BlockConfigs,
} from '@superhuit/starterpack-blocks/utils/typings';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import block from './block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
type CardSubscriptionProps = ThemeProps &
	ChildrenProps & {
		title: string;
		price: string;
	};

/**
 * COMPONENT
 */
export const CardSubscription: FC<CardSubscriptionProps> & BlockConfigs = ({
	title,
	price,
	theme = {},
	children,
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx(
		'supt-card supt-cardSubscription',
		getStyles(blockTheme)
	);

	const htmlProps = {
		'data-variant': variant,
	};

	return (
		<div className={rootClass} data-block={block.slug} {...htmlProps}>
			<div className="supt-cardSubscription__title">
				<p dangerouslySetInnerHTML={{ __html: title }}></p>
			</div>

			<div className="supt-cardSubscription__price">
				<p dangerouslySetInnerHTML={{ __html: price }}></p>
			</div>

			<div className="supt-cardSubscription__button">{children}</div>
		</div>
	);
};

CardSubscription.slug = block.slug;
CardSubscription.title = block.title;

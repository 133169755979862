import React, { FC } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';
// styles
import { getStyles } from './styles.css';
import { CardProduction, CardProductionProps } from '../CardProduction/render';

/**
 * TYPINGS
 */
export type ListProductionsProps = ThemeProps & {
	productions?: Array<CardProductionProps>;
};

/**
 * COMPONENT
 */
export const ListProductions: FC<ListProductionsProps> & BlockConfigs = ({
	productions,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx(
		'supt-listCards supt-listProductions',
		getStyles(blockTheme)
	);

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			{productions?.map((production, index) => (
				<CardProduction key={index} {...production} />
			))}
		</div>
	);
};

ListProductions.slug = block.slug;
ListProductions.title = block.title;

import { BoxFluidControl } from '@superhuit/starterpack-customizer';

export const singlePageThemeAttributes = {
	section: {
		padding: {
			top: [80, 120],
			bottom: [80, 120],
		},
		margin: {
			top: [-40, -60],
		},
	},
};

export const SinglePageThemeControls = {
	// name of the block
	title: 'Single Page',

	// slug of the block (= the key used in theme.json)
	slug: 'singlePage',

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: singlePageThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return (
			<>
				<h4>Single Page specs</h4>
				{/* Sections */}
				<h4>Sections Vertical Spacing</h4>
				{/* Paddings */}
				<BoxFluidControl
					label="Section Padding"
					default={fullAttributes?.section?.padding.default}
					inheritsFrom={
						fullAttributes?.section?.padding.default_origin
					}
					values={fullAttributes?.section?.padding.value}
					onChange={(value) =>
						updateAttribute('section.padding', value)
					}
				/>
				{/* Margins */}
				<BoxFluidControl
					label="Section Margin (Reduce space between same variant sections)"
					default={fullAttributes?.section?.margin.default}
					inheritsFrom={
						fullAttributes?.section?.margin.default_origin
					}
					values={fullAttributes?.section?.margin.value}
					onChange={(value) =>
						updateAttribute('section.margin', value)
					}
				/>
			</>
		);
	},
};

import {
	BoxFluidControl,
	BoxRadiusFluidControl,
	SizeControl,
	CustomSelectControl,
	ColorControl,
	RadioGroupControl,
	TEXT_ALIGNS,
} from '@superhuit/starterpack-customizer';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const alertThemeAttributes = {
	backgroundColor: '@tokens.colors.brand-2-base.value',
	text: {
		color: '@tokens.variants.default.contentColor',
		typography: '@tokens.typographies.button',
		textAlignment: 'left',
	},
};

export const AlertThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: alertThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return <></>;
	},
};

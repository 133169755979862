import cx from 'classnames';
import React, { FC, Fragment, useContext, useMemo } from 'react';

import {
	BlockConfigs,
	ChildrenProps,
	ImageProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { DataContext } from '@superhuit/starterpack-context';
import { Link } from '@/components/atoms/Link/render';

import block from './block.json';

import { getStyles } from './styles.css';
import { Tag, TagProps } from '@/components/atoms/Tag/render';
import Image from 'next/image';

/**
 * TYPINGS
 */
type MetaType = {
	href: string;
	title: string;
	type?: string;
};

type HeaderMetasProps = {
	metas?: Array<string | MetaType>;
	genres?: Array<TagProps & { id: number; link?: string }>;
	thematics?: Array<TagProps & { id: number; link?: string }>;
	publics?: Array<{
		id: number;
		slug?: string;
		title: string;
		icon: ImageProps;
		link?: string;
	}>;
	children?: ChildrenProps & {
		props: { blocks: Array<{ attributes: any }> };
	};
};

const Picto = ({ icon, title }) => (
	<>
		<Image {...icon} />
		<p className="supt-headerMetas__public__title">{title}</p>
	</>
);

/**
 * COMPONENT
 */
export const HeaderMetas: FC<HeaderMetasProps> & BlockConfigs = ({
	metas = [],
	genres,
	thematics,
	publics,
	children,
}) => {
	const { __t, locale } = useContext(LocaleContext);
	const { settings } = useContext(DataContext);

	const rootClass = cx('supt-headerMetas', getStyles());

	const hasPracticalInfo = useMemo(
		() =>
			children.props.blocks.some(
				({ attributes }) =>
					attributes?.title && attributes?.title !== ''
			),
		[children.props.blocks]
	);

	return (
		<div className={rootClass} data-block={block.slug}>
			<div className="supt-headerMetas__info">
				{metas.map((m: MetaType | string, i) => {
					if (!m) return null;
					if (
						typeof m === 'object' &&
						m.type === 'special_programme'
					) {
						return (
							<a
								key={i}
								href={
									locale === 'fr'
										? 'https://programme-commun.ch/edition-2024/'
										: 'https://programme-commun.ch/edition-2024-en/'
								}
								target="_blank"
								style={{
									display: 'block',
									marginBottom: '20px',
								}}
							>
								<img
									key={i}
									src={`/images/pc-${locale}.svg`}
									className="supt-footer__logoImg"
									alt=""
									style={{
										width: '90%',
										maxWidth: '240px',
									}}
								/>
							</a>
						);
					} else if (typeof m === 'object' && m.href) {
						return (
							<Link
								key={i}
								className="supt-headerMetas__meta"
								href={m.href}
							>
								{m.title}
							</Link>
						);
					} else {
						return (
							<p
								key={i}
								className="supt-headerMetas__meta"
								dangerouslySetInnerHTML={{
									__html: m as string,
								}}
							/>
						);
					}
				})}
			</div>
			<div className="supt-headerMetas__taxonomies">
				{genres && !!genres.length && (
					<ul className="supt-headerMetas__terms">
						{genres.map(({ id, ...genre }) => (
							<li key={id}>
								<Tag
									{...genre}
									link={`${settings?.pageForSeason?.uri}?genres=${genre?.slug}`}
									isLink
									isClickable
									hasBorder={true}
								/>
							</li>
						))}
						{thematics &&
							thematics.map(({ id, ...thematic }) => (
								<li key={id}>
									<Tag
										{...thematic}
										link={`${settings?.pageForSeason?.uri}?thematics=${thematic?.slug}`}
										isClickable
										isLink
										isSquared
										hasBorder={true}
									/>
								</li>
							))}
					</ul>
				)}
			</div>
			{publics && !!publics.length && (
				<ul className="supt-headerMetas__public">
					{publics.map(({ id, link, ...pictoProps }) => (
						<li key={id}>
							{!!link ? (
								<Link href={link}>
									<Picto {...pictoProps} />
								</Link>
							) : (
								<Picto {...pictoProps} />
							)}
						</li>
					))}
				</ul>
			)}
			{hasPracticalInfo && (
				<div className="supt-headerMetas__practical">
					<p className="supt-headerMetas__practical__title">
						{__t(
							'show-metas-practical-info-title',
							'Infos pratiques'
						)}
					</p>
					{children}
				</div>
			)}
		</div>
	);
};

HeaderMetas.slug = block.slug;
HeaderMetas.title = block.title;

import React, { FC } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
	ChildrenProps,
} from '@superhuit/starterpack-blocks/utils/typings';
// internal
import { getStyles as getDfltStyles } from './styles.css';
import block from './block.json';

export type SubsectionLeadingArtistsProps = {
	title?: string;
	slug: string;
	getStyles?: (styles: any) => string;
} & ThemeProps &
	ChildrenProps;

/**
 * COMPONENT
 */
export const SubsectionLeadingArtists: FC<SubsectionLeadingArtistsProps> &
	BlockConfigs = ({
	slug,
	title,
	children,
	theme = {},
	getStyles = getDfltStyles,
}) => {
	const { variant, blockTheme } = useThemeBlock(slug, theme);

	const trimmedSlug = slug.split('/');
	const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

	const rootClass = cx(
		'supt-subsection',
		`supt-subsection--${modifierSlug}`,
		getStyles(blockTheme)
	);

	return (
		<div className={rootClass} data-block={slug} data-variant={variant}>
			{title ? (
				<h3
					className="supt-subsection__title"
					dangerouslySetInnerHTML={{ __html: title }}
				></h3>
			) : null}
			<div className="supt-subsection__content">{children}</div>
		</div>
	);
};

SubsectionLeadingArtists.slug = block.slug;
SubsectionLeadingArtists.title = block.title;

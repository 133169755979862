import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Section } from '@superhuit/starterpack-blocks/helpers/Section';
import {
	BlockConfigs,
	SectionOneColumnProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { ListShows } from '@/components/renders';
// styles
import { getStyles as getDfltStyles } from '@superhuit/starterpack-blocks/components/organisms/Sections/SectionOneColumn/styles.css';
import block from './block.json';
import { CardShowType } from '@/components/molecules/CardShow/render';
import { isEmpty } from 'lodash';

export type SectionCardsShowProps = {
	isSlider?: boolean;
	events: Array<CardShowType>;
} & SectionOneColumnProps;

/**
 * COMPONENT
 */
export const SectionCardsShow: FC<SectionCardsShowProps> & BlockConfigs = ({
	anchor,
	className,
	uptitle,
	title,
	introduction,
	slug,
	variant: currentVariant = 'default',
	titleTag = 'h2',
	theme = {},
	getStyles = getDfltStyles,
	isSlider = true,
	children,
	events = [],
}) => {
	const { variant, blockTheme } = useThemeBlock(slug, theme, currentVariant);

	const trimmedSlug = slug.split('/');
	const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

	const rootClass = cx(
		'supt-section supt-section--oneCol',
		`supt-section--${modifierSlug}`,
		'supt-section--colored',
		getStyles(blockTheme),
		className
	);

	const { hasButtons, buttons } = useMemo(() => {
		const newBtnsChildren: any = { ...(children as any) };

		if (newBtnsChildren.props.blocks[0]) {
			newBtnsChildren.props.blocks[0].innerBlocks = (
				children as any
			).props.blocks?.[0]?.innerBlocks.filter(
				(block) => !!(block.attributes?.href ?? null)
			);
		}

		return {
			hasButtons: !!newBtnsChildren.props.blocks[0]?.innerBlocks.length,
			buttons: newBtnsChildren,
		};
	}, [children]);

	return isEmpty(events) ? null : (
		<Section
			id={anchor}
			className={rootClass}
			variant={variant}
			slug={slug}
		>
			<div className="supt-section__inner">
				{(uptitle || title || introduction) && (
					<div className="supt-section__headline">
						{title && (
							<Section.Title text={title} titleTag={titleTag} />
						)}
					</div>
				)}
				{hasButtons ? (
					<div className="supt-section__buttons">{buttons}</div>
				) : null}
				<div className="supt-section__content">
					<ListShows isSlider={isSlider} events={events}></ListShows>
				</div>
			</div>
		</Section>
	);
};

SectionCardsShow.slug = block.slug;
SectionCardsShow.title = block.title;

/**
 * FLUID SIZING BREAKPOINTS
 *
 * This defines at which size the fluid-sizing mechanism
 * should start, and end.
 */
export const customBreakpoints = {
	fluidMin: '375px',
	fluidMax: '1680px',
	fluidHuge: '2560px',
	iphone: '414px',
	mobile: '480px',
	small: '600px',
	tablet: '768px',
	medium: '900px',
	laptop: '1024px',
	bigLaptop: '1200px',
	desktop: '1440px',
	bigDesktop: '1920px',
	huge: '2560px',
};

/**
 * TRANSITIONS
 *
 * Define standard transition timings.
 */
export const customTransitions = {
	fast: '0.15s',
	mid: '0.3s',
	slow: '0.5s',
	slowest: '0.8s',
};

/**
 * GRID
 */
/* container */
export const customContainers = {
	minOffset: '16px',
	tabletOffset: '20px',
	laptopOffset: '30px',
	desktopOffset: '36px',
	maxWidth: '1608px',
	maxWidthMq:
		'1680px' /* when the grid reaches its max width: maxWidth + desktopOffset * 2 */,
};

/*
 * GRID LAYOUT
 */
export const customGridLayout = {
	gap: '2.14vw',
	gapMax: '36px',
	gapMaxMq: '1680px' /* approx. when grid-gap reaches grid-gap-max */,
};

/**
 * Z-INDEXES
 */
export const customZIndex = {
	page: 10,
	gdprBanner: 14,
	gdprModal: 15,
	modal: 20,
};

export const buttonIcons = {
	download: {
		white: `url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23FFF' stroke-width='1.4' fill='none' fill-rule='evenodd'%3E%3Cpath d='m12.251 4.657-5.656 5.657L.938 4.657M11.938 12h-11'/%3E%3Cpath stroke-linecap='square' d='M6.594 9.763v-9'/%3E%3C/g%3E%3C/svg%3E")`,
		black: `url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23000' stroke-width='1.4' fill='none' fill-rule='evenodd'%3E%3Cpath d='m12.251 4.657-5.656 5.657L.938 4.657M11.938 12h-11'/%3E%3Cpath stroke-linecap='square' d='M6.594 9.763v-9'/%3E%3C/g%3E%3C/svg%3E")`,
	},
	arrow: {
		white: `url("data:image/svg+xml,%3Csvg width='14' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23FFF' stroke-width='1.4' fill='none' fill-rule='evenodd'%3E%3Cpath d='M7.207.843 12.864 6.5l-5.657 5.657M12.5 6.5H.5'/%3E%3C/g%3E%3C/svg%3E")`,
		black: `url("data:image/svg+xml,%3Csvg width='14' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23000' stroke-width='1.4' fill='none' fill-rule='evenodd'%3E%3Cpath d='M7.207.843 12.864 6.5l-5.657 5.657'/%3E%3Cpath stroke-linecap='square' d='M11.5 6.5H1'/%3E%3C/g%3E%3C/svg%3E")`,
	},
	external: {
		white: `url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23FFF' stroke-width='1.4'%3E%3Cpath d='M2.79 2.4h8v8'/%3E%3Cpath stroke-linecap='square' d='M9.825 3.365 2.4 10.789'/%3E%3C/g%3E%3C/svg%3E")`,
		black: `url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-width='1.4'%3E%3Cpath d='M2.79 2.4h8v8'/%3E%3Cpath stroke-linecap='square' d='M9.825 3.65 2.4 10.789'/%3E%3C/g%3E%3C/svg%3E%0A")`,
	},
	minus: {
		white: `url("data:image/svg+xml,%3Csvg width='13' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6.5H1' stroke='%23FFF' stroke-width='1.4' fill='none' stroke-linecap='square' stroke-miterlimit='10'/%3E%3C/svg%3E")`,
		black: `url("data:image/svg+xml,%3Csvg width='13' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6.5H1' stroke='%23000' stroke-width='1.4' fill='none' stroke-linecap='square' stroke-miterlimit='10'/%3E%3C/svg%3E")`,
	},
	plus: {
		white: `url("data:image/svg+xml,%3Csvg width='13' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6.5H1M6.5 12V1' stroke='%23FFF' stroke-width='1.4' fill='none' stroke-linecap='square' stroke-miterlimit='10'/%3E%3C/svg%3E")`,
		black: `url("data:image/svg+xml,%3Csvg width='13' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6.5H1M6.5 12V1' stroke='%23000' stroke-width='1.4' fill='none' stroke-linecap='square' stroke-miterlimit='10'/%3E%3C/svg%3E")`,
	},
	search: {
		black: `url("data:image/svg+xml,%3Csvg width='16' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(.308)' stroke='%23000' stroke-width='1.5' fill='none' fill-rule='evenodd'%3E%3Ccircle cx='7' cy='7' r='6.25'/%3E%3Cpath d='m11.385 12 3.692 3.692'/%3E%3C/g%3E%3C/svg%3E")`,
	},
};

export const colors = {
	brand: {
		green: '#C6E6D7',
		orange: '#ffcc89',
		blue: '#A8C8F2',
	},
};

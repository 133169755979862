import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	fluidValues,
	flexGapPolyfill,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	gridNoMargin,
	resetFirstLastChildMargins,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';

const getDynamicStyles = (styles) => cssObj();

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		'.supt-subsection__content': cssObj(gridNoMargin, {
			gridColumn: '1/13',

			'.supt-figure': {
				display: 'flex',
				flexDirection: 'column',
			},

			'.supt-figure:first-child': {
				gridColumn: '1/11',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '1/7',
					gridRow: '1/2',
				},
			},

			'.supt-figure:last-child': {
				gridColumn: '5/13',
				marginTop: '62px',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '9/13',
					gridRow: '1/2',
					marginTop: 0,
				},
			},
		}),

		'&.supt-subsection--firstColIsRight': {
			'.supt-subsection__content': {
				'.supt-figure:first-child': {
					gridColumn: '3/13',
					order: 1,
					marginTop: '62px',

					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '7/13',
						marginTop: 0,
					},
				},

				'.supt-figure:last-child': {
					gridColumn: '1/9',
					marginTop: 0,

					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '1/5',
					},
				},
			},
		},
	});

const getEditStyles = () =>
	css({
		'.supt-subsection__content': {
			'.wp-block[data-type="supt/image"]:first-child': {
				gridColumn: '1/11',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '1/7',
					gridRow: '1/2',
					marginTop: 0,
				},
			},

			'.wp-block[data-type="supt/image"]:last-child': {
				gridColumn: '5/13',
				marginTop: '62px',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '9/13',
					gridRow: '1/2',
					marginTop: 0,
				},
			},
		},

		'&.supt-subsection--firstColIsRight': {
			'.supt-subsection__content': {
				'.wp-block[data-type="supt/image"]:first-child': {
					gridColumn: '3/13',
					order: 1,
					marginTop: '62px',

					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '7/13',
						marginTop: 0,
					},
				},

				'.wp-block[data-type="supt/image"]:last-child': {
					gridColumn: '1/9',
					marginTop: 0,

					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '1/5',
						marginTop: 0,
					},
				},
			},
		},
	});

export { getStyles, getEditStyles };

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	gridColumnGap,
	resetFirstLastMargins,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	transitions,
	breakpoints,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	expandLink,
	fluidValues,
	fontStyle,
	fluidPadding,
	fluidBorderWidth,
	fluidBorderRadius,
	lineClamp,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	imageExpandTransition,
	imageReduceTransition,
	linkNoUnderlineWithHover,
} from '@/css/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj(
		fluidBorderWidth(styles?.border?.width),
		fluidBorderRadius(styles?.border?.radius),
		fluidPadding(styles?.padding),
		{
			borderColor: styles?.border?.color,

			'.supt-cardProduction__artist': fontStyle(
				{
					...styles?.artist?.typography,
					textAlign: styles?.artist?.textAlignment,
					color: styles?.artist?.color,
				},
				false
			),

			'.supt-cardProduction__title': fontStyle(
				{
					...styles?.title?.typography,
					textAlign: styles?.title?.textAlignment,
					color: styles?.title?.color,
				},
				false
			),

			'.supt-cardProduction__info p': fontStyle(
				{
					...styles?.text?.typography,
					textAlign: styles?.text?.textAlignment,
					color: styles?.text?.color,
				},
				false
			),
		}
	);

const getStyles = (styles) =>
	css(getDynamicStyles(styles), gridColumnGap, {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		width: '100%',
		borderStyle: 'solid',
		overflow: 'hidden',
		marginTop: '-1px',

		[`@media (min-width: ${breakpoints.laptop})`]: {
			display: 'grid',
			gridTemplateColumns: 'repeat(6, 1fr)',
		},

		'.supt-cardProduction__media': {
			display: 'flex',
			height: '255px',
			width: '100%',

			[`@media (min-width: ${breakpoints.laptop})`]: {
				height: '250px',
				gridColumn: '1/4',
			},
		},

		'.supt-cardProduction__image': {
			width: '100%',
			position: 'relative',
			overflow: 'hidden',

			figure: {
				display: 'flex',
				width: '100%',
				height: '100%',
				maxWidth: 'none !important',
			},

			img: {
				objectFit: 'cover',
				maxWidth: 'none !important',
			},
		},

		'.supt-cardProduction__wrapperContent': {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			paddingTop: '15px',

			[`@media (min-width: ${breakpoints.laptop})`]: {
				paddingRight: '50px',
				paddingTop: '0',
				gridColumn: '4/7',
			},
		},

		'.supt-cardProduction__categories': cssObj(fluidValues('top', 33, 28), {
			display: 'flex',
			gap: '5px',
			position: 'absolute',
			left: '8px',

			[`@media (min-width: ${breakpoints.laptop})`]: {
				marginBottom: '15px',
				position: 'initial',
			},
		}),

		'.supt-cardProduction__content': {
			width: '100%',
			marginBottom: 'auto',
		},

		'.supt-cardProduction__title': cssObj(fluidValues('marginTop', 8, 10), {
			fontStyle: 'italic',
		}),

		'.supt-cardProduction__info': cssObj(fluidValues('marginTop', 8, 12), {
			'.supt-cardProduction__coming': lineClamp(2),
		}),

		'.supt-cardProduction__state': cssObj(fluidValues('marginTop', 8, 10), {
			display: 'inline-flex',
		}),

		'.supt-cardProduction__arrow': {
			backgroundColor: 'black',
			fill: 'white',
			position: 'absolute',
			right: '0',
			display: 'none',
			width: '38px',
			height: '38px',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '50%',
			transition: `opacity ${transitions.mid} ease-out`,
			opacity: 0,

			[`@media (min-width: ${breakpoints.laptop})`]: {
				display: 'flex',
			},
		},

		'&:hover, &:focus': {
			'.supt-cardProduction__arrow': {
				[`@media (min-width: ${breakpoints.laptop})`]: {
					opacity: 1,
				},
			},
		},

		/* with link */
		'&.supt-withLink': cssObj(expandLink(), {
			transition: `all ${transitions.mid} ease-out`,

			'.supt-figure': cssObj(imageReduceTransition),

			'.supt-cardProduction__artist': cssObj(linkNoUnderlineWithHover, {
				backgroundPosition: '0 0.9em',
			}),

			'&:hover, &:focus-within': cssObj({
				'.supt-figure': cssObj(imageExpandTransition),
				'.supt-cardProduction__artist': {
					backgroundSize: '100% 1px',
				},
			}),
		}),
	});

// edit
const getEditGlobalStyles = (styles) =>
	cssObj({
		'.wp-block[data-type="supt/card-production"]': {
			width:
				styles?.minWidth &&
				styles?.maxWidth &&
				`clamp(${styles?.minWidth}, 100%, ${styles?.maxWidth})`,

			figure: {
				height: '100% !important',
			},

			'.supt-card': {
				height: '100%',
			},

			'.editor-post-featured-image__preview img': {
				width: '100%',
				height: '100%',
			},
		},
	});

export { getStyles, getEditGlobalStyles };

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { gridNoMargin } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
// import { resetLastChildMarginBottom } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	breakpoints,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getStyles = (styles) =>
	css({
		position: 'relative',
		overflow: 'hidden',

		'.supt-section__background': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			opacity: 0.4,

			'.supt-section__background__media': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				opacity: 0,
				transition: `opacity ${transitions.mid} ease-out`,

				'&.supt-section__background__media--video, img': {
					objectFit: 'cover',
				},

				'&.supt-section__background__media--active': {
					opacity: 1,
				},
			},
		},

		'.supt-section__headline': {
			zIndex: '1',
		},

		'.supt-section__content': cssObj({
			gridColumn: '7/13',

			[`@media (max-width: calc(${breakpoints.medium} - 1px))`]: {
				marginTop: '40px',
			},
		}),
	});

const getEditStyles = () => css({});

export { getStyles, getEditStyles };

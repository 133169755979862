import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import {
	fluidPadding,
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	grid,
	resetButton,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	breakpoints,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getDynamicStyles = (styles) =>
	cssObj(fluidPadding(styles?.padding), {
		'.supt-resultsInfo__title': fontStyle(
			{
				...styles?.title?.typography,
				textAlign: styles?.title?.textAlignment,
				color: styles?.title?.color,
			},
			false
		),

		'.supt-resultsInfo__text': fontStyle(
			{
				...styles?.text?.typography,
				textAlign: styles?.text?.textAlignment,
				color: styles?.text?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(
		getDynamicStyles(styles),
		grid,
		fluidValues('paddingTop', 40, 80),
		fluidValues('paddingBottom', 75, 150),
		{
			'.supt-resultsInfo__title, .supt-resultsInfo__text, .supt-button': {
				gridColumn: '1/13',
			},

			'.supt-resultsInfo__title': {
				marginTop: '10px',
			},

			'.supt-button': {
				cursor: 'pointer',
				marginTop: '30px',
			},
		}
	);

export { getStyles };

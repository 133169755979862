import React, { FC, useRef, useEffect } from 'react';
import cx from 'classnames';

import { useRouter } from 'next/router';
import { Link } from '@/components/atoms/Link/render';

export const Drawer: FC<any> = ({
	i,
	item,
	uri,
	handleNavSmallItemClick,
	openedDrawer,
	handleDrawerBackButtonClick,
}) => {
	const accordionsRef = useRef([]);

	const router = useRouter();

	// Open accordion if isCurrent (current page is one of its child), otherwise open the 1st accordion by default
	useEffect(() => {
		if (!accordionsRef.current.length) return; // Only for drawers that have accordions

		const handleRouteChange = () => {
			const currentItem = item.items.reduce((acc, subItem, i) => {
				const hasCurrentChild = subItem.items.filter(
					(productItem) => uri === productItem.path
				).length;

				if (hasCurrentChild) {
					return i;
				}
				return acc;
			}, 0);

			const currentAccordion = accordionsRef.current[currentItem];
			currentAccordion.openAccordion();
		};

		router.events.on('routeChangeComplete', handleRouteChange);

		// cleanup
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, []);

	return (
		<>
			<button onClick={() => handleNavSmallItemClick(i)}>
				<span>{item.label}</span>
				<svg viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
					<use href="#chevron" />
				</svg>
			</button>

			<div
				className={cx('supt-mainNav__drawer', {
					'supt-mainNav__drawer--opened': openedDrawer === i,
				})}
			>
				<div className="supt-mainNav__drawer__inner">
					<button
						className="supt-mainNav__drawer__back-btn"
						onClick={handleDrawerBackButtonClick}
					>
						<svg
							viewBox="0 0 13 13"
							width="25"
							height="25"
							xmlns="http://www.w3.org/2000/svg"
						>
							<use href="#arrow-right" />
						</svg>
					</button>
					<p className="supt-mainNav__drawer__title">{item.label}</p>

					<ul className="supt-mainNav__drawer__nav">
						{item.items.map((subItem) => (
							<li
								key={subItem.id}
								className="supt-mainNav__drawer__item"
							>
								<Link
									href={subItem.path ?? '#'}
									suppressHydrationWarning
									className={cx(
										'supt-mainNav__drawer__link',
										...subItem.cssClasses,
										{
											isCurrent:
												uri === subItem.path ||
												uri.includes(subItem.path),
										}
									)}
									aria-current={
										uri === subItem.path ||
										uri.includes(subItem.path)
											? 'page'
											: null
									}
									target={subItem.target}
								>
									{subItem.label}
								</Link>
							</li>
						))}
					</ul>
				</div>
			</div>
		</>
	);
};

import React, { FC, useContext, useMemo } from 'react';
import cx from 'classnames';
// package imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ImageProps,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { Image } from '@superhuit/starterpack-blocks/components/renders';
// internal imports
import { ButtonPrimary } from '@/components/atoms/renders';
import { Tag } from '@/components/atoms/Tag/render';
import useTicketsBtnProps from '@/hooks/useTicketsBtnProps';
// styles
import block from './block.json';
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type CardDateTimeProps = {
	date: any;
	weekdayDate: string;
	time: string;
	location: string;
	availability: 'high' | 'low' | 'none' | 'free' | 'soon';
	availabilityDate?: string;
	state?: 'canceled' | 'postponed';
	stateLabel?: string;
	ticketLink?: string;
	ticketButtonText?: string;
	publics?: Array<{ id: number; title: string; icon: ImageProps }>;
} & ThemeProps;

/**
 * COMPONENT
 */
export const CardDateTime: FC<CardDateTimeProps> & BlockConfigs = ({
	date,
	weekdayDate,
	time,
	location,
	availability,
	availabilityDate,
	state,
	stateLabel,
	ticketLink,
	ticketButtonText,
	theme = {},
	publics = [],
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx(
		'supt-cardDateTime',
		{ [`supt-cardDateTime--${state}`]: !!state },
		ticketLink && 'supt-withLink',
		getStyles(blockTheme)
	);

	const { __t, locale } = useContext(LocaleContext);

	const formattedTime = useMemo(() => {
		if (time) {
			const formattedTime = time.replace(':', 'h');

			// Remove the dot at the end of the weekday if needed
			return formattedTime;
		}
	}, [time]);

	const ticketsBtn = useTicketsBtnProps({
		link: ticketLink,
		text: ticketButtonText,
		availability,
		availabilityDate,
	});

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
			data-availability={availability}
		>
			<div className="supt-cardDateTime__date">
				<p className="supt-cardDateTime__date__text">{weekdayDate}</p>
				{state && (state === 'canceled' || state === 'postponed') ? (
					<Tag
						title={
							stateLabel ? stateLabel : __t(`state-${state}`, '')
						}
						isSquared
						type="alert"
					/>
				) : null}
			</div>

			<div className="supt-cardDateTime__time">
				<p>
					{formattedTime !== '00h00' &&
					formattedTime !== '0h00' &&
					formattedTime !== '12h00 AM'
						? formattedTime
						: ''}
				</p>
			</div>

			<div className="supt-cardDateTime__location-publics">
				<div className="supt-cardDateTime__location">
					<p>{location}</p>
				</div>
				{!!publics.length && (
					<ul className="supt-cardDateTime__public">
						{publics.slice(0, 3).map(({ id, icon, title }) => (
							<li key={id}>
								<Image {...icon} />
							</li>
						))}
						{publics.length > 3 && (
							<li className="supt-cardDateTime__public-extra">
								{publics.length - 3}+
							</li>
						)}
					</ul>
				)}
			</div>

			<button className="supt-cardDateTime__availability">
				<span className="supt-cardDateTime__availability__label">
					{__t(`card-calendar-availability-${availability}`, '')}
				</span>
				<span className="supt-cardDateTime__availability__spot"></span>
			</button>

			{ticketsBtn && (
				<ButtonPrimary
					{...ticketsBtn}
					className="supt-cardDateTime__tickets-btn"
				/>
			)}
		</div>
	);
};

CardDateTime.slug = block.slug;
CardDateTime.title = block.title;

import React, { FC, forwardRef, LinkHTMLAttributes, Ref } from 'react';
import NextLink from 'next/link';

export type LinkProps = LinkHTMLAttributes<any> & {
	scroll?: boolean;
	target?: string;
	ref?: Ref<any>;
	prefetch?: boolean;
};

export const Link: FC<LinkProps> = forwardRef(
	(
		{
			href = '',
			className,
			children,
			scroll = true,
			prefetch = false,
			...props
		},
		ref
	) => {
		const linkProps = {
			...props,
			className,
			ref,
		};

		// Return a button if there is no href
		if (!href) {
			// @ts-ignore
			return <button {...linkProps}>{children}</button>;
		}

		// Don't return next/link if it's an anchor
		if (href.startsWith('#')) {
			return (
				<a href={href} {...linkProps}>
					{children}
				</a>
			);
		}

		// Don't return next/link if it's a file to download (only if domain name isn't external)
		if (
			href.startsWith('/') &&
			(href.endsWith('.pdf') || href.endsWith('.zip'))
		) {
			return (
				<a href={href} {...linkProps} download>
					{children}
				</a>
			);
		}
		// If it links to an external domain name, open in a new tab + add rel to improve performance / prevent security vulnerabilities
		if (
			(!linkProps?.target &&
				!href.startsWith('/') &&
				!href.includes('billetterie.vidy.ch')) ||
			linkProps?.target === '_blank'
		) {
			linkProps.target = '_blank';
			linkProps.rel = 'noopener noreferrer';
		}

		return (
			<NextLink href={href} scroll={scroll} prefetch={prefetch}>
				<a {...linkProps} data-next-link>
					{children}
				</a>
			</NextLink>
		);
	}
);

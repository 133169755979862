import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	gridColumnGap,
	grid,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	breakpoints,
	gridLayout,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getStyles = () =>
	css(
		grid,
		fluidValues('paddingTop', 60, 80),

		{
			marginTop: '0',
			marginBottom: '0',
			paddingBottom: '20px',

			'&:last-child': fluidValues('paddingBottom', 60, 80),

			'.supt-listCards__list': cssObj(
				gridColumnGap,
				// fluidValues('gridRowGap', 43, 82),
				{
					display: 'grid',
					gridTemplateColumns: '1fr',
					gridRowGap: '40px',
					gridColumn: '1/13',

					[`@media (min-width: ${breakpoints.small})`]: {
						gridTemplateColumns: '1fr 1fr',
						gridAutoRows: '20px',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridTemplateColumns: '1fr 1fr 1fr',
					},

					'.supt-cardEvent': {
						gridColumnStart: 'auto !important',
						marginTop: '0 !important',
					},
				}
			),
		}
	);

export { getStyles };

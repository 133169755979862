import React, { FC } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
	ChildrenProps,
} from '@superhuit/starterpack-blocks/utils/typings';
// internal
import { getStyles as getDfltStyles } from './styles.css';
import block from './block.json';

export type SubsectionTwoImagesProps = {
	slug: string;
	firstColIsRight?: boolean;
	getStyles?: (styles: any) => string;
} & ThemeProps &
	ChildrenProps;

/**
 * COMPONENT
 */
export const SubsectionTwoImages: FC<SubsectionTwoImagesProps> &
	BlockConfigs = ({
	slug,
	children,
	firstColIsRight = false,
	theme = {},
	getStyles = getDfltStyles,
}) => {
	const { variant, blockTheme } = useThemeBlock(slug, theme);

	const trimmedSlug = slug.split('/');
	const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

	const rootClass = cx(
		'supt-subsection',
		`supt-subsection--${modifierSlug}`,
		{ 'supt-subsection--firstColIsRight': firstColIsRight },
		getStyles(blockTheme)
	);

	return (
		<div className={rootClass} data-block={slug} data-variant={variant}>
			<div className="supt-subsection__content">{children}</div>
		</div>
	);
};

SubsectionTwoImages.slug = block.slug;
SubsectionTwoImages.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { resetFirstLastMargins } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	transitions,
	breakpoints,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	expandLink,
	fluidValues,
	fontStyle,
	fluidPadding,
	fluidBorderWidth,
	fluidBorderRadius,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-listHighlightedArtists__name': fontStyle(
			{
				...styles?.name?.typography,
				textAlign: styles?.name?.textAlignment,
				color: styles?.name?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		position: 'relative',

		'.supt-listHighlightedArtists__images': {
			display: 'flex',
			opacity: '0',
			width: '100%',
			height: 'auto',
			maxWidth: '375px',
			transition: 'ease .2s',
			position: 'fixed',
			top: 0,
			left: 0,
			pointerEvents: 'none',

			'.supt-listHighlightedArtists__image': {
				transform: 'translate(-50%, -50%)',
				position: 'absolute',
				opacity: '0',
			},

			'&.supt-listHighlightedArtists__images--active': {
				opacity: 1,

				'.supt-listHighlightedArtists__image--active': {
					opacity: 1,
				},
			},
		},

		'.supt-listHighlightedArtists__names': {
			display: 'flex',
			flexDirection: 'column',

			'.supt-listHighlightedArtists__name': cssObj(
				resetFirstLastMargins,
				{
					position: 'relative !important' as any,
					transition: `all ${transitions.mid} ease-out`,
					marginRight: 'auto',

					[`@media (min-width: ${breakpoints.medium})`]: {
						'&.supt-listHighlightedArtists__name--active': {
							opacity: 1,
						},

						'&.supt-listHighlightedArtists__name--notActive': {
							opacity: 0.1,
						},
					},

					/* with link */
					'&.supt-listHighlightedArtists__link': cssObj(
						expandLink(),
						{
							textDecoration: 'initial',
						}
					),
				}
			),
		},
	});

// edit
const getEditGlobalStyles = (styles) =>
	cssObj({
		'.wp-block[data-type="supt/card-highlighted-artist"]': {
			width:
				styles?.minWidth &&
				styles?.maxWidth &&
				`clamp(${styles?.minWidth}, 100%, ${styles?.maxWidth})`,

			'.supt-card': {
				height: '100%',
			},
		},
	});

export { getStyles, getEditGlobalStyles };

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	breakpoints,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { getCustomStyles as getListAccordionsCustomStyles } from '@/components/molecules/ListAccordions/styles.css';
import { getCustomStyles as getListCalendarCustomStyles } from '@/components/molecules/ListCalendar/styles.css';
import { getCustomStyles as getListShowsCustomStyles } from '@/components/molecules/ListShows/styles.css';
import { getCustomStyles as getListShopCustomStyles } from '@/components/molecules/ListShop/styles.css';
import { getCustomStyles as getHeaderACustomStyles } from '@/components/organisms/HeaderA/styles.css';
import { getCustomStyles as getSectionCtasCustomStyles } from '@/components/organisms/SectionCtas/styles.css';
import { getCustomStyles as getSectionCardsEventCustomStyles } from '@/components/organisms/SectionCardsEvent/styles.css';
import { getCustomStyles as getSectionCardsShowCustomStyles } from '@/components/organisms/SectionCardsShow/styles.css';
import { getCustomStyles as getSectionCardsShopCustomStyles } from '@/components/organisms/SectionCardsShop/styles.css';
import { getCustomStyles as getSectionCardsProductionCustomStyles } from '@/components/organisms/SectionCardsProduction/styles.css';
import { getCustomStyles as getSectionDetailsCustomStyles } from '@/components/organisms/SectionDetails/styles.css';
import {
	getCustomStyles as getSectionDocumentationCustomStyles,
	getCustomEditStyles as getSectionDocumentationCustomEditStyles,
} from '@/components/organisms/SectionDocumentation/styles.css';
import { getCustomStyles as getSectionFaqCustomStyles } from '@/components/organisms/SectionFaq/styles.css';
import { getCustomStyles as getSectionPartnersCustomStyles } from '@/components/organisms/SectionPartners/styles.css';
import {
	getCustomStyles as getSectionPressSliderCustomStyles,
	getEditCustomStyles as getSectionPressSliderEditCustomStyles,
} from '@/components/organisms/SectionPressSlider/styles.css';
import {
	getCustomStyles as getSectionArtistsSliderCustomStyles,
	getEditCustomStyles as getSectionArtistsSliderEditCustomStyles,
} from '@/components/organisms/SectionArtistsSlider/styles.css';
import { getCustomStyles as getSectionCardsPartnerCustomStyles } from '@/components/organisms/SectionCardsPartner/styles.css';
// import { getCustomStyles as getSectionRichContentCustomStyles } from '@/components/organisms/SectionRichContent/styles.css';
import {
	getLinkStyle,
	linkUnderlineWithHoverRedraw,
} from '@/css/placeholders.css';
import { handleIconButton } from '@/css/mixins.css';
import {
	sliderButtonNextHover,
	sliderButtonPrevHover,
} from '@/css/animations.css';

const getButtonPrimaryStyle = () => {
	return cssObj(fluidValues('minHeight', 34, 38), {
		minWidth: 'auto !important',

		'&:not(.supt-cardShow__ticket__button)[data-variant="default"]':
			handleIconButton('white', 'black'),
		'&:not(.supt-cardShow__ticket__button)[data-variant="dark"]':
			handleIconButton('black', 'white'),
	});
};

const getButtonSecondaryStyle = () => {
	return cssObj(fluidValues('minHeight', 34, 38), {
		minWidth: 'auto !important',

		'&[data-variant="default"]': handleIconButton('black', 'white'),
		'&[data-variant="dark"]': handleIconButton('white', 'black'),
	});
};

const getButtonAnchorStyle = () => {
	return cssObj(linkUnderlineWithHoverRedraw, {
		minWidth: 'auto !important',
		padding: 0,
		borderWidth: 0,

		'&[data-variant="dark"]': {
			'.supt-button__inner span': {
				'&::after': {
					filter: 'invert(1)',
				},
			},
		},

		'.supt-button__inner': {
			margin: 0,

			span: {
				paddingRight: '25px !important',
				position: 'relative',

				'&:after': {
					position: 'absolute',
					right: 0,
					paddingTop: '0.1em',

					content: `url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-width='1.4'%3E%3Cpath d='m12.251 5.87-5.656 5.657L.938 5.87'/%3E%3Cpath stroke-linecap='square' d='M6.594 10.163v-9'/%3E%3C/g%3E%3C/svg%3E")`,
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundColor: 'transparent',
					borderRadius: '50%',
					transition: `background-color ${transitions.fast} ease`,
					display: 'inline',
					width: '21px',
					height: '21px',
				},
			},
		},
	});
};

const getCustomStyles = (theme, tokens) => {
	return cssObj(
		getListAccordionsCustomStyles(),
		getListCalendarCustomStyles(),
		getListShowsCustomStyles(),
		getListShopCustomStyles(),
		getHeaderACustomStyles(),
		getSectionCardsEventCustomStyles(theme),
		getSectionCardsShowCustomStyles(theme),
		getSectionCtasCustomStyles(),
		getSectionDetailsCustomStyles(),
		getSectionDocumentationCustomStyles(),
		getSectionFaqCustomStyles(),
		getSectionPartnersCustomStyles(),
		getSectionPressSliderCustomStyles(),
		getSectionArtistsSliderCustomStyles(),
		getSectionCardsPartnerCustomStyles(),
		getSectionCardsProductionCustomStyles(),
		getSectionCardsShopCustomStyles(theme),
		// getSectionRichContentCustomStyles(),
		{
			// '.supt-section': {
			// 	backgroundColor: 'red',
			// },

			'.supt-cardNews': {
				'.supt-cardNews__eventLink': fontStyle({
					...tokens?.typographies?.['button'],
					fontFamily: tokens?.fonts?.secondary?.fontFamily,
					color: 'black',
				}),
			},

			'.supt-section--accordion': {
				padding: '0 !important',

				// Remove negative margin top caused by default variant section following each others
				marginTop: '0 !important',

				'& + .supt-section--default': {
					marginTop: '0 !important',
				},

				// underlines of accordion - remove underline if section before or after is not white
				'& + .supt-section:not(.supt-section[--default]), & + .supt-section--colored':
					{
						marginTop: '-1px !important',
					},

				'&:last-child': {
					'.supt-section__inner': {
						borderBottom: 0,
					},
				},
			},

			'.supt-section:not(.supt-section--default), .supt-section--colored':
				{
					'& + .supt-section--accordion .supt-section__inner': {
						borderTop: 0,
					},
				},

			'.supt-figure': {
				'.supt-figure__figcaption': {
					opacity: 0.6,
				},
			},
			'.supt-video': {
				'.supt-video__caption': {
					opacity: 0.6,
				},
			},

			'ul.supt-list': {
				'& >li::before': {
					top: '0.4em',
					width: '8px',
					height: '8px',
					borderTopWidth: '4px',
					borderRightWidth: '0px',
					borderBottomWidth: '4px',
					borderLeftWidth: '8px',
				},
			},
			'ol.supt-list': {
				'& >li::before': {
					fontWeight: '400',
				},
			},

			'.supt-paragraph': {
				wordBreak: 'break-word',
			},

			/***** LINKS *****/
			'.supt-paragraph a, .supt-link': getLinkStyle(),

			/***** BUTTONS *****/
			// Button primary
			'.supt-buttonPrimary': getButtonPrimaryStyle(),
			// Button secondary
			'.supt-buttonSecondary': getButtonSecondaryStyle(),
			// Button secondary
			'.supt-buttonAnchor': getButtonAnchorStyle(),

			/***** LIST CTAS *****/
			'.supt-listCards--list-ctas': {
				'> .supt-listCards__list': {
					display: 'grid',
					gridTemplateColumns: 'repeat(2, 1fr)',

					'.supt-cardCta': {
						gridColumnEnd: 'span 2',

						'&.isSmall': {
							[`@media (min-width: ${breakpoints.tablet})`]: {
								gridColumnEnd: 'span 1',
							},
						},
					},
				},
			},

			/***** SLIDERS *****/
			'.supt-slider': {
				'.supt-slider__controls': {
					position: 'relative',

					'.supt-slider__navigation': cssObj(
						fluidValues('top', 100, 180),
						{
							position: 'absolute',
							zIndex: '2',
							width: '100%',

							'.supt-slider__button': {
								width: 38,
								height: 38,
								backgroundColor:
									tokens?.colors?.['neutral-000'].value,
								margin: 0,

								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '50%',
								position: 'absolute',
								top: 0,

								transition: `background ${transitions.mid} ease`,

								'&.supt-slider__button--prev': {
									left: '0',

									[`@media (min-width: ${breakpoints.laptop})`]:
										{
											transform: 'translateX(-50%)',
										},
								},
								'&.supt-slider__button--next': {
									right: '0',

									[`@media (min-width: ${breakpoints.laptop})`]:
										{
											transform: 'translateX(50%)',
										},
								},

								'&[disabled]': {
									opacity: 0,
								},

								'&:hover, &:focus-within': {
									backgroundColor:
										tokens?.colors?.['neutral-900'].value,

									'&.supt-slider__button--prev': {
										svg: {
											animation: `${sliderButtonPrevHover} ${transitions.mid} forwards`,
										},
									},
									'&.supt-slider__button--next': {
										svg: {
											animation: `${sliderButtonNextHover} ${transitions.mid} forwards`,
										},
									},
								},

								span: {
									width: '13px',
									height: '13px',
									overflow: 'hidden',

									svg: {
										stroke: '#000',
										width: '100%',
									},
								},
							},
						}
					),
				},

				'.supt-slider__container': {
					paddingTop: 0,
				},
			},

			/*
			 * Utility class to hide content visually while keeping it screen reader-accessible.
			 * Source: https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html
			 */
			'.visually-hidden:not(:focus):not(:active)': {
				clip: 'rect(0 0 0 0)',
				clipPath: 'inset(100%)',
				height: '1px',
				overflow: 'hidden',
				position: 'absolute',
				whiteSpace: 'nowrap',
				width: '1px',
			},
		}
	);
};

/* CUSTOM STYLE FOR VIDY */
const getStyles = (theme, tokens) =>
	css(getCustomStyles(theme, tokens), {
		// COOKIES
		'.supt-gdpr': {
			'.supt-gdprBanner': {
				width: '100% !important',
				maxWidth: theme.blocks['supt/gdpr'].banner.width[1],

				'.supt-gdprBanner__buttons': {
					marginTop: '25px',

					button: {
						marginTop: 0,
						marginBottom: 0,
					},

					'.supt-button': {
						width: '100%',
					},

					'.supt-gdprBanner__rejectButton': {
						backgroundSize: '0 0',

						'.supt-gdprBanner__rejectButton__inner':
							linkUnderlineWithHoverRedraw,
					},
				},
			},

			'.supt-gdprModal': {
				'.supt-gdprModal__desc': {
					marginBottom: '1em',
				},

				'.supt-gdprCategory': {
					'.supt-gdprCategory__label': {
						boxShadow:
							'rgb(0 0 0) 0px 0px 1px 1px inset, rgb(0 0 0) 0px 0px 1px 1px',

						'&::before': {
							backgroundColor: 'black',
							border: 'none',
						},
					},

					'.supt-gdprCategory__checkbox:checked + label::before': {
						backgroundColor: 'white',
					},
				},
			},
		},
	});

const getEditStyles = (theme, tokens) =>
	cssObj(
		getCustomStyles(theme, tokens),
		getSectionDocumentationCustomEditStyles(),
		getSectionArtistsSliderEditCustomStyles(),
		getSectionPressSliderEditCustomStyles(),
		{
			//'.supt-buttonPrimary': {
			// pointerEvents: 'none',
			//},
			'h2.wp-block[data-type="core/heading"]': fontStyle({
				...tokens?.typographies?.['heading-2'],
			}),
			'h3.wp-block[data-type="core/heading"]': fontStyle({
				...tokens?.typographies?.['heading-5'],
			}),

			// List of accordions
			'.wp-block[data-type="supt/list-accordions"] .block-editor-block-list__layout':
				{
					columns: 'unset !important',
				},

			'.supt-paragraph': {
				wordBreak: 'break-word',
			},

			// Color of links in paragraph
			'.supt-section--default': {
				'.supt-paragraph a': {
					color: '#000 !important',
				},
			},
			'.supt-section--dark': {
				'.supt-paragraph a': {
					color: '#fff !important',
				},

				'.supt-button.supt-buttonSecondary:hover': {
					color: 'white !important',
				},
			},
			'.supt-cardCta[data-background="black"]': {
				'.supt-button.supt-buttonSecondary:hover': {
					color: 'white !important',
				},
			},

			// Fix button hover
			'span.supt-buttonSecondary': {
				textarea: {
					backgroundColor: 'transparent !important',
				},
				'&:hover, &:focus-within': {
					backgroundColor: 'transparent !important',
					color: '#000 !important',
				},
			},

			'.supt-buttonSecondary, .supt-buttonPrimary': {
				'.supt-button__inner span': {
					'&::before, &::after': {
						paddingTop: '0.25em !important',
					},
				},
			},
		}
	);

export { getStyles, getEditStyles };

import {
	CustomSelectControl,
	ColorControl,
	RadioGroupControl,
	TEXT_ALIGNS,
} from '@superhuit/starterpack-customizer';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const tagThemeAttributes = {
	backgroundColor: '@tokens.colors.neutral-000.value',
	exclusive: '@tokens.colors.highlight.value',
	alert: '@tokens.colors.brand-2-base.value',
	color: '@tokens.colors.neutral-900.value',
	typography: '@tokens.typographies.caption',
	textAlignment: 'left',
};

export const TagThemeControls: ThemeControlsType = {
	// name of the block
	title: block.title,

	// slug of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: tagThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return (
			<>
				<>
					{/* Tag */}
					<h4>Tag typography</h4>
					<CustomSelectControl
						label="Style"
						options={typographiesOptions}
						default={fullAttributes?.typography.default}
						inheritsFrom={fullAttributes?.typography.default_origin}
						value={fullAttributes?.typography.value}
						onChange={(value) =>
							updateAttribute('typography', value)
						}
					/>
					<RadioGroupControl
						label="Text alignment"
						value={fullAttributes?.textAlignment.value}
						default={fullAttributes?.textAlignment.default}
						options={TEXT_ALIGNS}
						onChange={(value) => {
							updateAttribute('textAlignment', value);
						}}
					/>
					<ColorControl
						label="Color"
						default={fullAttributes?.color.default}
						inheritsFrom={fullAttributes?.color.default_origin}
						value={fullAttributes?.color.value}
						onChange={(value) => updateAttribute('color', value)}
						theme={theme}
						type="color"
					/>
				</>
			</>
		);
	},
};

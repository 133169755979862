import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { transitions } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	resetButton,
	resetFirstLastMargins,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-video__caption': fontStyle({
			...styles?.caption?.typography,
			textAlign: styles?.caption?.textAlignment,
			color: styles?.caption?.color,
		}),

		'.supt-video__play-icon': cssObj(
			fluidValues(
				'width',
				styles?.play?.width?.[0],
				styles?.play?.width?.[1]
			),
			fluidValues(
				'height',
				styles?.play?.height?.[0],
				styles?.play?.height?.[1]
			),
			{
				circle: {
					fill: styles?.play?.background,
				},
				path: {
					fill: styles?.play?.foreground,
				},
			}
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		'.supt-video__wrapper': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},

		'.supt-video__inner': {
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
		},

		'.supt-video__player': {
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0,
		},

		'.supt-video__poster': {
			width: '100%',
			'> *': {
				display: 'block !important',
			},
			img: {
				objectFit: 'contain',
				objectPosition: 'center',
			},
		},

		'.supt-video__caption': resetFirstLastMargins,

		'.supt-video__play-btn': cssObj(resetButton, {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',

			'&.is-hidden': {
				opacity: 0,
				visibility: 'hidden',
			},

			'&:hover, &:focus-within': cssObj(
				getDynamicStyles(styles?.states?.hover),
				{
					'.supt-video__play-icon': {
						transform: 'scale(1.05)',
					},
				}
			),
		}),

		'.supt-video__play-icon, .supt-video__play-icon circle, .supt-video__play-icon path':
			{
				transition: `all ${transitions.mid} ease-out`,
			},

		'.supt-video__play-icon': {
			position: 'absolute',
			zIndex: 1,
		},
	});

// edit

const getEditStyles = () =>
	css({
		'.supt-video__wrapper': {
			'&.is-empty': {
				position: 'relative',
				width: '100%',
				height: 0,
				/* Ratio 16/9 */
				paddingTop: 'calc(100% * 9 / 16)',

				'> *': {
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
				},
			},
		},
		'.supt-video__poster': {
			margin: 0,
		},
	});

export { getStyles, getEditStyles };

import React, { FC } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Section } from '@superhuit/starterpack-blocks/helpers/Section';
import {
	SectionOneColumnProps,
	BlockConfigs,
} from '@superhuit/starterpack-blocks/utils/typings';
import { ButtonSecondary } from '@/components/atoms/renders';
import {
	ListNews,
	ListNewsProps,
} from '@/components/molecules/ListNews/render';
// styles
import { getStyles } from './styles.css';
import block from './block.json';

/**
 * TYPINGS
 */
type LinkProps = {
	title: string;
	href: string;
};

export type SectionCardsPressProps = {
	seeAllLink: LinkProps;
	count: number;
} & ListNewsProps &
	SectionOneColumnProps;

/**
 * COMPONENT
 */
export const SectionCardsPress: FC<SectionCardsPressProps> & BlockConfigs = ({
	anchor,
	className,
	uptitle,
	title,
	introduction,
	variant: currentVariant = 'default',
	seeAllLink,
	posts = [],
	count = 3,
	titleTag = 'h2',
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(
		block.slug,
		theme,
		currentVariant
	);

	const rootClass = cx(
		'supt-section supt-sectionCardsPress',
		'supt-section--colored',
		getStyles(blockTheme),
		className
	);

	return (
		<Section
			id={anchor}
			className={rootClass}
			variant={variant}
			slug={block.slug}
			data-num-post={posts.length}
			data-count={count}
		>
			<div className="supt-section__inner">
				{uptitle || title || introduction || seeAllLink?.href ? (
					<div className="supt-section__headline">
						{uptitle && <Section.Uptitle text={uptitle} />}
						{title && (
							<Section.Title text={title} titleTag={titleTag} />
						)}
						{introduction && (
							<Section.Introduction text={introduction} />
						)}
						{seeAllLink?.href ? (
							<div className="supt-sectionCardsPress__linkWrapper">
								<ButtonSecondary
									{...seeAllLink}
									className="supt-sectionCardsPress__link"
								/>
							</div>
						) : null}
					</div>
				) : null}
				<div className="supt-section__content">
					<ListNews posts={posts} />
				</div>
			</div>
		</Section>
	);
};

SectionCardsPress.slug = block.slug;
SectionCardsPress.title = block.title;

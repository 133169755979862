import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const quoteThemeAttributes = {
	content: {
		color: '@tokens.variants.default.contentColor',
		typography: '@tokens.typographies.heading-6',
		textAlignment: 'left',
	},
	citation: {
		color: '@tokens.variants.default.contentColor',
		typography: '@tokens.typographies.small-text',
		textAlignment: 'left',
	},
};

export const QuoteThemeControls: ThemeControlsType = {
	// name of the block
	title: block.title,

	// slug of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: quoteThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return <></>;
	},
};

import React, { FC, useContext } from 'react';
import cx from 'classnames';

import { DataContext } from '@superhuit/starterpack-context';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	ThemeProps,
	BlockConfigs,
} from '@superhuit/starterpack-blocks/utils/typings';

import { LocaleContext } from '@superhuit/starterpack-i18n';
import { Image } from '@superhuit/starterpack-blocks/renders';

import { Link } from '@/components/atoms/Link/render';

import block from './block.json';
import { getStyles } from './styles.css';

export type FooterProps = {
	main: { items: Array<any> };
	legal: { items: Array<any> };
	social: { items: Array<any> };
	isHome?: boolean;
} & ThemeProps;

export const Footer: FC<FooterProps> & BlockConfigs = ({
	legal,
	social,
	main,
	theme = {},
	isHome,
}) => {
	const { blockTheme } = useThemeBlock(block.slug, theme);

	const { footer: footerData, settings } = useContext(DataContext);
	const { locale, __t } = useContext(LocaleContext);

	const rootClass = cx('supt-footer', getStyles(blockTheme));

	return (
		<footer className={rootClass} data-block={block.slug}>
			<div className="supt-footer__top">
				<div className="supt-footer__inner">
					<Link
						href={`/${locale}/`}
						className="supt-footer__logo"
						aria-label={settings?.title}
					>
						<Image
							src={'/images/vidy-logo-footer.svg'}
							width={200}
							height={61}
							className="supt-footer__logoImg"
							alt={settings?.title}
						/>
					</Link>

					<div className="supt-footer__navs">
						{footerData ? (
							<div className="supt-footer__address">
								<p className="supt-footer__address__title">
									{__t('footer-address-title', 'Contact')}
								</p>
								<div
									className="supt-footer__address__text"
									dangerouslySetInnerHTML={{
										__html: footerData.address,
									}}
								/>
							</div>
						) : null}

						<div className="supt-footer__menu">
							<p className="supt-footer__menu__title">
								{__t('footer-menu-title', 'Liens utiles')}
							</p>
							<ul className="supt-footer__menu__nav">
								{main?.items
									? main.items.map(
											({ label, path, target }, i) => (
												<li key={i}>
													<Link
														href={path}
														suppressHydrationWarning
														className="supt-footer__link"
														target={target}
													>
														<span className="supt-footer__link__icon"></span>
														<span className="supt-footer__link__label">
															{label}
														</span>
													</Link>
												</li>
											)
									  )
									: null}
							</ul>
						</div>

						<div className="supt-footer__social">
							<p className="supt-footer__social__title">
								{__t('socials-label', 'Suivez-nous')}
							</p>
							<ul className="supt-footer__social__nav">
								{social?.items
									? social.items.map(({ label, path }, i) => (
											<li key={i}>
												<Link
													href={path}
													suppressHydrationWarning
													className="supt-footer__link"
												>
													<span className="supt-footer__link__icon"></span>
													<span className="supt-footer__link__label">
														{label}
													</span>
												</Link>
											</li>
									  ))
									: null}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="supt-footer__bottom">
				<div className="supt-footer__inner">
					<div className="supt-footer__legal">
						<ul className="supt-footer__legal__nav">
							{legal?.items ? (
								<>
									{legal.items.map(
										({ label, path, target }, i) => (
											<li key={i}>
												<Link
													href={path}
													suppressHydrationWarning
													target={target}
												>
													{label}
												</Link>
											</li>
										)
									)}
								</>
							) : null}
						</ul>
						<p className="supt-footer__copyright">
							<span>©{new Date().getFullYear()} </span>
							{footerData?.copyright}
						</p>
					</div>

					<p className="supt-footer__credits">
						{footerData?.madeBy}
						<a
							className="supt-footer__logo"
							href="https://superhuit.ch"
							target="_blank"
						>
							superhuit
						</a>
					</p>
				</div>
			</div>
		</footer>
	);
};

Footer.slug = block.slug;
Footer.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	fluidValues,
	flexGapPolyfill,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	gridNoMargin,
	resetFirstLastChildMargins,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';

const getDynamicStyles = (styles) => cssObj();

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		'.supt-subsection__content': cssObj(gridNoMargin, {
			gridColumn: '1/13',
			alignItems: 'center',

			'.supt-figure': {
				display: 'flex',
				flexDirection: 'column',
			},

			'.supt-figure:first-child': {
				gridColumn: '1/11',
				gridRow: '1/2',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '1/7',
					gridRow: '1/2',
				},
			},

			'.supt-figure:nth-child(2)': {
				gridColumn: '3/13',
				gridRow: '3/4',
				marginTop: '82px',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '8/13',
					gridRow: '1/2',
					marginTop: 0,
				},
			},

			'.supt-figure:nth-child(3)': cssObj(
				fluidValues('marginTop', -120, -190),
				{
					gridColumn: '7/13',
					gridRow: '2/3',

					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '3/6',
					},
				}
			),
		}),
	});

const getEditStyles = () =>
	css({
		'.supt-subsection__content': {
			'.wp-block[data-type="supt/image"]': {
				marginTop: 0,
			},
			'.wp-block[data-type="supt/image"]:first-child': {
				gridColumn: '1/11',
				gridRow: '1/2',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '1/7',
					gridRow: '1/2',
				},
			},

			'.wp-block[data-type="supt/image"]:nth-child(2)': {
				gridColumn: '3/13',
				gridRow: '3/4',
				marginTop: '82px',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '8/13',
					gridRow: '1/2',
					marginTop: 0,
				},
			},

			'.wp-block[data-type="supt/image"]:nth-child(3)': {
				gridColumn: '7/13',
				gridRow: '2/3',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '3/6',
				},
			},
		},
	});

export { getStyles, getEditStyles };

import React, { FC, useContext, useEffect } from 'react';
import cx from 'classnames';
// package imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { Global } from '@superhuit/starterpack-blocks/utils/inject-global';
import block from './block.json';
// styles
import { getStyles, getGlobalStyles } from './styles.css';

/**
 * TYPINGS
 */
type GoogleSearchProps = {
	title: string;
} & ThemeProps;

/**
 * COMPONENT
 */
export const GoogleSearch: FC<GoogleSearchProps> & BlockConfigs = ({
	title = 'Search',
	theme,
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx('supt-googleSearch', getStyles(blockTheme));

	const { __t } = useContext(LocaleContext);

	useEffect(() => {
		var s = document.createElement('script');
		s.setAttribute(
			'src',
			'https://cse.google.com/cse.js?cx=8261343846d3d41a9'
		);
		s.async = true;
		document.body.appendChild(s);
	}, []);

	return (
		<>
			<Global styles={getGlobalStyles(blockTheme)} />
			<div className={rootClass} data-block={block.slug}>
				<div className="supt-googleSearch__content">
					<h1 className="supt-googleSearch__title">{title}</h1>
					{/* <script
						async
						src="https://cse.google.com/cse.js?cx=8261343846d3d41a9"
					></script> */}
					<div className="gcse-search"></div>
				</div>
			</div>
		</>
	);
};

GoogleSearch.slug = block.slug;
GoogleSearch.title = block.title;

import { css } from '@emotion/css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

// const getDynamicStyles = (styles) => cssObj();

const getStyles = (styles) =>
	css(
		// getDynamicStyles(styles),
		{
			'.supt-subsection__content': {
				gridColumn: '1/13',
				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '1/11',
				},
				[`@media (min-width: ${breakpoints.bigLaptop})`]: {
					gridColumn: '1/10',
				},
			},

			'&.supt-section--contentIsRight': {
				'.supt-subsection__content': {
					gridColumn: '1/13',
					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '3/13',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridColumn: '4/13',
					},
				},
			},
		}
	);

export { getStyles };

import { css } from '@emotion/css';
import { container } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	breakpoints,
	containers,
	gridLayout,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getStyles = () =>
	css({
		overflow: 'hidden',
		marginTop: 5,
		marginBottom: 10,

		marginLeft: `calc(-1 * ${containers.minOffset})`,
		marginRight: `calc(-1 * ${containers.minOffset})`,
		[`@media (min-width: ${breakpoints.tablet})`]: {
			marginLeft: `calc(-1 * ${containers.tabletOffset})`,
			marginRight: `calc(-1 * ${containers.tabletOffset})`,
			marginTop: 10,
			marginBottom: 25,
		},
		[`@media (min-width: ${breakpoints.laptop})`]: {
			marginLeft: `calc(-1 * ${containers.laptopOffset})`,
			marginRight: `calc(-1 * ${containers.laptopOffset})`,
		},
		[`@media (min-width: ${breakpoints.desktop})`]: {
			marginLeft: `calc(-1 * ${containers.desktopOffset})`,
			marginRight: `calc(-1 * ${containers.desktopOffset})`,
		},
		[`@media (min-width: ${containers.maxWidthMq})`]: {
			marginLeft: `calc(-1 * (100vw - ${containers.maxWidth})/2)`,
			marginRight: `calc(-1 * (100vw - ${containers.maxWidth})/2)`,
		},

		'.supt-slider': container,

		'.supt-slider__container': {
			overflow: 'visible !important',
		},

		'.supt-slider__wrapper': {
			gap: gridLayout.gap,

			[`@media (min-width: ${gridLayout.gapMaxMq})`]: {
				gap: gridLayout.gapMax,
			},
		},
		'.supt-slider__slide': {
			paddingRight: gridLayout.gap,
			width: `calc(((100% + ${gridLayout.gap}) / 12) * 8 - ${gridLayout.gap})`,

			[`@media (min-width: ${breakpoints.tablet})`]: {
				width: `calc(((100% + ${gridLayout.gap}) / 12) * 4 - ${gridLayout.gap})`,
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				width: `calc(((100% + ${gridLayout.gap}) / 12) * 3 - ${gridLayout.gap})`,
			},
			[`@media (min-width: ${gridLayout.gapMaxMq})`]: {
				paddingRight: gridLayout.gapMax,
				width: `calc(((100% + ${gridLayout.gapMax}) / 12) * 3 - ${gridLayout.gapMax})`,
			},

			'&:first-child': {
				paddingLeft: 0,
			},
			'&:last-child': {
				paddingRight: 0,
			},
		},

		'.supt-slider__button': {
			boxShadow: 'rgba(0, 0, 0, 0.1) 0 0 10px 0',
		},
	});

export { getStyles };

import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { CardLinkProps } from '@superhuit/starterpack-blocks/helpers/CardControls/typings';
import {
	BlockConfigs,
	ThemeProps,
	ImageProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';
import { Image } from '@superhuit/starterpack-blocks/renders';
import { Link } from '@/components/atoms/Link/render';
// import gsap from 'gsap';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type HighlightedArtistType = {
	id: number;
	image?: ImageProps;
	name: string;
	link?: CardLinkProps;
};

export type ListHighlightedArtistsProps = ThemeProps & {
	artists?: Array<HighlightedArtistType>;
};

/**
 * COMPONENT
 */
export const ListHighlightedArtists: FC<ListHighlightedArtistsProps> &
	BlockConfigs = ({ artists, theme = {} }) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);
	const refImages = useRef(null);
	const [activeId, setActiveId] = useState(-1);
	const [hovered, setHovered] = useState(false);

	const handleHoverOn = (id) => {
		setActiveId(() => id);
		setHovered(true);
	};
	const handleHoverOff = () => {
		setActiveId(() => -1);
		setHovered(false);
	};

	useEffect(() => {
		if (!refImages.current) return;

		document.addEventListener('mousemove', updateImage);

		// clean up function
		return () => {
			document.removeEventListener('mousemove', updateImage);
		};
	}, []);

	const updateImage = (e) => {
		const x = e.clientX;
		const y = e.clientY;

		refImages.current.style.transform = `translate(${x}px, ${y}px)`;

		// gsap.to(refImages.current, {
		// 	x: e.clientX,
		// 	y: e.clientY,
		// 	duration: 0.2,
		// 	ease: 'power2.out',
		// });
	};

	const rootClass = cx(
		'supt-listCards supt-listHighlightedArtists',
		getStyles(blockTheme)
	);

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			<div
				className={cx(
					'supt-listHighlightedArtists__images',
					hovered && 'supt-listHighlightedArtists__images--active'
				)}
				ref={refImages}
			>
				{artists?.map((artist, index) => (
					<Fragment key={index}>
						{artist.image && (
							<Image
								layout="fixed"
								{...{
									...artist.image,
									...{
										width: 375,
										height:
											artist.image.height *
											(375 / artist.image.width),
									},
								}}
								className={cx(
									'supt-listHighlightedArtists__image',
									{
										'supt-listHighlightedArtists__image--active':
											activeId === artist.id,
									}
								)}
							/>
						)}
					</Fragment>
				))}
			</div>
			<div className="supt-listHighlightedArtists__names">
				{artists?.map((artist, index) => (
					<Fragment key={index}>
						{artist?.link?.href ? (
							<Link
								href={artist?.link?.href}
								{...(artist?.link.opensInNewTab && {
									target: '_blank',
									rel: 'noopener noreferrer',
								})}
								className={cx(
									'supt-listHighlightedArtists__link supt-listHighlightedArtists__name',
									activeId >= 0 &&
										activeId === artist.id &&
										'supt-listHighlightedArtists__name--active',
									activeId >= 0 &&
										activeId !== artist.id &&
										'supt-listHighlightedArtists__name--notActive'
								)}
								onMouseEnter={() => handleHoverOn(artist.id)}
								onMouseLeave={handleHoverOff}
							>
								<span
									dangerouslySetInnerHTML={{
										__html: artist.name,
									}}
								></span>
							</Link>
						) : (
							<p
								className={cx(
									'supt-listHighlightedArtists__name',
									activeId >= 0 &&
										activeId === artist.id &&
										'supt-listHighlightedArtists__name--active',
									activeId >= 0 &&
										activeId !== artist.id &&
										'supt-listHighlightedArtists__name--notActive'
								)}
								onMouseEnter={() => handleHoverOn(artist.id)}
								onMouseLeave={handleHoverOff}
								dangerouslySetInnerHTML={{
									__html: artist.name,
								}}
							></p>
						)}
					</Fragment>
				))}
			</div>
		</div>
	);
};

ListHighlightedArtists.slug = block.slug;
ListHighlightedArtists.title = block.title;

import fs from 'fs';
import path from 'path';

import downloadFile from './download-file';
import persistFiles from './persist-files';
import getAssetFilepath from './get-asset-filepath';
import { getFontFaceCSS, parseFontUrls } from '@/lib/theme/fonts';
import { getWpRestUrl } from '@/utils/node-utils.js';
import { getThemeWithFallbacks } from '@superhuit/starterpack-customizer';

const NEXT_ROOT_PATH = path.resolve(process.cwd());
const PUBLIC_PATH = path.resolve(NEXT_ROOT_PATH, 'public');
const CACHE_FILEPATH = path.resolve(NEXT_ROOT_PATH, '.next/theme-cache.json');
const MAX_CACHED_TIME = 15; // in seconds
const INIT_CACHE_DATA = {
	cacheTime: 0,
	theme: {},
};

/**
 * Get the current timestamps in seconds
 *
 * @returns number the current timestamp in seconds
 */
function now() {
	return Math.floor(Date.now() / 1000);
}

/**
 * Query the Theme API endpoint
 *
 * @param options  object for the `fetch` function
 * @returns Promise
 */
function queryThemeEndpoint(options?: object) {
	const apiEndpoint = getWpRestUrl();
	return fetch(`${apiEndpoint}/supt/v1/theme`, options);
}

async function saveCacheData(newData = {}) {
	const cacheData: any = await getCacheData();
	const data = { ...cacheData, ...newData, cacheTime: now() };
	await fs.promises
		.writeFile(CACHE_FILEPATH, JSON.stringify(data, null, 2))
		.catch((err) => `can't write to cache file ${err}`);
}
async function getCacheData() {
	return new Promise(async (resolve, reject) => {
		try {
			await fs.promises.stat(CACHE_FILEPATH);
			const data = await fs.promises.readFile(CACHE_FILEPATH, 'utf-8');
			resolve(JSON.parse(data));
		} catch (err) {
			// console.error(
			// 	`Failed to stat/read/parse file ${CACHE_FILEPATH}. Returning empty cache data. ${err.code}`
			// );
			resolve(INIT_CACHE_DATA); // If any error always resolve with initial cache data to avoid any problem
		}
	});
}

/**
 * GET theme configurations
 *
 * @returns object theme
 */
async function getTheme(bypassCache = false) {
	// Retrieve cached data
	const { cacheTime, theme: themeCached }: any = bypassCache
		? INIT_CACHE_DATA
		: await getCacheData();

	const theme: any = await new Promise(async (resolve, reject) => {
		// Only fetch from API if the cache is too old
		if (now() - cacheTime > MAX_CACHED_TIME) {
			queryThemeEndpoint()
				.then((response) => response.json())
				.then(async (theme) => {
					if (!bypassCache) await saveCacheData({ theme });
					resolve(theme);
				})
				.catch(reject);
		} else {
			resolve(themeCached);
		}
	});

	// Fix to always have theme with fallbacks
	const themeWithFallbacks = getThemeWithFallbacks(theme);

	if (!bypassCache) {
		parseFontUrls(themeWithFallbacks.tokens?.fonts);
	}

	return themeWithFallbacks;
}

/**
 * POST theme
 * @param data  object of new theme
 * @returns  object result of POST
 */
async function saveTheme(wpRestApiEndpoint, nonce, signal = null, data) {
	const res = await fetch(`${wpRestApiEndpoint}/supt/v1/theme`, {
		method: 'POST',
		signal,
		// credentials: 'include',
		// headers: { 'X-WP-Nonce': nonce },
		body: JSON.stringify(data),
	});

	const json = await res.json();

	return json;
}

export {
	PUBLIC_PATH,
	downloadFile,
	getAssetFilepath,
	persistFiles,
	getFontFaceCSS,
	queryThemeEndpoint,
	getTheme,
	saveTheme,
};

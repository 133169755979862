import React, { FC } from 'react';
import cx from 'classnames';
import InnerHTML from 'dangerously-set-html-content';

import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { ThemeProps } from '@superhuit/starterpack-blocks/utils/typings';

import block from './block.json';
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type HTMLProps = {
	content: string;
} & ThemeProps;

/**
 * COMPONENT
 */
export const HTML: FC<HTMLProps> & BlockConfigs = ({ content, theme = {} }) => {
	const { variant } = useThemeBlock(block.slug, theme);

	const htmlProps = {
		'data-variant': variant,
	};

	const rootClass = cx('supt-html', getStyles());

	return (
		content && (
			<div className={rootClass} data-block={block.slug} {...htmlProps}>
				<InnerHTML className="supt-html__inner" html={content} />
			</div>
		)
	);
};

HTML.slug = block.slug;
HTML.title = block.title;

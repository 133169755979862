import React, { FC } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { ThemeProps } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type QuoteProps = {
	content: string;
	author?: string;
	detail?: string;
} & ThemeProps;

/**
 * COMPONENT
 */
export const Quote: FC<QuoteProps> & BlockConfigs = ({
	content,
	author,
	detail,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const htmlProps = {
		'data-variant': variant,
	};

	const rootClass = cx('supt-quote', getStyles(blockTheme));

	return (
		<blockquote
			className={rootClass}
			data-block={block.slug}
			{...htmlProps}
		>
			<span
				className="supt-quote__content"
				dangerouslySetInnerHTML={{ __html: content }}
			/>
			{author || detail ? (
				<cite>
					{author ? (
						<span
							className="supt-quote__author"
							dangerouslySetInnerHTML={{ __html: author }}
						/>
					) : null}
					{detail ? (
						<span
							className="supt-quote__detail"
							dangerouslySetInnerHTML={{ __html: detail }}
						/>
					) : null}
				</cite>
			) : null}
		</blockquote>
	);
};

Quote.slug = block.slug;
Quote.title = block.title;

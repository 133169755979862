import React, { FC, useContext, useMemo } from 'react';
import cx from 'classnames';
import { sprintf } from '@wordpress/i18n';
// package imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ImageProps,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { Image } from '@superhuit/starterpack-blocks/components/renders';
// internal imports
import { Link } from '@/components/atoms/Link/render';
import { ButtonPrimary } from '@/components/atoms/renders';
import { Tag, TagProps } from '@/components/atoms/Tag/render';
import useTicketsBtnProps from '@/hooks/useTicketsBtnProps';
// styles
import block from './block.json';
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type CardCalendarType = {
	id: string;
	artist?: string;
	title: string;
	time: string;
	duration?: string;
	location: string;
	link?: string;
	genres: Array<TagProps>;
	availability: 'high' | 'low' | 'none' | 'free' | 'soon';
	availability_date?: string;
	date?: Date | string;
	ticketsLink?: string;
	ticketButtonText?: string;
	state?: 'canceled' | 'postponed';
	isEvent?: boolean;
	isAroundTheShow?: boolean;
	publics?: Array<{ id: number; title: string; icon: ImageProps }>;
};
export type CardCalendarProps = CardCalendarType & ThemeProps;

/**
 * COMPONENT
 */
export const CardCalendar: FC<CardCalendarProps> & BlockConfigs = ({
	artist,
	title,
	time,
	duration,
	location,
	link,
	genres = [],
	availability,
	availability_date,
	date,
	ticketsLink,
	ticketButtonText,
	state,
	isEvent,
	isAroundTheShow,
	theme = {},
	publics = [],
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx(
		'supt-cardCalendar',
		`supt-cardCalendar--${isEvent ? 'event' : 'show'}`,
		link && 'supt-withLink',
		getStyles(blockTheme),
		{ [`supt-cardCalendar--${state}`]: !!state },
		{ 'supt-cardCalendar--hasDate': !!date }
	);

	const { __t, locale } = useContext(LocaleContext);

	const formattedDate = useMemo(
		() =>
			date ? (typeof date === 'string' ? new Date(date) : date) : null,
		[date]
	);
	const dateDigit = useMemo(() => {
		if (formattedDate) {
			const digit = formattedDate.getDate();

			return String(digit).padStart(2, '0'); // To always have a 2-digit number
		}
	}, [formattedDate]);
	const dateWeekday = useMemo(() => {
		if (formattedDate) {
			const weekday = formattedDate.toLocaleDateString(locale, {
				weekday: 'short',
			});

			// Remove the dot at the end of the weekday if needed
			return weekday.endsWith('.')
				? weekday.substring(0, weekday.length - 1)
				: weekday;
		}
	}, [formattedDate]);

	const ticketsBtn = useTicketsBtnProps({
		link: ticketsLink,
		text: ticketButtonText,
		availability,
		availabilityDate: availability_date,
	});

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
			data-availability={availability}
		>
			<div className="supt-cardCalendar__header">
				{formattedDate ? (
					<div className="supt-cardCalendar__date">
						<span className="supt-cardCalendar__date__digit">
							{dateDigit}
						</span>
						<span className="supt-cardCalendar__date__weekday">
							{dateWeekday}
						</span>
					</div>
				) : null}
				<div className="supt-cardCalendar__headlines">
					{isEvent && isAroundTheShow ? (
						<span className="supt-cardCalendar__label">
							{__t(
								'card-calendar-event-label',
								'Autour du spectacle'
							)}
						</span>
					) : null}
					{artist ? (
						<p className="supt-cardCalendar__artist">{artist}</p>
					) : null}
					{link ? (
						<Link href={link} className="supt-cardCalendar__link">
							<h5 className="supt-cardCalendar__title">
								{title}
							</h5>
						</Link>
					) : (
						<h5 className="supt-cardCalendar__title">{title}</h5>
					)}
				</div>
			</div>

			<div className="supt-cardCalendar__infos">
				{time || duration ? (
					<p className="supt-cardCalendar__time">
						{time ? `${time}${duration ? ' - ' : ''}` : ''}
						{duration
							? sprintf(
									'%s %s',
									__t('card-calendar-duration', 'durée'),
									duration
							  )
							: ''}
					</p>
				) : null}
				{state && (state === 'canceled' || state === 'postponed') ? (
					<Tag
						title={__t(`state-${state}`, state)}
						isSquared
						type="alert"
					/>
				) : null}
				<p className="supt-cardCalendar__location">{location}</p>
				{!!publics.length && (
					<ul className="supt-cardCalendar__public-list">
						{publics.slice(0, 3).map(({ id, icon, title }) => (
							<li key={id}>
								<Image {...icon} />
							</li>
						))}
						{publics.length > 3 && (
							<li className="supt-cardCalendar__public-extra">
								{publics.length - 3}+
							</li>
						)}
					</ul>
				)}
				<div className="supt-cardCalendar__genres">
					{genres.map((cat, id) => (
						<Tag key={id} {...cat} hasBorder />
					))}
				</div>
			</div>

			{ticketsBtn && (
				<>
					<button className="supt-cardCalendar__availability">
						<span className="supt-cardCalendar__availability__label">
							{__t(
								`card-calendar-availability-${availability}`,
								availability
							)}
						</span>
						<span className="supt-cardCalendar__availability__spot"></span>
					</button>

					<ButtonPrimary
						{...ticketsBtn}
						className="supt-cardCalendar__tickets-btn"
					/>
				</>
			)}
		</div>
	);
};

CardCalendar.slug = block.slug;
CardCalendar.title = block.title;

import {
	BoxFluidControl,
	SizeFluidControl,
	CustomSelectControl,
	ColorControl,
	RadioGroupControl,
	TEXT_ALIGNS,
} from '@superhuit/starterpack-customizer';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const footerThemeAttributes = {
	backgroundColor: '#000000',
	divider: {
		color: '#ffffff',
		width: [1, 1],
	},
	top: {
		padding: {
			top: [40, 60],
			bottom: [20, 40],
		},
	},
	bottom: {
		padding: {
			top: [20, 40],
			bottom: [40, 60],
		},
	},
	text: {
		color: '#ffffff',
		typography: '@tokens.typographies.introduction',
		textAlignment: 'left',
	},
	menu: {
		color: '#ffffff',
		typography: '@tokens.typographies.paragraph',
		textAlignment: 'left',
	},
	legal: {
		color: '#ffffff',
		typography: '@tokens.typographies.introduction',
		textAlignment: 'left',
	},
};

export const FooterThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: footerThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return (
			<>
				<h4>Footer specs</h4>
				{/* Background color */}
				<ColorControl
					label="Background color"
					value={fullAttributes?.backgroundColor.value}
					default={fullAttributes?.backgroundColor.default}
					inheritsFrom={
						fullAttributes?.backgroundColor.default_origin
					}
					onChange={(value) =>
						updateAttribute('backgroundColor', value)
					}
					theme={theme}
				/>
				{/* Divider width */}
				<SizeFluidControl
					label="Divider width"
					default={fullAttributes?.divider?.width?.default}
					inheritsFrom={
						fullAttributes?.divider?.width?.default_origin
					}
					value={fullAttributes?.divider?.width?.value}
					onChange={(value) =>
						updateAttribute('divider.width', value)
					}
				/>
				{/* Divider color */}
				<ColorControl
					label="Divider color"
					default={fullAttributes?.divider?.color.default}
					inheritsFrom={fullAttributes?.divider?.color.default_origin}
					value={fullAttributes?.divider?.color.value}
					onChange={(value) =>
						updateAttribute('divider.color', value)
					}
					theme={theme}
					type="color"
				/>
				{/* Footer Top */}
				<h4>Footer Top specs</h4>
				{/* Paddings */}
				<BoxFluidControl
					label="Padding"
					default={fullAttributes?.top?.padding.default}
					inheritsFrom={fullAttributes?.top?.padding.default_origin}
					values={fullAttributes?.top?.padding.value}
					onChange={(value) => updateAttribute('top.padding', value)}
				/>
				{/* Footer Bottom */}
				<h4>Footer Bottom specs</h4>
				{/* Paddings */}
				<BoxFluidControl
					label="Padding"
					default={fullAttributes?.bottom?.padding.default}
					inheritsFrom={
						fullAttributes?.bottom?.padding.default_origin
					}
					values={fullAttributes?.bottom?.padding.value}
					onChange={(value) =>
						updateAttribute('bottom.padding', value)
					}
				/>
				{/* Menu */}
				<h4>Menu typography</h4>
				<CustomSelectControl
					label="Style"
					options={typographiesOptions}
					default={fullAttributes?.menu.typography.default}
					inheritsFrom={
						fullAttributes?.menu.typography.default_origin
					}
					value={fullAttributes?.menu.typography.value}
					onChange={(value) =>
						updateAttribute('menu.typography', value)
					}
				/>
				<RadioGroupControl
					label="Text alignment"
					value={fullAttributes?.menu?.textAlignment.value}
					default={fullAttributes?.menu?.textAlignment.default}
					options={TEXT_ALIGNS}
					onChange={(value) => {
						updateAttribute('menu.textAlignment', value);
					}}
				/>
				<ColorControl
					label="Color"
					default={fullAttributes?.menu?.color.default}
					inheritsFrom={fullAttributes?.menu?.color.default_origin}
					value={fullAttributes?.menu?.color.value}
					onChange={(value) => updateAttribute('menu.color', value)}
					theme={theme}
					type="color"
				/>
				{/* Legal Menu */}
				<h4>Legal Menu typography</h4>
				<CustomSelectControl
					label="Style"
					options={typographiesOptions}
					default={fullAttributes?.legal.typography.default}
					inheritsFrom={
						fullAttributes?.legal.typography.default_origin
					}
					value={fullAttributes?.legal.typography.value}
					onChange={(value) =>
						updateAttribute('legal.typography', value)
					}
				/>
				<RadioGroupControl
					label="Text alignment"
					value={fullAttributes?.legal?.textAlignment.value}
					default={fullAttributes?.legal?.textAlignment.default}
					options={TEXT_ALIGNS}
					onChange={(value) => {
						updateAttribute('legal.textAlignment', value);
					}}
				/>
				<ColorControl
					label="Color"
					default={fullAttributes?.legal?.color.default}
					inheritsFrom={fullAttributes?.legal?.color.default_origin}
					value={fullAttributes?.legal?.color.value}
					onChange={(value) => updateAttribute('legal.color', value)}
					theme={theme}
					type="color"
				/>
				{/* Text */}
				<h4>Text typography</h4>
				<CustomSelectControl
					label="Style"
					options={typographiesOptions}
					default={fullAttributes?.text.typography.default}
					inheritsFrom={
						fullAttributes?.text.typography.default_origin
					}
					value={fullAttributes?.text.typography.value}
					onChange={(value) =>
						updateAttribute('text.typography', value)
					}
				/>
				<RadioGroupControl
					label="Text alignment"
					value={fullAttributes?.text?.textAlignment.value}
					default={fullAttributes?.text?.textAlignment.default}
					options={TEXT_ALIGNS}
					onChange={(value) => {
						updateAttribute('text.textAlignment', value);
					}}
				/>
				<ColorControl
					label="Color"
					default={fullAttributes?.text?.color.default}
					inheritsFrom={fullAttributes?.text?.color.default_origin}
					value={fullAttributes?.text?.color.value}
					onChange={(value) => updateAttribute('text.color', value)}
					theme={theme}
					type="color"
				/>
			</>
		);
	},
};

import React, { FC, useContext } from 'react';
import cx from 'classnames';
// package imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
// internal imports
import block from './block.json';
// styles
import { getStyles } from './styles.css';
import { LocaleContext } from '@superhuit/starterpack-i18n';

/**
 * TYPINGS
 */
type ListFilesProps = {
	files: Array<{
		id: number;
		name: string;
		url: string;
		extension?: string;
	}>;
} & ThemeProps;

/**
 * COMPONENT
 */
export const ListFiles: FC<ListFilesProps> & BlockConfigs = ({
	files,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx('supt-listFiles', getStyles(blockTheme));

	const { __t } = useContext(LocaleContext);

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			{files.map((file, index) => (
				<p
					className="supt-listFiles__file supt-paragraph"
					key={`${file.id}-${index}`}
				>
					<span className="supt-listFiles__file__name">
						{file.name}
					</span>
					<a
						href={file?.url}
						className="supt-listFiles__file__link"
						download
					>
						{__t('item-download-link-title', 'Télécharger')}
						{file.extension ? ` (${file.extension})` : ''}
					</a>
				</p>
			))}
		</div>
	);
};

ListFiles.slug = block.slug;
ListFiles.title = block.title;

import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { resetLastChildMarginBottom } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-section--section-details': {
			'.supt-section__col--col': cssObj(resetLastChildMarginBottom, {
				gridColumnEnd: 'span 12',
				gridRow: 'auto !important',
				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumnEnd: 'span 6',
				},
				[`@media (min-width: ${breakpoints.bigLaptop})`]: {
					gridColumnEnd: 'span 3',
				},
				'> *:first-child:empty': {
					marginTop: 0,
				},
				'> *:first-child:not(:empty)': {
					marginTop: 5,
					[`@media (min-width: ${breakpoints.medium})`]: {
						marginTop: 15,
					},
				},
				'&:nth-child(2)': {
					'> *:first-child:not(:empty)': {
						[`@media (max-width: calc(${breakpoints.medium} - 1px))`]:
							{
								marginTop: 20,
							},
					},
				},
				'&:nth-child(3), &:nth-child(4)': {
					'> *:first-child:not(:empty)': {
						[`@media (max-width: calc(${breakpoints.bigLaptop} - 1px))`]:
							{
								marginTop: 20,
							},
					},
				},
			}),
		},
	});

export { getCustomStyles };

import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { gridColumnGap } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	breakpoints,
	gridLayout,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-listCards--list-shows': {
			'.supt-listCards__list': cssObj(
				gridColumnGap,
				// fluidValues('gridRowGap', 43, 82),
				{
					display: 'grid',
					gridTemplateColumns: '1fr',
					gridRowGap: '40px',

					[`@media (min-width: ${breakpoints.small})`]: {
						gridTemplateColumns: '1fr 1fr',
						gridAutoRows: '20px',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridTemplateColumns: '1fr 1fr 1fr',
					},
				}
			),

			'.supt-slider': {
				'.supt-slider__slide': cssObj(fluidValues('width', 382, 512), {
					paddingRight: gridLayout.gap,
					maxWidth: '100%',

					[`@media (min-width: ${gridLayout.gapMaxMq})`]: {
						paddingRight: gridLayout.gapMax,
					},

					'&:last-child': {
						paddingRight: 0,
					},
				}),
			},
		},
	});

export { getCustomStyles };

import React, { FC, useMemo } from 'react';
// package imports
import {
	ChildrenProps,
	SectionTwoColumnsProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionAccordion } from '../SectionAccordion/render';
// internal imports
import block from './block.json';

type SectionDocumentationProps = SectionTwoColumnsProps & {
	children?: ChildrenProps & {
		props: { blocks: Array<{ attributes: any }> };
	};
};

/**
 * COMPONENT
 */
export const SectionDocumentation: FC<SectionDocumentationProps> &
	BlockConfigs = (props) => {
	const hasChildren = useMemo(
		() =>
			props.children.props.blocks.some(({ innerBlocks }) =>
				// supt/section-documentation-col
				innerBlocks.some(
					({ attributes }) =>
						// supt/list-files
						attributes?.files && attributes?.files.length > 0
				)
			),
		[props.children.props.blocks]
	);

	return hasChildren ? <SectionAccordion {...props} /> : null;
};

SectionDocumentation.slug = block.slug;
SectionDocumentation.title = block.title;

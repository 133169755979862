import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { DataContext } from '@superhuit/starterpack-context';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { ButtonSecondary } from '@/components/atoms/renders';
import { phpToJsDateFormat } from '@superhuit/starterpack-blocks/utils';
import { Link } from '@/components/atoms/Link/render';

import block from '@superhuit/starterpack-blocks/components/molecules/cards/CardNews/block.json';
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type EventLinkProps = {
	label: string;
	href: string;
};

export type CardNewsProps = ThemeProps & {
	index?: number;
	title: string;
	journal?: string;
	date: string;
	excerpt?: string;
	link?: string;
	file?: string;
	isRandom?: boolean;
	eventLink?: EventLinkProps;
};

/**
 * COMPONENTS
 */
export const CardNews: FC<CardNewsProps> & BlockConfigs = ({
	index,
	journal,
	date,
	title,
	excerpt,
	link,
	file,
	isRandom,
	eventLink = {},
	theme = {},
}) => {
	const { locale, __t } = useContext(LocaleContext);
	const { settings } = useContext(DataContext);

	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx('supt-card supt-cardNews', getStyles(blockTheme));

	const formatDate = (date) =>
		// `.replace(/-/g, '/')` is to fix safari which fails parsing xxxx-xx-xx date format
		new Date(date.replace(/-/g, '/')).toLocaleDateString(
			locale,
			phpToJsDateFormat(
				__t('date_format', settings.dateFormat ?? 'F j, Y') // Fallback for wp editor
			)
		);
	const formatedDate = useMemo(() => (date ? formatDate(date) : ''), [date]);

	const removeTags = (str) => {
		if (str === null || str === '') return false;
		else str = str.toString();
		return str.replace(/(<([^>]+)>)/gi, '');
	};
	const stripedExcerpt = useMemo(() => removeTags(excerpt), [excerpt]);

	const [columnStart, setColumnStart] = useState(null);
	const [marginTop, setMarginTop] = useState(0);

	/**
	 * Set a random column start (only for desktop)
	 */
	useEffect(() => {
		if (!isRandom) return; // Only if the card is going to be positionned randomly

		const handleWindowResize = () => {
			const desktopMQ = window.matchMedia('(min-width: 1024px)');
			if (desktopMQ.matches && !columnStart) {
				const minColumnStart = index % 2 === 0 ? 1 : 7;
				let maxColumnStart = index % 2 === 0 ? 6 : 13 - 4; // 13 is on right and 4 is the minimum number of column for the card's width

				const randomColumnStart = Math.round(
					Math.random() * (maxColumnStart - minColumnStart) +
						minColumnStart
				);
				setColumnStart(randomColumnStart);
			} else if (!desktopMQ.matches && columnStart) {
				setColumnStart(null);
			}
		};

		// Trigger on 1st load
		handleWindowResize();

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [columnStart]);

	/**
	 * Set a random margin top
	 */
	useEffect(() => {
		if (!isRandom) return; // Only if the card is going to be positionned randomly

		const minMarginTop = 0; //-50;
		const maxMarginTop = 150;

		const randomMarginTop = Math.round(
			Math.random() * (maxMarginTop - minMarginTop) + minMarginTop
		);

		setMarginTop(randomMarginTop);
	}, []);

	return (
		<article
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
			style={{ gridColumnStart: columnStart, marginTop }}
		>
			<div className="supt-cardNews__inner">
				<div className="supt-cardNews__content">
					<h3
						className="supt-cardNews__title"
						dangerouslySetInnerHTML={{ __html: title }}
					></h3>
					<p className="supt-cardNews__metas">
						{journal ? (
							<>
								<span className="supt-cardNews__journal">
									{journal}
								</span>
								<span className="supt-cardNews__metas__space">
									{' - '}
								</span>
							</>
						) : null}
						<span className="supt-cardNews__date">
							{formatedDate}
						</span>
					</p>
					{excerpt && (
						<p
							className="supt-cardNews__excerpt"
							dangerouslySetInnerHTML={{ __html: stripedExcerpt }}
						/>
					)}
				</div>
				<div className="supt-cardNews__buttonsWrapper">
					<div className="supt-cardNews__buttons">
						{(link || file) && (
							<ButtonSecondary
								href={link || file}
								title={__t(
									`card-news-button-label-${
										link ? 'link' : 'file'
									}`,
									"Lire l'article"
								)}
							/>
						)}
						{eventLink?.href ? (
							<Link
								href={eventLink?.href}
								target="_blank"
								className="supt-cardNews__eventLink"
							>
								{eventLink?.label}
							</Link>
						) : null}
					</div>
				</div>
			</div>
		</article>
	);
};

CardNews.slug = block.slug;
CardNews.title = block.title;

import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-section--section-cards-production': cssObj({
			// Remove negative margin top caused by default variant section following each others
			marginTop: '0 !important',

			'.supt-section__inner': {
				'.supt-section__headline': cssObj({
					'.supt-section__title': {
						margin: 0,
					},
				}),

				'.supt-section__content': {
					'.supt-listCards': fluidValues('marginTop', 40, 60),
				},
			},
		}),
	});

export { getCustomStyles };

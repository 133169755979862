import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';
import Alert from '@/next-components/alert';
import Meta from '@/next-components/meta';
import { injectFontFaces } from '@/utils/inject-font-faces';
import { useInputDevice } from '@superhuit/starterpack-hooks';
import { ThemeContext } from '@superhuit/starterpack-context';
import { saveTheme } from '@/lib/theme';
import { getCanUserUpdateBlockTheme } from '@/lib/api';
// import { getGlobalStyles } from '@superhuit/starterpack-blocks/css/global.css';
// import { Global } from '@superhuit/starterpack-blocks/utils/inject-global';
import { getStyles } from './styles/layout.css';
import Icons from './icons';

const CLASS_USING_MOUSE = 'using-mouse';
const CLASS_CAN_TOUCH = 'can-touch';

// remember: node can be undefined! see https://nextjs.org/docs/advanced-features/automatic-static-optimization#how-it-works
export default function Layout({
	node,
	preview,
	previewData = null,
	children,
	cssFonts,
}) {
	const [isUsingMouse, setIsUsingMouse] = useState(true);
	const [isTouchDevice, setIsTouchDevice] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [canUserUpdateBlockTheme, setCanUserUpdateBlockTheme] =
		useState(false);

	const customizerConfig = useRef();
	const Customizer = useRef();
	const [customizerIsLoaded, setCustomizerIsLoaded] = useState();

	const htmlClasses = useMemo(() => {
		const { htmlAttributes } = Helmet?.peek() ?? {
			htmlAttributes: { class: '' },
		};
		const initialClasses = String(htmlAttributes.class)
			.split(' ')
			.reduce((c, i) => ({ ...c, [i]: true }), {});

		return cx(
			initialClasses,
			isUsingMouse && CLASS_USING_MOUSE,
			isTouchDevice && CLASS_CAN_TOUCH
		);
	}, [isUsingMouse, isTouchDevice]);

	const { rawTheme, setRawTheme, fullRawTheme, theme } =
		useContext(ThemeContext);

	// update isUsingMouse on device change
	useInputDevice(({ touch, mouse }) => {
		setIsTouchDevice(touch);
		setIsUsingMouse(mouse);
	});

	// set css font faces globally (only once)
	useEffect(() => {
		if (cssFonts) {
			injectFontFaces(cssFonts);
		}
	}, []);

	useEffect(() => {
		setIsLoaded(true);
	}, []);

	// Dynamically load customizer - only if on user has capabilities
	useEffect(() => {
		if (!canUserUpdateBlockTheme) return;

		// Load Customizer component
		const customizerPromise = import(
			'@superhuit/starterpack-customizer'
		).then((module) => {
			Customizer.current = module.Customizer;
		});

		// Load blocks theme controls for Customizer
		const themingLoaderPromise = import('@/theming-loader').then(
			(module) => {
				// Exported array of blocks theme controls
				customizerConfig.current = {
					blocks: module.blocksThemeControls,
					templates: module.templatesThemeControls,
				};
			}
		);

		// Load theming css
		const themingCssPromise = import(
			'@wordpress/components/build-style/style.css'
		);

		Promise.all([
			customizerPromise,
			themingLoaderPromise,
			themingCssPromise,
		]).then(() => {
			setCustomizerIsLoaded(true);
		});
	}, [canUserUpdateBlockTheme]);

	// Check user capabilities - only if on preview mode
	useEffect(() => {
		if (!preview) return;
		// controller / signal are here to ensure we can
		// abort the request if componenent is unmounted
		// before the request is completed
		const controller = new AbortController();
		const signal = controller.signal;

		getCanUserUpdateBlockTheme(
			previewData.wpRestApiEndpoint,
			previewData.nonce,
			signal
		).then((can) => {
			setCanUserUpdateBlockTheme(can);
		});

		return () => controller.abort();
	}, []);

	return (
		<>
			<Helmet
				htmlAttributes={{
					class: htmlClasses,
				}}
			/>
			<Meta node={node} />
			<div
				className={cx(
					'app',
					{ isLoading: !isLoaded },
					getStyles(theme, rawTheme.tokens)
				)}
			>
				<Alert
					preview={preview}
					wpUrl={previewData?.wpUrl}
					editLink={node?.editLink}
					showCustomizerBtn={canUserUpdateBlockTheme}
				/>

				<div className="main">{children}</div>

				<Icons />
			</div>

			{/* TODO: add ErrorBoundary on Customizer so it doesn't crash the whole app when it crashes */}
			{customizerIsLoaded ? (
				<Customizer.current
					config={customizerConfig.current}
					rawTheme={rawTheme}
					fullRawTheme={fullRawTheme}
					onChange={(value) => setRawTheme(value)}
					onSave={async (value, callback) => {
						const result = await saveTheme(
							previewData.wpRestApiEndpoint,
							previewData.nonce,
							null,
							value
						);
						callback();
						return result;
					}}
				/>
			) : null}
		</>
	);
}

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import {
	fluidPadding,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	grid,
	resetButton,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	breakpoints,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getDynamicStyles = (styles) =>
	cssObj(fluidPadding(styles?.padding), {
		'.supt-filters__title': fontStyle(
			{
				...styles?.title?.typography,
				textAlign: styles?.title?.textAlignment,
				color: styles?.title?.color,
			},
			false
		),

		'.supt-filters__label, .supt-filters__reset-btn, .supt-filters__tags-link':
			fontStyle(
				{
					...styles?.label?.typography,
					textAlign: styles?.label?.textAlignment,
					color: styles?.label?.color,
				},
				false
			),

		'.supt-filters__filter': fontStyle({
			...styles?.filter?.typography,
			textAlign: styles?.filter?.textAlignment,
			color: styles?.filter?.color,
		}),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), grid, {
		'.supt-filters__header, .supt-filters__filters': {
			gridColumn: '1 / span 12',
		},

		'.supt-filters__header': {
			display: 'flex',
			flexDirection: 'column',
			marginBottom: 65,

			[`@media (min-width: ${breakpoints.small})`]: {
				marginBottom: 50,
			},
			[`@media (min-width: ${breakpoints.medium})`]: {
				flexDirection: 'row',
				alignItems: 'flex-end',
				justifyContent: 'space-between',
			},
		},

		'.supt-filters__buttons': {
			marginTop: 10,
			display: 'flex',
			flexWrap: 'wrap',
			gap: 8,
			[`@media (min-width: ${breakpoints.medium})`]: {
				marginTop: 0,
				gap: 10,
			},

			'.supt-button': {
				margin: 0,
			},
		},

		'.supt-filters__filters': {
			border: '1px solid black',
		},

		'.supt-filters__main-bar': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',

			padding: '10px 5px',
			position: 'relative',

			[`@media (min-width: ${breakpoints.fluidMin})`]: {
				padding: '10px',
			},
			[`@media (min-width: ${breakpoints.small})`]: {
				padding: '10px 20px',
				justifyContent: 'flex-start',
			},
			[`@media (min-width: ${breakpoints.tablet})`]: {
				padding: '16px 20px',
			},

			'.supt-filters__label': {
				[`@media (max-width: calc(${breakpoints.small} - 1px))`]: {
					position: 'absolute',
					top: -10,
					left: 0,
					transform: 'translateY(-100%)',
				},
			},
		},

		'.supt-filters__filter': cssObj(resetButton, {
			paddingLeft: 7,
			marginLeft: 7,
			borderLeft: '1px solid rgba(0,0,0,0.2)',
			lineHeight: '12px',

			[`@media (min-width: ${breakpoints.fluidMin})`]: {
				paddingLeft: 15,
				marginLeft: 15,
			},
			[`@media (min-width: ${breakpoints.mobile})`]: {
				paddingLeft: 20,
				marginLeft: 20,
			},

			svg: {
				display: 'inline-block',
				verticalAlign: 'middle',
				marginLeft: 6,
				marginTop: -1,
				stroke: '#000',
				transition: `transform ${transitions.fast} ease`,
			},

			'&.isActive': {
				svg: {
					transform: 'rotate(-180deg)',
				},
			},

			'&:first-of-type': {
				[`@media (max-width: calc(${breakpoints.small} - 1px))`]: {
					marginLeft: 0,
					paddingLeft: 0,
					borderLeft: 0,
				},
			},
		}),

		'.supt-filters__list-bar, .supt-filters__selected-bar': {
			padding: '18px',
			borderTop: '1px solid #000',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				padding: '17px 20px',
			},
		},

		'.supt-filters__list-bar': {
			display: 'flex',
			flexDirection: 'column',
			gap: 25,
			alignItems: 'start',

			[`@media (min-width: ${breakpoints.laptop})`]: {
				flexDirection: 'row',
				flexShrink: 0,
				gap: 16,
				justifyContent: 'space-between',
			},
		},

		'.supt-filters__selected-bar': {
			display: 'grid',
			gridTemplateColumns: '1fr 1fr',
			gap: 15,

			[`@media (min-width: ${breakpoints.laptop})`]: {
				gap: 12,
			},

			'.supt-filters__tags': {
				gridColumn: '1 / -1',
			},
		},

		'.supt-filters__tags': {
			display: 'flex',
			flexWrap: 'wrap',
			listStyle: 'none',
			columnGap: 5,
			rowGap: 10,

			[`@media (min-width: ${breakpoints.tablet})`]: {
				rowGap: 5,
			},

			'&.withDesc': {
				gap: 10,

				[`@media (min-width: ${breakpoints.tablet})`]: {
					gap: 12,
					li: {
						flexBasis: 'calc(50% - (1*12px/2))',
					},
				},
				[`@media (min-width: ${breakpoints.laptop})`]: {
					gap: 15,
					li: {
						flexBasis: 'calc(100% / 3 - (2*15px/3))',
					},
				},
				[`@media (min-width: ${breakpoints.bigLaptop})`]: {
					gap: 20,
					li: {
						flexBasis: 'calc(25% - (3*20px/4))',
					},
				},

				'.supt-tag': {
					height: '100%',
					alignContent: 'start',
				},
			},
		},

		'.supt-filters__tags-link': {
			lineHeight: '25px', // height of tags
			overflow: 'hidden',

			'&::before': {
				bottom: '0.25em',
			},

			span: {
				position: 'relative',
				paddingRight: 20,
				lineHeight: '1.1em',

				'&::before, &::after': {
					content:
						"url(\"data:image/svg+xml,%3Csvg width='13' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='currentColor' stroke-width='1.4'%3E%3Cpath d='M6.2071.8432 11.864 6.5 6.207 12.1569'/%3E%3Cpath stroke-linecap='square' d='M10.5 6.5H0'/%3E%3C/g%3E%3C/svg%3E\")",
					position: 'absolute',
					right: 0,
					paddingTop: '0.1em',
					transition: 'all 0.15s ease',
					width: '13px',
					height: '13px',
					lineHeight: '13px',
					top: '50%',
					transform: 'translate(0,-50%)',
				},
				'&::after': {
					transform: 'translate(-100%, -50%)',
					opacity: 0,
				},

				'&:hover': {
					'&::before': {
						transform: 'translate(100%, -50%)',
						opacity: 0,
					},
					'&::after': {
						transform: 'translate(0%, -50%)',
						opacity: 1,
					},
				},
			},
		},

		'.supt-filters__reset-btn': cssObj(resetButton, {
			gridColumn: '2',
			justifySelf: 'end',
		}),
	});

export { getStyles };

import React, { forwardRef, Ref } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Section } from '@superhuit/starterpack-blocks/helpers/Section';
import { SectionOneColumnProps } from '@superhuit/starterpack-blocks/utils/typings';
import { LinkProps } from '@superhuit/starterpack-blocks/helpers/Link/render';
// styles
import { getStyles as getDfltStyles } from '@superhuit/starterpack-blocks/components/organisms/Sections/SectionOneColumn/styles.css';
import block from './block.json';
import { ListProductions } from '@/components/renders';
import { ListProductionsProps } from '@/components/molecules/ListProductions/render';

/**
 * TYPINGS
 */
type SectionCardsProductionProps = SectionOneColumnProps & {
	link?: LinkProps;
} & ListProductionsProps;

/**
 * COMPONENT
 */
export const SectionCardsProduction = forwardRef<
	Ref<any>,
	SectionCardsProductionProps
>(
	(
		{
			anchor,
			className,
			title,
			productions,
			slug,
			variant: currentVariant = 'default',
			titleTag = 'h2',
			theme = {},
			getStyles = getDfltStyles,
		},
		ref
	) => {
		const { variant, blockTheme } = useThemeBlock(
			slug,
			theme,
			currentVariant
		);

		const trimmedSlug = slug.split('/');
		const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

		const rootClass = cx(
			'supt-section supt-section--oneCol',
			`supt-section--${modifierSlug}`,
			getStyles(blockTheme),
			className
		);

		return (
			<Section
				id={anchor}
				className={rootClass}
				variant={variant}
				slug={slug}
				ref={ref}
			>
				<div className="supt-section__inner">
					{title && (
						<div className="supt-section__headline">
							{title && (
								<Section.Title
									text={title}
									titleTag={titleTag}
								/>
							)}
						</div>
					)}
					<div className="supt-section__content">
						<ListProductions productions={productions} />
					</div>
				</div>
			</Section>
		);
	}
);

// @ts-ignore
SectionCardsProduction.slug = block.slug;
// @ts-ignore
SectionCardsProduction.title = block.title;

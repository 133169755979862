import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-listCards--list-calendar': {
			'.supt-listCards__list': {
				display: 'block',
				rowGap: 0,
				columnGap: 0,
			},
			'.supt-cardCalendar': {
				'&:first-child': {
					borderTop: '1px solid #000',
				},
			},
		},
	});

export { getCustomStyles };

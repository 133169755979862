import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-section--header-a': cssObj(
			fluidValues('paddingBottom', 66, 33),
			{
				paddingTop: 0,

				[`@media (max-width: calc(${breakpoints.medium} - 1px))`]: {
					paddingTop: '80px',
				},

				'.supt-section__inner': {
					position: 'relative',

					'.supt-section__headline': {
						[`@media (min-width: ${breakpoints.medium})`]: {
							gridRow: '2/3',
						},

						'.supt-section__uptitle': {
							margin: '0 0 14px 0',
						},
						'.supt-section__title': {
							margin: 0,
							paddingTop: '0.15em',
						},
						'.supt-section__introduction': cssObj(
							fluidValues('marginTop', 20, 30),
							{
								marginBottom: 0,
							}
						),
					},
					'.supt-section__col--left': {
						[`@media (min-width: ${breakpoints.medium})`]: {
							gridRow: '3/4',
						},
					},
					'.supt-section__col--right': {
						[`@media (max-width: calc(${breakpoints.medium} - 1px))`]:
							{
								marginTop: '40px',
							},

						[`@media (min-width: ${breakpoints.medium})`]: {
							gridRow: '1/5',
						},

						'.supt-figure': {
							display: 'flex',

							span: {
								width: '100% !important',
								aspectRatio: '1/1',
							},

							'> span > img': {
								objectFit: 'cover',
							},

							'.supt-figure__figcaption': {
								display: 'none',
							},
						},
					},
				},

				'&:not(.supt-section--hasButtons)': {
					'.supt-section__inner': {
						'.supt-section__col--left': {
							display: 'none',
						},
					},
				},
				'&:not(.supt-section--hasImage)': cssObj(
					fluidValues('paddingTop', 110, 160),
					{
						'.supt-section__inner': {
							'.supt-section__col--right': {
								display: 'none',
							},
						},
					}
				),

				'.supt-button': {
					position: 'relative',
					zIndex: 2,
				},

				// make the first button of the first "buttons list" cover the whole section (doesn't apply to editor, thanks to the use of :not())
				':not(.wp-block) > .supt-listCards:first-of-type .supt-button:first-of-type':
					{
						position: 'initial',
						'&::after': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							zIndex: 1,
						},
					},
			}
		),
	});

export { getCustomStyles };

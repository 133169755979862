import {} from '@superhuit/starterpack-customizer';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const homeCalendarThemeAttributes = {
	header: {
		color: '@tokens.variants.default.contentColor',
		typography: '@tokens.typographies.small-text',
		textAlignment: 'left',
		day: {
			margin: {
				left: [13, 25],
				right: [13, 25],
			},
		},
	},
	content: {
		padding: {
			top: [90, 110],
			bottom: [55, 100],
		},
		date: {
			color: '@tokens.variants.default.titleColor',
			typography: '@tokens.typographies.heading-2',
			textAlignment: 'left',
		},
	},
	states: {},
};

export const HomeCalendarThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: homeCalendarThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return (
			<>
				<h4>Specs</h4>
				{/* Customizer controls */}
			</>
		);
	},
};

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-quote__content': fontStyle({
			...styles?.content?.typography,
			textAlign: styles?.content?.textAlignment,
			color: styles?.content?.color,
		}),

		cite: fontStyle({
			...styles?.citation?.typography,
			textAlign: styles?.citation?.textAlignment,
			color: styles?.citation?.color,
		}),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		cite: cssObj(fluidValues('marginTop', 27, 46), {
			fontStyle: 'normal',
			display: 'block',
			marginBottom: 0,

			'.supt-quote__detail': {
				opacity: 0.6,
			},
		}),
	});

const getEditStyles = () =>
	css({
		'cite p': {
			margin: '0 !important',
		},
	});

export { getStyles, getEditStyles };

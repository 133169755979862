import React, { FC } from 'react';
import cx from 'classnames';
// package imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';

// internal imports
import { Link } from '@/components/atoms/Link/render';
import block from './block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type LocationProps = {
	title: string;
	href?: string;
} & ThemeProps;

export type CardDateTourProps = {
	date: string;
	location: LocationProps;
	city: string;
} & ThemeProps;

/**
 * COMPONENT
 */
export const CardDateTour: FC<CardDateTourProps> & BlockConfigs = ({
	date,
	location,
	city,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx('supt-cardDateTour', getStyles(blockTheme));

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			<div className="supt-cardDateTour__date">
				<p>{date}</p>
			</div>

			<div className="supt-cardDateTour__city">
				<p>{city}</p>
			</div>

			<div className="supt-cardDateTour__location">
				{location.href ? (
					<Link
						className="supt-cardDateTour__location__link"
						href={location.href}
						target="_blank"
						rel="noopener noreferrer"
					>
						{location.title}
					</Link>
				) : (
					<p>{location.title}</p>
				)}
			</div>
		</div>
	);
};

CardDateTour.slug = block.slug;
CardDateTour.title = block.title;

import React, { FC } from 'react';
// package imports
import {
	ChildrenProps,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { ListCards } from '@superhuit/starterpack-blocks/components/renders'; // Needs to be called from /components folder to work properly..
// internal imports
import block from './block.json';

export type ListCalendarProps = {
	title?: string;
	getStyles?: (styles: any) => string;
} & ThemeProps &
	ChildrenProps;

/**
 * COMPONENT
 */
export const ListCalendar: FC<ListCalendarProps> & BlockConfigs = (props) => {
	return <ListCards {...props} slug={block.slug} />;
};

ListCalendar.slug = block.slug;
ListCalendar.title = block.title;

import { LocaleContext } from '@superhuit/starterpack-i18n';
import { useContext, useMemo } from 'react';

type useTicketsBtnPropsType = {
	availability: string;
	availabilityDate?: string;
	link?: string;
	text?: string;
};

type ticketsBtnProps = {
	title: string;
	href?: string;
};

const useTicketsBtnProps = ({
	availability,
	availabilityDate,
	link,
	text,
}: useTicketsBtnPropsType) => {
	const { __t } = useContext(LocaleContext);

	const title = useMemo(() => {
		// Custom title
		if (text) return text;

		// Free
		if (availability === 'free') {
			return __t('card-calendar-tickets-free', 'Entrée libre');
		}

		// Soon
		if (availability === 'soon') {
			return availabilityDate
				? `${__t(
						'card-calendar-tickets-soon',
						'Billets dès le'
				  )} ${availabilityDate}`
				: __t('card-calendar-tickets-soon', 'Bientôt disponible');
		}

		if (link) return __t('card-tickets', 'Billets');

		return null;
	}, [availability, availabilityDate, text, link]);

	return !title
		? null
		: {
				title,
				href: link,
		  };
};

export default useTicketsBtnProps;

import { css } from '@emotion/css';

import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { grid } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { customBreakpoints } from '@/css/variables.css';

const getStyles = () =>
	css(
		grid,
		cssObj(
			fluidValues('marginTop', 33, 66),
			fluidValues('marginBottom', 66, 88),
			{
				'.supt-html__inner': {
					gridColumn: '1 / span 12',
				},

				'iframe[src*="soundcloud"], iframe[src*="spotify"]': {
					maxWidth: '570px',
					margin: '0 auto',
					display: 'block',
				},

				'iframe[src*="rts.ch/play"]': {
					aspectRatio: '16 / 9',
					margin: '0 auto',
					display: 'block',
					height: 'auto',

					width: '100%',

					[`@media (min-width: ${customBreakpoints.tablet})`]: {
						width: 'calc(10 / 12 * 100%)',
					},
					[`@media (min-width: ${customBreakpoints.bigLaptop})`]: {
						width: 'calc(8 / 12 * 100%)',
					},
				},
			}
		)
	);

export { getStyles };

import React, { FC, useRef, useEffect, useCallback } from 'react';
import cx from 'classnames';
import ImagesLoaded from 'react-images-loaded';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
// package imports
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { CardShow } from '@/components/renders';
import { Slider } from '@superhuit/starterpack-blocks/renders';
import {
	ThemeProps,
	ChildrenProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { getStyles } from '@superhuit/starterpack-blocks/components/molecules/lists/ListCards/styles.css';

// internal imports
import { CardShowType } from '@/components/molecules/CardShow/render';
import block from './block.json';

export type ListShowsProps = {
	title?: string;
	slug?: string;
	getStyles?: (styles: any) => string;
	isSlider?: Boolean;
	events: Array<CardShowType>;
} & ThemeProps &
	ChildrenProps;

/**
 * COMPONENT
 */
export const ListShows: FC<ListShowsProps> & BlockConfigs = ({
	slug = block.slug,
	isSlider,
	events = [],
	theme = {},
}) => {
	const refList = useRef(null);
	const refCards = useRef([]);

	useEffect(() => {
		window.addEventListener('resize', resizeAllGridItems);

		refList.current = document.querySelector('.supt-listCards__list');

		// clean up function
		return () => {
			window.removeEventListener('resize', resizeAllGridItems);
		};
	}, []);

	useEffect(() => {
		resizeAllGridItems();
	}, [isSlider, events]);

	const resizeGridItem = (item) => {
		if (!item) return;

		const rowHeight = 20; // griAutoRows from css styles
		const rowGap = 40; // gridRowGap from css styles
		const rowSpan = Math.ceil(
			(item.querySelector('.supt-card__wrapper').getBoundingClientRect()
				.height +
				rowGap) /
				(rowHeight + rowGap)
		);
		item.style.gridRowEnd = 'span ' + rowSpan;
	};

	const resizeAllGridItems = () => {
		if (isSlider) return;
		for (let x = 0; x < refCards.current.length; x++) {
			resizeGridItem(refCards.current[x]);
		}
	};

	const { variant, blockTheme } = useThemeBlock(slug, theme);

	const trimmedSlug = slug.split('/');
	const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

	const rootClass = cx(
		'supt-listCards',
		`supt-listCards--${modifierSlug}`,
		isSlider ? 'supt-lisCards--slider' : 'supt-listCards--list',
		getStyles(blockTheme)
	);

	const Cards = useCallback(
		() =>
			events.map((event, i) => (
				<CardShow
					key={event.id}
					{...event}
					ref={(ref) => (refCards.current[i] = ref)}
				/>
			)),
		[events]
	);

	return (
		<div className={rootClass} data-block={slug} data-variant={variant}>
			{isSlider ? (
				<Slider
					className="supt-listCards__slider"
					slides={Cards()}
					// hasStagger
					loop={false}
					transition={{
						tension: 80,
						friction: 20,
					}}
					navigation={{
						icon: (
							<svg
								width="13"
								height="13"
								viewBox="0 0 13 13"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g
									fill="none"
									fillRule="evenodd"
									strokeWidth="1.4"
								>
									<path d="M6.207.843 11.864 6.5l-5.657 5.657" />
									<path
										strokeLinecap="square"
										d="M10.5 6.5H0"
									/>
								</g>
							</svg>
						),
					}}
				/>
			) : (
				<ImagesLoaded
					elementType={'div'}
					done={resizeAllGridItems}
					className="supt-listCards__list"
				>
					{Cards()}
				</ImagesLoaded>
			)}
		</div>
	);
};

ListShows.slug = block.slug;
ListShows.title = block.title;

export const eventsPageThemeAttributes = {
	backgroundColor: '@tokens.colors.brand-1-base.value',
	title: {
		color: '@tokens.variants.default.titleColor',
		typography: '@tokens.typographies.heading-1',
		textAlignment: 'left',
	},
};

export const EventsPageThemeControls = {
	// name of the block
	title: 'Events Page',

	// slug of the block (= the key used in theme.json)
	slug: 'eventsPage',

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: eventsPageThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return <></>;
	},
};

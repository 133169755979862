import React, { FC } from 'react';
// package imports
import { SectionOneColumnProps } from '@superhuit/starterpack-blocks/utils/typings';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionAccordion } from '../SectionAccordion/render';
// internal imports
import block from './block.json';
import { ListPartners } from '@/components/molecules/ListPartners/render';
import listBlock from '@/components/molecules/ListPartners/block.json';
import { CardPartnerProps } from '@/components/molecules/CardPartner/render';
import { isEmpty } from 'lodash';

type SectionCardsPartnerProps = {
	partners?: Array<CardPartnerProps>;
} & SectionOneColumnProps;

/**
 * COMPONENT
 */
export const SectionCardsPartner: FC<SectionCardsPartnerProps> &
	BlockConfigs = ({ partners, ...props }) => {
	return isEmpty(partners) ? null : (
		<SectionAccordion {...props}>
			<ListPartners partners={partners} slug={listBlock.slug} />
		</SectionAccordion>
	);
};

SectionCardsPartner.slug = block.slug;
SectionCardsPartner.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { resetFirstLastMargins } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	transitions,
	breakpoints,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	expandLink,
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { linkUnderlineWithHoverRedraw } from '@/css/placeholders.css';
import { buttonIcons } from '@/css/variables.css';

const getDynamicStyles = (styles) =>
	cssObj({
		width:
			styles?.minWidth &&
			styles?.maxWidth &&
			`clamp(${styles?.minWidth}, 100%, ${styles?.maxWidth})`,

		'.supt-cardPartner__text': fontStyle(
			{
				...styles?.text?.typography,
				textAlign: styles?.text?.textAlignment,
				color: styles?.text?.color,
			},
			false
		),

		'.supt-cardPartner__link': fontStyle(
			{
				...styles?.button?.typography,
				textAlign: styles?.button?.textAlignment,
				color: styles?.button?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		overflow: 'visible',

		'.supt-cardPartner__logo': cssObj(fluidValues('marginBottom', 15, 35), {
			height: '50px',
			display: 'inline-flex',

			span: {
				height: '100%',
			},

			img: {
				objectFit: 'contain',
				objectPosition: 'left',
			},
		}),

		'.supt-cardPartner__text': resetFirstLastMargins,

		'.supt-cardPartner__link': cssObj(linkUnderlineWithHoverRedraw, {
			marginTop: '15px',
			display: 'inline-block',
			backgroundPosition: '0 90%',

			span: {
				paddingRight: '25px !important',
				position: 'relative',

				'&:after': {
					position: 'absolute',
					right: 0,
					paddingTop: '0.1em',

					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						paddingTop: '0',
					},

					content: buttonIcons.external.black,
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundColor: 'transparent',
					borderRadius: '50%',
					transition: `background-color ${transitions.fast} ease, filter ${transitions.fast} ease`,
					display: 'inline',
					width: '21px',
					height: '21px',

					textAlign: 'center',
				},
			},

			'&:hover, &:focus-within': {
				backgroundPosition: '100% 90%',
			},
		}),

		/* with link */
		'&.supt-withLink': cssObj(expandLink(), {
			transition: `all ${transitions.mid} ease-out`,

			'.supt-cardPartner__text': {
				transition: `all ${transitions.mid} ease-out`,
			},

			'&:hover, &:focus-within': getDynamicStyles(styles?.states?.hover),
		}),
	});

// edit
const getEditGlobalStyles = (styles) =>
	cssObj({
		'.wp-block[data-type="supt/card-partner"]': {
			width:
				styles?.minWidth &&
				styles?.maxWidth &&
				`clamp(${styles?.minWidth}, 100%, ${styles?.maxWidth})`,

			'.supt-card': {
				height: '100%',
			},
		},
	});

export { getStyles, getEditGlobalStyles };

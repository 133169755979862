import React, { FC, useRef, useEffect } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
	ChildrenProps,
} from '@superhuit/starterpack-blocks/utils/typings';
// internal
import { getStyles as getDfltStyles } from './styles.css';
import block from './block.json';

export type SubsectionKeywordImageProps = {
	slug: string;
	title?: string;
	getStyles?: (styles: any) => string;
} & ThemeProps &
	ChildrenProps;

/**
 * COMPONENT
 */
export const SubsectionKeywordImage: FC<SubsectionKeywordImageProps> &
	BlockConfigs = ({
	slug,
	children,
	title,
	theme = {},
	getStyles = getDfltStyles,
}) => {
	const { variant, blockTheme } = useThemeBlock(slug, theme);
	const refTitle = useRef(null);
	const refContent = useRef(null);

	useResizeObserver(refTitle, (entry) => resizeTitle());

	useEffect(() => {
		setTimeout(() => {
			resizeTitle();
		}, 0);

		const resizeListener = () => {
			resizeTitle();
		};

		// set resize listener
		window.addEventListener('resize', resizeListener);

		// clean up function
		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	const isOverflown = ({ clientWidth, scrollWidth }) =>
		scrollWidth > clientWidth;

	const resizeTitle = () => {
		const minSize = 10;
		const maxSize = 512;
		const step = 0.5;
		const unit = 'px';
		let i = minSize;
		let overflow = false;

		const parent = refTitle.current.parentNode;

		while (!overflow && i < maxSize) {
			refTitle.current.style.fontSize = `${i}${unit}`;
			overflow = isOverflown(parent);

			if (!overflow) i += step;
		}

		// revert to last state where no overflow happened
		refTitle.current.style.fontSize = `${i - step}${unit}`;
		refContent.current.style.fontSize = `${i - step}${unit}`;
	};

	const trimmedSlug = slug.split('/');
	const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

	const rootClass = cx(
		'supt-subsection',
		`supt-subsection--${modifierSlug}`,
		getStyles(blockTheme)
	);

	return (
		<div className={rootClass} data-block={slug} data-variant={variant}>
			{title ? (
				<div className="supt-subsection__titleWrapper">
					<h2
						className="supt-subsection__title"
						ref={refTitle}
						dangerouslySetInnerHTML={{ __html: title }}
					/>
				</div>
			) : null}
			<div className="supt-subsection__content" ref={refContent}>
				{children}
			</div>
		</div>
	);
};

SubsectionKeywordImage.slug = block.slug;
SubsectionKeywordImage.title = block.title;

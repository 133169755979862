import { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const PARALLAX_AMOUNT = 140;

export default function useScrollTrigger() {
	gsap.registerPlugin(ScrollTrigger);
	const animations = useRef([]);
	const animatedElements = useRef([]);
	const animatedElementsWrapper = useRef(undefined);

	useLayoutEffect(() => {
		const updateScrollTrigger = () => {
			refreshScrollTrigger();
			window.removeEventListener('scroll', updateScrollTrigger);
		};

		refreshScrollTrigger();

		window.addEventListener('scroll', updateScrollTrigger);

		return () => {
			window.removeEventListener('scroll', updateScrollTrigger);
			animations.current.forEach((animation) => {
				animation.kill();
			});
		};
	}, []);

	const refreshScrollTrigger = () => {
		ScrollTrigger.refresh();
	};

	const setAnimationParallax = (elements, trigger = undefined) => {
		animatedElements.current = [...elements];
		animatedElementsWrapper.current = trigger;
		setAnimation(elements, trigger, 'parallax');
	};

	const setAnimation = (elements, trigger, type) => {
		elements.forEach((element) => {
			if (!element) return;

			const el = element?.current || element;
			const triggerEl = trigger?.current || trigger || el;

			let newAnimation = undefined;

			switch (type) {
				case 'parallax':
					newAnimation = getTimelineParallax(el, triggerEl);
					break;
				default:
					newAnimation = getTimelineParallax(el, triggerEl);
					break;
			}

			animations.current.push(newAnimation);
		});
	};

	// Used: images
	const getTimelineParallax = (element, trigger) => {
		const ani = gsap.fromTo(
			element,
			{
				transformOrigin: 'center center',
				y: PARALLAX_AMOUNT / 2,
				// outline: '1px solid red',
			},
			{
				scrollTrigger: {
					trigger: trigger,
					start: 'top bottom',
					end: 'bottom top',
					scrub: 0.5,
					invalidateOnRefresh: true, // to make it responsive
					// markers: true,
				},
				y: -PARALLAX_AMOUNT / 2,
				// outline: '1px solid pink',
			}
		);

		return ani;
	};

	return {
		setAnimationParallax,
	};
}

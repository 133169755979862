import React, { FC, useMemo } from 'react';
// package imports
import {
	ChildrenProps,
	SectionOneColumnProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionOneColumn } from '@superhuit/starterpack-blocks/components/renders'; // Needs to be called from /components folder to work properly..
// internal imports
import block from './block.json';

type SectionCtasProps = SectionOneColumnProps & {
	children?: ChildrenProps & {
		props: { blocks: Array<{ attributes: any }> };
	};
};

/**
 * COMPONENT
 */
export const SectionCtas: FC<SectionCtasProps> & BlockConfigs = (props) => {
	const hasChildren = useMemo(
		() =>
			props.children.props.blocks.some(({ innerBlocks }) =>
				innerBlocks.some(
					({ attributes }) =>
						(attributes?.title && attributes?.title !== '') ||
						(attributes?.uptitle && attributes?.uptitle !== '') ||
						(attributes?.text && attributes?.text !== '')
				)
			),
		[props.children.props.blocks]
	);

	return (props?.title && props?.title !== '') || hasChildren ? (
		<SectionOneColumn {...props} />
	) : null;
};

SectionCtas.slug = block.slug;
SectionCtas.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
// Resources
// import { } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
// import { } from '@superhuit/starterpack-blocks/css/resources/variables.css';
// import { } from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getStyles = () =>
	css(
		fluidValues('gridColumnGap', 15, 20),
		fluidValues('gridRowGap', 15, 20),
		{
			marginTop: '40px',
			overflowX: 'auto',
			overflowY: 'hidden',
			position: 'relative',

			[`@media (min-width: ${breakpoints.medium})`]: {
				flexWrap: 'wrap',
			},

			'&.supt-headerListAnchor--gradLeft': {
				'&::before': {
					content: "''",
					width: '50px',
					height: '100%',
					position: 'absolute',
					top: 0,
					left: 0,
					zIndex: 1,
					backgroundImage:
						'linear-gradient(-90deg, rgba(255,255,255,0.00) 0%, #FFFFFF 100%)',
				},
			},

			'&.supt-headerListAnchor--gradRight': {
				'&::after': {
					content: "''",
					width: '50px',
					height: '100%',
					position: 'absolute',
					top: 0,
					right: 0,
					backgroundImage:
						'linear-gradient(90deg, rgba(255,255,255,0.00) 0%, #FFFFFF 100%)',
				},
			},

			'.supt-headerListAnchor__list': cssObj(
				fluidValues('gridColumnGap', 15, 20),
				fluidValues('gridRowGap', 15, 20),
				{
					display: 'flex',
					alignItems: 'center',
					overflowX: 'auto',
					overflowY: 'hidden',
				}
			),

			'.supt-buttonPrimary': {
				margin: 0,

				'.supt-button__inner': {
					span: {
						whiteSpace: 'nowrap',
					},
				},
			},

			'.supt-buttonAnchor': {
				whiteSpace: 'nowrap',
			},
		}
	);

// edit
const getEditGlobalStyles = () =>
	cssObj({
		'.wp-block[data-type="supt/header-list-anchor"]': cssObj({
			'.supt-listCards': {
				margin: 0,

				'[data-type="supt/button-anchor"]': {
					margin: 0,

					'> div': {
						margin: 0,
					},
				},
			},
		}),
	});

export { getStyles, getEditGlobalStyles };

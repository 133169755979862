import { mergeDeep } from '@superhuit/starterpack-utils';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionOneColumnThemeControls } from '@superhuit/starterpack-blocks/components/themings'; // Needs to be called from /components folder to work properly..

import block from './block.json';

export const sectionHighlightedArtistsThemeAttributes = {
	columns: {
		headline: {
			desktop: {
				offsetLeft: 0,
				columnLeft: 5,
			},
			tablet: {
				offsetLeft: 0,
				columnLeft: 5,
			},
			mobile: {
				offsetLeft: 0,
				columnLeft: 12,
			},
		},
		content: {
			desktop: {
				offsetLeft: 6,
				columnLeft: 6,
			},
			tablet: {
				offsetLeft: 5,
				columnLeft: 7,
			},
			mobile: {
				offsetLeft: 0,
				columnLeft: 12,
			},
		},
	},
};

export const SectionHighlightedArtistsThemeControls: ThemeControlsType =
	mergeDeep(SectionOneColumnThemeControls, {
		// What's displayed
		title: block.title,

		// Unique string/id of the block (= the key used in theme.json)
		slug: block.slug,

		// styling attributes available for this block
		// ...the same way we define attributes for a gutenberg block
		attributes: sectionHighlightedArtistsThemeAttributes,
	});

import { mergeDeep } from '@superhuit/starterpack-utils';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionTwoColumnsThemeControls } from '@superhuit/starterpack-blocks/components/themings'; // Needs to be called from /components folder to work properly..

import block from './block.json';

export const SectionDetailsThemeControls: ThemeControlsType = mergeDeep(
	SectionTwoColumnsThemeControls,
	{
		// What's displayed
		title: block.title,

		// Unique string/id of the block (= the key used in theme.json)
		slug: block.slug,
	}
);

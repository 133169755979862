import cx from 'classnames';
import {
	FC,
	useContext,
	useState,
	useRef,
	useEffect,
	forwardRef,
	useImperativeHandle,
} from 'react';
import { useRouter } from 'next/router';

import LangSwitcher from '@superhuit/starterpack-blocks/components/molecules/LangSwitcher/render';
import { LocaleContext } from '@superhuit/starterpack-i18n';

import { Link } from '@/components/atoms/Link/render';
import { SecondaryNavProps } from '../typings';
import { SecondarySubmenu } from './secondary-submenu';
import { useShopCookies } from '../../useShopCookies';

export const SecondaryNav: FC<SecondaryNavProps> = forwardRef(
	(
		{
			items,
			uri,
			translations,
			setIsSecondarySubmenuOpen,
			primaryNavRef,
			isPrimarySubmenuOpen,
		},
		ref
	) => {
		const { __t } = useContext(LocaleContext);

		const submenuRefs = useRef([]);
		const router = useRouter();

		const [openedSubmenuId, setOpenedSubmenuId] = useState(-1);

		useImperativeHandle(ref, () => ({
			close: () => {
				return closeSubmenu();
			},
		}));

		useEffect(() => {
			// close submenu when pressing ESC
			window.addEventListener('keydown', handleKeyDown);
			// close submenu when a new page gets rendered
			router.events.on('routeChangeComplete', hashListener);

			return () => {
				window.removeEventListener('keydown', handleKeyDown);
				router.events.off('routeChangeComplete', hashListener);
			};
		}, []);

		const shopCart = useShopCookies();

		const hashListener = () => {
			closeSubmenu();
		};

		const handleKeyDown = (e) => {
			if (['Escape', 'Esc'].includes(e?.key)) closeSubmenu();
		};

		const toggleSubmenu = (index) => {
			if (openedSubmenuId === index) {
				// Close current submenu
				closeSubmenu();
			} else {
				// Open current submenu
				openSubmenu(index);

				// Close primary submenu if it has been opened
				if (isPrimarySubmenuOpen) {
					primaryNavRef.current.close();
				}
			}
		};

		const openSubmenu = (index) => {
			setOpenedSubmenuId(index);

			setIsSecondarySubmenuOpen(true);
		};
		const closeSubmenu = () => {
			setOpenedSubmenuId(-1);

			setIsSecondarySubmenuOpen(false);
		};

		const isActiveParent = (subItems) => {
			const activeSubItems = subItems.filter(
				(subItem) => subItem.path === uri || uri.includes(subItem.path)
			);

			const activeSubSubItems = subItems.filter(
				(subItem) =>
					subItem.items.filter(
						(subItem) =>
							subItem.path === uri || uri.includes(subItem.path)
					).length > 0
			);

			if (activeSubItems.length || activeSubSubItems.length) return true;

			return false;
		};

		return (
			<div
				className="supt-mainNav__secondary"
				aria-label={__t('secondary-nav-label', 'Secondary navigation')}
				role="navigation"
			>
				<nav className="supt-mainNav__secondary__nav">
					{items.map(
						({ label, path, id, items: subItems, target }, i) => {
							const hasSubItems = !!subItems.length;

							return (
								<div
									className={cx(
										'supt-mainNav__secondary__item',
										{
											isOpened: openedSubmenuId === i,
										},
										{
											isCurrent:
												uri === path ||
												isActiveParent(subItems),
										},
										{
											'supt-mainNav__secondary__item--hasSubmenu':
												hasSubItems,
										}
									)}
									key={id}
								>
									{hasSubItems ? (
										<button
											className={cx(
												'supt-mainNav__secondary__item__label'
											)}
											onClick={() => toggleSubmenu(i)}
										>
											<span>{label}</span>
											<svg
												width="10"
												height="6"
												xmlns="http://www.w3.org/2000/svg"
											>
												<use href="#chevron" />
											</svg>
										</button>
									) : (
										<Link
											href={path ?? '#'}
											suppressHydrationWarning
											className={cx(
												'supt-mainNav__secondary__item__label'
											)}
											aria-current={
												uri === path ? 'page' : null
											}
											target={target}
										>
											<span>{label}</span>
										</Link>
									)}

									{hasSubItems ? (
										<SecondarySubmenu
											items={subItems}
											uri={uri}
											ref={(el) =>
												(submenuRefs.current[i] = el)
											}
										/>
									) : null}
								</div>
							);
						}
					)}
				</nav>

				<Link
					href="https://billetterie.vidy.ch/"
					className="cart supt-mainNav__secondary__cart"
				>
					<svg
						viewBox="0 0 16 16"
						width="16"
						height="16"
						xmlns="http://www.w3.org/2000/svg"
					>
						<use href="#cart-white" />
					</svg>
					{shopCart.qty > 0 ? (
						<span className="cart__qty">{shopCart.qty}</span>
					) : null}
				</Link>

				<LangSwitcher translations={translations} />
			</div>
		);
	}
);

import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-section--section-press-slider': {
			overflow: 'hidden',

			'.supt-sliderNews': {
				gridColumn: '1 / span 12',
			},

			'&.supt-section--is-open .supt-section__content': {
				overflow: 'visible',
			},
		},
	});

const getEditCustomStyles = () =>
	cssObj({
		'.components-notice.is-info': {
			backgroundColor: '#069ce82e',
			marginLeft: 0,
			marginRight: 0,
		},
		'.supt-section--section-press-slider': {
			'.supt-sliderNews': {
				width: '100%',
				margin: 0,
			},
		},
	});

export { getCustomStyles, getEditCustomStyles };

import { aspectRatio } from '@/css/mixins.css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	breakpoints,
	containers,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-section--section-ctas': {
			'.supt-section__uptitle': {
				display: 'none !important',
			},

			'.supt-section__content': {
				'&:first-child': {
					'> .supt-listCards': {
						marginTop: 0,
					},
				},
			},

			'.supt-listCards__list': {
				paddingLeft: containers.minOffset,
				paddingRight: containers.minOffset,

				[`@media (min-width: ${breakpoints.tablet})`]: {
					paddingLeft: containers.tabletOffset,
					paddingRight: containers.tabletOffset,
				},

				/* If it's a list with 2 small cards with 1 which has an image => force the other one to be on square size */
				'&.hasImage': {
					'.supt-cardCta:not(.hasImage)': cssObj(aspectRatio(1, 1), {
						'.supt-cardCta__content': {
							WebkitLineClamp: 4,
							[`@media (min-width: ${breakpoints.fluidMin})`]: {
								WebkitLineClamp: 6,
							},
							[`@media (min-width: ${breakpoints.mobile})`]: {
								WebkitLineClamp: 8,
							},
							[`@media (min-width: ${breakpoints.tablet})`]: {
								WebkitLineClamp: 4,
							},
							[`@media (min-width: ${breakpoints.bigLaptop})`]: {
								WebkitLineClamp: 6,
							},
							[`@media (min-width: ${breakpoints.desktop})`]: {
								WebkitLineClamp: 8,
							},
						},
					}),
				},
			},
		},
	});

export { getCustomStyles };

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	grid,
	resetFirstLastChildMargins,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	fontStyle,
	fluidPadding,
	fluidValues,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getDynamicStyles = (styles) =>
	cssObj(fluidPadding(styles?.padding), {
		background: styles?.backgroundColor,

		'.supt-section__inner': {
			'.supt-section__headline': {
				'.supt-section__uptitle': fontStyle({
					...styles?.uptitle?.typography,
					textAlign: styles?.uptitle?.textAlignment,
					color: styles?.uptitle?.color,
				}),
				'.supt-section__title': fontStyle({
					...styles?.title?.typography,
					textAlign: styles?.title?.textAlignment,
					color: styles?.title?.color,
				}),
				'.supt-section__introduction': fontStyle({
					...styles?.introduction?.typography,
					textAlign: styles?.introduction?.textAlignment,
					color: styles?.introduction?.color,
				}),
			},
		},
	});

const getStyles = (styles) =>
	css(
		getDynamicStyles(styles),
		fluidValues('paddingTop', 60, 100, true),
		fluidValues('paddingBottom', 60, 130, true),
		{
			// Remove negative margin top caused by default variant section following each others
			marginTop: '0 !important',

			'.supt-section__inner': cssObj(grid, {
				'.supt-sectionCardsPress__linkWrapper':
					resetFirstLastChildMargins,
			}),

			'.supt-section__headline': cssObj(
				fluidValues('top', 135, 240, true),
				{
					gridColumn: '1 / span 12',
					[`@media (min-width: ${breakpoints.laptop})`]: {
						gridColumnEnd: 'span 10',

						position: 'sticky',
						alignSelf: 'baseline',
						zIndex: 0,
						paddingBottom: 200,
					},
					[`@media (min-width: ${breakpoints.desktop})`]: {
						gridColumnEnd: 'span 8',
					},
				}
			),
			'.supt-section__title': {
				marginBottom: '0.25em !important',
			},

			'.supt-section__content': {
				position: 'relative',
				zIndex: 1,
				gridColumn: '1 / span 12',
				pointerEvents: 'none',
				[`@media (min-width: ${breakpoints.laptop})`]: {
					marginTop: -200,
				},
				'.supt-cardNews': {
					pointerEvents: 'auto',
					backgroundColor: 'rgba(255, 255, 255, 0.95)',
				},
			},

			// Remove negative margin top caused by default variant section following each others
			'& + .supt-section--default': {
				marginTop: '0 !important',
			},
		}
	);

const getEditGlobalStyles = () =>
	cssObj({
		'.wp-block[data-type="supt/section-cards-press"]': {
			'.supt-sectionCardsPress__linkInner': {
				display: 'inline-block',

				textarea: {
					background: 'none',
				},
			},
		},
	});

export { getStyles, getEditGlobalStyles };

import React, { FC } from 'react';
// package imports
import { SectionTwoColumnsProps } from '@superhuit/starterpack-blocks/utils/typings';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionTwoColumns } from '@superhuit/starterpack-blocks/components/renders'; // Needs to be called from /components folder to work properly..
// internal imports
import block from './block.json';

/**
 * COMPONENT
 */
export const SectionFaq: FC<SectionTwoColumnsProps> & BlockConfigs = (
	props
) => {
	return <SectionTwoColumns {...props} />;
};

SectionFaq.slug = block.slug;
SectionFaq.title = block.title;

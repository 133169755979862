import React, {
	forwardRef,
	Ref,
	useRef,
	useEffect,
	useState,
	useContext,
} from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { Section } from '@superhuit/starterpack-blocks/helpers/Section';
import { SectionOneColumnProps } from '@superhuit/starterpack-blocks/utils/typings';
import { LinkProps } from '@superhuit/starterpack-blocks/helpers/Link/render';
import { ResultsInfo } from '@/components/renders';
import buttonAnchorBlock from '@superhuit/starterpack-blocks/components/atoms/Buttons/ButtonAnchor/block.json';
// styles
import { getStyles as getDfltStyles } from '@superhuit/starterpack-blocks/components/organisms/Sections/SectionOneColumn/styles.css';
import block from './block.json';
import { getStyles } from './styles.css';
import { getStyles as getButtonAnchorStyles } from '@superhuit/starterpack-blocks/components/atoms/Buttons/ButtonAnchor/styles.css';
import { ListArtists } from '@/components/renders';
import { ListArtistsProps } from '@/components/molecules/ListArtists/render';

const LIST_LENGTH = 16;

/**
 * TYPINGS
 */
type SectionCardsArtistProps = SectionOneColumnProps & {
	link?: LinkProps;
} & ListArtistsProps;

/**
 * COMPONENT
 */
export const SectionCardsArtist = forwardRef<Ref<any>, SectionCardsArtistProps>(
	(
		{
			anchor,
			className,
			title,
			artists,
			slug,
			variant: currentVariant = 'default',
			titleTag = 'h2',
			theme = {},
			getStyles: getSectionStyles = getDfltStyles,
		},
		ref
	) => {
		const inputSearchRef = useRef(null);
		const [filteredArtists, setFilteredArtists] = useState(
			artists.slice(0, LIST_LENGTH)
		);
		const [isSearching, setIsSearching] = useState(false);
		const { __t } = useContext(LocaleContext);

		const { variant, blockTheme } = useThemeBlock(
			slug,
			theme,
			currentVariant
		);

		const { blockTheme: buttonAnchorTheme } = useThemeBlock(
			buttonAnchorBlock.slug,
			theme,
			variant
		);

		const trimmedSlug = slug.split('/');
		const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

		const rootClass = cx(
			'supt-section supt-section--oneCol',
			`supt-section--${modifierSlug}`,
			'supt-section--colored',
			getSectionStyles(blockTheme),
			getStyles(blockTheme),
			className
		);

		useEffect(() => {
			if (!inputSearchRef.current) return;

			inputSearchRef.current.addEventListener('input', handleInputChange);

			return () => {
				if (inputSearchRef?.current)
					inputSearchRef.current.removeEventListener(
						'input',
						handleInputChange
					);
			};
		}, []);

		const toNormalForm = (str) => {
			return str
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase();
		};

		const handleInputChange = (e) => {
			const search = e.target.value;

			setIsSearching(() => search.length > 0);

			const result = artists.filter((artist) =>
				toNormalForm(artist.name).includes(toNormalForm(search))
			);

			setFilteredArtists(() => result.slice(0, LIST_LENGTH));
		};

		const handleResetButtonClick = (e) => {
			setIsSearching(false);
			inputSearchRef.current.value = '';

			setFilteredArtists(() => artists.slice(0, LIST_LENGTH));
		};

		return (
			<Section
				id={anchor}
				className={rootClass}
				variant={variant}
				slug={slug}
				ref={ref}
			>
				<div className="supt-section__inner">
					<div className="supt-section__headline">
						{title ? (
							<Section.Title text={title} titleTag={titleTag} />
						) : null}

						<div className="supt-section__search">
							<input
								className="supt-section__search__input"
								id="myInput"
								ref={inputSearchRef}
								type="text"
								name="artist"
								placeholder={__t(
									'artists-list-search-placeholder',
									'Rechercher parmi les 1’000+ artistes'
								)}
							></input>
							{isSearching ? (
								<button
									className={cx(
										'supt-button',
										'supt-button--reset',
										getButtonAnchorStyles(buttonAnchorTheme)
									)}
									data-type="reset"
									onClick={handleResetButtonClick}
								>
									{__t(
										'artists-list-search-reset',
										'Rénitialiser'
									)}
								</button>
							) : null}
						</div>
					</div>
					<div className="supt-section__content">
						{isSearching ? (
							<ResultsInfo
								number={filteredArtists.length}
							></ResultsInfo>
						) : null}
						<ListArtists artists={filteredArtists} />
					</div>
				</div>
			</Section>
		);
	}
);

// @ts-ignore
SectionCardsArtist.slug = block.slug;
// @ts-ignore
SectionCardsArtist.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	resetFirstLastMargins,
	gridColumnGap,
	gridRowGap,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	transitions,
	breakpoints,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	expandLink,
	fluidValues,
	fontStyle,
	lineClamp,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { aspectRatio } from '@/css/mixins.css';
import {
	imageExpandTransition,
	imageReduceTransition,
	linkNoUnderlineWithHover,
	linkUnderlineWithHoverRedraw,
} from '@/css/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-cardArtist__name': fontStyle(
			{
				...styles?.name?.typography,
				textAlign: styles?.name?.textAlignment,
				color: styles?.name?.color,
			},
			false
		),
		'.supt-cardArtist__role': fontStyle(
			{
				...styles?.role?.typography,
				textAlign: styles?.role?.textAlignment,
				color: styles?.role?.color,
			},
			false
		),

		'.supt-cardArtist__bio': fontStyle(
			{
				...styles?.bio?.typography,
				textAlign: styles?.bio?.textAlignment,
				color: styles?.bio?.color,
			},
			false
		),

		'.supt-cardArtist__link': fontStyle(
			{
				...styles?.link?.typography,
				textAlign: styles?.link?.textAlignment,
				color: styles?.link?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		overflow: 'hidden',

		'.supt-cardArtist__imageWrapper': cssObj(
			fluidValues('marginBottom', 15, 20),
			aspectRatio(1, 1),
			{
				display: 'flex',
				position: 'relative',
				overflow: 'hidden',

				'.supt-figure img': {
					objectFit: 'cover',
				},
			}
		),

		'.supt-cardArtist__image': {
			display: 'inline-flex',
			margin: 0,
			width: '100%',

			'> span': {
				width: '100% !important',
			},
		},

		'.supt-cardArtist__link': {
			textDecoration: 'initial',
		},
		'.supt-cardArtist__name': cssObj(resetFirstLastMargins),
		'.supt-cardArtist__role': cssObj(fluidValues('marginTop', 8, 10)),

		/* with link */
		'&.supt-withLink': cssObj(expandLink(), {
			transition: `all ${transitions.mid} ease-out`,

			'.supt-cardArtist__image': cssObj(imageReduceTransition),

			'.supt-cardArtist__name': cssObj(linkNoUnderlineWithHover, {
				backgroundPosition: '0 0.85em',
			}),

			'&:hover, &:focus-within': cssObj(
				getDynamicStyles(styles?.states?.hover),
				{
					'.supt-cardArtist__image': cssObj(imageExpandTransition),
					'.supt-cardArtist__name': {
						backgroundSize: '100% 1px',
					},
				}
			),
		}),

		/* with link */
		'&.supt-cardArtist--bio': cssObj(gridColumnGap, gridRowGap, {
			display: 'grid',
			gridTemplateColumns: 'repeat(12, 1fr)',
			alignItems: 'center',
			justifyContent: 'center',

			[`@media (min-width: ${breakpoints.medium})`]: {
				gridTemplateColumns: 'repeat(6, 1fr)',
			},

			[`@media (min-width: ${breakpoints.bigLaptop})`]: {
				gridTemplateColumns: 'repeat(5, 1fr)',
			},

			'.supt-cardArtist__imageWrapper': cssObj(aspectRatio(1, 1), {
				gridColumn: '1/6',
				gridRow: '1/2',
				margin: 0,

				'.supt-figure img': {
					objectFit: 'cover',
				},

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '1/4',
				},

				[`@media (min-width: ${breakpoints.bigLaptop})`]: {
					gridColumn: '1/3',
				},
			}),

			'.supt-cardArtist__content': cssObj(
				fluidValues('paddingLeft', 12, 24),
				{
					gridColumn: '6/13',
					gridRow: '1/2',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',

					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '4/7',
					},

					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridColumn: '3/6',
					},
				}
			),

			'.supt-cardArtist__title': {
				display: 'inline-flex',
			},

			'.supt-cardArtist__bio': cssObj(lineClamp(3), {
				marginTop: '16px',

				maxHeight: `calc(22px * 3)`,

				[`@media (min-width: ${breakpoints.desktop})`]: cssObj(
					lineClamp(5),
					{
						maxHeight: `calc(22px * 5)`,
					}
				),
			}),

			'.supt-cardArtist__link': cssObj({
				marginTop: '16px',
				textDecoration: 'none',
				display: 'inline-flex',

				span: cssObj(linkUnderlineWithHoverRedraw, {
					zIndex: 2,
				}),
			}),
		}),
	});

export { getStyles };

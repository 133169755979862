import { css } from '@emotion/css';

import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

export const getCustomStyles = (theme) =>
	cssObj({
		'.supt-section--section-cards-show': cssObj(
			fluidValues(
				'paddingTop',
				theme.blocks['supt/section-cards-show'].padding.top[0],
				theme.blocks['supt/section-cards-show'].padding.top[1],
				true
			),
			fluidValues(
				'paddingBottom',
				theme.blocks['supt/section-cards-show'].padding.bottom[0],
				theme.blocks['supt/section-cards-show'].padding.bottom[1],
				true
			),
			{
				// Remove negative margin top caused by default variant section following each others
				marginTop: '0 !important',

				'.supt-section__inner': {
					'.supt-section__headline': cssObj({
						[`@media (min-width: ${breakpoints.medium})`]: {
							gridRow: '1/2',
						},

						'.supt-section__title': {
							margin: 0,
						},
					}),
					'.supt-section__buttons': {
						gridColumn: '1/13',
						marginTop: '20px',

						[`@media (min-width: ${breakpoints.medium})`]: {
							gridColumn: '9/13',
							gridRow: '1/2',
							marginTop: 0,
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'flex-end',
						},
						[`@media (min-width: ${breakpoints.bigLaptop})`]: {
							gridColumn: '7/13',
						},

						'.supt-listCards': {
							margin: 0,
						},
					},

					'.supt-section__content': {
						[`@media (min-width: ${breakpoints.medium})`]: {
							gridRow: '2/3',
						},

						'.supt-listCards': fluidValues('marginTop', 40, 50),
					},
				},

				// Don't apply the negative margin-top on next section to avoid overlapping
				'& + .supt-section': {
					marginTop: '0 !important',
				},
			}
		),
	});

export const getIsLoadingEditStyles = () =>
	css({
		'&::before': {
			content: "''",
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			zIndex: 10,
			display: 'block',
			backgroundColor: 'rgba(255,255,255,.5)',
			pointerEvents: 'none',
			opacity: 0,
			transition: 'opacity 0.2s ease-in-out',
		},

		'.supt-section__content': {
			position: 'relative',
		},

		'&.is-loading': {
			'&::before': {
				opacity: 1,
			},
			'.supt-section__loading': {
				position: 'absolute',
				top: '100px',
				left: '50%',
				zIndex: 11,
				transform: 'translate(-50%,-50%)',
			},
		},
	});

import { mergeDeep } from '@superhuit/starterpack-utils';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionOneColumnThemeControls } from '@superhuit/starterpack-blocks/components/themings'; // Needs to be called from /components folder to work properly..

import block from './block.json';

export const sectionCardsShopThemeAttributes = {
	padding: {
		// without control (for Storybook only)
		top: [60, 83],
		bottom: [62, 104],
	},
	columns: {
		headline: {
			desktop: {
				offsetLeft: 0,
				columnLeft: 7,
			},
			tablet: {
				offsetLeft: 0,
				columnLeft: 9,
			},
			mobile: {
				offsetLeft: 0,
				columnLeft: 12,
			},
		},
		content: {
			desktop: {
				offsetLeft: 0,
				columnLeft: 12,
			},
			tablet: {
				offsetLeft: 0,
				columnLeft: 12,
			},
			mobile: {
				offsetLeft: 0,
				columnLeft: 12,
			},
		},
	},
};

export const SectionCardsShopThemeControls: ThemeControlsType = mergeDeep(
	SectionOneColumnThemeControls,
	{
		// What's displayed
		title: block.title,

		// Unique string/id of the block (= the key used in theme.json)
		slug: block.slug,

		// styling attributes available for this block
		// ...the same way we define attributes for a gutenberg block
		attributes: sectionCardsShopThemeAttributes,
	}
);

import React, { FC, useContext } from 'react';
import cx from 'classnames';

import { _x } from '@wordpress/i18n';

import { LocaleContext } from '@superhuit/starterpack-i18n';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';

import block from './block.json';
import { getStyles } from './styles.css';

import { isEmpty } from 'lodash';

/**
 * TYPINGS
 */
type FalcDescriptionProps = {
	text: string;
};

/**
 * COMPONENT
 */
export const FalcDescription: FC<FalcDescriptionProps> & BlockConfigs = ({
	text,
}) => {
	const rootClass = cx('supt-falcDescription', getStyles());

	const { __t } = useContext(LocaleContext);

	return isEmpty(text) ? null : (
		<details className={rootClass} data-block={block.slug}>
			<summary>
				<strong>{__t('falc-description-title', 'FALC')}</strong>-
				<span>
					{__t(
						'falc-description-subtitle',
						'Facile à lire et à comprendre'
					)}
				</span>
				<svg
					width="28"
					height="28"
					viewBox="0 0 28 28"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					aria-hidden="true"
					focusable="false"
				>
					<g
						stroke="currentColor"
						strokeWidth="2"
						fill="none"
						fillRule="evenodd"
					>
						<line x1="0" y1="14" x2="28" y2="14"></line>
						<line x1="14" y1="0" x2="14" y2="28"></line>
					</g>
				</svg>
			</summary>
			<p
				className="supt-falcDescription__text"
				dangerouslySetInnerHTML={{ __html: text }}
			></p>
		</details>
	);
};

FalcDescription.slug = block.slug;
FalcDescription.title = block.title;

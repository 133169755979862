import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	flexGapPolyfill,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { resetFirstLastChildMargins } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-subsection__title': fontStyle(
			{
				...styles?.title?.typography,
				textAlign: styles?.title?.textAlignment,
				color: styles?.title?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), resetFirstLastChildMargins, {
		'.supt-subsection__title': {
			gridColumn: '1/13',
			marginBottom: '30px',
		},
		'.supt-subsection__content': cssObj(
			flexGapPolyfill([10, 20], [22, 10], [0, 0], [0, 0]),
			{
				gridColumn: '1/13',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',

				[`@media (min-width: ${breakpoints.medium})`]: {
					flexDirection: 'row',
					flexWrap: 'wrap',
				},
			}
		),
	});

export { getStyles };

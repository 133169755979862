import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	fluidValues,
	flexGapPolyfill,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	gridNoMargin,
	resetFirstLastChildMargins,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-subsection__introduction, .supt-subsection__content': fontStyle(
			{
				...styles?.introduction?.typography,
				textAlign: styles?.introduction?.textAlignment,
				color: styles?.introduction?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		'.supt-subsection__introduction': {
			margin: 0,
			gridColumn: '1/13',

			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '1/11',
			},
			[`@media (min-width: ${breakpoints.bigLaptop})`]: {
				gridColumn: '1/10',
			},
		},

		'.supt-subsection__content': cssObj(gridNoMargin, {
			gridColumn: '1/13',
			alignItems: 'center',
			marginTop: '0',

			'.supt-figure': {
				display: 'flex',
				flexDirection: 'column',
				opacity: 0.95,
			},

			'.supt-figure:first-of-type': {
				gridColumn: '1/9',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '2/6',
					gridRow: '2/3',
				},
			},

			'.supt-figure:last-of-type': {
				gridColumn: '3/13',
				marginTop: '62px',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '8/13',
					gridRow: '2/3',
					marginTop: 0,
				},
			},
		}),

		'&.supt-subsection--firstColIsRight': {
			'.supt-subsection__content': {
				'.supt-figure:first-of-type': {
					gridColumn: '5/13',
					order: 1,
					marginTop: '62px',

					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '9/13',
						margin: 0,
					},
				},

				'.supt-figure:last-of-type': {
					gridColumn: '1/11',
					marginTop: 0,

					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '2/7',
					},
				},
			},
		},
	});

const getEditStyles = () =>
	css({
		'.supt-subsection__content': {
			marginTop: '0 !important',

			'.wp-block[data-type="supt/image"]:first-of-type': {
				gridColumn: '1/9',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '2/6',
					gridRow: '2/3',
					margin: 'auto 0',
				},
			},

			'.wp-block[data-type="supt/image"]:last-of-type': {
				gridColumn: '3/13',
				marginTop: '62px',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '8/13',
					gridRow: '2/3',
					marginTop: 0,
				},
			},
		},

		'&.supt-subsection--firstColIsRight': {
			'.supt-subsection__content': {
				'.wp-block[data-type="supt/image"]:first-of-type': {
					gridColumn: '5/13',
					order: 1,
					marginTop: '62px',

					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '9/13',
						margin: 'auto 0',
					},
				},

				'.wp-block[data-type="supt/image"]:last-of-type': {
					gridColumn: '1/11',
					marginTop: 0,

					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '2/7',
						marginTop: 0,
					},
				},
			},
		},
	});

export { getStyles, getEditStyles };

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { gridNoMargin } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
// import { resetLastChildMarginBottom } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getStyles = (styles) =>
	css({
		'.supt-section__content': cssObj({
			gridColumn: '7/13',

			[`@media (max-width: calc(${breakpoints.medium} - 1px))`]: {
				marginTop: '40px',
			},
		}),
	});

const getEditStyles = () => css({});

export { getStyles, getEditStyles };

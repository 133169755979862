import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import { resetFirstLastMargins } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	expandLink,
	fontStyle,
	fluidPadding,
	fluidBorderWidth,
	fluidBorderRadius,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-cardRole__role': fontStyle(
			{
				...styles?.role?.typography,
				textAlign: styles?.role?.textAlignment,
				color: styles?.role?.color,
			},
			false
		),
		'.supt-cardRole__name': fontStyle(
			{
				...styles?.name?.typography,
				textAlign: styles?.name?.textAlignment,
				color: styles?.name?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',

		[`@media (min-width: ${breakpoints.medium})`]: {
			alignItems: 'center',
			flexDirection: 'row',
		},

		'.supt-cardRole__role': {
			order: 1,
			marginTop: 0,
			marginBottom: 0,

			[`@media (min-width: ${breakpoints.medium})`]: {
				order: 0,
				marginRight: '20px',
			},
		},

		'.supt-cardRole__name': {
			marginTop: 0,
			marginBottom: 0,
		},
	});

// edit
const getEditGlobalStyles = (styles) =>
	cssObj({
		'.wp-block[data-type="supt/card-role"]': {},
	});

export { getStyles, getEditGlobalStyles };

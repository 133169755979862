import React, { FC, useMemo } from 'react';
import {
	BlockConfigs,
	ChildrenProps,
	SectionTwoColumnsProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { SectionAccordion } from '../SectionAccordion/render';
import block from './block.json';

type SectionDetailsProps = SectionTwoColumnsProps & {
	children?: ChildrenProps & {
		props: { blocks: Array<{ attributes: any }> };
	};
};

/**
 * COMPONENT
 */
export const SectionDetails: FC<SectionDetailsProps> & BlockConfigs = (
	props
) => {
	const hasChildren = useMemo(
		() =>
			props.children.props.blocks.some(({ innerBlocks }) =>
				// supt/section-details-col
				innerBlocks.some(
					({ attributes }) =>
						// supt/paragraph
						attributes?.content && attributes?.content !== ''
				)
			),
		[props.children.props.blocks]
	);

	return hasChildren ? <SectionAccordion {...props} /> : null;
};

SectionDetails.slug = block.slug;
SectionDetails.title = block.title;

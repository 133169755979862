import React, { FC } from 'react';
// package imports
import { SectionOneColumnProps } from '@superhuit/starterpack-blocks/utils/typings';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionAccordion } from '../SectionAccordion/render';
// internal imports
import block from './block.json';
import { SliderArtists } from '@/components/molecules/SliderArtists/render';
import { CardArtistType } from '@/components/molecules/CardArtist/render';
import { isEmpty } from 'lodash';
import { ListArtists } from '@/components/renders';

/**
 * TYPINGS
 */
type SectionArtistsSliderProps = SectionOneColumnProps & {
	artists?: Array<CardArtistType>;
	showBio?: Boolean;
};

/**
 * COMPONENT
 */
export const SectionArtistsSlider: FC<SectionArtistsSliderProps> &
	BlockConfigs = ({ artists, showBio = false, ...props }) => {
	return isEmpty(artists) ? null : (
		<SectionAccordion {...props}>
			{showBio ? (
				<ListArtists artists={artists} showBio={showBio} />
			) : (
				<SliderArtists artists={artists} />
			)}
		</SectionAccordion>
	);
};

SectionArtistsSlider.slug = block.slug;
SectionArtistsSlider.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	gridNoMargin,
	container,
	resetFirstLastMargins,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	transitions,
	breakpoints,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import { fontStyle } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { getLinkStyleWithDefaultIcon } from '@/css/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj({
		background: styles?.backgroundColor,

		'.supt-alert__content': fontStyle(
			{
				...styles?.text?.typography,
				textAlign: styles?.text?.textAlignment,
				color: styles?.text?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(
		getDynamicStyles(styles),

		{
			paddingTop: '14px',
			paddingBottom: '11px',
			minHeight: '48px',
			display: 'flex',
			alignItems: 'center',

			'.supt-alert__inner': cssObj(container, {
				display: 'flex',
				width: '100%',
			}),

			'.supt-alert__type': {
				marginRight: '10px',
			},

			'.supt-alert__content': {
				display: 'flex',
				flexDirection: 'column',
				lineHeight: 'initial',
				marginRight: '18px',

				[`@media (min-width: ${breakpoints.medium})`]: {
					flexDirection: 'row',
				},
			},

			'.supt-alert__text': cssObj(resetFirstLastMargins, {}),

			'.supt-alert__linkWrapper': {
				marginTop: '4px',

				[`@media (min-width: ${breakpoints.medium})`]: {
					marginTop: '0',
					marginLeft: '5px',
				},

				'.supt-alert__link': getLinkStyleWithDefaultIcon(),
			},

			'.supt-alert__close': {
				marginLeft: 'auto',
				display: 'block',
				width: '13px',
				height: '13px',
				marginTop: 'auto',
				marginBottom: 'auto',
			},

			'&.supt-alert--hidden': {
				display: 'none',
			},
		}
	);

export { getStyles };

import React, { FC } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';
// styles
import { getStyles } from './styles.css';
import { CardArtist, CardArtistProps } from '../CardArtist/render';

/**
 * TYPINGS
 */
export type ListArtistsProps = ThemeProps & {
	artists?: Array<CardArtistProps>;
	showBio?: Boolean;
};

/**
 * COMPONENT
 */
export const ListArtists: FC<ListArtistsProps> & BlockConfigs = ({
	artists,
	showBio = false,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx(
		'supt-listCards supt-listArtists',
		showBio && 'supt-listArtists--bio',
		getStyles()
	);

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			{(Array.isArray(artists) ? artists : []).map((artist, index) => (
				<CardArtist key={index} showBio={showBio} {...artist} />
			))}
		</div>
	);
};

ListArtists.slug = block.slug;
ListArtists.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import {
	expandLink,
	fluidPadding,
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	gridNoMargin,
	resetButton,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	breakpoints,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';

const buttonDisable = cssObj({
	pointerEvents: 'none',
	borderColor: 'transparent',
	backgroundColor: 'rgba(0, 0, 0, 0.1)',
	color: 'rgba(0, 0, 0, 0.5)',

	'&[target="_blank"]': {
		'.supt-button__inner span::before': {
			content: `url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' opacity='0.5' stroke-width='1.4'%3E%3Cpath d='M2.79 2.4h8v8'/%3E%3Cpath stroke-linecap='square' d='M9.825 3.365 2.4 10.789'/%3E%3C/g%3E%3C/svg%3E")`,
		},
	},
});

const getDynamicStyles = (styles) =>
	cssObj(fluidPadding(styles?.padding), {
		'.supt-cardCalendar__label': fontStyle(
			{
				...styles?.label?.typography,
				textAlign: styles?.label?.textAlignment,
				color: styles?.label?.color,
			},
			false
		),
		'.supt-cardCalendar__artist': fontStyle(
			{
				...styles?.artist?.typography,
				textAlign: styles?.artist?.textAlignment,
				color: styles?.artist?.color,
			},
			false
		),
		'.supt-cardCalendar__title': fontStyle(
			{
				...styles?.title?.typography,
				textAlign: styles?.title?.textAlignment,
				color: styles?.title?.color,
			},
			false
		),
		'.supt-cardCalendar__time': fontStyle(
			{
				...styles?.time?.typography,
				textAlign: styles?.time?.textAlignment,
				color: styles?.time?.color,
			},
			false
		),
		'.supt-cardCalendar__location': fontStyle(
			{
				...styles?.location?.typography,
				textAlign: styles?.location?.textAlignment,
				color: styles?.location?.color,
			},
			false
		),
		'.supt-cardCalendar__availability__label': fontStyle(
			{
				...styles?.availability?.label?.typography,
				textAlign: styles?.availability?.label?.textAlignment,
				color: styles?.availability?.label?.color,
			},
			false
		),

		'.supt-cardCalendar__date__digit': fontStyle(
			{
				...styles?.date?.digit?.typography,
				textAlign: styles?.date?.digit?.textAlignment,
				color: styles?.date?.digit?.color,
			},
			false
		),
		'.supt-cardCalendar__date__weekday': fontStyle(
			{
				...styles?.date?.weekday?.typography,
				textAlign: styles?.date?.weekday?.textAlignment,
				color: styles?.date?.weekday?.color,
			},
			false
		),

		// Availabilities
		'&[data-availability="high"], &[data-availability="free"]': {
			'.supt-cardCalendar__availability__spot': {
				backgroundColor: styles?.availability?.backgroundColor?.high,
			},
		},
		'&[data-availability="low"]': {
			'.supt-cardCalendar__availability__spot': {
				backgroundColor: styles?.availability?.backgroundColor?.low,
			},
		},
		'&[data-availability="none"]': {
			'.supt-cardCalendar__availability__spot': {
				backgroundColor: styles?.availability?.backgroundColor?.none,
			},
		},
		'&[data-availability="soon"]': {
			'.supt-cardCalendar__availability__spot': {
				backgroundColor: styles?.availability?.backgroundColor?.soon,
			},
		},

		// Event type
		'&.supt-cardCalendar--event': {
			'.supt-cardCalendar__title': fontStyle(
				{
					...styles?.artist?.typography,
					textAlign: styles?.artist?.textAlignment,
					color: styles?.artist?.color,
				},
				false
			),
			'.supt-cardCalendar__artist': fontStyle(
				{
					...styles?.title?.typography,
					textAlign: styles?.title?.textAlignment,
					color: styles?.title?.color,
					textTransform: 'none',
				},
				false
			),
		},
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), gridNoMargin, {
		position: 'relative',
		alignItems: 'start',
		borderBottom: '1px solid #000',
		overflow: 'hidden',

		'.supt-cardCalendar__headlines, .supt-cardCalendar__infos': {
			paddingRight: 30,
			[`@media (min-width: ${breakpoints.tablet})`]: {
				paddingRight: 0,
			},
		},

		'.supt-cardCalendar__header': {
			gridColumn: '1 / span 12',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '1 / span 7',
			},

			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '1 / span 4',
				display: 'flex',
			},
		},

		'.supt-cardCalendar__date': {
			marginBottom: 20,

			[`@media (min-width: ${breakpoints.tablet})`]: {
				marginTop: -1,
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				marginRight: 40,
				marginBottom: 0,
				marginTop: 0,
			},
		},
		'.supt-cardCalendar__date__digit, .supt-cardCalendar__date__weekday': {
			display: 'block',
		},
		'.supt-cardCalendar__date__digit': {
			lineHeight: 1,
		},

		'.supt-cardCalendar__headlines': {
			display: 'flex',
			flexDirection: 'column',
		},

		'.supt-cardCalendar__label': {
			display: 'block',
			marginBottom: 8,
		},
		'.supt-cardCalendar__artist': {
			marginBottom: 6,
		},

		'.supt-cardCalendar__infos': {
			gridColumn: '1 / span 12',
			gridRow: 2,
			marginTop: 10,

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '1 / span 8',
				gridRow: 'auto',
			},
			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '1 / span 9',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '5 / span 4',
				marginTop: 0,
			},
		},

		'.supt-cardCalendar__time': {
			marginBottom: 1,
			display: 'inline-block',
			marginRight: 6,
		},

		'.supt-tag[data-type="alert"]': {
			display: 'inline-block',
			top: -2,
		},

		'.supt-cardCalendar__public-list': cssObj(
			fluidValues('marginTop', 6, 8),
			fluidValues('gap', 10, 12),
			{
				listStyle: 'none',
				padding: 0,
				display: 'flex',
				position: 'relative',

				li: {
					display: 'flex',
					alignItems: 'center',

					'figure, span': {
						width: '100%',
						maxHeight: '22px',
					},

					'&.supt-cardCalendar__public-extra': cssObj(
						fluidValues('fontSize', 15, 16),
						{
							letterSpacing: '-0.03125em',
							lineHeight: '1.375em',
						}
					),
				},
			}
		),

		'.supt-cardCalendar__genres': {
			display: 'flex',
			flexWrap: 'wrap',
			marginTop: 30,
			gap: 5,
			paddingRight: 80,
			[`@media (min-width: ${breakpoints.tablet})`]: {
				paddingRight: 0,
				marginTop: 20,
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				marginTop: 14,
			},
		},

		'.supt-cardCalendar__availability': cssObj(resetButton, {
			display: 'block',
			width: 14,
			height: 14,

			position: 'absolute',
			top: 28,
			right: 0,

			cursor: 'default',
			zIndex: 1,

			'&::before': {
				content: "''",
				position: 'absolute',
				top: '-10px',
				left: '-100px',
				right: '-50px',
				bottom: '-10px',
			},

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '9 / span 1',
				position: 'relative',
				top: 'auto',
				right: 'auto',
				marginTop: 12,
				gridRow: '1',
				height: '100%',
				display: 'flex',
				alignItems: 'flex-start',
				justifyContent: 'flex-end',
				width: '100%',
			},

			'&__label': {
				position: 'absolute',
				right: 22,
				top: '0.5em',
				whiteSpace: 'nowrap',
				pointerEvents: 'none',
				transform: 'translateY(-50%) translateX(4px)',
				opacity: 0,
				transition: `opacity ${transitions.fast} ease, transform ${transitions.fast} ease`,

				'&::before': {
					content: '""',
					position: 'absolute',
					top: -8,
					right: 0,
					width: '200%',
					height: 30,
					background:
						'linear-gradient(to left, rgba(255, 255, 255) 60%, rgba(255, 255, 255, 0) 100%)',
					zIndex: -1,
					[`@media (min-width: ${breakpoints.tablet})`]: {
						top: -14,
						height: 50,
					},
				},
			},

			'&__spot': {
				display: 'block',
				width: 14,
				height: 14,
				borderRadius: '50%',
			},

			'&:hover, &:focus-within': {
				'.supt-cardCalendar__availability__label': {
					transform: 'translateY(-50%) translateX(0px)',
					opacity: 1,
				},
			},
		}),

		'.supt-cardCalendar__tickets-btn': {
			gridColumn: '1 / span 12',
			gridRow: 2,
			alignSelf: 'end',
			justifySelf: 'end',
			margin: '0 0 -3px',
			zIndex: '2',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '9 / span 4',
				gridRow: '1',
				alignSelf: 'auto',
				margin: 0,
			},
			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '10 / span 3',
			},
		},

		/* with link */
		'&.supt-withLink': cssObj(expandLink(), {
			'.supt-cardCalendar__tickets-btn': {
				'&::before': {
					content: `none`,
				},
			},
		}),

		'&:not(.supt-withLink) .supt-cardDateTime__tickets-btn, &[data-availability="none"] .supt-cardCalendar__tickets-btn, &[data-availability="soon"] button.supt-cardCalendar__tickets-btn, &[data-availability="free"] button.supt-cardCalendar__tickets-btn':
			buttonDisable,

		'&[data-availability="free"]': {
			'.supt-cardCalendar__genres': {
				paddingRight: 110,
				[`@media (min-width: ${breakpoints.tablet})`]: {
					paddingRight: 0,
				},
			},
		},
		'&[data-availability="soon"]': {
			'button.supt-cardCalendar__tickets-btn': {
				'.supt-button__inner span': {
					backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 13 13' width='13' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath d='M6.5 0C10.0899 0 13 2.9101 13 6.5S10.0899 13 6.5 13 0 10.0899 0 6.5 2.9101 0 6.5 0Z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M6.5 13c3.5899 0 6.5-2.9101 6.5-6.5S10.0899 0 6.5 0 0 2.9101 0 6.5 2.9101 13 6.5 13Z' stroke='%23737373' stroke-width='2.8' fill='none' stroke-miterlimit='5'/%3E%3C/g%3E%3Cpath d='M8.9944 8.1606 6.44 6.6366 6.4974 3' stroke='%23737373' stroke-width='1.4' fill='none' stroke-miterlimit='10'/%3E%3C/svg%3E") !important`,
					backgroundPosition: 'calc(100% - 1px) center',
					backgroundRepeat: 'no-repeat',
					paddingRight: '22px !important',

					'&::before, &::after': {
						content: `none`,
					},
				},
			},

			'.supt-cardCalendar__genres': {
				paddingRight: 150,
				[`@media (min-width: ${breakpoints.tablet})`]: {
					paddingRight: 0,
				},
			},
		},

		// Show type
		'&.supt-cardCalendar--show': {
			'.supt-cardCalendar__title': {
				fontStyle: 'italic',
			},
		},
		// Event type
		'&.supt-cardCalendar--event': {
			'.supt-cardCalendar__artist': {
				order: 1,
				marginTop: 7,
				marginBottom: 0,
			},
		},

		// States
		'&.supt-cardCalendar--canceled, &.supt-cardCalendar--postponed': {
			'.supt-cardCalendar__time': {
				textDecoration: 'line-through',
			},
		},

		// With date
		'&.supt-cardCalendar--hasDate': {
			'.supt-cardCalendar__availability': {
				top: 34,
				[`@media (min-width: ${breakpoints.mobile})`]: {
					top: 37,
				},
				[`@media (min-width: ${breakpoints.small})`]: {
					top: 40,
				},
				[`@media (min-width: ${breakpoints.tablet})`]: {
					top: 'auto',
				},
			},
		},
	});

export { getStyles };

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-subsection__title': fontStyle(
			{
				...styles?.title?.typography,
				textAlign: styles?.title?.textAlignment,
				color: styles?.title?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		'.supt-subsection__titleWrapper': {
			gridColumn: '1/13',
			overflowX: 'auto',
			overflowY: 'hidden',
			margin: 0,
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
		},

		'.supt-subsection__title': {
			margin: 0,
			whiteSpace: 'nowrap',
			textAlign: 'center',
			display: 'block',
			lineHeight: '1em',
		},

		'.supt-subsection__content': {
			gridColumn: '1/13',
			display: 'flex',
			justifyContent: 'center',
			marginTop: '-0.3em',

			'.supt-figure': cssObj(fluidValues('width', 250, 345), {
				display: 'flex',
				flexDirection: 'column',
				opacity: 0.9,
			}),
		},
	});

const getEditStyles = () =>
	css({
		'.supt-instructions': {
			fontFamily: 'sans-serif',
			fontWeight: 400,
			fontSize: '12px',
			color: '#9e9e9e',
			textAlign: 'center',
			gridColumn: '1/13',
			margin: '1em',

			'.supt-instructions__text': {
				margin: 0,
			},

			'.supt-instructions__warning': {
				color: '#ff0000',
				margin: 0,
			},
		},

		'.supt-subsection__title': {
			backgroundColor: 'transparent',
		},

		'.supt-subsection__content': {
			marginTop: '0 !important',

			'.wp-block[data-type="supt/image"]': {
				marginTop: 0,
			},
		},
	});

export { getStyles, getEditStyles };

import cx from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';

import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import {
	ButtonAnchorProps,
	ButtonProps,
} from '@superhuit/starterpack-blocks/helpers/Button/typings';

import { ButtonAnchor } from '@superhuit/starterpack-blocks/renders';
import { ButtonPrimary } from '@/components/atoms/renders';

import block from './block.json';
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
type HeaderListAnchorProps = {
	ticket?: ButtonProps;
	anchors?: Array<ButtonAnchorProps>;
};

/**
 * COMPONENT
 */
export const HeaderListAnchor: FC<HeaderListAnchorProps> & BlockConfigs = ({
	ticket = null,
	anchors = [],
}) => {
	const navRef = useRef(null);
	const [scrollLeft, setScrollLeft] = useState(-1);

	useEffect(() => {
		navRef.current.addEventListener('scroll', handleScrollLeft);
		window.addEventListener('resize', handleScrollLeft);

		return () => {
			if (navRef.current)
				navRef.current.removeEventListener('scroll', handleScrollLeft);
			window.addEventListener('resize', handleScrollLeft);
		};
	}, []);

	const handleScrollLeft = () => {
		if (!navRef.current) return;

		const desktopMQ = window.matchMedia('(min-width: 1024px)');

		setScrollLeft(() =>
			desktopMQ.matches ? -1 : Math.round(navRef.current.scrollLeft)
		);
	};

	const rootClass = cx(
		'supt-headerListAnchor',
		scrollLeft > 0 && 'supt-headerListAnchor--gradLeft',
		scrollLeft >= 0 &&
			navRef.current.scrollWidth -
				navRef.current.clientWidth -
				scrollLeft >
				1 &&
			'supt-headerListAnchor--gradRight',
		getStyles()
	);

	return (
		<div className={rootClass} data-block={block.slug}>
			<div className="supt-headerListAnchor__list" ref={navRef}>
				{ticket && <ButtonPrimary {...ticket} />}
				{anchors.map((anchor, i) => (
					<ButtonAnchor key={i} {...anchor} />
				))}
			</div>
		</div>
	);
};

HeaderListAnchor.slug = block.slug;
HeaderListAnchor.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import {
	container,
	resetButton,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	breakpoints,
	containers,
	gridLayout,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	fluidMargin,
	fluidPadding,
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { customBreakpoints } from '@/css/variables.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-homeCalendar__month, .supt-homeCalendar__day': fontStyle(
			{
				...styles?.header?.typography,
				textAlign: styles?.header?.textAlignment,
				color: styles?.header?.color,
			},
			false
		),

		'.supt-homeCalendar__day': fluidMargin(styles?.header?.day?.margin),

		'.supt-homeCalendar__content': fluidPadding(styles?.content?.padding),

		'.supt-homeCalendar__date': fontStyle(
			{
				...styles?.content?.date?.typography,
				textAlign: styles?.content?.date?.textAlignment,
				color: styles?.content?.date?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		'.supt-homeCalendar__header': {
			position: 'relative',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				borderTop: '1px solid #000',
			},

			'&__inner': cssObj(container, {
				height: '100%',

				[`@media (min-width: ${breakpoints.tablet})`]: {
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: gridLayout.gap,
				},

				[`@media (min-width: ${gridLayout.gapMaxMq})`]: {
					gap: gridLayout.gapMax,
				},
			}),
		},

		'.supt-homeCalendar__month': {
			textTransform: 'capitalize',
			marginBottom: 10,

			[`@media (min-width: ${breakpoints.tablet})`]: {
				marginBottom: 0,
			},
		},

		'.supt-homeCalendar__days': cssObj(fluidValues('height', 60, 71), {
			display: 'flex',
			alignSelf: 'stretch',
			borderTop: '1px solid #000',
			marginLeft: `calc(-1 * ${containers.minOffset})`,
			marginRight: `calc(-1 * ${containers.minOffset})`,

			[`@media (min-width: ${breakpoints.tablet})`]: {
				borderTop: 0,
				marginLeft: 0,
				marginRight: 0,
			},
		}),

		'.supt-homeCalendar__day': cssObj(resetButton, {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			position: 'relative',
			transition: `color ${transitions.mid} ease`,

			'&::before': cssObj(fluidValues('width', 56, 77), {
				content: '""',
				position: 'absolute',
				top: 0,
				left: '50%',
				height: '100%',
				transform: 'translateX(-50%)',
				backgroundColor: '#000',
				opacity: 0,
				zIndex: 0,
				transition: `opacity ${transitions.mid} ease`,
			}),

			'&:disabled': {
				color: '#767676',
				pointerEvents: 'none',
			},

			'> span': {
				position: 'relative',
				zIndex: 1,
			},

			'&__week': {
				textTransform: 'lowercase',
			},

			'&:hover, &:focus-within, &.isActive': {
				color: '#fff',
				'&::before': {
					opacity: 1,
				},
			},

			'&.isActive': {
				fontWeight: '500',
			},

			// Show / Hide depending on viewport size
			'&:nth-child(6)': {
				[`@media (max-width: calc(${breakpoints.fluidMin} - 1px))`]: {
					display: 'none',
				},
			},
			'&:nth-child(7)': {
				[`@media (max-width: calc(${customBreakpoints.iphone} - 1px))`]:
					{
						display: 'none',
					},
			},
			'&:nth-child(8)': {
				[`@media (max-width: calc(${breakpoints.mobile} - 1px))`]: {
					display: 'none',
				},
			},
			'&:nth-child(9), &:nth-child(10)': {
				[`@media (max-width: calc(${breakpoints.small} - 1px))`]: {
					display: 'none',
				},
				[`@media (min-width: ${breakpoints.tablet}) and (max-width: calc(${breakpoints.medium} - 1px))`]:
					{
						display: 'none',
					},
			},
		}),

		'.supt-homeCalendar__calendar-btn': {
			'&__mobile': cssObj(resetButton, fluidValues('bottom', 23, 30), {
				position: 'absolute',
				right: 15,

				[`@media (min-width: ${breakpoints.tablet})`]: {
					display: 'none',
				},

				svg: {
					display: 'block',
				},
			}),

			'&__desktop': {
				margin: 0,
				[`@media (max-width: calc(${breakpoints.tablet} - 1px))`]: {
					display: 'none',
				},
			},
		},

		'.supt-homeCalendar__content': {
			borderTop: '1px solid #000',
			position: 'relative',

			'&__inner': container,
		},

		'.supt-homeCalendar__close-btn': cssObj(resetButton, {
			position: 'absolute',
			top: 20,
			right: containers.minOffset,

			[`@media (min-width: ${breakpoints.tablet})`]: {
				right: containers.tabletOffset,
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				right: containers.laptopOffset,
			},
			[`@media (min-width: ${breakpoints.desktop})`]: {
				right: containers.desktopOffset,
			},
			[`@media (min-width: ${containers.maxWidthMq})`]: {
				right: `calc((100vw - ${containers.maxWidth}) / 2)`,
			},

			svg: {
				display: 'block',
			},
		}),
	});

// edit
const getEditGlobalStyles = (styles) =>
	cssObj({
		'.wp-block[data-type="supt/home-calendar"]': cssObj({
			'.components-notice.is-info': {
				backgroundColor: '#069ce82e',
				marginLeft: 0,
				marginRight: 0,
			},
			'.supt-homeCalendar__header__inner': {
				height: 'auto',
			},
		}),
	});

export { getStyles, getEditGlobalStyles };

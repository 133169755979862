import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	breakpoints,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { gridNoMargin } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-subsection__title': fontStyle(
			{
				...styles?.title?.typography,
				textAlign: styles?.title?.textAlignment,
				color: styles?.title?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		'&:first-child': {
			paddingTop: '0 !important',
		},

		'.supt-subsection__inner': cssObj(gridNoMargin, {
			gridColumn: '1/13',
			position: 'relative',
			minHeight: '570px',
			alignContent: 'center',
		}),

		'.supt-subsection__titleWrapper': cssObj(
			fluidValues('paddingTop', 55, 90),
			fluidValues('paddingBottom', 55, 65),
			{
				gridColumn: '1/13',
				overflowX: 'auto',
				overflowY: 'hidden',
				margin: 0,
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
			}
		),

		'.supt-subsection__title': {
			margin: 0,
			textAlign: 'center',
			display: 'block',
			lineHeight: '1em',
			opacity: 0,
			transition: `opacity ${transitions.mid} ease-out`,

			[`@media (min-width: ${breakpoints.small})`]: {
				whiteSpace: 'nowrap',
			},

			span: {
				lineHeight: '1em',
			},

			'.supt-subsection__title__year': {
				display: 'block',

				[`@media (min-width: ${breakpoints.small})`]: {
					display: 'initial',
				},
			},

			'.supt-subsection__title__bullet': {
				display: 'none',

				[`@media (min-width: ${breakpoints.small})`]: {
					display: 'initial',
				},
			},
		},

		'.supt-subsection__content': cssObj(gridNoMargin, {
			gridColumn: '1/13',
			position: 'absolute',
			width: '100%',
			height: '100%',

			'.supt-figure': cssObj({
				display: 'flex',
				flexDirection: 'column',
				opacity: 0.8,
				gridRow: '1/2',

				'.supt-figure__figcaption': {
					display: 'none',
				},
			}),

			'.supt-figure:first-child': {
				marginTop: 'auto',
				gridColumn: '1/7',

				[`@media (min-width: ${breakpoints.small})`]: {
					gridColumn: '1/5',
				},

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '1/4',
				},
			},

			'.supt-figure:nth-child(2)': {
				marginTop: 'auto',
				marginBottom: 'auto',
				gridColumn: '5/9',

				[`@media (min-width: ${breakpoints.small})`]: {
					gridColumn: '5/8',
				},
				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '5/7',
				},
			},

			'.supt-figure:last-child': {
				marginBottom: 'auto',
				gridColumn: '7/13',

				[`@media (min-width: ${breakpoints.small})`]: {
					gridColumn: '8/13',
				},
				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '9/13',
				},
			},
		}),

		'&.supt-subsection--isResized': {
			'.supt-subsection__title': {
				opacity: 1,
			},
		},
	});

const getEditStyles = () =>
	css({
		'.supt-subsection__content': {
			marginTop: '0 !important',
			position: 'relative !important' as 'relative',

			'.wp-block[data-type="supt/image"]': {
				marginTop: '0',
				marginBottom: '0',
			},

			'.wp-block[data-type="supt/image"]:first-child': {
				gridColumn: '1/7',

				[`@media (min-width: ${breakpoints.small})`]: {
					gridColumn: '1/5',
				},

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '1/4',
				},
			},

			'.wp-block[data-type="supt/image"]:nth-child(2)': {
				gridColumn: '5/9',

				[`@media (min-width: ${breakpoints.small})`]: {
					gridColumn: '5/8',
				},
				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '5/7',
				},
			},

			'.wp-block[data-type="supt/image"]:last-child': {
				gridColumn: '7/13',

				[`@media (min-width: ${breakpoints.small})`]: {
					gridColumn: '8/13',
				},
				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '9/13',
				},
			},
		},
	});

export { getStyles, getEditStyles };

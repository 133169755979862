import {
	SizeFluidControl,
	BoxFluidControl,
	BoxRadiusFluidControl,
	SizeControl,
	CustomSelectControl,
	ColorControl,
	RadioGroupControl,
	FLEX_ALIGNS,
	TEXT_ALIGNS,
} from '@superhuit/starterpack-customizer';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const cardShowThemeAttributes = {
	artist: {
		color: '@tokens.variants.default.cardTitleColor',
		typography: '@tokens.typographies.heading-4',
		textAlignment: 'left',
	},
	title: {
		color: '@tokens.variants.default.cardTitleColor',
		typography: '@tokens.typographies.fresco-s-italic',
		textAlignment: 'left',
	},
	content: {
		color: '@tokens.variants.default.cardContentColor',
		typography: '@tokens.typographies.small-text',
		textAlignment: 'left',
	},
	states: {
		hover: {
			button: {
				color: '@tokens.colors.neutral-000.value',
				backgroundColor: '@tokens.colors.neutral-900.value',
			},
		}, // adds hover state
	},
};

export const CardShowThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: cardShowThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return <></>;
	},
};

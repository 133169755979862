import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { gridColumnGap } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj(
		fluidValues(
			'marginTop',
			styles?.margin?.top?.[0],
			styles?.margin?.top?.[1]
		),
		fluidValues(
			'marginBottom',
			styles?.margin?.bottom?.[0],
			styles?.margin?.bottom?.[1]
		),
		fluidValues(
			'gridRowGap',
			styles?.grid?.gap?.[0],
			styles?.grid?.gap?.[1]
		)
	);

const getStyles = (styles) =>
	css(getDynamicStyles(styles), gridColumnGap, {
		display: 'grid',
		gridTemplateColumns: 'repeat(12, 1fr)',
		alignItems: 'start',

		'.supt-cardNews': {
			gridColumnEnd: 'span 10',
			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumnEnd: 'span 6',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumnEnd: 'span 4',
			},

			[`@media (max-width: calc(${breakpoints.tablet} - 1px))`]: {
				'&:nth-child(odd)': {
					gridColumnStart: '1 !important',
				},
				'&:nth-child(even)': {
					gridColumnStart: '3 !important',
				},
			},
		},
	});

export { getStyles };

import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const cardShopThemeAttributes = {
	title: {
		color: '@tokens.variants.default.cardTitleColor',
		typography: '@tokens.typographies.heading-4',
		textAlignment: 'left',
	},
	content: {
		color: '@tokens.variants.default.cardContentColor',
		typography: '@tokens.typographies.small-text',
		textAlignment: 'left',
	},
	link: {
		color: '@tokens.variants.default.cardContentColor',
		typography: '@tokens.typographies.button',
		textAlignment: 'left',
	},
	states: {
		hover: {}, // adds hover state
	},
};

export const CardShopThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: cardShopThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return <></>;
	},
};

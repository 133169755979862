import React, { FC, forwardRef, Ref, useContext } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Link } from '@/components/atoms/Link/render';
import {
	ThemeProps,
	ImageProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { CardLinkProps } from '@superhuit/starterpack-blocks/helpers/CardControls/typings';
import { Image } from '@superhuit/starterpack-blocks/components/renders';
import { ButtonPrimary } from '@/components/atoms/ButtonPrimary/render';
import { Tag, TagProps } from '@/components/atoms/Tag/render';
import block from './block.json';
// styles
import { getStyles } from './styles.css';
import { ButtonProps } from '@superhuit/starterpack-blocks/helpers/Button/typings';
import { LocaleContext } from '@superhuit/starterpack-i18n';

/**
 * TYPINGS
 */
export type CardShowType = {
	id: number;
	artist: string;
	title: string;
	dates?: string;
	location?: string;
	link?: CardLinkProps;
	image?: ImageProps;
	linkTicket?: ButtonProps;
	state?: 'canceled' | 'postponed' | 'closed';
	categories?: Array<TagProps>;
	publics?: Array<{ id: number; title: string; icon: ImageProps }>;
};

export type CardShowProps = ThemeProps & CardShowType;

/**
 * COMPONENT
 */
export const CardShow = forwardRef<Ref<any>, CardShowProps>(
	(
		{
			artist,
			title,
			dates,
			location,
			image,
			categories = [],
			link,
			linkTicket,
			state,
			theme = {},
			publics = [],
		},
		ref
	) => {
		const { variant, blockTheme } = useThemeBlock(block.slug, theme);

		const rootClass = cx(
			'supt-card supt-cardShow',
			getStyles(blockTheme),
			link?.href && 'supt-withLink',
			state ? `supt-cardShow--${state}` : null
		);

		const { __t } = useContext(LocaleContext);

		return (
			<div
				className={rootClass}
				data-block={block.slug}
				data-variant={variant}
				// @ts-ignore
				ref={ref}
			>
				<div className="supt-card__wrapper">
					{image?.src && (
						<div className="supt-cardShow__imageWrapper">
							<Image
								sizes="(max-width: 599px) 90vw, (max-width: 1199px) 45vw, (max-width: 1679px) 30vw, 512px"
								{...image}
								className="supt-cardShow__image"
							/>
							<div className="supt-cardShow__categories">
								{categories.map(({ title, type }, i) => {
									return (
										<div
											key={i}
											className="supt-cardShow__category"
										>
											<Tag
												title={title}
												type={type}
											></Tag>
										</div>
									);
								})}
							</div>
						</div>
					)}

					{link?.href ? (
						<Link
							className="supt-cardShow__link supt-cardShow__artist"
							href={link?.href}
							{...(link?.opensInNewTab && {
								target: '_blank',
								rel: 'noopener noreferrer',
							})}
						>
							{artist ? (
								<h3
									className="supt-cardShow__artist"
									dangerouslySetInnerHTML={{ __html: artist }}
								></h3>
							) : null}
						</Link>
					) : artist ? (
						<h3
							className="supt-cardShow__artist"
							dangerouslySetInnerHTML={{ __html: artist }}
						></h3>
					) : null}
					<p
						className="supt-cardShow__title"
						dangerouslySetInnerHTML={{ __html: title }}
					></p>
					<div className="supt-cardShow__details">
						<div className="supt-cardShow__day">
							<p
								className="supt-cardShow__day__info"
								dangerouslySetInnerHTML={{ __html: dates }}
							></p>
							{state ? (
								<Tag
									title={__t(`state-${state}`, state)}
									isSquared
									type="alert"
								/>
							) : null}
						</div>
						<p
							className="supt-cardShow__location"
							dangerouslySetInnerHTML={{ __html: location }}
						></p>
					</div>
					{!!publics.length && (
						<ul className="supt-cardShow__public-list">
							{publics.slice(0, 3).map(({ id, icon, title }) => (
								<li key={id}>
									<Image {...icon} />
								</li>
							))}
							{publics.length > 3 && (
								<li className="supt-cardShow__public-extra">
									{publics.length - 3}+
								</li>
							)}
						</ul>
					)}
					{linkTicket?.href && (
						<div className="supt-cardShow__ticket">
							<ButtonPrimary
								{...linkTicket}
								className="supt-cardShow__ticket__button"
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
);

// @ts-ignore
CardShow.slug = block.slug;
// @ts-ignore
CardShow.title = block.title;

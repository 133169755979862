import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import { grid } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	fontStyle,
	fluidPadding,
	fluidValues,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getDynamicStyles = (styles) =>
	cssObj(fluidPadding(styles?.padding), {
		background: styles?.backgroundColor,

		'.supt-section__title': fontStyle(
			{
				...styles?.title?.typography,
				textAlign: styles?.title?.textAlignment,
				color: styles?.title?.color,
			},
			false
		),
		'.supt-section__info': fontStyle(
			{
				...styles?.info?.typography,
				textAlign: styles?.info?.textAlignment,
				color: styles?.info?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), fluidValues('maxHeight', 705, 474), {
		maxHeight: '464px',
		overflow: 'hidden',
		position: 'relative',

		'&::after': {
			content: '""',
			position: 'absolute',
			bottom: 0,
			left: 0,
			backgroundImage:
				'linear-gradient(180deg, rgba(198,230,215,0.00) 0%, #C6E6D7 100%)',
			width: '100%',
			height: '250px',
			zIndex: 1,
		},

		'.supt-section__inner': cssObj(grid, {
			borderWidth: '0 !important',

			paddingBottom: '32px',
			[`@media (min-width: ${breakpoints.medium})`]: {
				paddingBottom: '60px',
			},

			'.supt-section__trigger, .supt-section__headline': {
				gridColumn: '1 / span 12',
			},

			'.supt-section__trigger': {
				pointerEvents: 'none',
			},
			'.supt-section__content': {
				gridColumn: '1 / span 12',
				margin: 0,
				padding: 0,
				maxHeight: 'none',
				overflow: 'auto',
				visibility: 'visible',

				'.supt-section__content__inner': {
					display: 'block',
					paddingBottom: 0,

					[`@media (min-width: ${breakpoints.medium})`]: {
						paddingTop: '22px',
					},
				},
			},
		}),

		'.supt-section__info': {
			marginBottom: '15px',
		},

		/* OPENED STATE */
		'&.supt-section--is-open': {
			maxHeight: 'none',
			overflow: 'auto',

			'&::after': {
				content: `none`,
			},
		},
	});

// edit
const getEditGlobalStyles = () =>
	cssObj({
		'.wp-block[data-type="supt/section-subscription"]': cssObj({
			'.supt-section.supt-section--section-subscription': {
				maxHeight: 'none',
				overflow: 'auto',

				'&::after': {
					content: `none`,
				},
			},
		}),
	});

export { getStyles, getEditGlobalStyles };

import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getCustomStyles = (theme) =>
	cssObj({
		'.supt-section--section-cards-event': cssObj(
			fluidValues('paddingTop', 70, 105, true),
			{
				// Remove negative margin top caused by default variant section following each others
				marginTop: '0 !important',

				'.supt-section__headline': cssObj(
					fluidValues('top', 135, 240, true),
					{
						[`@media (min-width: ${breakpoints.laptop})`]: {
							position: 'sticky',
							alignSelf: 'baseline',
							zIndex: 0,
							paddingBottom: 200,
						},
					}
				),
				'.supt-section__title': {
					marginBottom: '0.25em !important',
				},

				'.supt-section__content': {
					position: 'relative',
					zIndex: 1,
					pointerEvents: 'none',
					[`@media (min-width: ${breakpoints.laptop})`]: {
						marginTop: -200,
					},

					'.supt-cardEvent': {
						pointerEvents: 'auto',
					},
				},

				// Remove negative margin top caused by default variant section following each others
				'& + .supt-section--default': {
					marginTop: '0 !important',
				},
			}
		),
	});

export { getCustomStyles };

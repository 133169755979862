import React, { forwardRef, Ref } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Section } from '@superhuit/starterpack-blocks/helpers/Section';
import { SectionOneColumnProps } from '@superhuit/starterpack-blocks/utils/typings';
import { LinkProps } from '@superhuit/starterpack-blocks/helpers/Link/render';
// styles
import { getStyles as getDfltStyles } from '@superhuit/starterpack-blocks/components/organisms/Sections/SectionOneColumn/styles.css';
import { ButtonSecondary } from '@/components/atoms/renders';
import block from './block.json';

/**
 * TYPINGS
 */
type SectionPartnersProps = SectionOneColumnProps & {
	link?: LinkProps;
};

/**
 * COMPONENT
 */
export const SectionPartners = forwardRef<Ref<any>, SectionPartnersProps>(
	(
		{
			anchor,
			className,
			uptitle,
			title,
			introduction,
			link,
			slug,
			variant: currentVariant = 'default',
			titleTag = 'h2',
			children,
			theme = {},
			getStyles = getDfltStyles,
		},
		ref
	) => {
		const { variant, blockTheme } = useThemeBlock(
			slug,
			theme,
			currentVariant
		);

		const trimmedSlug = slug.split('/');
		const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

		const rootClass = cx(
			'supt-section supt-section--oneCol',
			`supt-section--${modifierSlug}`,
			getStyles(blockTheme),
			className
		);

		return (
			<Section
				id={anchor}
				className={rootClass}
				variant={variant}
				slug={slug}
				ref={ref}
			>
				<div className="supt-section__inner">
					{(uptitle || title || introduction) && (
						<div className="supt-section__headline">
							{uptitle && <Section.Uptitle text={uptitle} />}
							{title && (
								<Section.Title
									text={title}
									titleTag={titleTag}
								/>
							)}
							{introduction && (
								<Section.Introduction text={introduction} />
							)}
						</div>
					)}
					{link?.href ? (
						<div className="supt-section__linkWrapper">
							<ButtonSecondary {...link} />
						</div>
					) : null}
					<div className="supt-section__content">{children}</div>
				</div>
			</Section>
		);
	}
);

// @ts-ignore
SectionPartners.slug = block.slug;
// @ts-ignore
SectionPartners.title = block.title;

import {
	BoxFluidControl,
	SizeControl,
	SizeFluidControl,
	ColorControl,
} from '@superhuit/starterpack-customizer';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const mainNavThemeAttributes = {
	height: [115, 134],
	padding: {
		top: [16, 32],
		bottom: [16, 32],
	},
	logo: {
		width: [100, 120],
	},
	cart: {
		typography: {
			fontFamily: '@tokens.fonts.secondary.fontFamily',
			fontSize: [11, 11],
			fontWeight: 600,
			letterSpacing: [0, 0],
			color: '@tokens.variants.default.contentColor',
		},
		backgroundColor: '#FFE772',
	},
	desktop: {
		primary: {
			color: '@tokens.variants.default.contentColor',
			typography: '@tokens.typographies.heading-4',
			textAlignment: 'left',
			grid: {
				gap: [20, 25],
			},
		},
		secondary: {
			color: '@tokens.variants.default.contentColor',
			typography: '@tokens.typographies.text-s-medium',
			textAlignment: 'left',
			grid: {
				gap: [15, 20],
			},
		},
		submenu: {
			color: '@tokens.variants.default.contentColor',
			typography: '@tokens.typographies.caption',
			textAlignment: 'left',
		},
	},
	mobile: {
		hamburger: {
			color: '@tokens.variants.default.contentColor',
			width: '26px',
			height: '17px',
		},
		primary: {
			color: '@tokens.variants.default.contentColor',
			typography: '@tokens.typographies.heading-2',
			textAlignment: 'left',
			submenu: {
				color: '@tokens.variants.default.contentColor',
				typography: '@tokens.typographies.small-text',
				textAlignment: 'left',
			},
		},
		secondary: {
			color: '@tokens.variants.default.contentColor',
			typography: '@tokens.typographies.text-s-medium',
			textAlignment: 'left',
		},
	},
	states: {
		active: {}, // adds active state
	},
};

export const MainNavThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: mainNavThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return (
			<>
				<h4>Main Nav specs</h4>
				{/* Paddings */}
				<BoxFluidControl
					label="Padding"
					default={fullAttributes?.padding.default}
					inheritsFrom={fullAttributes?.padding.default_origin}
					values={fullAttributes?.padding.value}
					onChange={(value) => updateAttribute('padding', value)}
				/>
				{/* Logo */}
				<SizeFluidControl
					label="Logo Width"
					default={fullAttributes?.logo?.width?.default}
					inheritsFrom={fullAttributes?.logo?.width?.default_origin}
					value={fullAttributes?.logo?.width?.value}
					onChange={(value) => updateAttribute('logo.width', value)}
				/>
				{/* Hamburger Specs */}
				<h4>Mobile Hamburger Specs</h4>
				{/* Hamburger color */}
				<ColorControl
					label="Color"
					value={fullAttributes?.mobile?.hamburger?.color.value}
					default={fullAttributes?.mobile?.hamburger?.color.default}
					inheritsFrom={
						fullAttributes?.mobile?.hamburger?.color.default_origin
					}
					onChange={(value) =>
						updateAttribute('mobile.hamburger.color', value)
					}
					theme={theme}
					type="color"
				/>
				{/* Hamburger width */}
				<SizeControl
					label="Width"
					value={fullAttributes?.mobile?.hamburger?.width.value}
					default={fullAttributes?.mobile?.hamburger?.width.default}
					inheritsFrom={
						fullAttributes?.mobile?.hamburger?.width.default_origin
					}
					onChange={(value) =>
						updateAttribute('mobile.hamburger.width', value)
					}
				/>
				{/* Hamburger height */}
				<SizeControl
					label="Height"
					value={fullAttributes?.mobile?.hamburger?.height.value}
					default={fullAttributes?.mobile?.hamburger?.height.default}
					inheritsFrom={
						fullAttributes?.mobile?.hamburger?.height.default_origin
					}
					onChange={(value) =>
						updateAttribute('mobile.hamburger.height', value)
					}
				/>
			</>
		);
	},
};

import React, { FC, useContext, useState } from 'react';
import cx from 'classnames';
// package imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { ButtonProps } from '@superhuit/starterpack-blocks/helpers/Button/typings';
import buttonPrimaryBlock from '@superhuit/starterpack-blocks/components/atoms/Buttons/ButtonPrimary/block.json';
import block from './block.json';
// styles
import { getStyles } from './styles.css';
import { getStyles as getButtonPrimaryStyles } from '@superhuit/starterpack-blocks/components/atoms/Buttons/ButtonPrimary/styles.css';

/**
 * TYPINGS
 */
type ResultsInfoProps = {
	onChange?: Function;
	number: number;
} & ThemeProps;

/**
 * COMPONENT
 */
export const ResultsInfo: FC<ResultsInfoProps> & BlockConfigs = ({
	onChange,
	number = 0,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);
	const { blockTheme: buttonPrimaryTheme } = useThemeBlock(
		buttonPrimaryBlock.slug,
		theme,
		variant
	);

	const rootClass = cx('supt-resultsInfo', getStyles(blockTheme));

	const { __t } = useContext(LocaleContext);

	const handleResetButtonClick = () => {
		onChange([]);
	};

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			<p className="supt-resultsInfo__text">
				<b>{number}</b>{' '}
				{number !== 1
					? __t('results-info-text-multiple', 'Résultats trouvés')
					: __t('results-info-text-singular', 'Résultat trouvé')}
			</p>
			{number == 0 ? (
				<h2 className="supt-resultsInfo__title">
					{__t(
						'results-info-zero',
						'Oops, aucun résultat ne correspond à ta recherche.'
					)}
				</h2>
			) : null}
			{onChange ? (
				<button
					className={cx(
						'supt-button',
						'supt-buttonPrimary',
						getButtonPrimaryStyles(buttonPrimaryTheme)
					)}
					data-type="reset"
					onClick={handleResetButtonClick}
				>
					<span className="supt-button__inner">
						<span>
							{__t(
								'results-info-button-reset',
								'Rénitialiser la recherche'
							)}
						</span>
					</span>
				</button>
			) : null}
		</div>
	);
};

ResultsInfo.slug = block.slug;
ResultsInfo.title = block.title;

import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const headerBThemeAttributes = {
	uptitle: {
		color: '@tokens.variants.default.titleColor',
		typography: '@tokens.typographies.heading-1',
		textAlignment: 'center',
	},
	title: {
		color: '@tokens.variants.default.titleColor',
		typography: '@tokens.typographies.fresco-l',
		textAlignment: 'center',
	},
	subtitle: {
		color: '@tokens.variants.default.titleColor',
		typography: '@tokens.typographies.introduction',
		textAlignment: 'center',
	},
	introduction: {
		color: '@tokens.variants.default.titleColor',
		typography: '@tokens.typographies.fresco-s',
		textAlignment: 'left',
	},

	metas: {
		text: {
			color: '@tokens.variants.default.contentColor',
			typography: '@tokens.typographies.paragraph',
			textAlignment: 'left',
		},
		practical: {
			title: {
				color: '@tokens.variants.default.contentColor',
				typography: '@tokens.typographies.text-s-bold',
				textAlignment: 'left',
			},
			text: {
				color: '@tokens.variants.default.contentColor',
				typography: '@tokens.typographies.caption',
				textAlignment: 'left',
			},
		},
	},
};

export const HeaderBThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: headerBThemeAttributes,

	edit: ({}) => {},
};

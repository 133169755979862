import { css } from '@emotion/css';

const toggleWidth = 36;
const toggleHeight = 18;
const toggleBorderWidth = 1;

const getStyles = () =>
	css({
		display: 'flex',
		alignItems: 'center',
		userSelect: 'none',

		'.supt-switch-toggle__label': {
			marginRight: 20,
			cursor: 'pointer',
		},

		'.supt-switch-toggle__field': {
			position: 'relative',
			display: 'inline-block',
		},

		'.supt-switch-toggle__track': {
			content: '""',
			display: 'inline-block',
			boxSizing: 'border-box',
			verticalAlign: 'top',
			width: `${toggleWidth}px`,
			height: `${toggleHeight}px`,
			borderRadius: `${toggleHeight / 2}px`,
			transition: '0.2s background ease',
		},

		'.supt-switch-toggle__thumb': {
			display: 'block',
			position: 'absolute',
			boxSizing: 'border-box',
			top: `${toggleBorderWidth * 3}px`,
			left: `${toggleBorderWidth * 3}px`,
			width: `${toggleHeight - toggleBorderWidth * 6}px`,
			height: `${toggleHeight - toggleBorderWidth * 6}px`,
			borderRadius: '50%',
			transition: '0.1s transform ease',
		},

		'.supt-switch-toggle__input': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			opacity: 0,
			margin: 0,
			padding: 0,
			zIndex: 1,
			cursor: 'pointer',

			// This overrides a border style that is inherited from parent checkbox styles.
			border: 'none',
			'&:checked': {
				background: 'none',
			},

			// Don't show custom checkbox checkmark.
			'&::before': {
				content: '""',
			},
		},
	});

export { getStyles };

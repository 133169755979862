import React, {
	useState,
	forwardRef,
	Ref,
	useRef,
	useEffect,
	useContext,
	useLayoutEffect,
	useCallback,
	useMemo,
} from 'react';
import cx from 'classnames';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
// package imports
import { ThemeContext } from '@superhuit/starterpack-context';
import { SectionOneColumnProps } from '@superhuit/starterpack-blocks/utils/typings';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Section } from '@superhuit/starterpack-blocks/helpers/Section';
import { getStyles as getDfltStyles } from '@superhuit/starterpack-blocks/components/organisms/Sections/SectionOneColumn/styles.css';
import { getStyles as getButtonSecondaryStyles } from '@superhuit/starterpack-blocks/components/atoms/Buttons/ButtonSecondary/styles.css';
import { getStyles as getButtonPrimaryStyles } from '@superhuit/starterpack-blocks/components/atoms/Buttons/ButtonPrimary/styles.css';
import buttonSecondaryBlock from '@superhuit/starterpack-blocks/components/atoms/Buttons/ButtonSecondary/block.json';
import buttonPrimaryBlock from '@superhuit/starterpack-blocks/components/atoms/Buttons/ButtonPrimary/block.json';
// internal imports
import block from './block.json';
import { getStyles as getCustomStyles } from './styles.css';

export type SectionRichContentProps = {
	isCropped: boolean;
	nbBlocksWhenCropped: number;
	openButtonTitle?: string;
	closeButtonTitle?: string;
} & SectionOneColumnProps;

/**
 * COMPONENT
 */
export const SectionRichContent = forwardRef<Ref<any>, SectionRichContentProps>(
	(
		{
			anchor,
			className,
			uptitle,
			title,
			introduction,
			slug,
			variant: currentVariant = 'default',
			titleTag = 'h2',
			children,
			theme = {},
			getStyles = getDfltStyles,
			isCropped: isCroppedAttr,
			nbBlocksWhenCropped = 1,
			openButtonTitle,
			closeButtonTitle,
		},
		ref
	) => {
		gsap.registerPlugin(ScrollTrigger);

		const { variant, blockTheme } = useThemeBlock(
			slug,
			theme,
			currentVariant
		);

		const { blockTheme: buttonSecondaryTheme } = useThemeBlock(
			buttonSecondaryBlock.slug,
			theme,
			currentVariant
		);
		const { blockTheme: buttonPrimaryTheme } = useThemeBlock(
			buttonPrimaryBlock.slug,
			theme,
			currentVariant
		);

		const { rawTheme } = useContext(ThemeContext);

		const trimmedSlug = slug.split('/');
		const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

		const [isCropped, setIsCropped] = useState(isCroppedAttr);
		const [croppedHeight, setCroppedHeight] = useState(0);
		const [isOpened, setIsOpened] = useState(isCroppedAttr ? false : true);
		const refSection = useRef(null);
		const refSectionInner = useRef(null);
		const refContent: React.MutableRefObject<HTMLDivElement> = useRef(null);
		const refProgressBar = useRef(null);
		const refToggleBtn = useRef(null);
		const refNav = useRef(null);
		const tlShowProgress = useRef(gsap.timeline());
		const stickyNav = useRef(null);
		const rootClass = cx(
			'supt-section supt-section--oneCol',
			`supt-section--${modifierSlug}`,
			getStyles(blockTheme),
			// @ts-ignore
			getCustomStyles(blockTheme, rawTheme?.tokens, croppedHeight),
			className,
			isCropped && 'supt-section--isCropped',
			isOpened ? 'supt-section--isOpened' : 'supt-section--isClosed'
		);

		const openSection = useCallback(() => {
			setIsOpened(true);

			if (refSection.current) {
				const distanceToTop =
					refSection.current.getBoundingClientRect().top;

				requestAnimationFrame(() => {
					// Keep scroll position, relative to top of section
					// This is to avoid the scroll to jump down on some browsers (which "attach" the scroll to the bottom of the viewport)
					window.scroll({
						top: refSection.current?.offsetTop - distanceToTop,
						left: 0,
						behavior: 'auto',
					});
				});
			}
		}, []);

		const closeSection = useCallback(() => {
			setIsOpened(false);
			requestAnimationFrame(() => {
				// Scroll to have next section in the middle of the screen
				if (
					refSection.current &&
					refSection.current.nextElementSibling
				) {
					window.scroll({
						top:
							refSection.current.nextElementSibling.offsetTop -
							window.innerHeight / 2,
						left: 0,
						behavior: 'auto',
					});
				}
			});
		}, []);

		const handleToogleBtnClick = useCallback(() => {
			isOpened ? closeSection() : openSection();
		}, [isOpened, openSection, closeSection]);

		const openIfTooShort = useCallback(() => {
			if (!isCropped || !refSectionInner.current || !refSection.current)
				return;

			const innerHeight = refSectionInner.current.offsetHeight;
			const sectionHeight = refSection.current.offsetHeight;

			if (innerHeight - sectionHeight <= 60) {
				// setIsCropped(false);
				setIsOpened(true);
			}
		}, [isCropped]);

		const refreshScrollTrigger = useCallback(() => {
			ScrollTrigger.refresh();
		}, []);

		const killAnimations = useCallback(() => {
			if (!isCropped) return;

			tlShowProgress.current?.kill();
			stickyNav.current?.kill();
		}, [isCropped]);

		const updateCroppedHeight = useCallback(() => {
			if (!isCropped || !refContent.current) return;

			const children = Array.from(
				refContent.current.children as HTMLCollectionOf<HTMLDivElement>
			);
			const lastChild =
				children[Math.min(nbBlocksWhenCropped, children.length) - 1];

			if (lastChild) {
				const totalHeight =
					lastChild.offsetTop +
					Math.min(lastChild.offsetHeight * 0.45, 700);
				setCroppedHeight(Math.max(totalHeight, 800));
			}
		}, [isCropped, nbBlocksWhenCropped]);

		useLayoutEffect(() => {
			const handleResize = () => {
				refreshScrollTrigger();
				openIfTooShort();
				updateCroppedHeight();
			};

			requestAnimationFrame(handleResize);

			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}, [refreshScrollTrigger, openIfTooShort, updateCroppedHeight]);

		useEffect(() => {
			if (!isCropped) return;

			if (isOpened) {
				tlShowProgress.current
					.clear()
					.set(
						[refProgressBar.current, refToggleBtn.current],
						{ opacity: 0 },
						0
					)
					.to(
						[refProgressBar.current, refToggleBtn.current],
						{ opacity: 1, duration: 0.3, ease: 'power1.inOut' },
						0
					)
					.to(refProgressBar.current, {
						scaleX: 1,
						ease: 'none',
						scrollTrigger: {
							trigger: refSection.current,
							scrub: 0.3,
							// improve progress bar growth
							start: 'top bottom',
							end: 'bottom bottom',
						},
					});

				stickyNav.current = ScrollTrigger.create({
					trigger: refSection.current,
					pin: refNav.current,
					start: 'top top',
					end: 'bottom bottom',
				});
			} else {
				killAnimations();
			}

			return () => {
				killAnimations();
			};
		}, [isCropped, isOpened, killAnimations]);

		const toggleButtonVariantStyles = useMemo(
			() =>
				isOpened
					? getButtonPrimaryStyles(buttonPrimaryTheme)
					: getButtonSecondaryStyles(buttonSecondaryTheme),
			[isOpened, buttonPrimaryTheme, buttonSecondaryTheme]
		);

		return (
			<Section
				id={anchor}
				className={rootClass}
				variant={variant}
				slug={slug}
				ref={refSection}
			>
				<div className="supt-section__inner" ref={refSectionInner}>
					{(uptitle || title || introduction) && (
						<div className="supt-section__headline">
							{uptitle && <Section.Uptitle text={uptitle} />}
							{title && (
								<Section.Title
									text={title}
									titleTag={titleTag}
								/>
							)}
							{introduction && (
								<Section.Introduction text={introduction} />
							)}
						</div>
					)}
					<div className="supt-section__content" ref={refContent}>
						{children}
					</div>
					{isCropped && (
						<div className="supt-section__navigation" ref={refNav}>
							<div
								className={cx('supt-progress', {
									'is-hidden': !isOpened,
								})}
							>
								<div
									className="supt-progress__bar"
									ref={refProgressBar}
								/>
							</div>
							<button
								className={cx(
									'supt-button',
									toggleButtonVariantStyles,
									{
										'supt-buttonPrimary': isOpened,
										'supt-button--close': isOpened,
										'supt-buttonSecondary': !isOpened,
										'supt-button--open': !isOpened,
									}
								)}
								data-type={isOpened ? 'close' : 'open'}
								ref={refToggleBtn}
								onClick={handleToogleBtnClick}
							>
								<span className="supt-button__inner">
									<span>
										{isOpened
											? closeButtonTitle
											: openButtonTitle}
									</span>
								</span>
							</button>
						</div>
					)}
				</div>
			</Section>
		);
	}
);

// @ts-ignore
SectionRichContent.slug = block.slug;
// @ts-ignore
SectionRichContent.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import {
	fluidPadding,
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-card__wrapper': fluidPadding(styles?.padding),

		'.supt-cardEvent__title': fontStyle(
			{
				...styles?.title?.typography,
				textAlign: styles?.title?.textAlignment,
				color: styles?.title?.color,
			},
			false
		),
		'.supt-cardEvent__artist': fontStyle(
			{
				...styles?.artist?.typography,
				textAlign: styles?.artist?.textAlignment,
				color: styles?.artist?.color,
			},
			false
		),
		'.supt-cardEvent__infos': fontStyle({
			...styles?.infos?.typography,
			textAlign: styles?.infos?.textAlignment,
			color: styles?.infos?.color,
		}),
		'.supt-cardEvent__link': fontStyle(
			{
				...styles?.link?.typography,
				textAlign: styles?.link?.textAlignment,
				color: styles?.link?.color,
			},
			false
		),
		'.supt-cardEvent__text': fontStyle(
			{
				...styles?.text?.typography,
				textAlign: styles?.text?.textAlignment,
				color: styles?.text?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		backgroundColor: 'rgba(255, 255, 255, 0.95)',

		'.supt-card__wrapper': {
			position: 'relative',
		},

		[`@media (max-width: calc(${breakpoints.tablet} - 1px))`]: {
			marginTop: '0 !important',
		},

		'.supt-cardEvent__genres': {
			display: 'flex',
			flexWrap: 'wrap',
			gap: 5,
			marginBottom: 20,

			[`@media (min-width: ${breakpoints.tablet})`]: {
				marginBottom: 25,
			},
		},

		'.supt-category': {
			'&[data-type="default"]::before': {
				backgroundColor: 'transparent',
			},
			'&[data-type="exclusive"]': {
				color: '#fff',
				'&::before': {
					backgroundColor: '#000',
					opacity: 1,
				},
			},
		},

		'.supt-cardEvent__artist': {
			marginTop: '5px !important',
		},
		'.supt-cardEvent__infos': {
			marginTop: 13,
		},
		'.supt-cardEvent__date': {
			marginBottom: 2,
			display: 'inline-block',

			'.times': {
				whiteSpace: 'nowrap',
			},
		},
		'.supt-tag[data-type="alert"]': {
			display: 'inline-block',
			marginLeft: 6,
			top: -2,
		},

		'.supt-cardEvent__link, .supt-cardEvent__text': {
			marginTop: 14,

			[`@media (min-width: ${breakpoints.tablet})`]: {
				marginTop: 18,
			},
		},

		'.supt-cardEvent__text': {
			paddingTop: 15,
			borderTop: '1px solid #000',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				paddingTop: 20,
			},
		},

		'.supt-cardEvent__image': cssObj(fluidValues('marginBottom', 20, 24), {
			'> span': {
				display: 'block !important',
			},
		}),

		'.supt-cardEvent__public-list': cssObj(
			fluidValues('marginTop', 8, 12),
			fluidValues('gap', 10, 12),
			{
				listStyle: 'none',
				padding: 0,
				display: 'flex',
				position: 'relative',

				li: {
					display: 'flex',
					alignItems: 'center',

					'figure, span': {
						width: '100%',
						maxHeight: '22px',
					},

					'&.supt-cardEvent__public-extra': cssObj(
						fluidValues('fontSize', 15, 16),
						{
							letterSpacing: '-0.03125em',
							lineHeight: '1.375em',
						}
					),
				},
			}
		),

		// States
		'&.supt-cardEvent--canceled, &.supt-cardEvent--postponed': {
			'.supt-cardEvent__date': {
				textDecoration: 'line-through',
			},
		},
		'&.supt-cardEvent--outline': {
			backgroundColor: 'transparent',
			border: '1px solid black',
		},
	});

export { getStyles };

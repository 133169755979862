import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { resetFirstLastMargins } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	transitions,
	breakpoints,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	expandLink,
	fluidValues,
	fontStyle,
	fluidPadding,
	fluidBorderWidth,
	fluidBorderRadius,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getStyles = (styles) =>
	css({
		position: 'relative',

		'.supt-cardProduction': {
			transition: `opacity ${transitions.mid} ease-out`,

			'.supt-cardProduction__arrow': {
				backgroundColor: 'white',
				fill: 'black',
			},

			'&.supt-cardProduction--active': {
				opacity: 1,

				'.supt-cardProduction__arrow': {
					[`@media (min-width: ${breakpoints.laptop})`]: {
						opacity: 1,
					},
				},
			},
			'&.supt-cardProduction--notActive': {
				opacity: 0.2,
			},
		},
	});

// edit
const getEditGlobalStyles = (styles) => cssObj({});

export { getStyles, getEditGlobalStyles };

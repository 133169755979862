import React, { FC, forwardRef } from 'react';
import cx from 'classnames';

import { Link } from '@/components/atoms/Link/render';

import { PrimarySubmenuProps } from '../typings';

export const PrimarySubmenu: FC<PrimarySubmenuProps> = forwardRef(
	({ items, uri, style = {} }, ref: any) => {
		return (
			<div className="supt-mainNav__submenu" ref={ref} style={style}>
				<div className="supt-mainNav__submenu__inner">
					<nav className="supt-mainNav__submenu__nav">
						{items.map(
							({ id, label, path, cssClasses, target }) => {
								return (
									<Link
										key={id}
										href={path ?? '#'}
										suppressHydrationWarning
										className={cx(
											'supt-mainNav__submenu__link',
											...cssClasses,
											{
												isCurrent:
													uri === path ||
													uri.includes(path),
											}
										)}
										aria-current={
											uri === path || uri.includes(path)
												? 'page'
												: null
										}
										style={{ opacity: '0' }}
										target={target}
									>
										{label}
									</Link>
								);
							}
						)}
					</nav>
				</div>
			</div>
		);
	}
);

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { resetFirstLastMargins } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	linkNoUnderlineWithHover,
	linkUnderlineWithHoverRedraw,
} from '@/css/placeholders.css';
import {
	breakpoints,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	fontStyle,
	fluidPadding,
	lineClamp,
	fluidBorderWidth,
	fluidBorderRadius,
	fluidValues,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getDynamicStyles = (styles) =>
	cssObj(
		fluidBorderWidth(styles?.border?.width),
		fluidBorderRadius(styles?.border?.radius),
		{
			background: styles?.backgroundColor,
			borderColor: styles?.border?.color,

			'.supt-cardNews__inner': fluidPadding(styles?.padding),

			'.supt-cardNews__title': fontStyle(
				{
					...styles?.title?.typography,
					textAlign: styles?.title?.textAlignment,
					color: styles?.title?.color,
				},
				false
			),

			'.supt-cardNews__journal': fontStyle(
				{
					...styles?.category?.typography,
					textAlign: styles?.category?.textAlignment,
					color: styles?.category?.color,
				},
				false
			),

			'.supt-cardNews__date': fontStyle(
				{
					...styles?.date?.typography,
					textAlign: styles?.date?.textAlignment,
					color: styles?.date?.color,
				},
				false
			),

			'.supt-cardNews__excerpt': fontStyle({
				...styles?.excerpt?.typography,
				textAlign: styles?.excerpt?.textAlignment,
				color: styles?.excerpt?.color,
			}),

			'.supt-cardNews__read': fontStyle({
				...styles?.read?.typography,
				textAlign: styles?.read?.textAlignment,
				color: styles?.read?.color,
			}),
		}
	);

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		textDecoration: 'none',
		borderStyle: 'solid',
		overflow: 'hidden',
		width: '100%',

		[`@media (max-width: calc(${breakpoints.tablet} - 1px))`]: {
			marginTop: '0 !important',
		},

		'.supt-cardNews__inner': {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},

		'.supt-cardNews__journal, .supt-cardNews__date, .supt-cardNews__title, .supt-cardNews__excerpt, .supt-cardNews__read':
			{
				transition: `all ${transitions.mid} ease-out`,
			},

		'.supt-cardNews__content': {
			display: 'block',
			marginBottom: 5,
		},

		'.supt-cardNews__metas': {
			display: 'flex',
			alignItems: 'center',
			flexWrap: 'wrap',
			margin: '5px 0 20px',
			'&__space': {
				margin: '0 7px',
			},
		},
		'.supt-cardNews__label': resetFirstLastMargins,

		'.supt-cardNews__title': cssObj(
			resetFirstLastMargins,
			lineClamp(5),
			linkNoUnderlineWithHover,
			{
				backgroundPosition: '100% 90%',
			}
		),

		'.supt-cardNews__excerpt': cssObj(resetFirstLastMargins, lineClamp(4)),

		'.supt-cardNews__buttonsWrapper': {
			marginTop: 'auto',
		},
		'.supt-cardNews__buttons': cssObj(fluidValues('marginTop', 20, 25), {
			marginTop: 'auto',
			display: 'flex',
			alignItems: 'center',

			'.supt-button': {
				margin: 0,

				'&::after': {
					content: '""',
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 1,
					display: 'block',
					width: '100%',
					height: '100%',
				},
			},

			'.supt-cardNews__eventLink': cssObj(linkUnderlineWithHoverRedraw, {
				marginLeft: '30px',
				zIndex: 1,
				opacity: 0.6,
				textDecoration: 'none',
			}),
		}),

		'&:hover, &:focus-within': cssObj(
			getDynamicStyles(styles?.states?.hover),
			{
				'.supt-cardNews__title': {
					backgroundPosition: '0% 90%',
					backgroundSize: '100% 1px',
				},
			}
		),
	});

export { getStyles };

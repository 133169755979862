import React, { FC, useState, useEffect } from 'react';
import cx from 'classnames';
// import Cookies, { CookieAttributes } from 'js-cookie';
import {
	ThemeProps,
	BlockConfigs,
} from '@superhuit/starterpack-blocks/utils/typings';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Link } from '@/components/atoms/Link/render';
import block from './block.json';
// styles
import { getStyles } from './styles.css';

// const COOKIES_OPTIONS: CookieAttributes = {
// 	expires: 365,
// 	sameSite: 'Lax',
// };
// const COOKIE_NAME = 'supt-cookie-alert-vidy';

/**
 * TYPINGS
 */
export type CtaProps = {
	url?: string;
	label?: string;
};

export type AlertProps = ThemeProps & {
	type: 'info' | 'warning';
	text: string;
	cta?: CtaProps;
	enabled: Boolean;
	alertDismissed: Boolean;
	setAlertDismissed: Function;
};

/**
 * COMPONENT
 */
export const Alert: FC<AlertProps> & BlockConfigs = ({
	type = 'information',
	text,
	cta = {},
	theme = {},
	alertDismissed,
	setAlertDismissed,
}) => {
	// const [alertDismissed, setAlertDismissed] = useState(false);

	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx(
		'supt-alert',
		alertDismissed && 'supt-alert--hidden',
		getStyles(blockTheme)
	);

	const htmlProps = {
		'data-variant': variant,
	};

	// useEffect(() => {
	// 	const hasCookies = Cookies.get(COOKIE_NAME) === 'dismiss';

	// 	setAlertDismissed(hasCookies);
	// }, []);

	// useEffect(() => {
	// 	if (alertDismissed) {
	// 		Cookies.set(COOKIE_NAME, 'dismiss', COOKIES_OPTIONS);
	// 	} else {
	// 		Cookies.remove(COOKIE_NAME);
	// 	}
	// }, [alertDismissed]);

	const closeAlert = () => {
		setAlertDismissed(true);
	};

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			aria-hidden={!alertDismissed}
			{...htmlProps}
		>
			<div className="supt-alert__inner">
				<div className="supt-alert__type">
					{type === 'info' ? (
						<svg
							viewBox="0 0 16 16"
							width="16"
							height="16"
							xmlns="http://www.w3.org/2000/svg"
						>
							<use href="#info" />
						</svg>
					) : (
						<svg
							viewBox="0 0 18 17"
							width="18"
							height="17"
							xmlns="http://www.w3.org/2000/svg"
						>
							<use href="#warning" />
						</svg>
					)}
				</div>
				<div className="supt-alert__content">
					<p
						className="supt-alert__text"
						dangerouslySetInnerHTML={{ __html: text }}
					></p>
					<p className="supt-alert__linkWrapper">
						{cta.url ? (
							<Link
								href={cta.url}
								className="supt-alert__text supt-alert__link"
								dangerouslySetInnerHTML={{ __html: cta.label }}
							></Link>
						) : null}
					</p>
				</div>
				<button className="supt-alert__close" onClick={closeAlert}>
					<svg
						viewBox="0 0 13 13"
						width="13"
						height="13"
						xmlns="http://www.w3.org/2000/svg"
					>
						<use href="#close" />
					</svg>
				</button>
			</div>
		</div>
	);
};

Alert.slug = block.slug;
Alert.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	breakpoints,
	fontWeights,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import { grid } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	fontStyle,
	fluidPadding,
	fluidValues,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { linkWithArrowLeft } from '@/css/mixins.css';
import {
	linkNoUnderlineWithHover,
	linkUnderlineWithHoverRedraw,
} from '@/css/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj({
		background: styles?.backgroundColor,

		'.supt-footer__inner': {
			color: styles?.text?.color,
		},

		'.supt-footer__top': fluidPadding(styles?.top?.padding),

		'.supt-footer__menu, .supt-footer__social': cssObj(
			fontStyle(
				{
					...styles?.menu?.typography,
					textAlign: styles?.menu?.textAlignment,
					color: styles?.menu?.color,
				},
				false
			),
			{
				/* stylelint-disable-next-line */
				'li + li': fluidValues(
					'marginTop',
					styles?.menu?.typography?.margin?.top?.[0],
					styles?.menu?.typography?.margin?.top?.[1]
				),
			}
		),

		'.supt-footer__legal__nav': fontStyle(
			{
				...styles?.legal?.typography,
				textAlign: styles?.legal?.textAlignment,
				color: styles?.legal?.color,
			},
			false
		),
		'.supt-footer__address': fontStyle(
			{
				...styles?.text?.typography,
				textAlign: styles?.text?.textAlignment,
				color: styles?.text?.color,
			},
			false
		),

		'.supt-footer__bottom': cssObj(
			fluidPadding(styles?.bottom?.padding),
			fluidValues(
				'borderTopWidth',
				styles?.divider?.width?.[0],
				styles?.divider?.width?.[1]
			),
			{
				borderTopColor: styles?.divider?.color,

				'.supt-footer__copyright, .supt-footer__credits': fontStyle(
					{
						...styles?.text?.typography,
						textAlign: styles?.text?.textAlignment,
						color: styles?.text?.color,
					},
					false
				),
			}
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		position: 'relative',

		'.supt-footer__inner': cssObj(grid, {
			position: 'relative',
			zIndex: 1,
		}),

		'.supt-footer__top, .supt-footer__bottom': {
			a: {
				textDecoration: 'none',
			},
		},

		'.supt-footer__top': {
			marginLeft: '0 !important',
			marginRight: '0 !important',
			maxWidth: 'none',
		},

		'.supt-footer__menu, .supt-footer__social, .supt-footer__legal, .supt-footer__address':
			{
				'&__title': {
					opacity: 0.6,
					marginBottom: 5,
					[`@media (min-width: ${breakpoints.tablet})`]: {
						marginBottom: 9,
					},
				},
				'&__nav': {
					listStyleType: 'none',
				},
				'&__text': {
					lineHeight: '22px',
				},
			},

		'.supt-footer__menu, .supt-footer__social': {
			'&__nav': {
				lineHeight: '24px',
			},
		},

		'.supt-footer__logo': {
			gridColumn: '1 / span 12',
			justifySelf: 'start',
			alignSelf: 'start',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '1 / span 3',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '1 / span 5',
			},

			'.supt-figure': fluidValues('width', 100, 200),
		},

		'.supt-footer__navs': {
			gridColumn: '1 / span 12',
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 1fr)',
			columnGap: 'inherit',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '5 / span 8',
				display: 'flex',
				justifyContent: 'space-between',
			},
			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '5 / span 7',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '6 / span 6',
			},
		},

		'.supt-footer__menu, .supt-footer__legal': {
			li: {
				display: 'block',
			},
		},

		'.supt-footer__menu': {
			order: 1,
			[`@media (min-width: ${breakpoints.tablet})`]: {
				order: 'unset',
			},
		},

		'.supt-footer__address': {
			margin: '40px 0',
			gridColumn: '1 / span 2',
			columnGap: 'inherit',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				margin: 0,
			},

			strong: {
				fontWeight: fontWeights.medium,
			},

			a: cssObj(linkNoUnderlineWithHover, {
				backgroundPosition: '100% 100%',

				'&:hover': {
					backgroundPosition: '0 100%',
					backgroundSize: '100% 1px',
				},
			}),

			'&__text': {
				display: 'grid',
				gridTemplateColumns: 'repeat(2, 1fr)',
				columnGap: 'inherit',

				[`@media (min-width: ${breakpoints.tablet})`]: {
					display: 'block',
				},

				'p:not(:last-child)': {
					[`@media (min-width: ${breakpoints.tablet})`]: {
						marginBottom: 15,
					},
				},
			},
		},

		'.supt-footer__legal, .supt-footer__credits': {
			opacity: 0.6,

			a: linkUnderlineWithHoverRedraw,
		},

		'.supt-footer__legal': {
			gridColumn: '1 / span 12',

			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '1 / span 10',
			},

			'> *': {
				display: 'inline',
			},

			'&__nav': {
				li: {
					display: 'inline-block',
					marginRight: 15,
					marginTop: 6,
					[`@media (min-width: ${breakpoints.medium})`]: {
						marginTop: 0,
					},
				},
			},
		},

		'.supt-footer__credits': {
			gridColumn: '1 / span 12',
			marginTop: 6,

			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '11 / span 2',
				justifySelf: 'right',
				marginTop: 0,
			},

			strong: {
				fontWeight: fontWeights.medium,
			},

			a: {
				marginLeft: '5px',

				'&:hover': {},
			},
		},

		'.supt-footer__copyright': {
			strong: {
				fontWeight: fontWeights.medium,
			},

			span: {
				whiteSpace: 'nowrap',
			},
		},

		'.supt-footer__link': linkWithArrowLeft('.supt-footer', '#fff'),
	});

export { getStyles };

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	fluidValues,
	flexGapPolyfill,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	gridNoMargin,
	resetFirstLastChildMargins,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';

const getDynamicStyles = (styles) => cssObj();

const getStyles = (styles) =>
	css(getDynamicStyles(styles), resetFirstLastChildMargins, {
		'.supt-subsection__content': cssObj(gridNoMargin, {
			gridColumn: '1/13',

			'.supt-media': {
				gridColumn: '1/13',

				'.supt-video .supt-video__caption, .supt-figure .supt-figure__figcaption':
					cssObj(fluidValues('marginTop', 30, 20), {
						[`@media (max-width: calc(${breakpoints.bigLaptop} - 1px))`]:
							{
								marginTop: '0.5em !important',
							},

						[`@media (min-width: ${breakpoints.bigLaptop})`]: {
							position: 'absolute',
							right: 0,
							top: '100%',
							textAlign: 'right',
							maxWidth: '185px',
						},

						[`@media (min-width: ${breakpoints.desktop})`]: {
							maxWidth: '238px',
						},
					}),
			},

			'.supt-paragraph': cssObj(fluidValues('marginTop', 30, 20), {
				gridColumn: '1/13',
				marginBottom: 0,

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '1/8',
				},
				[`@media (min-width: ${breakpoints.bigLaptop})`]: {
					gridColumn: '1/7',
				},
			}),
		}),

		'&.supt-subsection--left': {
			'.supt-subsection__content': {
				'.supt-media': {
					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '1/10',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridColumn: '1/9',
					},
				},

				'.supt-paragraph': {
					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '1/8',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridColumn: '1/7',
					},
				},
			},
		},

		'&.supt-subsection--right': {
			'.supt-subsection__content': {
				'.supt-media': {
					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '4/13',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridColumn: '5/13',
					},
				},

				'.supt-paragraph': {
					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '4/11',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridColumn: '5/11',
					},
				},
			},
		},
	});

const getEditStyles = () =>
	css({
		'.supt-subsection__content': {
			'.wp-block[data-type="supt/media"]': {
				gridColumn: '1/13',
			},

			'.wp-block[data-type="supt/paragraph"]': {
				gridColumn: '1/13',

				[`@media (min-width: ${breakpoints.medium})`]: {
					gridColumn: '1/8',
				},
				[`@media (min-width: ${breakpoints.bigLaptop})`]: {
					gridColumn: '1/7',
				},
			},
		},

		'&.supt-subsection--left': {
			'.supt-subsection__content': {
				'.wp-block[data-type="supt/media"]': {
					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '1/10',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridColumn: '1/9',
					},
				},

				'.wp-block[data-type="supt/paragraph"]': {
					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '1/8',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridColumn: '1/7',
					},
				},
			},
		},

		'&.supt-subsection--right': {
			'.supt-subsection__content': {
				'.wp-block[data-type="supt/media"]': {
					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '4/13',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridColumn: '5/13',
					},
				},

				'.wp-block[data-type="supt/paragraph"]': {
					[`@media (min-width: ${breakpoints.medium})`]: {
						gridColumn: '4/11',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridColumn: '5/11',
					},
				},
			},
		},
	});

export { getStyles, getEditStyles };

import React, { FC } from 'react';
// package imports
import { ListCardsProps } from '@superhuit/starterpack-blocks/utils/typings';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { ListCards } from '@superhuit/starterpack-blocks/components/renders'; // Needs to be called from /components folder to work properly..
// internal imports
import block from './block.json';

/**
 * COMPONENT
 */
export const ListShop: FC<ListCardsProps> & BlockConfigs = (props) => {
	return <ListCards {...props} />;
};

ListShop.slug = block.slug;
ListShop.title = block.title;

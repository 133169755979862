import React, { FC, useRef, useEffect, useCallback, useContext } from 'react';
import cx from 'classnames';
import ImagesLoaded from 'react-images-loaded';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
// package imports
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { CardShow, CardEvent } from '@/components/renders';
import {
	ThemeProps,
	ChildrenProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { getStyles as getDefaultStyles } from '@superhuit/starterpack-blocks/components/molecules/lists/ListCards/styles.css';
import { getStyles } from './styles.css';

// internal imports
import block from './block.json';

export type ListShowsEventsProps = {
	title?: string;
	slug?: string;
	getStyles?: (styles: any) => string;
	events: Array<any>;
} & ThemeProps &
	ChildrenProps;

/**
 * COMPONENT
 */
export const ListShowsEvents: FC<ListShowsEventsProps> & BlockConfigs = ({
	slug = block.slug,
	events = [],
	theme = {},
}) => {
	const refList = useRef(null);
	const refCards = useRef([]);

	const { __t, locale } = useContext(LocaleContext);

	useEffect(() => {
		window.addEventListener('resize', resizeAllGridItems);

		refList.current = document.querySelector('.supt-listCards__list');

		// clean up function
		return () => {
			window.removeEventListener('resize', resizeAllGridItems);
		};
	}, []);

	useEffect(() => {
		resizeAllGridItems();
	}, [events]);

	const resizeGridItem = (item) => {
		if (!item) return;

		const rowHeight = 20; // griAutoRows from css styles
		const rowGap = 40; // gridRowGap from css styles
		const rowSpan = Math.ceil(
			(item.querySelector('.supt-card__wrapper').getBoundingClientRect()
				.height +
				rowGap) /
				(rowHeight + rowGap)
		);
		item.style.gridRowEnd = 'span ' + rowSpan;
	};

	const resizeAllGridItems = () => {
		for (let x = 0; x < refCards.current.length; x++) {
			resizeGridItem(refCards.current[x]);
		}
	};

	const { variant, blockTheme } = useThemeBlock(slug, theme);

	const trimmedSlug = slug.split('/');
	const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

	const rootClass = cx(
		'supt-listCards',
		`supt-listCards--${modifierSlug}`,
		getDefaultStyles(blockTheme),
		getStyles()
	);

	return events.length > 0 ? (
		<div className={rootClass} data-block={slug} data-variant={variant}>
			{/* <div > */}
			<ImagesLoaded
				elementType={'div'}
				done={resizeAllGridItems}
				className="supt-listCards__list"
			>
				{events.map((event, index) => {
					if (event.isEvent) {
						return (
							<CardEvent
								key={index}
								{...event}
								index={index}
								categories={event.genres}
								ref={(ref) => (refCards.current[index] = ref)}
								style="outline"
							/>
						);
					} else {
						return (
							<CardShow
								key={index}
								{...event}
								index={index}
								categories={event.genres}
								linkTicket={{
									href: event.linkTicket?.href,
									title:
										event.linkTicket?.title ??
										__t('card-tickets', 'Billets'),
								}}
								ref={(ref) => (refCards.current[index] = ref)}
							/>
						);
					}
				})}
			</ImagesLoaded>
			{/* </div> */}
		</div>
	) : null;
};

ListShowsEvents.slug = block.slug;
ListShowsEvents.title = block.title;

import React, { FC, useContext, useMemo, useState } from 'react';
import cx from 'classnames';

// package imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { ButtonProps } from '@superhuit/starterpack-blocks/helpers/Button/typings';
import {
	Link,
	LinkProps,
} from '@superhuit/starterpack-blocks/helpers/Link/render';

// internal imports
import { Tag } from '@/components/renders';
import { ButtonPrimary, ButtonSecondary } from '@/components/atoms/renders';

// Local imports
import { getStyles } from './styles.css';
import block from './block.json';

/**
 * TYPINGS
 */
type FilterTagType = {
	id: number;
	name: string;
	isSquared?: boolean;
	desc?: string;
	link?: string;
};
type FilterType = {
	filter: string;
	items: Array<FilterTagType>;
	link?: LinkProps;
};
type FiltersProps = {
	title?: string;
	buttons?: Array<ButtonProps>;
	filters: Array<FilterType>;
	selectedFilters: Array<FilterTagType>;
	onChange: Function;
	initialActiveFilters?: number;
	withBorders: boolean;
} & ThemeProps;

/**
 * COMPONENT
 */
export const Filters: FC<FiltersProps> & BlockConfigs = ({
	title,
	buttons,
	filters,
	selectedFilters,
	onChange,
	initialActiveFilters = -1,
	withBorders = false,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx('supt-filters', getStyles(blockTheme));

	const { __t } = useContext(LocaleContext);

	const [activeFilterIndex, setActiveFilterIndex] =
		useState(initialActiveFilters);

	const handleFilterClick = (index) =>
		setActiveFilterIndex(index === activeFilterIndex ? -1 : index);

	const handleResetButtonClick = () => {
		onChange([]);
	};

	// Add tag to the selected ones
	const handleTagClick = (filter, tag) => {
		onChange([...selectedFilters, { ...tag, filter: filter }]);
	};

	// Remove tag from the selected ones
	const handleSelectedTagClick = (tag) => {
		const tagIndex = selectedFilters.findIndex((t) => t.id === tag.id);

		const newSelectedFilters = [...selectedFilters];
		newSelectedFilters.splice(tagIndex, 1);

		onChange(newSelectedFilters);
	};

	const [selectedTags, selectedTagsWithDesc] = useMemo(
		() =>
			selectedFilters.reduce(
				([a, b], tag) => [
					[...a, !tag?.desc ? tag : null].filter(Boolean),
					[...b, !!tag?.desc ? tag : null].filter(Boolean),
				],
				[[], []] as [FilterTagType[], FilterTagType[]]
			),
		[selectedFilters]
	);

	if (!filters) return null;

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			<div className="supt-filters__header">
				{title ? (
					<h1 className="supt-filters__title">{title}</h1>
				) : null}

				{buttons ? (
					<div className="supt-filters__buttons">
						{buttons.map(({ type, ...button }, i) =>
							type === 'secondary' ? (
								<ButtonSecondary {...button} key={i} />
							) : (
								<ButtonPrimary {...button} key={i} />
							)
						)}
					</div>
				) : null}
			</div>

			<div className="supt-filters__filters">
				<div className="supt-filters__main-bar">
					<p className="supt-filters__label">
						{__t('filters-label', 'Filtrer par')}:
					</p>

					{filters.map((filter, index) => (
						<button
							className={cx('supt-filters__filter', {
								isActive: activeFilterIndex === index,
							})}
							onClick={() => handleFilterClick(index)}
							key={index}
						>
							{__t(`filters-${filter.filter}`, '')}
							<svg
								width="10"
								height="6"
								xmlns="http://www.w3.org/2000/svg"
							>
								<use href="#chevron" />
							</svg>
						</button>
					))}
				</div>
				{activeFilterIndex > -1 ? (
					<div className="supt-filters__list-bar">
						<ul className="supt-filters__tags">
							{filters[activeFilterIndex].items.map((item) => (
								<li className="supt-filters__tag" key={item.id}>
									<Tag
										title={item.name}
										isClickable
										hasBorder={withBorders}
										onClick={() =>
											handleTagClick(
												filters[activeFilterIndex]
													.filter,
												item
											)
										}
										isDisabled={
											!!selectedFilters.find(
												(tag) => tag.id === item.id
											)
										}
										isSquared={item.isSquared}
									/>
								</li>
							))}
						</ul>
						{filters[activeFilterIndex]?.link ? (
							<Link
								{...filters[activeFilterIndex].link}
								className="supt-filters__tags-link supt-link"
							>
								<span>
									{filters[activeFilterIndex].link.title}
								</span>
							</Link>
						) : null}
					</div>
				) : null}

				{selectedFilters.length ? (
					<div className="supt-filters__selected-bar">
						<p className="supt-filters__label">
							{__t(
								'filters-selected-label',
								'Filtres sélectionnés'
							)}
							:
						</p>
						<button
							className="supt-filters__reset-btn supt-link"
							onClick={handleResetButtonClick}
						>
							{__t('filters-reset', 'Réinitialiser')}
						</button>

						<ul className="supt-filters__tags">
							{selectedTags.map((tag) => (
								<li className="supt-filters__tag" key={tag.id}>
									<Tag
										title={tag.name}
										desc={tag?.desc}
										hasBorder
										isClickable
										isDeletable
										onClick={() =>
											handleSelectedTagClick(tag)
										}
										isSquared={tag.isSquared}
									/>
								</li>
							))}
						</ul>
						<ul className="supt-filters__tags withDesc">
							{selectedTagsWithDesc.map((tag) => (
								<li className="supt-filters__tag" key={tag.id}>
									<Tag
										title={tag.name}
										desc={tag?.desc}
										link={tag?.link}
										hasBorder
										isClickable
										isDeletable
										onClick={() =>
											handleSelectedTagClick(tag)
										}
										isSquared={tag.isSquared}
									/>
								</li>
							))}
						</ul>
					</div>
				) : null}
			</div>
		</div>
	);
};

Filters.slug = block.slug;
Filters.title = block.title;

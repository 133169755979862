import { css } from '@emotion/css';
import {
	fontStyle,
	lineClamp,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	breakpoints,
	fontWeights,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getDynamicStyles = (styles) =>
	cssObj(
		fontStyle(
			{
				...styles?.typography,
				textAlign: styles?.textAlignment,
				color: styles?.color,
			},
			false
		),
		{
			'&::before': {
				backgroundColor: styles?.backgroundColor,
			},

			// Modifiers
			'&[data-type="exclusive"]': {
				'&::before': {
					backgroundColor: styles?.exclusive,
				},
			},
			'&[data-type="alert"]': {
				'&::before': {
					backgroundColor: styles?.alert,
				},
			},
		}
	);

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		display: 'inline-block',
		margin: 0,
		padding: '4px 10px 3px',
		position: 'relative',
		zIndex: '0',

		'&::before': {
			content: "''",
			opacity: 0.6,
			position: 'absolute',
			zIndex: '-1',
			width: '100%',
			height: '100%',
			top: '0',
			left: '0',
			borderRadius: '1em',
		},

		// Modifiers
		'&[data-type="vidy"]': {
			'.supt-tag__icon--before': {
				display: 'inline-block',
				width: '10px',
				height: '10px',
				backgroundRepeat: 'no-repeat',
				backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 0 5.007 10.066L10 0z' fill='%23000' fill-rule='evenodd'/%3E%3C/svg%3E")`,
				marginRight: '6px',
			},
		},

		'&[data-type="exclusive"]': {
			'&::before': {
				opacity: 0.95,
			},
		},
		'&[data-type="alert"]': {
			'&::before': {
				opacity: 1,
			},
		},

		'&.hasBorder': {
			'&[data-type="default"], &[data-type="vidy"]': {
				'&::before': {
					border: '1px solid rgba(0, 0, 0, 0.2)',
				},
			},
		},

		'&.isTransparent': {
			'&::before': {
				backgroundColor: 'transparent',
			},
		},

		'&.isSquared': {
			'&::before': {
				borderRadius: '4px',
			},
		},

		'&.isDisabled': {
			opacity: 0.6,
			pointerEvents: 'none',
		},

		'&.isDeletable': {
			'.supt-tag__icon--after': {
				svg: {
					display: 'inline-block',
					marginLeft: 5,
					opacity: 0.5,
					transition: 'opacity 0.2s ease-in-out',
				},
			},

			'&:hover': {
				'.supt-tag__icon--after': {
					svg: {
						opacity: 1,
					},
				},
			},
		},

		'&.hasDesc': {
			display: 'flex',
			flexWrap: 'wrap',
			padding: '18px 18px 17px',

			[`@media (min-width: ${breakpoints.laptop})`]: {
				padding: '20px 20px 19px',
			},

			'.supt-tag__title': {
				fontWeight: fontWeights.semibold,
				flexGrow: 1,
			},
			'.supt-tag__desc': cssObj(lineClamp(4), {
				width: '100%',
				marginTop: 10,
			}),

			'.supt-tag__link': {
				paddingTop: 10,
			},
		},

		// Reset button style (if it's clickable)
		'button&': {
			backgroundColor: 'transparent',
			border: 0,
			borderRadius: 0,
			outline: 0,
			appearance: 'none',
			cursor: 'pointer',

			'&::-moz-focus-inner': {
				border: 0,
			},
		},
	});

export { getStyles };

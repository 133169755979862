import React, {
	forwardRef,
	Ref,
	useRef,
	useState,
	useImperativeHandle,
	useEffect,
} from 'react';
import cx from 'classnames';
// internal imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	ChildrenProps,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import block from '@superhuit/starterpack-blocks/components/molecules/Accordion/block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
type AccordionProps = ThemeProps &
	ChildrenProps & {
		index: number;
		title: string;
		category_title?: string;
		onButtonClick?: Function;
		onButtonFocus?: Function;
	};

/**
 * COMPONENT
 */
export const Accordion = forwardRef(
	(
		{
			index,
			title,
			category_title,
			onButtonClick,
			onButtonFocus,
			theme = {},
			children,
		}: AccordionProps,
		ref: Ref<{}>
	) => {
		const buttonRef = useRef(null);

		const [isExpanded, setIsExpanded] = useState(false);
		const [isSelected, setIsSelected] = useState(false);

		const { variant, blockTheme } = useThemeBlock(block.slug, theme);

		const rootClass = cx('supt-accordion', getStyles(blockTheme), {
			'supt-accordion--is-open': isExpanded,
			'supt-accordion--has-focus': isSelected,
		});

		const htmlProps = {
			'data-variant': variant,
		};

		// Allows to call these functions from the parent
		useImperativeHandle(ref, () => ({
			openAccordion,
			closeAccordion,
			selectAccordion,
			unselectAccordion,
			isExpanded,
		}));

		/**
		 * EVENTS
		 */
		const handleButtonClick = () => {
			setIsExpanded(!isExpanded); // Toggle current accordion

			onButtonClick ? onButtonClick(index) : null; // Close all accordions

			// Focus the clicked button
			buttonRef.current.focus();
		};

		const handleButtonFocus = () => {
			selectAccordion();
		};

		const handleButtonBlur = () => {
			unselectAccordion();
		};

		/**
		 * ACTIONS
		 */
		const openAccordion = () => {
			setIsExpanded(true);
		};

		const closeAccordion = (currentId) => {
			if (index !== currentId && isExpanded) {
				setIsExpanded(false);
			}
		};
		const selectAccordion = () => {
			setIsSelected(true);
			buttonRef.current.focus();

			onButtonFocus ? onButtonFocus(index) : null;
		};
		const unselectAccordion = () => {
			setIsSelected(false);
		};

		useEffect(() => {
			if (!isExpanded) return;

			const nav = document.querySelector(
				'.supt-mainNav'
			) as HTMLDivElement;

			const buttonRect = buttonRef.current.getBoundingClientRect();
			const scrollTop =
				window.scrollY || document.documentElement.scrollTop;

			window.scrollTo({
				top: buttonRect.top + scrollTop - nav.offsetHeight,
				behavior: 'smooth',
			});
		}, [isExpanded]);

		return (
			<div className={rootClass} data-block={block.slug} {...htmlProps}>
				<button
					className="supt-accordion__title"
					ref={buttonRef}
					aria-expanded={isExpanded}
					aria-selected={isSelected}
					onClick={handleButtonClick}
					onFocus={handleButtonFocus}
					onBlur={handleButtonBlur}
				>
					{category_title ? (
						<span className="supt-accordion__title-label">
							{category_title}
						</span>
					) : null}
					{title}
					<svg
						width="13"
						height="13"
						xmlns="http://www.w3.org/2000/svg"
						className="supt-accordion__icon"
						aria-hidden="true"
						focusable="false"
					>
						<use href="#arrow-right" />
					</svg>
				</button>
				<div
					className="supt-accordion__panel"
					aria-hidden={!isExpanded}
				>
					<div className="supt-accordion__content">{children}</div>
				</div>
			</div>
		);
	}
);

// @ts-ignore
Accordion.slug = block.slug;
// @ts-ignore
Accordion.title = block.title;

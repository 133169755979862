import { mergeDeep } from '@superhuit/starterpack-utils';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import { ListCardsThemeControls } from '@superhuit/starterpack-blocks/components/themings'; // Needs to be called from /components folder to work properly..

import block from './block.json';

export const ListHighlightedProductionsThemeAttributes = {};

export const ListHighlightedProductionsThemeControls: ThemeControlsType =
	mergeDeep(ListCardsThemeControls, {
		// What's displayed
		title: block.title,

		// Unique string/id of the block (= the key used in theme.json)
		slug: block.slug,

		// styling attributes available for this block
		// ...the same way we define attributes for a gutenberg block
		attributes: ListHighlightedProductionsThemeAttributes,
	});

import React, { FC, useState, useRef } from 'react';
import cx from 'classnames';
// package imports
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
// internal imports
import block from './block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
type HeaderPracticalInfoProps = {
	title: string;
	text: string;
	opened: boolean;
};

/**
 * COMPONENT
 */
export const HeaderPracticalInfo: FC<HeaderPracticalInfoProps> &
	BlockConfigs = ({ title, text, opened }) => {
	const [isExpanded, setIsExpanded] = useState(opened);
	const buttonRef = useRef(null);

	const rootClass = cx('supt-headerPracticalInfo', getStyles(), {
		'supt-headerPracticalInfo--is-open': isExpanded,
	});

	/**
	 * EVENTS
	 */
	const handleButtonClick = () => {
		setIsExpanded(!isExpanded); // Toggle current accordion
	};

	return title ? (
		<div
			className={rootClass}
			data-block={block.slug}
			aria-expanded={isExpanded ? 'true' : 'false'}
		>
			<button
				className="supt-headerPracticalInfo__title"
				ref={buttonRef}
				aria-expanded={isExpanded}
				onClick={handleButtonClick}
			>
				<svg
					width="10"
					height="6"
					xmlns="http://www.w3.org/2000/svg"
					className="supt-headerPracticalInfo__icon"
				>
					<use href="#chevron" />
				</svg>
				{title}
			</button>
			<div className="supt-headerPracticalInfo__panel">
				<div className="supt-headerPracticalInfo__panelInner">
					<p
						className="supt-headerPracticalInfo__content"
						dangerouslySetInnerHTML={{ __html: text }}
					/>
				</div>
			</div>
		</div>
	) : null;
};

HeaderPracticalInfo.slug = block.slug;
HeaderPracticalInfo.title = block.title;

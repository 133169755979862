import React, { FC } from 'react';
// package imports
import { SectionOneColumnProps } from '@superhuit/starterpack-blocks/utils/typings';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { SectionOneColumn } from '@superhuit/starterpack-blocks/components/renders'; // Needs to be called from /components folder to work properly..
// internal imports
import block from './block.json';

/**
 * COMPONENT
 */
export const SectionCardsShop: FC<SectionOneColumnProps> & BlockConfigs = (
	props
) => {
	return <SectionOneColumn {...props} />;
};

SectionCardsShop.slug = block.slug;
SectionCardsShop.title = block.title;

import React, { forwardRef, Ref, useContext, useState } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { Section } from '@superhuit/starterpack-blocks/helpers/Section';
import { SectionTwoColumnsProps } from '@superhuit/starterpack-blocks/utils/typings';
// styles
import { getStyles as getDfltStyles } from '@superhuit/starterpack-blocks/components/organisms/Sections/SectionTwoColumns/styles.css';
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
type SectionAccordionProps = SectionTwoColumnsProps & {
	isOpened?: boolean;
	bottomTrigger?: boolean;
};

/**
 * COMPONENT
 */
export const SectionAccordion = forwardRef<Ref<any>, SectionAccordionProps>(
	(
		{
			anchor,
			className,
			uptitle,
			title,
			introduction,
			isOpened: isOpenedDflt,
			slug,
			variant: currentVariant = 'default',
			children,
			titleTag = 'h2',
			theme = {},
			bottomTrigger = false,
		},
		ref
	) => {
		const { variant, blockTheme } = useThemeBlock(
			slug,
			theme,
			currentVariant
		);

		const { __t } = useContext(LocaleContext);

		const [isOpened, setIsOpened] = useState(isOpenedDflt);

		const trimmedSlug = slug.split('/');
		const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

		const rootClass = cx(
			'supt-section supt-section--accordion',
			`supt-section--${modifierSlug}`,
			{
				'supt-section--is-open': isOpened,
			},
			getDfltStyles(blockTheme),
			getStyles(blockTheme),
			className
		);

		const handleButtonClick = () => {
			setIsOpened(!isOpened); // Toggle current accordion
		};

		return (
			<Section
				id={anchor}
				className={rootClass}
				variant={variant}
				slug={slug}
				ref={ref}
			>
				<div className="supt-section__inner">
					<button
						className="supt-section__trigger"
						aria-expanded={isOpened}
						onClick={handleButtonClick}
					>
						{(uptitle || title) && (
							<div className="supt-section__headline">
								{uptitle && <Section.Uptitle text={uptitle} />}
								{title && (
									<Section.Title
										text={title}
										titleTag={titleTag}
									/>
								)}
								{introduction && (
									<Section.Introduction text={introduction} />
								)}
							</div>
						)}
						<svg
							width="28"
							height="28"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
							focusable="false"
						>
							<use href="#plus" />
						</svg>
					</button>
					<div
						className="supt-section__content"
						aria-hidden={!isOpened}
					>
						<div className="supt-section__content__inner">
							{children}
						</div>
					</div>
				</div>
				{bottomTrigger && (
					<button
						className="supt-section__bottom-trigger"
						onClick={handleButtonClick}
					>
						<span className="visually-hidden">
							{__t(
								'SectionAccordion-bottom-trigger-text',
								'Expand %s'
							).replace('%s', title || uptitle)}
						</span>
					</button>
				)}
			</Section>
		);
	}
);

import { keyframes } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { transitions } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import { customTransitions } from './variables.css';

export const underlineScaleAnim = keyframes`
  0% {
		transform-origin: right center;
    transform: scaleX(1);
  }

  49% {
		transform-origin: right center;
    transform: scaleX(0);
  }

  51% {
		transform-origin: left center;
    transform: scaleX(0);
  }

  100% {
    transform-origin: left center;
    transform: scaleX(1);
  }
`;

/**
 * Link already has an underline => undraw to the right on hover
 */
export const linkUnderlineWithHover = cssObj({
	textDecoration: 'none',
	background: 'linear-gradient(currentColor, currentColor) no-repeat',
	backgroundSize: '100% 1px',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: '0 100%',
	transition: `background-size ${transitions.slow} cubic-bezier(.77,0,.175,1)`,

	'&:hover, &:focus-within': {
		backgroundPosition: '100% 100%',
		backgroundSize: '0% 1px',
	},
});

/**
 * Link already has an underline => undraw to the right on hover - redraw from the left on out
 */
export const linkUnderlineWithHoverRedraw = cssObj({
	position: 'relative',
	paddingBottom: '0px',
	whiteSpace: 'nowrap',

	'&::before': {
		content: '""',
		position: 'absolute',
		display: 'block',
		left: '0',
		bottom: '0.15em',
		width: '100%',
		height: '1px',
		backgroundColor: 'currentColor',
		transformOrigin: 'right center',
		transform: 'scaleX(1)',
		willChange: 'transform',
	},

	'&:hover, html:not(.using-mouse) &:focus-within': {
		'&::before': {
			animation: `${underlineScaleAnim} ${transitions.slow} ease-in-out`,
		},
	},
});

/**
 * Link has no underline => on hover draw underline
 */
export const linkNoUnderlineWithHover = cssObj({
	display: 'inline',
	textDecoration: 'none',
	background: 'linear-gradient(currentColor, currentColor) no-repeat',
	backgroundSize: '0% 1px',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: '0 100%',
	transition: `background-size ${transitions.slow} cubic-bezier(.77,0,.175,1)`,

	'&:hover, &:focus-within': {
		backgroundSize: '100% 1px',
	},
});

export const getLinkStyle = () => {
	return cssObj(linkUnderlineWithHoverRedraw, {
		// Modifiers
		'&[target="_blank"], &[download]': {
			'&::after': {
				content: "'\\00a0'",
				position: 'relative',
				top: 5,
				left: 3,
				borderRadius: '50%',
				textAlign: 'center',
				backgroundColor: 'transparent',
				fontSize: 0,
				padding: '21px 21px 0 0', // Use paddings to set the size and not width/height as it's inline (and we must use inline for the icon to not have a breakable space)

				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',

				transition: `background-color ${transitions.fast} ease, filter ${transitions.fast} ease`,
			},
		},
		'&[download]': {
			'&::after': {
				backgroundImage: `url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23000' stroke-width='1.4' fill='none' fill-rule='evenodd'%3E%3Cpath d='m12.251 4.657-5.656 5.657L.938 4.657M11.938 12h-11'/%3E%3Cpath stroke-linecap='square' d='M6.594 9.763v-9'/%3E%3C/g%3E%3C/svg%3E")`,
			},
		},
		'&[target="_blank"]': {
			'&::after': {
				backgroundImage: `url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-width='1.4'%3E%3Cpath d='M2.79 2.4h8v8'/%3E%3Cpath stroke-linecap='square' d='M9.825 3.365 2.4 10.789'/%3E%3C/g%3E%3C/svg%3E")`,
			},
		},
	});
};

export const getLinkStyleWithDefaultIcon = () => {
	return cssObj(linkUnderlineWithHoverRedraw, getLinkStyle(), {
		'&::after': {
			content: "'\\00a0'",
			position: 'relative',
			top: 5,
			left: 3,
			borderRadius: '50%',
			textAlign: 'center',
			backgroundColor: 'transparent',
			fontSize: 0,
			padding: '21px 21px 0 0', // Use paddings to set the size and not width/height as it's inline (and we must use inline for the icon to not have a breakable space)

			backgroundImage: `url("data:image/svg+xml,%3Csvg width='13' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23000' stroke-width='1.26' fill='none' fill-rule='evenodd'%3E%3Cpath d='M6.73.778 11.95 6 6.73 11.222'/%3E%3Cpath stroke-linecap='square' d='M10.692 6H1'/%3E%3C/g%3E%3C/svg%3E")`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',

			transition: `background-color ${transitions.fast} ease, filter ${transitions.fast} ease`,
		},
	});
};

/**
 * Initial Image state with hover-out transition
 */
export const imageReduceTransition = {
	transform: 'scale(1)',
	transition: `transform ${customTransitions.slowest} cubic-bezier(0.43, 0.01, 0.21, 1)`,
};
export const imageExpandTransition = {
	transform: 'scale(1.08)',
	transition: `transform ${customTransitions.slow} cubic-bezier(0.53, -0.01, 0.4, 1)`,
};

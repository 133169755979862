import cx from 'classnames';
import React, { FC, useContext, useState } from 'react';

import {
	BlockConfigs,
	SectionOneColumnProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { SectionAccordion } from '../SectionAccordion/render';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { getStyles as getDfltStyles } from '@superhuit/starterpack-blocks/components/organisms/Sections/SectionOneColumn/styles.css';

import block from './block.json';
import { getStyles } from './styles.css';
import { CardDateTour } from '@/components/renders';

/**
 * TYPINGS
 */
type SectionDatesTourProps = {
	rowsPast: Array<any>;
	rowsFuture: Array<any>;
} & SectionOneColumnProps;

/**
 * COMPONENT
 */
export const SectionDatesTour: FC<SectionDatesTourProps> & BlockConfigs = ({
	rowsPast = [],
	rowsFuture = [],
	...props
}) => {
	const { variant, blockTheme } = useThemeBlock(
		block.slug,
		props.theme,
		props.variant
	);
	const { __t, locale } = useContext(LocaleContext);

	const rootClass = cx(
		'supt-section--colored',
		getDfltStyles(blockTheme),
		getStyles(blockTheme)
	);

	return rowsFuture.length || rowsPast.length ? (
		<SectionAccordion {...props} className={rootClass} bottomTrigger={true}>
			{rowsFuture.length ? (
				<div className="supt-section__list supt-section__list--future">
					<h3 className="supt-section__list__title">
						{__t('production-tour-future', 'Dates à venir')}
					</h3>
					{rowsFuture.map((tour, i) => (
						<CardDateTour key={i} {...tour} />
					))}
				</div>
			) : null}
			{rowsPast.length ? (
				<div className="supt-section__list supt-section__list--past">
					<h3 className="supt-section__list__title">
						{__t('production-tour-past', 'Dates passées')}
					</h3>
					{rowsPast.map((tour, i) => {
						return <CardDateTour key={i} {...tour} />;
					})}
				</div>
			) : null}
		</SectionAccordion>
	) : null;
};

SectionDatesTour.slug = block.slug;
SectionDatesTour.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	resetButton,
	resetFirstLastChildMargins,
	textDefaultStyles,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { transitions } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	fluidValues,
	fontStyle,
	fluidPadding,
	fluidBorderRadius,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';

import { getLinkStyle } from '@/css/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj(fluidBorderRadius(styles?.border?.radius), {
		background: styles?.backgroundColor,
		boxShadow: `inset 0 0 0 ${styles?.border?.width} ${styles?.border?.color}`,

		'.supt-accordion__title': cssObj(
			fontStyle({
				...styles?.title?.typography,
				color: styles?.title?.color,
			}),
			fluidPadding(styles?.padding)
		),
		'.supt-accordion__title-label': fontStyle({
			...styles?.categoryTitle?.typography,
			color: styles?.categoryTitle?.color,
		}),
		'.supt-accordion__icon': cssObj(
			fluidValues(
				'right',
				styles?.padding?.right ? styles?.padding?.right?.[0] / 2 : null,
				styles?.padding?.right ? styles?.padding?.right?.[1] / 2 : null
			),
			fluidValues(
				'width',
				styles?.icon?.width?.[0],
				styles?.icon?.width?.[1]
			),
			fluidValues(
				'height',
				styles?.icon?.height?.[0],
				styles?.icon?.height?.[1]
			),
			{
				stroke: styles?.icon?.color,
			}
		),
		'.supt-accordion__panel::before': cssObj(
			fluidValues(
				'marginLeft',
				styles?.padding?.left?.[0],
				styles?.padding?.left?.[1]
			),
			fluidValues(
				'marginRight',
				styles?.padding?.left?.[0], // Padding-left as well
				styles?.padding?.left?.[1]
			),
			{
				backgroundColor: styles?.content?.border?.color,
			}
		),
		'.supt-accordion__content': {
			p: fontStyle({
				...styles?.content?.typography,
				color: styles?.content?.color,
			}),
			li: fontStyle(
				{
					...styles?.content?.typography,
					color: styles?.content?.color,
				},
				false
			),
		},
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		width: '100%',
		borderBottom: '1px solid #000',
		transition: `box-shadow ${transitions.mid} ease-out`,

		'.supt-accordion__title': cssObj(
			{ ...resetButton, padding: undefined }, // we don't want to override the dynamic padding!
			{
				margin: 0,
				display: 'block',
				position: 'relative',
				zIndex: 2,
				cursor: 'pointer',
				width: '100%',
				textAlign: 'left',
			}
		),

		'.supt-accordion__title-label': {
			display: 'block',
		},

		'.supt-accordion__icon': {
			display: 'block',
			position: 'absolute',
			top: '50%',
			transform: 'translate(50%, -50%) rotate(90deg)',
			transformOrigin: 'center center',
			fill: 'none',
			strokeWidth: '2px',
			transition: `transform ${transitions.fast} ease-in-out`,
		},

		'.supt-accordion__panel': {
			position: 'relative',
			zIndex: 1,
			maxHeight: '0vh',
			overflow: 'hidden',
			visibility: 'hidden',

			'&::before': {
				content: '""',
				display: 'block',
				height: '1px',
			},
			'> :last-child': {
				marginBottom: 0,
			},
		},

		'.supt-accordion__content': cssObj(
			fluidValues('paddingTop', 15, 25),
			fluidValues('paddingBottom', 50, 60),
			resetFirstLastChildMargins,
			{
				p: cssObj(textDefaultStyles, {
					'& > a': {
						background: 'none',

						'&::before': {
							bottom: '0em',
						},
					},
				}),

				li: {
					marginTop: '0 !important',
					marginBottom: '0.25rem !important',
				},

				'.supt-listCards--list-buttons, .supt-figure': cssObj(
					fluidValues('marginTop', 30, 40),
					fluidValues('marginBottom', 40, 60)
				),

				a: getLinkStyle(),
			}
		),

		/* hover / focus */
		'&:hover': getDynamicStyles(styles?.states?.hover),
		'&:focus-within, &.supt-accordion--has-focus': getDynamicStyles(
			styles?.states?.focus
		),

		/* open */
		'&.supt-accordion--is-open': cssObj(
			getDynamicStyles(styles?.states?.active),
			{
				'.supt-accordion__icon': {
					transform: 'translate(50%, -50%) rotate(-90deg)',
				},
				'.supt-accordion__panel': {
					maxHeight: 'none',
					overflow: 'auto',
					visibility: 'visible',
				},
			}
		),
	});

// edit
const getEditGlobalStyles = () =>
	cssObj({
		'.wp-block[data-type="supt/accordion"]': {
			marginTop: '0px',
			'.supt-accordion__panel': {
				maxHeight: 'none',
				overflow: 'auto',
				visibility: 'visible',
			},
			'button.supt-figure__image': {
				height: 'auto',
			},
		},
	});

export { getStyles, getEditGlobalStyles };

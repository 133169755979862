import {
	SizeFluidControl,
	BoxFluidControl,
	BoxRadiusFluidControl,
	SizeControl,
	CustomSelectControl,
	ColorControl,
	RadioGroupControl,
	TEXT_ALIGNS,
} from '@superhuit/starterpack-customizer';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const cardArtistThemeAttributes = {
	name: {
		color: '@tokens.variants.default.cardContentColor',
		typography: '@tokens.typographies.heading-4',
		textAlignment: 'left',
	},
	role: {
		color: '@tokens.variants.default.cardContentColor',
		typography: '@tokens.typographies.fresco-s',
		textAlignment: 'left',
	},
	link: {
		color: '@tokens.variants.default.cardContentColor',
		typography: '@tokens.typographies.button',
		textAlignment: 'left',
	},
	bio: {
		color: '@tokens.variants.default.cardContentColor',
		typography: '@tokens.typographies.small-text',
		textAlignment: 'left',
	},
	states: {
		hover: {}, // adds hover state
	},
};

export const CardArtistThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: cardArtistThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return (
			<>
				<h4>Box specs</h4>
				{/* Width */}
				<SizeControl
					label="Min width"
					value={fullAttributes?.minWidth.value}
					default={fullAttributes?.minWidth.default}
					inheritsFrom={fullAttributes?.minWidth.default_origin}
					onChange={(value) => updateAttribute('minWidth', value)}
				/>
				<SizeControl
					label="Max width"
					value={fullAttributes?.maxWidth.value}
					default={fullAttributes?.maxWidth.default}
					inheritsFrom={fullAttributes?.maxWidth.default_origin}
					onChange={(value) => updateAttribute('maxWidth', value)}
				/>
				{/* Paddings */}
				<BoxFluidControl
					label="Padding"
					values={fullAttributes?.padding.value}
					default={fullAttributes?.padding.default}
					inheritsFrom={fullAttributes?.padding.default_origin}
					onChange={(value) => updateAttribute('padding', value)}
				/>
				{/* Background color */}
				<ColorControl
					label="Background color"
					value={fullAttributes?.backgroundColor.value}
					default={fullAttributes?.backgroundColor.default}
					inheritsFrom={
						fullAttributes?.backgroundColor.default_origin
					}
					onChange={(value) =>
						updateAttribute('backgroundColor', value)
					}
					theme={theme}
				/>
				{/* Border width */}
				<BoxFluidControl
					label="Border width"
					values={fullAttributes?.border?.width.value}
					default={fullAttributes?.border.width.default}
					inheritsFrom={fullAttributes?.border.width.default_origin}
					onChange={(value) => updateAttribute('border.width', value)}
				/>
				{/* Border radius */}
				<BoxRadiusFluidControl
					label="Border radius"
					values={fullAttributes?.border?.radius.value}
					default={fullAttributes?.border?.radius.default}
					inheritsFrom={fullAttributes?.border?.radius.default_origin}
					onChange={(value) =>
						updateAttribute('border.radius', value)
					}
				/>
				{/* Border color */}
				<ColorControl
					label="Border color"
					value={fullAttributes?.border?.color.value}
					default={fullAttributes?.border?.color.default}
					inheritsFrom={fullAttributes?.border?.color.default_origin}
					onChange={(value) => updateAttribute('border.color', value)}
					theme={theme}
					type="color"
				/>
				<h4>Image</h4>
				{/* Image width */}
				<SizeFluidControl
					label="Width"
					default={fullAttributes?.image.width.default}
					inheritsFrom={fullAttributes?.image.width.default_origin}
					value={fullAttributes?.image?.width.value}
					onChange={(value) => updateAttribute('image.width', value)}
				/>
				{/* Text */}
				<h4>Text typography</h4>
				<CustomSelectControl
					label="Style"
					options={typographiesOptions}
					default={fullAttributes?.text.typography.default}
					inheritsFrom={
						fullAttributes?.text.typography.default_origin
					}
					value={fullAttributes?.text.typography.value}
					onChange={(value) =>
						updateAttribute('text.typography', value)
					}
				/>
				<RadioGroupControl
					label="Text alignment"
					value={fullAttributes?.text?.textAlignment.value}
					default={fullAttributes?.text?.textAlignment.default}
					options={TEXT_ALIGNS}
					onChange={(value) => {
						updateAttribute('text.textAlignment', value);
					}}
				/>
				<ColorControl
					label="Color"
					default={fullAttributes?.text?.color.default}
					inheritsFrom={fullAttributes?.text?.color.default_origin}
					value={fullAttributes?.text?.color.value}
					onChange={(value) => updateAttribute('text.color', value)}
					theme={theme}
					type="color"
				/>
			</>
		);
	},
};

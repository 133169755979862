import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import { fontStyle } from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-listFiles__file': fontStyle({
			...styles?.file?.typography,
			textAlign: styles?.file?.textAlignment,
			color: styles?.file?.color,
		}),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		'.supt-listFiles__file': {
			marginBottom: 17,
		},
		'.supt-listFiles__file__name': {
			fontWeight: 700,
			marginRight: 7,
		},
	});

// edit
const getEditStyles = () =>
	css({
		'.components-placeholder__instructions': {
			display: 'block !important',
		},

		'.supt-listFiles__file': {
			paddingTop: 13,
			position: 'relative',

			'&__link': {
				textDecoration: 'underline',
			},

			'&.isSelected .block-library-gallery-item__move-menu, &.isSelected .block-library-gallery-item__inline-menu':
				{
					background: 'white',
					border: '1px solid rgba(0,0,0,0.4)',
					borderRadius: 4,
					transition: 'box-shadow 0.2s ease-out',

					'&:hover': {
						boxShadow:
							'0 2px 10px rgba(0,0,0,0.1), 0 0 2px rgba(0,0,0,0.1)',
					},

					'.components-button': {
						color: 'rgba(0,0,0,0.62)',
						padding: 2,
						height: 24,

						// Remove hover box shadows, since they clash with the container.
						"&:not(:disabled):not([aria-disabled='true']):not(.is-default):hover":
							{
								boxShadow: 'none',
							},
					},

					'.components-button:focus': {
						color: 'inherit',
					},
				},
		},

		/**
		 * Below is the default styles of the core/gallery
		 */
		'.block-library-gallery-item__move-menu, .block-library-gallery-item__inline-menu':
			{
				position: 'absolute',
				top: -15,
				margin: 0,
				display: 'inline-flex',
				backgroundColor: 'transparent',
				zIndex: 10,
				border: 'none',

				'.components-button ': {
					color: 'transparent',
				},
			},

		'.block-library-gallery-item__inline-menu': {
			right: -2,
		},
		'.block-library-gallery-item__move-menu': {
			left: -2,
		},

		'.block-library-gallery-item__move-backward, .block-library-gallery-item__move-forward, .block-library-gallery-item__remove':
			{
				padding: 0,
			},
	});

export { getStyles, getEditStyles };

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	gridNoMargin,
	resetButton,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import {
	breakpoints,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getStyles = (styles) =>
	css({
		'.supt-section__inner': {
			borderTop: '1px solid #000',
			borderBottom: '1px solid #000',
		},

		'.supt-section__trigger, .supt-section__content': {
			gridColumn: '1 / span 12',
		},

		'.supt-section__trigger, .supt-section__content__inner': gridNoMargin,

		'.supt-section__trigger': cssObj(resetButton, {
			alignItems: 'center',
			padding: '32px 0',
			[`@media (min-width: ${breakpoints.medium})`]: {
				padding: '38px 0',
			},

			'.supt-section__headline': {
				gridColumn: '1 / span 10 !important',
			},
			svg: cssObj(
				fluidValues('width', 22, 28),
				fluidValues('height', 22, 28),
				{
					gridColumn: '11 / span 2',
					justifySelf: 'end',
					transition: `opacity ${transitions.fast} ease`,
				}
			),
		}),

		'.supt-section__title': {
			marginBottom: '0 !important',
			position: 'relative',
			top: 2,
		},

		'.supt-section__content': {
			position: 'relative',
			zIndex: 1,
			maxHeight: '0vh',
			overflow: 'hidden',
			visibility: 'hidden',

			'&__inner': fluidValues('paddingBottom', 50, 100),

			'.supt-slider__slide': {
				position: 'absolute !important' as 'absolute',
			},
		},

		/* OPENED STATE */
		'&.supt-section--is-open': {
			'.supt-section__trigger': {
				svg: {
					transform: 'rotate(45deg)',

					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						opacity: 0,
					},
				},
				'&:hover, &:focus-within': {
					svg: {
						opacity: 1,
					},
				},
			},
			'.supt-section__content': {
				maxHeight: 'none',
				overflow: 'auto',
				visibility: 'visible',
			},
		},

		// '& + .supt-section--accordion': {
		// 	paddingTop: '0 !important',
		// 	'.supt-section__inner': {
		// 		borderTop: 0,
		// 	},
		// },
	});

export { getStyles };

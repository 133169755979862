export function renderSocialIcon(lbl: string) {
	switch (lbl) {
		case 'twitter':
			return (
				<svg viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg">
					<use href="#twitter" />
				</svg>
			);
		case 'facebook':
			return (
				<svg viewBox="0 0 9 16" xmlns="http://www.w3.org/2000/svg">
					<use href="#facebook" />
				</svg>
			);
		case 'instagram':
			return (
				<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
					<use href="#instagram" />
				</svg>
			);
		case 'vimeo':
			return (
				<svg viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
					<use href="#vimeo" />
				</svg>
			);
	}
}

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import {
	fluidPadding,
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	grid,
	resetButton,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { transitions } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	calendarButtonPrevHover,
	calendarButtonNextHover,
} from '@/css/animations.css';

const getDynamicStyles = (styles) =>
	cssObj(fluidPadding(styles?.padding), {
		'.supt-sectionMonthCalendar__header': fluidValues(
			'top',
			styles?.header?.top?.[0],
			styles?.header?.top?.[1]
		),

		'.supt-sectionMonthCalendar__month': fontStyle({
			...styles?.month?.typography,
			textAlign: styles?.month?.textAlignment,
			color: styles?.month?.color,
		}),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		scrollMarginTop: 70,

		'&:last-child': fluidValues('paddingBottom', 100, 150),
		'html.is-nav-small &': {
			scrollMarginTop: 20,
		},

		'.supt-sectionMonthCalendar__inner': grid,

		'.supt-sectionMonthCalendar__header, .supt-sectionMonthCalendar__days':
			{
				gridColumn: '1 / span 12',
			},

		'.supt-sectionMonthCalendar__header': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			position: 'sticky',
			backgroundColor: '#fff',
			zIndex: 3,
			paddingTop: 5,

			'html.is-nav-small &': {
				top: 64,
			},
		},

		'.supt-sectionMonthCalendar__prev-btn, .supt-sectionMonthCalendar__next-btn':
			cssObj(resetButton, {
				position: 'relative',
				overflow: 'hidden',
				width: 26,
				height: 26,

				'&::before': {
					content: '""',
					position: 'absolute',
					width: '100%',
					height: '100%',
					top: 0,
					left: 0,
					backgroundColor: '#000',
					borderRadius: '50%',
					zIndex: -1,
					transform: 'scale(0)',
					transition: `transform ${transitions.mid} ease`,
				},

				svg: {
					display: 'block',
					margin: '0 auto',
				},

				'&:hover, &:focus-within': {
					'&::before': {
						transform: 'scale(1)',
					},
				},

				'&[disabled]': {
					pointerEvents: 'none',
					svg: {
						opacity: 0.5,
					},
				},
			}),

		'.supt-sectionMonthCalendar__prev-btn': {
			svg: {
				transform: 'scaleX(-1)',
			},

			'&:hover, &:focus-within': {
				svg: {
					animation: `${calendarButtonPrevHover} ${transitions.mid} forwards`,
				},
			},
		},
		'.supt-sectionMonthCalendar__next-btn': {
			'&:hover, &:focus-within': {
				svg: {
					animation: `${calendarButtonNextHover} ${transitions.mid} forwards`,
				},
			},
		},

		'.supt-sectionMonthCalendar__month': {
			marginLeft: 30,
			marginRight: 30,
		},

		'.supt-listCards--list-calendar': {
			margin: 0,
		},
	});

export { getStyles };

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	fluidValues,
	flexGapPolyfill,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	breakpoints,
	transitions,
} from '@superhuit/starterpack-blocks/css/resources/variables.css';
import { linkNoUnderlineWithHover } from '@/css/placeholders.css';
// Resources
// import { } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
// import { } from '@superhuit/starterpack-blocks/css/resources/variables.css';
// import { } from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getStyles = () =>
	css({
		marginTop: '80px',

		[`@media (min-width: ${breakpoints.medium})`]: {
			marginTop: '0',
		},

		'.supt-headerMetas__info': cssObj(fluidValues('marginBottom', 30, 40), {
			'a.supt-headerMetas__meta': cssObj(linkNoUnderlineWithHover, {
				display: 'inline',
			}),
			'.supt-headerMetas__meta': {
				'.times': {
					whiteSpace: 'nowrap',
				},
			},
		}),

		'.supt-headerMetas__taxonomies': cssObj(
			fluidValues('paddingTop', 18, 20),
			fluidValues('paddingBottom', 18, 20),
			flexGapPolyfill([6, 6], [10, 10], [0, 0], [0, 0]),
			{
				borderTop: '1px solid rgba(0, 0, 0, 0.2)',
				borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
				display: 'flex',

				'.supt-headerMetas__terms': cssObj(
					flexGapPolyfill([6, 6], [10, 10], [0, 0], [0, 0]),
					{
						listStyle: 'none',
						display: 'flex',
						padding: 0,
						flexWrap: 'wrap',
					}
				),
			}
		),

		'.supt-headerMetas__public': cssObj(fluidValues('marginTop', 30, 40), {
			listStyle: 'none',
			padding: 0,
			display: 'flex',
			position: 'relative',

			li: {
				display: 'flex',
				alignItems: 'center',
				paddingLeft: '15px',
				cursor: 'help',

				'&:first-child': {
					paddingLeft: 0,
				},

				'&::after': {
					content: "''",
					height: '15px',
					width: '1px',
					backgroundColor: 'rgba(0, 0, 0, 0.2)',
					marginLeft: '15px',
				},

				'&:last-child::after': {
					content: 'none',
				},

				span: {
					width: '100%',
					maxWidth: '35px',
				},

				'.supt-headerMetas__public__title': {
					position: 'absolute',
					top: '100%',
					left: 0,
					whiteSpace: 'nowrap',
					marginTop: '6px',
					opacity: 0,
					visibility: 'hidden',
					transition: `opacity ${transitions.fast} ease-in-out`,
				},

				'&:hover': {
					'.supt-headerMetas__public__title': {
						opacity: 1,
						visibility: 'visible',
					},
				},
			},
		}),

		'.supt-headerMetas__practical': cssObj(
			fluidValues('marginTop', 30, 40),
			{
				'.supt-headerMetas__practical__title': {
					marginBottom: '3px',
				},
			}
		),
	});

// edit
const getEditGlobalStyles = () =>
	cssObj({
		'.wp-block[data-type="supt/header-metas"]': cssObj({
			'.supt-headerMetas__info': {
				'.supt-headerMetas__meta': {
					margin: 0,
				},
			},

			'.supt-headerMetas__practical': {
				'.supt-headerMetas__practical__title': {
					marginTop: 0,
				},
			},
		}),
	});

export { getStyles, getEditGlobalStyles };

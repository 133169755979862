import React, {
	useContext,
	useRef,
	useEffect,
	forwardRef,
	ForwardedRef,
	useState,
} from 'react';
import cx from 'classnames';

import { LocaleContext } from '@superhuit/starterpack-i18n';
import { Accordion } from '@superhuit/starterpack-blocks/renders';
import LangSwitcher from '@superhuit/starterpack-blocks/components/molecules/LangSwitcher/render';

import { Link } from '@/components/atoms/Link/render';
import { NavMobileProps } from '../typings';
import { useRouter } from 'next/router';
import { Drawer } from './drawer';
import { renderSocialIcon } from '../../helpers/render-social-icon';

export const NavMobile = forwardRef(
	(
		{
			primary,
			secondary,
			social,
			uri,
			translations,
			isNavOpen,
		}: NavMobileProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const { __t, locale } = useContext(LocaleContext);

		const accordionsRef = useRef([]);
		const secondaryItemsRef = useRef([]);
		const mainRef = useRef(null);

		const router = useRouter();

		const [openedDrawer, setOpenedDrawer] = useState(-1);

		// Open accordion if isCurrent (current page is one of its child), otherwise open the 1st accordion by default
		useEffect(() => {
			// TODO => DO THIS FOR MAIN WITH DRAWERS AS WELL !!

			if (!accordionsRef.current.length) return; // Only for drawers that have accordions

			const handleRouteChange = () => {
				const currentItem = secondary.items.reduce(
					(acc, subItem, i) => {
						const hasCurrentChild = subItem.items.filter(
							(productItem) => uri === productItem.path
						).length;

						if (hasCurrentChild) {
							return i;
						}
						return acc;
					},
					-1
				);

				if (currentItem !== -1) {
					const currentAccordion = accordionsRef.current[currentItem];
					if (currentAccordion) currentAccordion.openAccordion();
				}
			};

			router.events.on('routeChangeComplete', handleRouteChange);

			// cleanup
			return () => {
				router.events.off('routeChangeComplete', handleRouteChange);
			};
		}, []);

		/**
		 * Close drawer on closing menu
		 */
		useEffect(() => {
			if (!isNavOpen) {
				setOpenedDrawer(-1);
				closeAllAccordions(-1);
			}
		}, [isNavOpen]);

		const isActiveParent = (subItems) => {
			const activeSubItems = subItems.filter(
				(subItem) => subItem.path === uri || uri.includes(subItem.path)
			);

			const activeSubSubItems = subItems.filter(
				(subItem) =>
					subItem.items.filter(
						(subItem) =>
							subItem.path === uri || uri.includes(subItem.path)
					).length > 0
			);

			if (activeSubItems.length || activeSubSubItems.length) return true;

			return false;
		};

		const handleAccordionClick = (currentIndex) => {
			if (!accordionsRef.current[currentIndex].isExpanded) {
				mainRef.current.scrollTo(
					0,
					secondaryItemsRef.current[currentIndex].offsetTop
				);
			} else {
				mainRef.current.scrollTo(0, 0);
			}

			closeAllAccordions(currentIndex);
		};

		const closeAllAccordions = (currentIndex) => {
			// Close other accordions when 1 is opened
			accordionsRef.current.forEach((accordion) => {
				accordion.closeAccordion(currentIndex);
			});
		};

		return (
			<div ref={ref} className="supt-mainNav__mobile">
				<div className="supt-mainNav__mobile__main" ref={mainRef}>
					<div className="supt-mainNav__mobile__inner">
						<ul
							id="menu_nav"
							role="navigation"
							className="supt-mainNav__mobile__primary"
							aria-label={__t(
								'main-mobile-nav-label',
								'Mobile main navigation'
							)}
						>
							{primary.items.map((item, i) => (
								<li
									key={item.id}
									className={cx(
										'supt-mainNav__mobile__primary__item',
										{
											'supt-mainNav__mobile__primary__item--hasSubmenu':
												!!item.items.length,
											isCurrent:
												uri === item.path ||
												isActiveParent(item.items),
										}
									)}
								>
									{!item.items.length ? (
										<Link
											href={item.path}
											suppressHydrationWarning
											className={cx(
												'supt-mainNav__mobile__primary__item__label',
												{
													isCurrent:
														uri === item.path,
												}
											)}
											aria-current={
												uri === item.path
													? 'page'
													: null
											}
											target={item.target}
										>
											<span>{item.label}</span>
										</Link>
									) : (
										<Drawer
											i={i}
											item={item}
											uri={uri}
											handleNavSmallItemClick={(i) => {
												setOpenedDrawer(i);
											}}
											openedDrawer={openedDrawer}
											handleDrawerBackButtonClick={() => {
												setOpenedDrawer(-1);
											}}
											className="supt-mainNav__mobile__primary__drawer"
										/>
									)}
								</li>
							))}
						</ul>

						<ul
							id="menu_nav"
							role="navigation"
							className="supt-mainNav__mobile__secondary"
							aria-label={__t(
								'secondary-mobile-nav-label',
								'Mobile secondary navigation'
							)}
						>
							{secondary.items.map((item, i) => {
								const hasSubItems = !!item.items.length;
								return (
									<li
										key={item.id}
										className={cx(
											'supt-mainNav__mobile__secondary__item',
											{
												'supt-mainNav__mobile__secondary__item--hasSubmenu':
													hasSubItems,
												isCurrent:
													uri === item.path ||
													isActiveParent(item.items),
											}
										)}
										ref={(ref) =>
											(secondaryItemsRef.current[i] = ref)
										}
									>
										{!hasSubItems ? (
											<Link
												href={item.path}
												suppressHydrationWarning
												className="supt-mainNav__mobile__secondary__item__label"
												aria-current={
													uri === item.path
														? 'page'
														: null
												}
												target={item.target}
											>
												{item.label}
											</Link>
										) : (
											<Accordion
												index={i}
												title={item.label}
												content={
													<nav className="supt-mainNav__mobile__secondary__item__submenu">
														{item.items.map(
															(subItem, i) => (
																<Link
																	key={i}
																	href={
																		subItem.path ??
																		'#'
																	}
																	suppressHydrationWarning
																	className={cx(
																		'supt-mainNav__drawer__link',
																		...subItem.cssClasses,
																		{
																			isCurrent:
																				uri ===
																					subItem.path ||
																				uri.includes(
																					subItem.path
																				),
																		}
																	)}
																	aria-current={
																		uri ===
																			subItem.path ||
																		uri.includes(
																			subItem.path
																		)
																			? 'page'
																			: null
																	}
																	target={
																		subItem.target
																	}
																>
																	{
																		subItem.label
																	}
																</Link>
															)
														)}
													</nav>
												}
												onButtonClick={
													handleAccordionClick
												}
												ref={(ref) =>
													(accordionsRef.current[i] =
														ref)
												}
											/>
										)}
									</li>
								);
							})}
						</ul>
					</div>
				</div>

				<div className="supt-mainNav__mobile__footer">
					<div className="supt-mainNav__mobile__inner">
						<div className="supt-mainNav__mobile__footer__top">
							<a
								className="supt-mainNav__mobile__search"
								href={
									locale === 'fr'
										? '/fr/recherche'
										: '/en/search'
								}
							>
								<svg
									viewBox="0 0 26 26"
									xmlns="http://www.w3.org/2000/svg"
								>
									<use href="#search" />
								</svg>

								<span>
									{__t('main-nav-search', 'Rechercher')}
								</span>
							</a>
							<LangSwitcher translations={translations} />
						</div>
						<div className="supt-mainNav__mobile__footer__bottom">
							<ul className="supt-mainNav__socials__list">
								{social?.items ? (
									<>
										{social.items.map(
											({ label, path }, i) => (
												<li
													key={i}
													className="supt-mainNav__socials__item"
												>
													<Link
														href={path}
														className="supt-mainNav__socials__link"
													>
														{renderSocialIcon(
															label
														)}
													</Link>
												</li>
											)
										)}
									</>
								) : null}
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
);

import React, { forwardRef, Ref } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Section } from '@superhuit/starterpack-blocks/helpers/Section';
import { SectionOneColumnProps } from '@superhuit/starterpack-blocks/utils/typings';
import { LinkProps } from '@superhuit/starterpack-blocks/helpers/Link/render';
// styles
import { getStyles as getDfltStyles } from '@superhuit/starterpack-blocks/components/organisms/Sections/SectionOneColumn/styles.css';
import block from './block.json';
import { ListEvents, ButtonSecondary } from '@/components/renders';
import { ListEventsProps } from '@/components/molecules/ListEvents/render';
import { isEmpty } from 'lodash';

/**
 * TYPINGS
 */
type SectionCardsEventProps = SectionOneColumnProps & {
	link?: LinkProps;
} & ListEventsProps;

/**
 * COMPONENT
 */
export const SectionCardsEvent = forwardRef<Ref<any>, SectionCardsEventProps>(
	(
		{
			anchor,
			className,
			uptitle,
			title,
			introduction,
			link,
			events,
			slug,
			variant: currentVariant = 'default',
			titleTag = 'h2',
			theme = {},
			getStyles = getDfltStyles,
		},
		ref
	) => {
		const { variant, blockTheme } = useThemeBlock(
			slug,
			theme,
			currentVariant
		);

		const trimmedSlug = slug.split('/');
		const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

		const rootClass = cx(
			'supt-section supt-section--oneCol',
			`supt-section--${modifierSlug}`,
			'supt-section--colored',
			getStyles(blockTheme),
			className
		);

		return isEmpty(events) ? null : (
			<Section
				id={anchor}
				className={rootClass}
				variant={variant}
				slug={slug}
				ref={ref}
			>
				<div className="supt-section__inner">
					{(uptitle || title || introduction || link?.href) && (
						<div className="supt-section__headline">
							{uptitle && <Section.Uptitle text={uptitle} />}
							{title && (
								<Section.Title
									text={title}
									titleTag={titleTag}
								/>
							)}
							{introduction && (
								<Section.Introduction text={introduction} />
							)}

							{link?.href ? (
								<div className="supt-section__linkWrapper">
									<ButtonSecondary {...link} />
								</div>
							) : null}
						</div>
					)}
					<div className="supt-section__content">
						<ListEvents events={events} />
					</div>
				</div>
			</Section>
		);
	}
);

// @ts-ignore
SectionCardsEvent.slug = block.slug;
// @ts-ignore
SectionCardsEvent.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { resetFirstLastMargins } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { transitions } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	expandLink,
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	imageExpandTransition,
	imageReduceTransition,
	linkNoUnderlineWithHover,
} from '@/css/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-cardShow__artist': fontStyle({
			...styles?.artist?.typography,
			textAlign: styles?.artist?.textAlignment,
			color: styles?.artist?.color,
		}),

		'.supt-cardShow__title': cssObj(
			fontStyle(
				{
					...styles?.title?.typography,
					textAlign: styles?.title?.textAlignment,
					color: styles?.title?.color,
				},
				false
			)
		),

		'.supt-cardShow__details p': fontStyle(
			{
				...styles?.content?.typography,
				textAlign: styles?.content?.textAlignment,
				color: styles?.content?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		overflow: 'hidden',
		maxWidth: '100%',

		'.supt-card__wrapper': {
			position: 'relative',
		},

		'.supt-cardShow__imageWrapper': cssObj(
			fluidValues('marginBottom', 15, 20),
			{
				display: 'flex',
				position: 'relative',
				overflow: 'hidden',
				marginRight: 'auto',

				'.supt-figure, span': {
					width: '100% !important',
				},

				'.supt-cardShow__categories': cssObj(
					fluidValues('top', 8, 10),
					fluidValues('left', 8, 10),
					{
						position: 'absolute',
						display: 'flex',
						gap: '5px',
					}
				),
			}
		),

		'.supt-cardShow__image': {
			display: 'inline-flex',
		},

		'.supt-cardShow__link': cssObj(resetFirstLastMargins, {
			display: 'block',
			textDecoration: 'none',
		}),

		'.supt-cardShow__link:empty': {
			marginBottom: 0,

			'& + .supt-cardShow__title': {
				marginTop: 0,
			},
		},

		'.supt-cardShow__title': cssObj(fluidValues('marginTop', 8, 10), {
			fontStyle: 'italic',
			marginBottom: 0,
		}),

		'.supt-cardShow__details': cssObj(fluidValues('marginTop', 7, 8), {
			p: {
				margin: 0,
			},
		}),

		'.supt-cardShow__public-list': cssObj(
			fluidValues('marginTop', 8, 12),
			fluidValues('gap', 10, 12),
			{
				listStyle: 'none',
				padding: 0,
				display: 'flex',
				position: 'relative',

				li: {
					display: 'flex',
					alignItems: 'center',

					'figure, span': {
						width: '100%',
						maxHeight: '22px',
					},

					'&.supt-cardShow__public-extra': cssObj(
						fluidValues('fontSize', 15, 16),
						{
							letterSpacing: '-0.03125em',
							lineHeight: '1.375em',
						}
					),
				},
			}
		),

		'.supt-cardShow__ticket': {
			position: 'absolute',
			right: 0,
			bottom: 0,
			zIndex: '2',

			'.supt-cardShow__ticket__button.supt-buttonPrimary': cssObj(
				fluidValues('width', 36, 38),
				fluidValues('height', 36, 38),
				{
					margin: 0,
					zIndex: '2',
					display: 'flex',
					transition: `all ${transitions.mid} ease-out`,
					float: 'right',
					whiteSpace: 'nowrap',

					'&:hover': {
						width: '100%',
						backgroundColor:
							styles.states.hover.button.backgroundColor,
						color: styles.states.hover.button.color,
					},

					'&::before': {
						content: `none`,
					},

					'&::after': cssObj(
						fluidValues('width', 36, 38),
						fluidValues('height', 36, 38),
						fluidValues('padding', 4, 5),
						{
							content: `url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M17.447 3.022a4.328 4.328 0 0 0-1.315 2.902c-.044 1.054.32 2.087 1.088 2.856.769.769 1.802 1.132 2.856 1.088a4.328 4.328 0 0 0 2.869-1.281l1.65 1.585-14.423 14.424-1.685-1.685c.484-.728.735-1.52.767-2.284.044-1.052-.321-2.056-1.073-2.808-.769-.768-1.802-1.132-2.856-1.088a4.28 4.28 0 0 0-2.252.766l-1.67-1.669L15.829 1.404Z' stroke='%23FFF' stroke-width='1.6'/%3E%3Cpath fill='%23FFF' d='m11.495 7.905 1.13 1.132-1.13 1.131-1.132-1.131zM13.333 9.744l1.131 1.131-1.131 1.132-1.131-1.132zM15.172 11.582l1.13 1.132-1.13 1.131-1.132-1.131zM17.01 13.42l1.131 1.132-1.131 1.131-1.131-1.13z'/%3E%3C/g%3E%3C/svg%3E")`,
							background: 'black',
							borderRadius: '50%',
							position: 'absolute',
							right: '0px',
							top: '0px',
							bottom: '0px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'flex-start',
							border: '1px solid black',
							boxSizing: 'border-box',
						}
					),

					'.supt-button__inner': {
						margin: 0,
						overflow: 'visible',
						display: 'flex',
						alignItems: 'center',

						'&::after': {
							content: "''",
							width: '30px',
							height: '100%',
						},
					},
				}
			),
		},

		/* with link */
		'&.supt-withLink': cssObj(expandLink(), {
			transition: `all ${transitions.mid} ease-out`,

			'.supt-cardShow__image': cssObj(imageReduceTransition),

			'.supt-cardShow__artist': cssObj(linkNoUnderlineWithHover, {
				backgroundPosition: '0 0.85em',
			}),

			'&:hover, &:focus-within': cssObj(
				getDynamicStyles(styles?.states?.hover),
				{
					'.supt-cardShow__image': cssObj(imageExpandTransition),
				}
			),
		}),

		'&.supt-cardShow--canceled, &.supt-cardShow--postponed, &.supt-cardShow--closed':
			{
				'.supt-cardShow__details': {
					'.supt-cardShow__day': {
						display: 'flex',
						alignItems: 'center',

						'.supt-cardShow__day__info': {
							textDecorationLine: 'line-through',
						},

						'.supt-tag[data-type="alert"]': {
							marginLeft: '7px',
						},
					},
				},
			},
	});

export { getStyles };

import {
	BoxFluidControl,
	CustomSelectControl,
	ColorControl,
	RadioGroupControl,
	TEXT_ALIGNS,
} from '@superhuit/starterpack-customizer';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';

export const sectionCardsPressThemeAttributes = {
	backgroundColor: '@tokens.variants.default.backgroundColor',
	padding: {
		// without control (for Storybook only)
		top: [80, 120],
		bottom: [80, 120],
	},
	uptitle: {
		color: '@tokens.variants.default.titleColor',
		typography: '@tokens.typographies.heading-6',
		textAlignment: 'left',
	},
	title: {
		color: '@tokens.variants.default.titleColor',
		typography: '@tokens.typographies.heading-1',
		textAlignment: 'left',
	},
	introduction: {
		color: '@tokens.variants.default.contentColor',
		typography: '@tokens.typographies.introduction',
		textAlignment: 'left',
	},
	link: {
		color: '@tokens.variants.default.contentColor',
		typography: '@tokens.typographies.links',
		textAlignment: 'right',
	},
};

export const SectionCardsPressThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: sectionCardsPressThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return (
			<>
				<h4>Section specs</h4>
				{/* Background color */}
				<ColorControl
					label="Background color"
					value={fullAttributes?.backgroundColor.value}
					default={fullAttributes?.backgroundColor.default}
					inheritsFrom={
						fullAttributes?.backgroundColor.default_origin
					}
					onChange={(value) =>
						updateAttribute('backgroundColor', value)
					}
					theme={theme}
				/>
				{/* Uptitle */}
				<h4>Uptitle typography</h4>
				<CustomSelectControl
					label="Style"
					options={typographiesOptions}
					default={fullAttributes?.uptitle.typography.default}
					inheritsFrom={
						fullAttributes?.uptitle.typography.default_origin
					}
					value={fullAttributes?.uptitle.typography.value}
					onChange={(value) =>
						updateAttribute('uptitle.typography', value)
					}
				/>
				<RadioGroupControl
					label="Text alignment"
					value={fullAttributes?.uptitle?.textAlignment.value}
					default={fullAttributes?.uptitle?.textAlignment.default}
					options={TEXT_ALIGNS}
					onChange={(value) => {
						updateAttribute('uptitle.textAlignment', value);
					}}
				/>
				<ColorControl
					label="Color"
					default={fullAttributes?.uptitle?.color.default}
					inheritsFrom={fullAttributes?.uptitle?.color.default_origin}
					value={fullAttributes?.uptitle?.color.value}
					onChange={(value) =>
						updateAttribute('uptitle.color', value)
					}
					theme={theme}
					type="color"
				/>
				{/* Title */}
				<h4>Title typography</h4>
				<CustomSelectControl
					label="Style"
					options={typographiesOptions}
					default={fullAttributes?.title.typography.default}
					inheritsFrom={
						fullAttributes?.title.typography.default_origin
					}
					value={fullAttributes?.title.typography.value}
					onChange={(value) =>
						updateAttribute('title.typography', value)
					}
				/>
				<RadioGroupControl
					label="Text alignment"
					value={fullAttributes?.title?.textAlignment.value}
					default={fullAttributes?.title?.textAlignment.default}
					options={TEXT_ALIGNS}
					onChange={(value) => {
						updateAttribute('title.textAlignment', value);
					}}
				/>
				<ColorControl
					label="Color"
					default={fullAttributes?.title?.color.default}
					inheritsFrom={fullAttributes?.title?.color.default_origin}
					value={fullAttributes?.title?.color.value}
					onChange={(value) => updateAttribute('title.color', value)}
					theme={theme}
					type="color"
				/>
				{/* Introduction */}
				<h4>Introduction typography</h4>
				<CustomSelectControl
					label="Style"
					options={typographiesOptions}
					default={fullAttributes?.introduction.typography.default}
					inheritsFrom={
						fullAttributes?.introduction.typography.default_origin
					}
					value={fullAttributes?.introduction.typography.value}
					onChange={(value) =>
						updateAttribute('introduction.typography', value)
					}
				/>
				<RadioGroupControl
					label="Text alignment"
					value={fullAttributes?.introduction?.textAlignment.value}
					default={
						fullAttributes?.introduction?.textAlignment.default
					}
					options={TEXT_ALIGNS}
					onChange={(value) => {
						updateAttribute('introduction.textAlignment', value);
					}}
				/>
				<ColorControl
					label="Color"
					default={fullAttributes?.introduction?.color.default}
					inheritsFrom={
						fullAttributes?.introduction?.color.default_origin
					}
					value={fullAttributes?.introduction?.color.value}
					onChange={(value) =>
						updateAttribute('introduction.color', value)
					}
					theme={theme}
					type="color"
				/>
				{/* Link */}
				<h4>Link typography</h4>
				<CustomSelectControl
					label="Style"
					options={typographiesOptions}
					default={fullAttributes?.link.typography.default}
					inheritsFrom={
						fullAttributes?.link.typography.default_origin
					}
					value={fullAttributes?.link.typography.value}
					onChange={(value) =>
						updateAttribute('link.typography', value)
					}
				/>
				<RadioGroupControl
					label="Text alignment"
					value={fullAttributes?.link?.textAlignment.value}
					default={fullAttributes?.link?.textAlignment.default}
					options={TEXT_ALIGNS}
					onChange={(value) => {
						updateAttribute('link.textAlignment', value);
					}}
				/>
				<ColorControl
					label="Color"
					default={fullAttributes?.link?.color.default}
					inheritsFrom={fullAttributes?.link?.color.default_origin}
					value={fullAttributes?.link?.color.value}
					onChange={(value) => updateAttribute('link.color', value)}
					theme={theme}
					type="color"
				/>
			</>
		);
	},
};

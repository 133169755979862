import React, { FC, useContext } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import block from './block.json';
import { CardProduction, CardProductionProps } from '../CardProduction/render';
// styles
import { getStyles } from './styles.css';
import HighProdContext from '@/contexts/high-prod-context';

/**
 * TYPINGS
 */
export type ListHighlightedProductionsProps = ThemeProps & {
	productions?: Array<CardProductionProps>;
};

/**
 * COMPONENT
 */
export const ListHighlightedProductions: FC<ListHighlightedProductionsProps> &
	BlockConfigs = ({ productions, theme = {} }) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const { activeId, setActiveId } = useContext(HighProdContext);

	const handleHoverOn = (id) => {
		setActiveId(() => id);
	};
	const handleHoverOff = () => {
		setActiveId(() => -1);
	};

	const rootClass = cx(
		'supt-listCards supt-listHighlightedProductions',
		getStyles(blockTheme)
	);

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			<div className="supt-listHighlightedProductions__list">
				{productions?.map((production, index) => (
					<CardProduction
						key={index}
						{...production}
						onMouseEnter={handleHoverOn}
						onMouseLeave={handleHoverOff}
						className={cx(
							activeId >= 0 &&
								activeId === production.id &&
								'supt-cardProduction--active',
							activeId >= 0 &&
								activeId !== production.id &&
								'supt-cardProduction--notActive'
						)}
					/>
				))}
			</div>
		</div>
	);
};

ListHighlightedProductions.slug = block.slug;
ListHighlightedProductions.title = block.title;

import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { gridNoMargin } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-section--section-cards-partner': {
			overflow: 'hidden',

			'.supt-section__content': {
				gridColumn: '1 / span 12 !important',
			},

			'&.supt-section--is-open .supt-section__content': {
				overflow: 'visible',
			},

			'.supt-listCards': {
				gridColumn: '1/13',
				marginTop: 5,
				marginBottom: 10,

				[`@media (min-width: ${breakpoints.tablet})`]: {
					marginTop: 10,
					marginBottom: 25,
				},

				'.supt-listCards__list': cssObj(gridNoMargin, {
					display: 'grid',
					gridTemplateColumns: '1fr',
					rowGap: '60px',

					[`@media (min-width: ${breakpoints.tablet})`]: {
						gridTemplateColumns: 'repeat(2, 1fr)',
						rowGap: '80px',
					},
					[`@media (min-width: ${breakpoints.medium})`]: {
						gridTemplateColumns: 'repeat(3, 1fr)',
						rowGap: '90px',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridTemplateColumns: 'repeat(4, 1fr)',
						rowGap: '120px',
					},
				}),
			},
		},
	});

export { getCustomStyles };

import { handleIconButton } from '@/css/mixins.css';
import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	container,
	gridNoMargin,
	resetFirstLastChildMargins,
} from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
// import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getStyles = (styles, tokens = {} as any, croppedHeight = 800) =>
	css({
		paddingTop: '0 !important',
		paddingBottom: '0 !important',
		position: 'relative',

		'.supt-section__content': {
			'.supt-subsection': cssObj(
				gridNoMargin,
				fluidValues('paddingTop', 60, 100),
				fluidValues('paddingBottom', 60, 100),
				resetFirstLastChildMargins,
				{
					'&:first-child': fluidValues('paddingTop', 100, 150),
					'&.supt-subsection--subsection-gallery:first-child, &.supt-subsection--subsection-text-media:first-child, &.supt-subsection--subsection-two-images:first-child':
						fluidValues('paddingTop', 16, 60),
					'&:last-child': fluidValues('paddingBottom', 100, 200),
				}
			),
		},

		'.pin-spacer': {
			pointerEvents: 'none',

			'.supt-button': {
				pointerEvents: 'auto',
			},
		},

		'.supt-section__navigation': {
			position: 'absolute',
			bottom: 0,
			left: 0,
			zIndex: 2,
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			gridColumn: '1/13',
			flexDirection: 'column',
			alignItems: 'center',

			'.supt-progress': cssObj(container, {
				width: '100%',
				height: '1px',
				position: 'relative',

				'&::before': {
					content: '""',
					backgroundColor: styles?.progress?.backgroundColor,
					width: '100%',
					height: '1px',
					position: 'absolute',
					opacity: 0.2,
				},

				'&.is-hidden': {
					opacity: 0,
					zIndex: -1000,
					pointerEvents: 'none',
				},

				'.supt-progress__bar': {
					position: 'absolute',
					height: '1px',
					left: 0,
					top: 0,
					backgroundColor: styles?.progress?.foregroundColor,
					width: '100%',
					transform: 'scaleX(0)',
					transformOrigin: 'top left',
				},
			}),

			'.supt-button': {
				margin: '30px 0',
				cursor: 'pointer',
			},

			'.supt-button--open': handleIconButton('black', 'white'),
			'.supt-button--close': handleIconButton('white', 'black'),
		},

		'&.supt-section--dark': {
			'&.supt-section--isCropped.supt-section--isClosed': {
				'&::after': {
					backgroundImage: `linear-gradient(180deg, ${tokens?.colors?.['neutral-900']?.value}00 0%, ${tokens?.colors?.['neutral-900']?.value} 99%)`,
				},
			},

			'.supt-section__navigation': {
				'.supt-progress': {
					'&::before': {
						backgroundColor: tokens?.colors?.['neutral-000']?.value,
					},

					'.supt-progress__bar': {
						backgroundColor: tokens?.colors?.['neutral-000']?.value,
					},
				},

				'.supt-button--open': handleIconButton('white', 'black'),
				'.supt-button--close': handleIconButton('black', 'white'),
			},
		},

		'&.supt-section--isCropped.supt-section--isClosed': cssObj(
			// fluidValues('height', 836, 814),
			{
				overflow: 'hidden',
				height: croppedHeight,

				'&::after': {
					content: '""',
					position: 'absolute',
					bottom: 0,
					left: 0,
					backgroundImage: `linear-gradient(180deg, ${styles?.overlay}00 0%, ${styles?.overlay} 99%)`,
					width: '100%',
					height: '250px',
					zIndex: 1,
				},
			}
		),

		'&.supt-section--isCropped.supt-section--isOpened': cssObj({
			'.supt-section__inner': {
				position: 'relative',
			},

			'.supt-section__navigation': {
				position: 'absolute',
				height: '50vh',
				bottom: 'none',
				top: '50vh',
				justifyContent: 'space-between',
			},
		}),
	});

const getEditStyles = () =>
	css({
		height: 'auto !important',
	});

export { getStyles, getEditStyles };

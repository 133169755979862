import React, { FC } from 'react';

// package imports
import { ListCardsProps } from '@superhuit/starterpack-blocks/utils/typings';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { useThemeBlock } from '@superhuit/starterpack-hooks';

import {
	CardPartner,
	CardPartnerProps,
} from '@/components/molecules/CardPartner/render';

// internal imports
import block from './block.json';

export type ListPartnersProps = {
	partners?: Array<CardPartnerProps>;
} & ListCardsProps;

/**
 * COMPONENT
 */
export const ListPartners: FC<ListPartnersProps> & BlockConfigs = ({
	partners,
	theme = {},
	...props
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	return (
		<div
			className="supt-listCards supt-listPartners"
			data-block={block.slug}
			data-variant={variant}
		>
			<div className="supt-listCards__list">
				{partners.map((partner) => (
					<CardPartner key={partner.id} {...partner} />
				))}
			</div>
		</div>
	);
};

ListPartners.slug = block.slug;
ListPartners.title = block.title;

import React, { FC } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { Slider } from '@superhuit/starterpack-blocks/renders';
import { CardNews, CardNewsProps } from '../CardNews/render';
import block from './block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type SliderNewsProps = ThemeProps & {
	posts?: Array<CardNewsProps>;
};

/**
 * COMPONENT
 */
export const SliderNews: FC<SliderNewsProps> & BlockConfigs = ({
	posts,
	theme = {},
}) => {
	const { variant } = useThemeBlock(block.slug, theme);

	const rootClass = cx('supt-listCards supt-sliderNews', getStyles());

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			<Slider
				slides={posts.map((post, index) => (
					<CardNews key={index} {...post} index={index} />
				))}
				// hasStagger
				loop={false}
				transition={{
					tension: 80,
					friction: 20,
				}}
				navigation={{
					icon: (
						<svg
							width="13"
							height="13"
							viewBox="0 0 13 13"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g fill="none" fillRule="evenodd" strokeWidth="1.4">
								<path d="M6.207.843 11.864 6.5l-5.657 5.657" />
								<path strokeLinecap="square" d="M10.5 6.5H0" />
							</g>
						</svg>
					),
				}}
			/>
		</div>
	);
};

SliderNews.slug = block.slug;
SliderNews.title = block.title;

import React, { FC, MouseEventHandler, useContext, useMemo } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { BlockConfigs } from '@superhuit/starterpack-blocks/utils/typings';
import { ThemeProps } from '@superhuit/starterpack-blocks/utils/typings';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { Link, LinkProps } from '../Link/render';
// styles
import block from './block.json';
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type TagType = 'default' | 'exclusive' | 'vidy' | 'alert';
export type TagProps = {
	id?: number;
	slug?: string;
	title: string;
	desc?: string;
	link?: string;
	type?: TagType;
	hasBorder?: boolean;
	isLink?: boolean;
	isSquared?: boolean;
	isClickable?: boolean;
	isDisabled?: boolean;
	isDeletable?: boolean;
	isTransparent?: boolean;
	onClick?: MouseEventHandler;
} & ThemeProps;

/**
 * COMPONENT
 */
export const Tag: FC<TagProps> & BlockConfigs = ({
	id,
	title,
	desc,
	link,
	type = 'default',
	hasBorder = false,
	isLink = false,
	isSquared = false,
	isClickable = false,
	isDisabled = false,
	isDeletable = false,
	isTransparent = false,
	theme = {},
	...props
}) => {
	const Element: keyof JSX.IntrinsicElements | FC<LinkProps> = useMemo(
		() => (isLink && !!link ? Link : isClickable ? 'button' : 'div'),
		[isClickable, link, isLink]
	);

	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const { __t } = useContext(LocaleContext);

	const rootClass = cx(
		'supt-tag',
		{
			hasBorder,
			isSquared,
			isDisabled,
			isDeletable,
			isTransparent,
			hasDesc: !!desc,
		},
		getStyles(blockTheme)
	);

	return (
		<Element
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
			data-type={type}
			href={isLink && !!link ? link : undefined}
			{...props}
		>
			<span className="supt-tag__icon--before"></span>
			<span
				className="supt-tag__title"
				dangerouslySetInnerHTML={{
					__html:
						type === 'vidy'
							? __t('event-production-vidy', title)
							: title,
				}}
			></span>
			<span className="supt-tag__icon--after">
				{isDeletable ? (
					<svg
						width="8"
						height="8"
						xmlns="http://www.w3.org/2000/svg"
					>
						<use href="#close" />
					</svg>
				) : null}
			</span>
			{!!desc && (
				<>
					<p className="supt-tag__desc">{desc}</p>
					{!!link && (
						<Link
							href={link}
							className="supt-tag__link supt-link"
							onClick={(e) => e.stopPropagation()}
						>
							{__t('tag-link', 'Découvrir')}
						</Link>
					)}
				</>
			)}
		</Element>
	);
};

Tag.slug = block.slug;
Tag.title = block.title;

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import { inputStyles } from '@superhuit/starterpack-blocks/css/shared/inputs.css';
import { buttonIcons } from '@/css/variables.css';
import { linkUnderlineWithHoverRedraw } from '@/css/placeholders.css';

const getStyles = (styles) =>
	css(
		fluidValues('paddingTop', 50, 80, true),
		fluidValues('paddingBottom', 50, 80, true),
		{
			'.supt-section__title': fluidValues('marginBottom', 40, 60, true),

			'.supt-section__search': {
				position: 'relative',
				display: 'flex',
				alignItems: 'center',

				'.supt-section__search__input': cssObj(inputStyles(styles), {
					'html:not(.using-mouse) &:focus-within, &:hover': {
						outline: 'none',
						boxShadow: '0 0 0 1px black',
					},

					[`@media (min-width: ${breakpoints.medium})`]: {
						paddingRight: '120px',
					},
				}),
				'&::before': cssObj({
					position: 'absolute',
					left: '20px',
					content: buttonIcons.search.black,
					// paddingTop: '0.1em',
				}),
				'.supt-button--reset': cssObj(linkUnderlineWithHoverRedraw, {
					cursor: 'pointer',
					minWidth: 'auto !important',
					padding: 0,
					borderWidth: 0,
					position: 'absolute',
					top: '100%',
					right: 0,
					marginTop: '6px',

					[`@media (min-width: ${breakpoints.medium})`]: {
						flexDirection: 'row',
						top: 'initial',
						right: '20px',
						marginTop: '0px',
					},
				}),
			},

			'.supt-section__content': fluidValues('marginTop', 60, 80),

			'.supt-resultsInfo': {
				marginTop: 0,
				marginBottom: '20px',
				paddingTop: 0,
				paddingBottom: 0,
			},
		}
	);

export { getStyles };

import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { transitions } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	linkArrowExternalHover,
	linkArrowExternalOut,
	linkArrowHover,
	linkArrowOut,
} from './animations.css';
import { buttonIcons } from './variables.css';

/**
 * Animate links that have and arrow on left
 * @param baseClass the base of the css class for the link/label/icon
 * @param iconColor the color of the icon
 * @returns
 */
export const linkWithArrowLeft = (baseClass, iconColor = '#000') =>
	cssObj({
		display: 'flex',
		alignItems: 'center',

		'&__icon, &__label': {
			transform: 'translateX(-20px)',
			transition: `transform ${transitions.mid} ease`,
		},
		'&__icon': {
			display: 'block',
			overflow: 'hidden',
			position: 'relative',
			top: 2,
			marginRight: 8,
			transform: 'translateX(-10px)',

			'&::before': {
				content: `url("data:image/svg+xml,%3Csvg width='13' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23${iconColor.replace(
					'#',
					''
				)}' stroke-width='1.4'%3E%3Cpath d='M6.2071.8432 11.864 6.5 6.207 12.1569'/%3E%3Cpath stroke-linecap='square' d='M10.5 6.5H0'/%3E%3C/g%3E%3C/svg%3E")`,
				display: 'block',
				transform: 'translateX(-100%)',
				animation: `${linkArrowOut} ${transitions.fast} ease`,
			},
		},

		'&[target="_blank"]': {
			[`${baseClass}__link__icon`]: {
				top: 0,
				'&::before': {
					content: `url("data:image/svg+xml,%3Csvg width='11' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.789 1.4h8v8' stroke='%23FFF' stroke-width='1.4' fill='none' stroke-miterlimit='10'/%3E%3Cpath d='M8.8246 2.3645 1.4 9.789' stroke='%23FFF' stroke-width='1.4' fill='none' stroke-linecap='square' stroke-miterlimit='10'/%3E%3C/svg%3E")`,
					animationName: linkArrowExternalOut,
				},
			},
		},

		'&:hover': {
			[`${baseClass}__link__icon::before`]: {
				transform: 'translateX(0%)',
				animation: `${linkArrowHover} ${transitions.mid} ease`,
			},
			[`${baseClass}__link__icon, ${baseClass}__link__label`]: {
				transform: 'translateX(0)',
			},

			'&[target="_blank"]': {
				[`${baseClass}__link__icon::before`]: {
					animationName: linkArrowExternalHover,
				},
			},
		},
	});

/**
 * Mixin for aspectRatio not working yet on some browsers
 * @param ratioW Number
 * @param ratioH Number
 */
export const aspectRatio = (ratioW, ratioH) =>
	cssObj({
		aspectRatio: `${ratioW} / ${ratioH}`,

		[`@supports not (aspect-ratio: ${ratioW} / ${ratioH})`]: {
			'&::before': {
				float: 'left',
				paddingTop: `${(ratioH / ratioW) * 100}%`,
				content: '""',
			},

			'&::after': {
				display: 'block',
				content: '""',
				clear: 'both',
			},
		},
	});

/**
 * Handle icons inside buttons when download or target blank
 * @param from initial icon's color (white | black)
 * @param from hover icons's color (white | black)
 * @returns
 */
export const handleIconButton = (from = 'black', to = 'white') =>
	cssObj({
		'&[download], &[target="_blank"], &[data-type="open"], &[data-type="close"]':
			{
				'.supt-button__inner span': {
					paddingRight: '25px !important',

					position: 'relative',
					overflow: 'hidden',
					display: 'flex',
					alignItems: 'center',

					'&::before, &::after': {
						position: 'absolute',
						right: 0,
						paddingTop: '0.1em',
						transition: `all ${transitions.fast} ease`,
					},
				},
			},

		'&[download]': {
			'.supt-button__inner span': {
				'&::before': {
					content: buttonIcons.download[`${from}`],
				},
				'&::after': {
					content: buttonIcons.download[`${to}`],
					transform: 'translateY(-100%)',
					opacity: '0',
				},
			},

			'&:hover, &:focus-within': {
				'.supt-button__inner span': {
					'&::before': {
						transform: 'translateY(100%)',
						opacity: 0,
					},
					'&::after': {
						transform: 'translateY(0%)',
						opacity: 1,
					},
				},
			},
		},
		'&[target="_blank"]': {
			'.supt-button__inner span': {
				'&::before': {
					content: buttonIcons.external[`${from}`],
				},
				'&::after': {
					content: buttonIcons.external[`${to}`],
					transform: 'translate(-100%, 100%)',
					opacity: '0',
				},
			},

			'&:hover, &:focus-within': {
				'.supt-button__inner span': {
					'&::before': {
						transform: 'translate(100%, -100%)',
						opacity: 0,
					},
					'&::after': {
						transform: 'translate(0%, 0%)',
						opacity: 1,
					},
				},
			},
		},
		'&[data-type="open"]': {
			'.supt-button__inner span': {
				'&::before': {
					content: buttonIcons.plus[`${from}`],
				},
				'&::after': {
					content: buttonIcons.plus[`${to}`],
					opacity: '0',
				},
			},

			'&:hover, &:focus-within': {
				'.supt-button__inner span': {
					'&::before': {
						opacity: 0,
					},
					'&::after': {
						opacity: 1,
					},
				},
			},
		},
		'&[data-type="close"]': {
			'.supt-button__inner span': {
				'&::before': {
					content: buttonIcons.minus[`${from}`],
				},
				'&::after': {
					content: buttonIcons.minus[`${to}`],
					opacity: '0',
				},
			},

			'&:hover, &:focus-within': {
				'.supt-button__inner span': {
					'&::before': {
						opacity: 0,
					},
					'&::after': {
						opacity: 1,
					},
				},
			},
		},
	});

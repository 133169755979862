import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import {
	fluidPadding,
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { grid } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import { buttonIcons } from '@/css/variables.css';

const getDynamicStyles = (styles) =>
	cssObj(fluidPadding(styles?.padding), {
		'.supt-googleSearch__title': fontStyle(
			{
				...styles?.title?.typography,
				textAlign: styles?.title?.textAlignment,
				color: styles?.title?.color,
			},
			false
		),
		input: fontStyle(
			{
				...styles?.text.typography,
				textAlign: styles?.text.textAlignment,
				color: styles?.text.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), grid, fluidValues('paddingTop', 100, 160), {
		paddingBottom: '100px',

		'.supt-googleSearch__content': {
			gridColumn: '1 / span 12',

			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '2 / span 10',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '3 / span 8',
			},
			[`@media (min-width: ${breakpoints.bigLaptop})`]: {
				gridColumn: '4 / span 6',
			},

			'.supt-googleSearch__title': {
				marginBottom: '20px',
			},

			'#___gcse_0': {
				'.gsc-control-cse': {
					padding: 0,

					'.gsc-control-wrapper-cse': {
						'.gsc-search-box': {
							marginBottom: '0px',

							'.gsc-input': {
								padding: 0,

								'.gsc-input-box': {
									border: '1px solid #000000',

									// input
									'.gsib_a': {
										padding: '19px',
										position: 'relative',

										'&::before': cssObj({
											position: 'absolute',
											left: '20px',
											content: buttonIcons.search.black,
										}),

										'input.gsc-input': {
											paddingLeft: '26px !important',
										},
									},

									// clear arrow
									'.gsib_b': {
										paddingRight: '15px',

										'.gscb_a': {
											color: '#000000',
										},
									},
								},
							},

							'.gsc-search-button': {
								display: 'none',
							},
						},
					},
				},
			},
		},
	});

const getGlobalStyles = (styles) =>
	cssObj({
		'.gssb_c': {
			// outline: '1px solid red',
			margin: 0,

			'.gssb_e': {
				boxShadow: 'none',
				margin: 0,

				'.gsc-completion-container': {
					margin: 0,
					boxShadow: 'none',
					borderColor: '#000000',
					padding: '20px 44px',
					display: 'block',

					'.gssb_a': cssObj(
						fontStyle(
							{
								...styles?.text.typography,
								textAlign: styles?.text.textAlignment,
								color: styles?.text.color,
							},
							false
						),
						{
							padding: '5px 0px',
						}
					),
				},
			},
		},
	});

export { getStyles, getGlobalStyles };

import {
	List,
	Paragraph,
	Image,
	ListButtons,
} from '@superhuit/starterpack-blocks/renders';

import { ButtonPrimary, ButtonSecondary } from '@/components/atoms/renders';

const blocksList = {
	[ButtonPrimary.slug]: ButtonPrimary,
	[ButtonSecondary.slug]: ButtonSecondary,
	// @ts-ignore
	[Image.slug]: Image,
	[Paragraph.slug]: Paragraph,
	[List.slug]: ({ children, ordered, ...props }) => {
		// this is a hack after update, because the List component was not able to render the children
		return (
			<List
				{...props}
				ordered={ordered}
				values={children?.props?.blocks
					?.map((block) => `<li>${block.attributes.content}</li>`)
					.join('')}
			></List>
		);
	},
	[ListButtons.slug]: ListButtons,
};

export default function AccordionChildren({ blocks }) {
	return (
		<>
			{blocks.map(({ name, ...props }, i) => {
				if (!!blocksList[name]) {
					const Block = blocksList[name];

					return (
						<Block key={i} {...props.attributes} slug={name}>
							{props.innerBlocks && (
								<AccordionChildren blocks={props.innerBlocks} />
							)}
						</Block>
					);
				} else {
					return null;
				}
			})}
		</>
	);
}

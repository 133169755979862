import {
	BoxFluidControl,
	CustomSelectControl,
	ColorControl,
	RadioGroupControl,
	TEXT_ALIGNS,
} from '@superhuit/starterpack-customizer';
import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';
import block from '@superhuit/starterpack-blocks/components/molecules/cards/CardCta/block.json';

export const cardCtaThemeAttributes = {
	minHeight: [221, 380],
	padding: {
		top: [30, 80],
		right: [20, 80],
		bottom: [20, 40],
		left: [20, 80],
	},
	title: {
		color: '@tokens.variants.default.cardTitleColor',
		typography: '@tokens.typographies.heading-2',
		textAlignment: 'center',
	},
	text: {
		color: '@tokens.variants.default.cardContentColor',
		typography: '@tokens.typographies.fresco-s',
		textAlignment: 'center',
	},
	backgroundColors: {
		grey: '@tokens.colors.neutral-200.value',
		pink: '@tokens.colors.brand-quaternary-base.value',
		black: '@tokens.colors.neutral-900.value',
	},
	states: {
		hover: {}, // adds hover state
	},
};

export const CardCtaThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: cardCtaThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return (
			<>
				<h4>Box specs</h4>
				{/* Paddings */}
				<BoxFluidControl
					label="Padding"
					values={fullAttributes?.padding.value}
					default={fullAttributes?.padding.default}
					inheritsFrom={fullAttributes?.padding.default_origin}
					onChange={(value) => updateAttribute('padding', value)}
				/>
				{/* Title */}
				<h4>Title typography</h4>
				<CustomSelectControl
					label="Style"
					options={typographiesOptions}
					default={fullAttributes?.title.typography.default}
					inheritsFrom={
						fullAttributes?.title.typography.default_origin
					}
					value={fullAttributes?.title.typography.value}
					onChange={(value) =>
						updateAttribute('title.typography', value)
					}
				/>
				<RadioGroupControl
					label="Text alignment"
					value={fullAttributes?.title?.textAlignment.value}
					default={fullAttributes?.title?.textAlignment.default}
					options={TEXT_ALIGNS}
					onChange={(value) => {
						updateAttribute('title.textAlignment', value);
					}}
				/>
				<ColorControl
					label="Color"
					default={fullAttributes?.title?.color.default}
					inheritsFrom={fullAttributes?.title?.color.default_origin}
					value={fullAttributes?.title?.color.value}
					onChange={(value) => updateAttribute('title.color', value)}
					theme={theme}
					type="color"
				/>
				{/* Text */}
				<h4>Text typography</h4>
				<CustomSelectControl
					label="Style"
					options={typographiesOptions}
					default={fullAttributes?.text.typography.default}
					inheritsFrom={
						fullAttributes?.text.typography.default_origin
					}
					value={fullAttributes?.text.typography.value}
					onChange={(value) =>
						updateAttribute('text.typography', value)
					}
				/>
				<RadioGroupControl
					label="Text alignment"
					value={fullAttributes?.text?.textAlignment.value}
					default={fullAttributes?.text?.textAlignment.default}
					options={TEXT_ALIGNS}
					onChange={(value) => {
						updateAttribute('text.textAlignment', value);
					}}
				/>
				<ColorControl
					label="Color"
					default={fullAttributes?.text?.color.default}
					inheritsFrom={fullAttributes?.text?.color.default_origin}
					value={fullAttributes?.text?.color.value}
					onChange={(value) => updateAttribute('text.color', value)}
					theme={theme}
					type="color"
				/>
			</>
		);
	},
};

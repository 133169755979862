import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { fluidValues } from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { gridColumnGap } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';

const getCustomStyles = () =>
	cssObj({
		'.supt-listCards--list-shop': {
			margin: 0,

			'.supt-listCards__list': cssObj(
				gridColumnGap,
				fluidValues('gridRowGap', 38, 78),
				{
					display: 'grid',
					gridTemplateColumns: '1fr',

					[`@media (min-width: ${breakpoints.small})`]: {
						gridTemplateColumns: '1fr 1fr',
					},
					[`@media (min-width: ${breakpoints.bigLaptop})`]: {
						gridTemplateColumns: '1fr 1fr 1fr',
					},
				}
			),
		},
	});

export { getCustomStyles };

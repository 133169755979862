import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { resetButton } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { getLinkStyle } from '@/css/placeholders.css';
// Resources
// import { } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { transitions } from '@superhuit/starterpack-blocks/css/resources/variables.css';
// import { } from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getStyles = () =>
	css({
		'.supt-headerPracticalInfo__title': cssObj({
			...resetButton,
		}),

		'.supt-headerPracticalInfo__icon': {
			display: 'inline-block',
			transformOrigin: 'center center',
			strokeWidth: '2px',
			transition: `transform ${transitions.fast} ease-in-out`,
			stroke: '#000',
			margin: '0 8px 2px 0',
		},

		'.supt-headerPracticalInfo__panel': {
			position: 'relative',
			zIndex: 1,
			maxHeight: '0vh',
			overflow: 'hidden',
			visibility: 'hidden',

			'.supt-headerPracticalInfo__panelInner': {
				padding: '5px 0 15px',
			},

			'.supt-headerPracticalInfo__content': {
				borderTop: '1px solid rgba(0, 0, 0, 0.2)',
				borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
				padding: '15px',

				a: getLinkStyle(),
			},
		},

		'&.supt-headerPracticalInfo--is-open': cssObj({
			'.supt-headerPracticalInfo__icon': {
				transform: 'rotate(180deg)',
			},
			'.supt-headerPracticalInfo__panel': {
				maxHeight: 'none',
				overflow: 'auto',
				visibility: 'visible',
			},
		}),
	});

// edit
const getEditGlobalStyles = () =>
	cssObj({
		'.wp-block[data-type="supt/header-practical-info"]': cssObj({
			margin: 0,

			'.supt-headerPracticalInfo__title': {
				display: 'flex',
				alignItems: 'center',

				h4: {
					fontWeight: '400',
					margin: 0,
				},
			},

			'.supt-headerPracticalInfo__panel': {
				'.supt-headerPracticalInfo__content': {
					margin: 0,

					a: {
						color: '#000',
					},
				},
			},
		}),
	});

export { getStyles, getEditGlobalStyles };

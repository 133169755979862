import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

// Resources
import {
	fluidPadding,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import { gridNoMargin } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
import { breakpoints } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import { linkNoUnderlineWithHover } from '@/css/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj(
		fluidPadding(styles?.padding),
		fontStyle(
			{
				...styles?.content?.typography,
				textAlign: styles?.content?.textAlignment,
				color: styles?.content?.color,
			},
			false
		),
		{}
	);

const getStyles = (styles) =>
	css(getDynamicStyles(styles), gridNoMargin, {
		position: 'relative',
		borderTop: '1px solid #000',
		borderBottom: '1px solid #000',

		[`@media (min-width: ${breakpoints.laptop})`]: {
			alignItems: 'center',
		},

		'.supt-cardDateTour__date': {
			gridColumn: '1 / span 12',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '1 / span 8',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '1 / span 3',
			},
		},

		'.supt-cardDateTour__city': {
			gridColumn: '1 / span 12',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '1 / span 8',
			},
			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '1 / span 9',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '4 / span 3',
				display: 'flex',
			},
		},

		'.supt-cardDateTour__location': {
			gridColumn: '1 / span 12',

			[`@media (min-width: ${breakpoints.tablet})`]: {
				gridColumn: '1 / span 8',
				gridRow: 'auto',
			},
			[`@media (min-width: ${breakpoints.medium})`]: {
				gridColumn: '1 / span 9',
			},
			[`@media (min-width: ${breakpoints.laptop})`]: {
				gridColumn: '7 / span 5',
				marginTop: 0,
			},

			'.supt-cardDateTour__location__link': linkNoUnderlineWithHover,
		},

		'& + .supt-cardDateTour': {
			borderTopWidth: 0,
		},
	});

export { getStyles };

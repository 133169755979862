import { ThemeControlsType } from '@superhuit/starterpack-blocks/utils/typings';

import block from './block.json';

export const subsectionQuoteThemeAttributes = {
	// margin: {
	// 	top: [24, 48],
	// 	bottom: [24, 48],
	// },
	// grid: {
	// 	gap: [16, 32],
	// },
	// title: {
	// 	color: '@tokens.variants.default.titleColor',
	// 	typography: '@tokens.typographies.heading-6',
	// 	textAlignment: 'left',
	// },
};

export const SubsectionQuoteThemeControls: ThemeControlsType = {
	// What's displayed
	title: block.title,

	// Unique string/id of the block (= the key used in theme.json)
	slug: block.slug,

	// styling attributes available for this block
	// ...the same way we define attributes for a gutenberg block
	attributes: subsectionQuoteThemeAttributes,

	// this renders in the Customizer panel
	// ...the same way "<InspectorControls />" renders in the gutenberg panel
	edit: ({ fullAttributes, updateAttribute, theme, typographiesOptions }) => {
		return <></>;
	},
};

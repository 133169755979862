import cx from 'classnames';
import React, { FC } from 'react';

import {
	BlockConfigs,
	SectionOneColumnProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { SectionAccordion } from '../SectionAccordion/render';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { getStyles as getDfltStyles } from '@superhuit/starterpack-blocks/components/organisms/Sections/SectionOneColumn/styles.css';

import block from './block.json';
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
type SectionSubscriptionProps = {
	info?: string;
} & SectionOneColumnProps;

/**
 * COMPONENT
 */
export const SectionSubscription: FC<SectionSubscriptionProps> &
	BlockConfigs = ({ info, children, ...props }) => {
	const { variant, blockTheme } = useThemeBlock(
		block.slug,
		props.theme,
		props.variant
	);

	const rootClass = cx(
		'supt-section--colored',
		getDfltStyles(blockTheme),
		getStyles(blockTheme)
	);

	return (
		<SectionAccordion {...props} isOpened={true} className={rootClass}>
			{info ? (
				<p
					className="supt-section__info"
					dangerouslySetInnerHTML={{ __html: info }}
				></p>
			) : null}
			{children}
		</SectionAccordion>
	);
};

SectionSubscription.slug = block.slug;
SectionSubscription.title = block.title;

import { css } from '@emotion/css';

const getStyles = () =>
	css({
		fontFamily: 'Arial, sans-serif',
		position: 'fixed',
		zIndex: 9999999,
		bottom: 0,
		left: 0,
		right: 0,
		gap: '20px',
		alignItems: 'center',
		width: '100%',
		backgroundColor: '#fff',
		fontSize: '13px',
		fontWeight: 600,
		lineHeight: 1.2,
		letterSpacing: 0,
		color: '#000',
		padding: '12px 20px',
		boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.16)',
		display: 'flex',
		justifyContent: 'space-between',

		'.supt-alert-toolbar__button': {
			fontWeight: 'bold',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center',
			padding: '8px 20px',
			cursor: 'pointer',
			backgroundColor: 'transparent',
			transition: 'background-color 0.2s ease-out',
			border: '1px solid #757575',
			borderRadius: '16px',

			'&:hover': {
				backgroundColor: 'lightgray',
			},
		},
	});

export { getStyles };

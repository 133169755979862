import React, { FC, forwardRef } from 'react';
import cx from 'classnames';

import { Link } from '@/components/atoms/Link/render';

import { SecondarySubmenuProps } from '../typings';

export const SecondarySubmenu: FC<SecondarySubmenuProps> = forwardRef(
	({ items, uri }, ref: any) => {
		return (
			<div className="supt-mainNav__secondary__submenu" ref={ref}>
				<nav className="supt-mainNav__secondary__submenu__nav">
					{items.map(({ id, label, path, cssClasses, target }) => {
						return (
							<Link
								key={id}
								href={path ?? '#'}
								suppressHydrationWarning
								className={cx(
									'supt-mainNav__secondary__submenu__link',
									...cssClasses,
									{
										isCurrent:
											uri === path || uri.includes(path),
									}
								)}
								aria-current={
									uri === path || uri.includes(path)
										? 'page'
										: null
								}
								target={target}
							>
								<span className="supt-mainNav__secondary__submenu__link__icon"></span>
								<span className="supt-mainNav__secondary__submenu__link__label">
									{label}
								</span>
							</Link>
						);
					})}
				</nav>
			</div>
		);
	}
);
